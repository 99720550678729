import * as angular from 'angular';
import documentHighlightComponent from './document.highlight.component';

const dependencies = [

];
const documentHighlight = angular.module('app.shared.documents.documentContentEditor.documentViewer.submodules.annotations.documentHighlight', dependencies)
    .component('highlight', documentHighlightComponent);

export default documentHighlight;
