import * as angular from 'angular';
import documentSelectionComponent from './document.selection.component';


const dependencies = [

];
const documentSelection = angular.module('app.shared.documents.documentContentEditor.documentViewer.submodules.primitives.documentSelection', dependencies)
    .component('documentSelection', documentSelectionComponent);

export default documentSelection;
