export default {
    EDIT_MODE_SET: 'setEditMode',
    ANNOTATIONS_DISABLE: 'disableAnnotations', // Make annotations non-interactive
    ANNOTATIONS_ENABLE: 'enableAnnoations', // Make annotations clickable / interactive
    SELECT: 'select',
    DESELECT: 'deselect',
    PAGE_NAVIGATE_TO: 'navigateToPage',
    PAGE_SCROLLED_TO: 'pageScrolled', // Indicates a scroll has happened to a page
    PAGE_MOUSEDOWN: 'pageMouseDown',
    PAGE_MOUSEMOVE: 'pageMouseMove',
    PAGE_MOUSEUP: 'pageMouseUp',
    PAGE_RESIZE: 'pageResize',
    PAGE_ROTATE: 'pageRotate'
};
