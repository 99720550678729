import * as angular from 'angular';
import signatureFormFieldComponent from './signature.form.field.component';

const dependencies = [];

const signatureFormField = angular
    .module('app.signatureFormField', dependencies)
    .component('signatureFormField', signatureFormFieldComponent);

export default signatureFormField;
