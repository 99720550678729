import template from './document.highlight.html';

const documentHighlightComponent: ng.IComponentOptions = {
    template,
    controller: () => {
        return undefined;
    },
    controllerAs: 'vm',
    bindings: {
        /**
         * @type { object } data
         * @type { number } data.x
         * @type { number } data.y
         * @type { number } data.w
         * @type { number } data.h
         * @type { string } data.color
         * @type { number} data.opacity
         */
        data: '<',
        /**
         * @type {boolean} Whether the object can receive mouse events
         */
        interactive: '<?',
        /**
         * @type {object} Page - the page this annotation appears on
         */
        page: '<',
        isSelected: '<',
        onSelect: '&'
    }
};

export default documentHighlightComponent;
