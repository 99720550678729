import Events from '../../../constants/document.content.events';

class SelectionToolController {
    constructor($scope, $window) {
        this._$scope = $scope;
        this._$window = $window;
        this.selection = null;
        this.active = false;
    }

    $onInit(): void {
        this._$scope.$on(Events.PAGE_MOUSEDOWN, this._onPageMousedown.bind(this));
        this._$scope.$on(Events.PAGE_MOUSEMOVE, this._onPageMousemove.bind(this));
        this._$scope.$on(Events.PAGE_MOUSEUP, this._onPageMouseup.bind(this));
        this._globalMouseupListener = this._onGlobalMouseup.bind(this);
    }

    $onDestroy(): void {
        this._removeGlobalMouseupListener();
    }

    _activateTool() {
        if (this.active) {
            return;
        }

        this.active = true;
        this._$scope.$emit(Events.ANNOTATIONS_DISABLE);
    }

    _deactivateTool() {
        if (!this.active) {
            return;
        }

        this.active = false;
        this._$scope.$emit(Events.ANNOTATIONS_ENABLE);
    }

    _addGlobalMouseupListener() {
        this._$window.addEventListener('mouseup', this._globalMouseupListener);
    }

    _removeGlobalMouseupListener() {
        this._$window.removeEventListener('mouseup', this._globalMouseupListener);
    }

    _onGlobalMouseup() {
        this._removeGlobalMouseupListener();
        this._handleSelect();
        this._deactivateTool();
    }

    _onPageMousedown(event, data) {
        if (data.page.id !== this.page.id) {
            return;
        }
        this._addGlobalMouseupListener();
        this._activateTool();
        this._handleSelectionStart(event, data);
    }

    _onPageMouseup(event, data) {
        if (data.page.id !== this.page.id || !this.selection) {
            return;
        }
        this._handleSelectionModify(event, data);
        this._handleSelect();
        this._deactivateTool();
    }

    _onPageMousemove(event, data) {
        if (data.page.id !== this.page.id || !this.selection) {
            return;
        }
        this._handleSelectionModify(event, data);
    }

    _handleSelectionStart(event, { x, y }) {
        this.selection = {
            start: { x, y },
            end: { x, y }
        };
    }

    _handleSelectionModify(event, { x, y }) {
        this.selection.end.x = x;
        this.selection.end.y = y;
    }

    _handleSelect() {
        if (!this.selection) {
            return;
        }

        // Extract what we need and null out the selection
        const coordinates = this._getRectangleFromSelection();
        this.selection = null;

        // Calculate the actual size of the selection so consumers of this
        // tool can decide whether to block the creation of annotations that are too small
        const pageBounds = this.page.element.getBoundingClientRect();
        const pixelDimensions = {
            width: pageBounds.width * coordinates.w,
            height: pageBounds.height * coordinates.h
        };

        this._$scope.$emit(Events.SELECT, { coordinates, pixelDimensions });
    }

    _getRectangleFromSelection() {
        if (!this.selection) {
            return {
                x: 0,
                y: 0,
                w: 0,
                h: 0
            };
        }

        const { x: startX, y: startY } = this.selection.start;
        const { x: endX, y: endY } = this.selection.end;
        return {
            x: Math.min(startX, endX),
            y: Math.min(startY, endY),
            w: Math.abs(endX - startX),
            h: Math.abs(endY - startY)
        };
    }
}

SelectionToolController.$inject = ['$scope', '$window'];

export default SelectionToolController;
