import controller from './navbar.controller';
import template from './navbar.html';
import './navbar.scss';

const navbarComponent: ng.IComponentOptions = {
    controller,
    controllerAs: 'vm',
    template,
    bindings: {
        currentUser: '<',
        currentTeam: '<',
        hasMultipleTeams: '<'
    }
};

export default navbarComponent;
