import * as angular from 'angular';
import * as _ from 'lodash';

class DocumentViewerPageEventService {
    constructor($window, DocumentViewerRotation) {
        this._$window = $window;
        this._DocumentViewerRotation = DocumentViewerRotation;
    }

    getPageDataFromEvent($event) {
        const pageEl = this._getPageElementFromEvent($event);
        if (!pageEl) {
            return {};
        }

        const {
            width,
            height,
            left,
            top
        } = pageEl.getBoundingClientRect();

        return this._DocumentViewerRotation.rotateCoordinates({
            width,
            height,
            x: $event.pageX - (left + this._$window.pageXOffset),
            y: $event.pageY - (top + this._$window.pageYOffset)
        });
    }

    _getPageElementFromEvent($event) {
        const isPageElement = ($el) => !_.isUndefined($el[0].dataset.pageIndex);
        let pageElement = angular.element($event.target);

        while (!isPageElement(pageElement) && pageElement.length) {
            // Check if we've found the page wrapper, grab the child page element and return it
            if (pageElement.hasClass('document-viewer-page-wrapper')) {
                const images = pageElement.find('img');
                return images.length ? images[0] : null;
            }
            pageElement = pageElement.parent();
        }

        return pageElement.length ? pageElement[0] : null;
    }
}

DocumentViewerPageEventService.$inject = [
    '$window',
    'DocumentViewerRotation'
];

export default DocumentViewerPageEventService;
