import { SigningReasons } from './signature.model';
import { Page } from './page.model';

export interface AnnotationStyles {
    fontFamily: string;
    fontSize: string;
    minHeight?: string;
    minWidth?: string;
    lineHeight: number | string;
    backgroundColor: string;
    color: string;
    padding: string;
}

// eslint-disable-next-line import/prefer-default-export
export enum AnnotationType {
    'add_text',
    'redaction',
    'highlight',
    'stamp',
    'signature',
    'timestamp'
}

export interface Annotation {
    textValue: string;
    backgroundColor: string;
    opacity: string;
    color: string;
    fontSize: number;
    font: string;
    lineHeight: number;
    id: string;
    type: keyof typeof AnnotationType;
    x: number;
    y: number;
    w: number;
    h: number;
    rotation: number;
    page: Page;
    resizable: boolean;
    propagatedInstances?: {
        deletedByPageIndex: {
            [key: number]: boolean;
        };
        modifiedByPageIndex: {
            [key: number]: Annotation;
        };
    };
    isPropagated?: boolean;
    reason?: SigningReasons;
}
