import * as _ from 'lodash';
import { DownloadCheckerService } from '@app/shared/downloads/download-checker.service';
import { Download, Crumb } from '@app/shared/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { StateService } from '@uirouter/angular';

class DownloadsIndexController {
    private readonly destroy$ = new Subject<boolean>();
    public allSelected = false;
    public loadedDownloads = false;
    public crumbs: Crumb[] = [{ name: 'My Downloads' }];

    constructor(
        $scope,
        $filter,
        SORT,
        Downloads,
        MultiSelect,
        CurrentSession,
        ApiError,
        private Notifications: NotificationsService,
        private $state: StateService,
        private DownloadChecker: DownloadCheckerService
    ) {
        this._$scope = $scope;
        this._$filter = $filter;
        this.SORT = SORT;
        this._Downloads = Downloads;
        this._MultiSelect = MultiSelect;
        this._CurrentSession = CurrentSession;
        this._ApiError = ApiError;

        this.selectedItems = [];
    }

    $onInit(): void {
        const { select, selectAll } = this._MultiSelect.makeMultiSelect();
        this.multiSelect = select;
        this.selectAllItems = selectAll;
        this.currentTeam = this._CurrentSession.getCurrentTeam();

        this.getDownloads();

        this.DownloadChecker.updatedDownload$
            .pipe(takeUntil(this.destroy$))
            .subscribe((updatedDownload: Download) => {
                const { id, status } = updatedDownload || {};
                if (!id) {
                    return;
                }
                const download = _.find(this.downloads, { id });
                download.status = status;
            });
    }

    $onDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getDownloads(): void {
        this.loadedDownloads = false;
        this._Downloads
            .getDownloads(this.currentTeam.id)
            .toPromise()
            .then((downloads) => {
                this.downloads = downloads;
                this.visibleDownloads = this.downloads;
                this.loadedDownloads = true;
                this.updateSort('createdAt', true);
            });
    }

    updateSort(sortName, isReversed): void {
        this.SORT.set(sortName, isReversed);
        this.visibleDownloads = this._$filter('orderBy')(this.visibleDownloads, sortName, this.SORT.isReversed, this.SORT.naturalSort);
    }

    selectAll(): void {
        this.selectedItems = this.selectAllItems(this.visibleDownloads, true);
        this.allSelected = true;
    }

    selectNone(): void {
        this.selectedItems = this.selectAllItems(this.visibleDownloads, false);
        this.allSelected = false;
    }

    select(event, index): void {
        this.selectedItems = this.multiSelect(event, index, this.visibleDownloads);
        this.allSelected = this.selectedItems.length === this.visibleDownloads.length;
    }

    toggleActions($event, $index, download): void {
        $event.stopPropagation();
        if (!download.selected) {
            this.selectNone();
            this.select($event, $index);
        }
    }

    openDownload($event, download): void {
        $event.stopPropagation(); // preventing row to be selected when download icon is clicked
        if (download.status === 'Pending' || download.status === 'Failed') {
            return;
        }
        this._Downloads.downloadItem(this.currentTeam.id, download);
    }

    deleteDownloads(): void {
        if (!this.selectedItems.length) {
            return;
        }

        this._Downloads.destroy(this.currentTeam.id, this.selectedItems)
            .toPromise()
            .then(() => {
                this.selectedItems.forEach(({ id }) => {
                    _.remove(this.downloads, { id });
                    _.remove(this.visibleDownloads, { id });
                });
                this.selectedItems = [];
                this.getDownloads();
                this.Notifications.success('Downloads deleted.');
            })
            .catch(this._ApiError.handleError);
    }

    onFilterChanged(filter): void {
        this.selectNone();
        this.visibleDownloads = this._$filter('filter')(this.downloads, { filename: filter });
        this.updateSort(this.SORT.by, this.SORT.isReversed);
    }
}

DownloadsIndexController.$inject = [
    '$scope',
    '$filter',
    'SORT',
    'Downloads',
    'MultiSelect',
    'CurrentSession',
    'ApiError',
    'Notifications',
    '$state',
    'DownloadChecker'
];

export default DownloadsIndexController;
