import uuid from 'uuid';
import AnnotationType from '../../../constants/annotation.type';
import Actions from '../../../constants/document.content.actions';
import Events from '../../../constants/document.content.events';
import ToolMode from '../../../constants/tool.mode';

class RedactToolController {
    constructor($scope, DocumentViewerToolsOptions) {
        this._$scope = $scope;
        this._DocumentViewerToolsOptions = DocumentViewerToolsOptions;

        this.minPixels = 5;
    }

    $onInit(): void {
        this.page.element.style.cursor = 'crosshair';
        this._$scope.$on(Events.SELECT, this._onSelect.bind(this));
    }

    $onDestroy(): void {
        this.page.element.style.cursor = 'auto';
    }

    _onSelect($event, { pixelDimensions, coordinates }) {
        $event.stopPropagation();

        if (pixelDimensions.width < this.minPixels || pixelDimensions.height < this.minPixels) {
            // Selection is too small - don't create annotation
            return;
        }

        const annotation = {
            ...this._DocumentViewerToolsOptions.getOptionValuesFor(ToolMode.REDACT),
            id: uuid.v4(),
            type: AnnotationType.redaction,
            ...coordinates,
            page: this.page
        };
        this._$scope.$emit(Actions.ANNOTATION_CREATE, { annotation });
    }
}

RedactToolController.$inject = [
    '$scope',
    'DocumentViewerToolsOptions'
];

export default RedactToolController;
