import './dashboard-timelines.scss';
import template from './dashboard-timelines.html';
import controller from './dashboard-timelines.controller';

const dashboardTimelinesComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        $transition$: '<'
    }
};

export default dashboardTimelinesComponent;
