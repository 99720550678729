import {
    AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StateService } from '@uirouter/core';
import {
    BehaviorSubject,
    Observable,
    Subject,
    of
} from 'rxjs';
import {
    catchError, startWith, take, tap
} from 'rxjs/operators';
import { MESSAGES, ROUTES } from '@app/core/constants';
import { HttpErrorResponse } from '@angular/common/http';
import {
    DoaLogTemplateCreateForm,
    DOA_LOG_TEMPLATE_STEP_FORM_CONTROL_NAMES,
    DOA_LOG_TEMPLATE_STEP_NAMES
} from '../doa-log-template.types';
import {
    CreateLogTemplateRequestBody,
    StudyResponsibilities,
    StudyRole,
    Team
} from '../../../../../shared/models';
import template from './doa-log-template-create-form.component.html';
import styles from './doa-log-template-create-form.component.scss';
import { CurrentSessionService } from '../../../../../core/current-session.service';
import { LogTemplatesService } from '../../../../../shared/log-templates/log-templates.service';
import { LogTemplateType } from '../../log-template-type-selector/log-template-type-selector.component.types';
import { NotificationsService } from '../../../../../core/notifications/notifications.service';
import { LogTemplateUtil } from '../../../utils/log-template.util';
import { ModalRef, ModalsService } from '../../../../../shared/modal-helper/modals.service';
import { ConfirmationModal } from '../../../../../shared/modals/confirmation-modal/confirmation-modal';
import { StudyRolesService } from '../../../../../shared/study-roles/study-roles.service';

@Component({
    selector: 'doa-log-template-create-form',
    template,
    styles: [String(styles)],
    providers: [ModalsService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoaLogTemplateCreateFormComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(
        private $state: StateService,
        private formBuilder: FormBuilder,
        private currentSessionService: CurrentSessionService,
        private logTemplatesService: LogTemplatesService,
        public studyRolesService: StudyRolesService,
        private notificationsService: NotificationsService,
        private modalsService: ModalsService
    ) {}

    doaLogTemplateForm = this.formBuilder.group({});
    currentTeam: Team;

    readonly stepNames = {
        logDetails: DOA_LOG_TEMPLATE_STEP_NAMES.LOG_DETAILS,
        studyResponsibilities: DOA_LOG_TEMPLATE_STEP_NAMES.STUDY_RESPONSIBILITIES,
        studyRoles: DOA_LOG_TEMPLATE_STEP_NAMES.STUDY_ROLES,
        createTemplate: DOA_LOG_TEMPLATE_STEP_NAMES.CREATE_TEMPLATE
    };

    readonly studyResponsibilitiesFormControlName = DOA_LOG_TEMPLATE_STEP_FORM_CONTROL_NAMES.STUDY_RESPONSIBILITIES;
    readonly studyRolesFormControlName = DOA_LOG_TEMPLATE_STEP_FORM_CONTROL_NAMES.STUDY_ROLES;
    private readonly entityName = 'DOA log template';

    studyResponsibilities$: Observable<StudyResponsibilities>;
    studyRoles$: Observable<StudyRole[]>;

    get studyResponsibilitiesFormGroup(): FormGroup {
        return <FormGroup> this.doaLogTemplateForm.get(this.studyResponsibilitiesFormControlName);
    }

    get studyRolesFormGroup(): FormGroup {
        return <FormGroup> this.doaLogTemplateForm.get(this.studyRolesFormControlName);
    }

    private isSubmittingForm = new BehaviorSubject(false);
    isSubmittingForm$ = this.isSubmittingForm.asObservable();

    private readonly destroy$ = new Subject<void>();

    ngOnInit(): void {
        this.currentTeam = this.currentSessionService.getCurrentTeam();
        this.studyRolesService.setStudyRolesList$.subscribe();
    }

    ngAfterViewInit(): void {
        this.syncStudyResponsibilityChangesToStudyRoles();
        this.syncStudyRolesChangesToStudyResponsibilities();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onCancel(): void {
        if (this.doaLogTemplateForm.pristine) {
            this.$state.go(ROUTES.doaLogs);
            return;
        }
        const unsavedChangesModal = this.openUnsavedChangesModal();

        unsavedChangesModal.content.handleConfirm = () => {
            this.modalsService.hide(1);
            this.$state.go(ROUTES.doaLogs);
        };
    }

    onSaveDraft(): void {
        // TODO: add save draft logic here
    }

    onCreateLogTemplate(): void {
        const createDoaLogTemplateForm = <DoaLogTemplateCreateForm> this.doaLogTemplateForm.getRawValue();
        const postLogTemplateRequestBody = this.formatCreateLogTemplateRequestBody(createDoaLogTemplateForm);

        this.isSubmittingForm.next(true);

        this.logTemplatesService.createLogTemplate(
            this.currentTeam.id,
            postLogTemplateRequestBody
        ).pipe(
            tap(() => {
                this.onFormSubmittedSuccessfully();
            }),
            catchError((httpErrorResponse: HttpErrorResponse) => {
                this.onFormSubmissionError(httpErrorResponse);
                return of(null);
            }),
            take(1)
        ).subscribe();
    }

    private openUnsavedChangesModal(): ModalRef<ConfirmationModal> {
        const unsavedChangesModal = this.modalsService.show(ConfirmationModal, {
            animated: true,
            initialState: {
                showModalCloseButton: true,
                confirmationTitle: 'Unsaved Template Alert',
                confirmationMessage: `It seems you're about to exit without saving your template. 
                    Be aware that if you continue, your template will not be saved.`,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Continue without saving'
            }
        });

        return unsavedChangesModal;
    }

    private formatCreateLogTemplateRequestBody(
        doaLogTemplateCreateForm: DoaLogTemplateCreateForm
    ): CreateLogTemplateRequestBody {
        const { studyResponsibilities, studyRoles, createTemplate } = doaLogTemplateCreateForm;
        const responsibilitiesObject = studyResponsibilities.responsibilities;

        const responsibilityIdNameDictionary = LogTemplateUtil.generateResponsibilityIdNameDictionary(
            responsibilitiesObject.values
        );

        const logTemplateStudyResponsibilitiesToCreate = LogTemplateUtil.formatResponsibilitiesToCreate(responsibilitiesObject);

        const logTemplateStudyRolesToCreate = LogTemplateUtil.formatStudyRolesToCreate(
            studyRoles.roles,
            responsibilityIdNameDictionary
        );

        const { values: studyResponsibilityNames } = logTemplateStudyResponsibilitiesToCreate;
        const studyRoleIds = logTemplateStudyRolesToCreate.map(({ studyRole }) => studyRole);

        const logColumnsToCreate = LogTemplateUtil.formatLogColumnsToCreate(
            createTemplate.columns,
            studyResponsibilityNames,
            studyRoleIds
        );

        const postLogTemplateRequestBody = {
            ...doaLogTemplateCreateForm.logDetails,
            ...doaLogTemplateCreateForm.createTemplate,
            type: LogTemplateType.DOA,
            columns: logColumnsToCreate,
            responsibilities: logTemplateStudyResponsibilitiesToCreate,
            roles: logTemplateStudyRolesToCreate
        };

        return postLogTemplateRequestBody;
    }

    private syncStudyResponsibilityChangesToStudyRoles(): void {
        this.studyResponsibilities$ = this.studyResponsibilitiesFormGroup?.controls.responsibilities.valueChanges.pipe(
            startWith(this.studyResponsibilitiesFormGroup.value)
        );
    }

    private syncStudyRolesChangesToStudyResponsibilities(): void {
        this.studyRoles$ = this.studyRolesFormGroup?.controls.roles.valueChanges.pipe(
            startWith(this.studyRolesFormGroup.value.roles)
        );
    }

    private onFormSubmittedSuccessfully(): void {
        const successMessage = MESSAGES.successfullyCreated(this.entityName);

        this.notificationsService.success(successMessage);
        this.isSubmittingForm.next(false);
        this.$state.go(ROUTES.doaLogs, { logType: LogTemplateType.DOA });
    }

    private onFormSubmissionError(httpErrorResponse: HttpErrorResponse): void {
        const { error } = httpErrorResponse;
        const isBadRequest = error.statusCode >= 400 && error.statusCode < 500;
        const errorMessage = isBadRequest
            ? error.message
            : MESSAGES.failedToCreate(this.entityName);

        this.notificationsService.error(errorMessage);
        this.isSubmittingForm.next(false);
    }
}
