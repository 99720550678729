import { StateService } from '@uirouter/angularjs';
import { BehaviorSubject } from 'rxjs';

import {
    Crumb,
    Team,
    TeamPermissions,
    AuditTrailSubject,
    Study,
    TeamSipIntegrationLink
} from '@app/shared/models';
import { ModalHelperService } from '@app/shared/modal-helper/modal-helper.service';
import { TeamService } from '@app/shared/teams/team.service';
import { AuditTrailService } from '@app/shared/audit-trail/audit-trail.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { StudiesService } from '@app/shared/studies/studies.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { ConfirmDestroySubmitEvent } from '@app/widgets/confirm-destroy/confirm-destroy.component.types';
import { AuditTrailModalItem, AuditTrailTypes } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component.types';
import ApiErrorFactory from '@app/shared/api-error/api-error.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { AuditTrailModalComponent } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { filter, tap } from 'rxjs/operators';
import { SaveParams as SaveTeamSipLinkParams } from '../../components/team-sip-link-create/team-sip-link-create.component';
import { TeamSipLinksService } from '../../team-sip-links.service';
import { SingleSignOnService } from '../../single-sign-on.service';
import { TeamSettingsTab, TeamSettingsTabs } from './manage-team-settings.types';
import { UpdateEvent } from '../../components/manage-team-security/manage-team-security.component';

class ManageTeamSettingsController {
    team: Team;
    crumbs: Crumb[] = [{ name: 'Manage Team Settings' }];
    activeTabIndex: number;
    private studiesRes = new BehaviorSubject<{ items: Study[]; recordCount: number }>(undefined);
    studiesRes$ = this.studiesRes.asObservable();
    private teamSipLinksRes = new BehaviorSubject<TeamSipIntegrationLink[]>(undefined);
    teamSipLinksRes$ = this.teamSipLinksRes.asObservable();
    createdTeamSipLinkId: string | undefined;
    teamSecurityUpdateLoading = false;
    teamTimeoutPeriodUpdateLoading = false;
    teamMfaUpdateLoading = false;
    studyProfileEntryTeamSettingLocation = true

    public tabs: TeamSettingsTabs = {
        settings: {
            state: 'app.team.manage-team-settings',
            index: 1,
            permCheck: 'canAccessManageTeamProfile'
        },
        labels: {
            state: 'app.team.manage-team-labels',
            index: 2,
            permCheck: 'canAccessLabels'
        },
        sso: {
            state: 'app.team.manage-team-sso',
            index: 3,
            permCheck: 'canAcessSingleSignOn'
        },
        studies: {
            state: 'app.team.manage-team-studies',
            index: 4,
            onLoad: 'loadTeamStudies',
            permCheck: 'canAcessTeamStudies'
        },
        teamSipLinks: {
            state: 'app.team.manage-team-sip-links',
            index: 5,
            onLoad: 'loadTeamSipLinks',
            permCheck: 'canAccessManageTeamSipLinks'
        },
        security: {
            state: 'app.team.manage-security',
            index: 6,
            permCheck: 'canAccessSecurityTab'
        }
    };

    constructor(
        private Teams: TeamService,
        private TeamSipLinks: TeamSipLinksService,
        private AuditTrail: AuditTrailService,
        private CurrentSession: CurrentSessionService,
        private $state: StateService,
        private SingleSignOn: SingleSignOnService,
        private Studies: StudiesService,
        private Notifications: NotificationsService,
        private modalHelper: ModalHelperService,
        private ApiError: ApiErrorFactory,
        private Modals: ModalsService,
        private FeatureFlags: FeatureFlagService
    ) { }

    $onInit(): void {
        this.getStudyProfileEntryFlag();
        this.team = this.CurrentSession.getCurrentTeam();
        this.crumbs = [{ name: `Manage Team Settings: ${this.team.name}` }];
        const perm = this.team ? this.team.permissions : {} as TeamPermissions;
        if (!(perm.manageTeam || perm.viewTeamProfile)) {
            this.$state.go('app.select-team');
            return;
        }
        this.initActiveTab(this.$state.current.name);
        this.generateTabsHref();
    }

    getStudyProfileEntryFlag() {
        this.FeatureFlags.getFlag(FEATURE_FLAGS.STUDY_PROFILE_SIDEMENU_ENTRY, false).pipe(
            filter((flag) => {
                return flag !== undefined;
            })
        ).subscribe((value) => {
            this.studyProfileEntryTeamSettingLocation = !value;
        });
    }

    initActiveTab(stateName): void {
        const tab = this.getViewableTab(stateName) || this.getViewableTab();
        if (!tab) {
            this.$state.go('app.select-team');
            return;
        }
        this.setActiveTab(tab);
    }

    getViewableTab(stateName?: string): TeamSettingsTab {
        return Object.values(this.tabs).find(({ state, permCheck }: TeamSettingsTab) => {
            if (stateName) {
                return state === stateName && this[permCheck]();
            }
            return this[permCheck]();
        });
    }

    setActiveTab(tab: TeamSettingsTab): void {
        if (this.activeTabIndex === tab.index) {
            return;
        }
        this.activeTabIndex = tab.index;
        tab.onLoad && this[tab.onLoad]();
        this.$state.transitionTo(tab.state, { teamId: this.team.id }, { location: 'replace' });
    }

    openAuditTrailModal(): void {
        if (this.team.permissions.viewTeamAuditTrail) {

            const auditItem: AuditTrailModalItem = {
                id: this.team.id,
                teamId: this.team.teamId,
                permissions: this.team.permissions,
                type: this.team.type as AuditTrailTypes,
                name: this.team.name

            };
            const params = {
                subject: AuditTrailSubject.TEAM_PROFILE,
                teamId: auditItem.teamId,
                objectId: auditItem.id,
                overwrittenObjectId: null,
                limitToOverwritten: false,
                ...this.AuditTrail.auditPagination
            };

            this.AuditTrail.getAudits(params).toPromise().then((audits) => {
                this.Modals.show(AuditTrailModalComponent, {
                    class: 'modal-lg',
                    animated: true,
                    initialState: {
                        data: audits,
                        item: auditItem,
                        subject: params.subject,
                        pagination: this.AuditTrail.auditPagination,
                        onPageChange: this.AuditTrail.getAudits.bind(this.AuditTrail)
                    }
                });
            }).catch((errorResponse) => {
                const message = errorResponse && errorResponse.error && errorResponse.error.message;
                if (message) {
                    this.Notifications.error(message);
                }
                else {
                    this.Notifications.unexpectedError();
                }
            });
        }
    }

    canAccessLabels(): boolean {
        if (this.team) {
            const {
                labelManage, labelList, labelAssign, labelCreate, labelEdit, labelDelete,
                labelValueManage, labelValueAdd, labelValueUpdate, labelValueDelete
            } = this.team.permissions;

            return this.team
                && this.team.settings.features.labels
                && (labelManage
                    || labelList
                    || labelAssign
                    || labelCreate
                    || labelEdit
                    || labelDelete
                    || labelValueManage
                    || labelValueAdd
                    || labelValueUpdate
                    || labelValueDelete);
        }
        return false;
    }

    canAccessSecurityTab(): boolean {
        return this.team && (
            this.team.permissions.updateTeamMFA
            || this.team.permissions.manageTeamTimeoutPeriod);
    }

    canAccessManageTeamProfile(): boolean {
        return this.team
            && (this.team.permissions.renameTeam || this.team.permissions.viewTeamProfile);
    }

    canAcessSingleSignOn(): boolean {
        return this.team && this.team.permissions.manageSSO && this.team.settings.features.sso;
    }

    canAcessTeamStudies(): boolean {
        return this.team && this.team.permissions.viewTeamStudyProfiles && this.studyProfileEntryTeamSettingLocation;
    }

    canEditTeamStudies(): boolean {
        return this.team && this.team.permissions.createEditTeamStudyProfiles;
    }

    canManageMonitorGroups(): boolean {
        return this.team && this.team.permissions.managePaywalls;
    }

    canAccessManageTeamSipLinks(): boolean {
        return this.team && this.team.permissions.teamManageSipLinks;
    }

    updateTeamProfile(updatedFields): ng.IPromise<Team | void> {
        return this.Teams.update(this.team.id, updatedFields)
            .toPromise()
            .then((updatedTeam: Team) => {
                this.CurrentSession.setCurrentTeam({ ...this.team, ...updatedTeam });
                this.$onInit();
                return updatedTeam;
            }).catch((error) => {
                const statusCode = error?.response?.status || error?.status;
                if (statusCode === 403) {
                    // Override default API service's redirect
                    this.$state.go(this.tabs.settings.state, {}, { reload: true });
                }
            });
    }

    loadTeamStudies(params): void {
        if (this.activeTabIndex === this.tabs.studies.index) {
            this.desposeTeamStudies();
        }

        this.Studies.getStudies(this.team.id, params).subscribe((value) => {
            this.studiesRes.next(value);
        });
    }

    desposeTeamStudies(): void {
        this.studiesRes.next(undefined);
    }

    loadTeamSipLinks(): void {
        this.TeamSipLinks.getTeamSipLinks(this.team.id).subscribe((value) => {
            this.teamSipLinksRes.next(value);
        });
    }

    desposeTeamTeamSipLinks(): void {
        this.teamSipLinksRes.next(undefined);
    }

    loadSponsorNames(): void {
        this.TeamSipLinks.getTeamSipLinks(this.team.id).subscribe((value) => {
            this.teamSipLinksRes.next(value);
        });
    }

    openCreateSipLink(): void {
        this.modalHelper.open({
            animation: true,
            component: 'team-sip-link-create-wrapper',
            size: 'md',
            resolve: {
                sponsors: this.TeamSipLinks.getSponsorNames.bind(this.TeamSipLinks),
                team: (): Team => this.team,
                save: () => ({ params, close, cb }: SaveTeamSipLinkParams): void => {
                    this.TeamSipLinks.createTeamSipLink(params).subscribe(
                        (val) => {
                            this.Notifications.success('Successfully created SIP Configuration.');
                            this.teamSipLinksRes.next([val].concat(this.teamSipLinksRes.value));
                            close();
                        }, ({ error }) => {
                            this.Notifications.error(error.message || 'Unable to create SIP Configuration.');
                            cb();
                        }
                    );
                }
            }
        });
    }

    openDestroySipLink(teamSipLink): void {
        const bodyText = 'This action <span class="strong text-uppercase">cannot</span> be undone. This action CANNOT be undone. '
            + 'This will permanently delete the SIP Configuration.';

        this.modalHelper.open({
            animation: true,
            component: 'confirm-destroy-wrapper',
            size: 'md',
            resolve: {
                requireReason: (): boolean => true,
                bodyText: (): string => bodyText,
                onSubmit: () => (event: ConfirmDestroySubmitEvent): void => {
                    this.TeamSipLinks.deleteTeamSipLink(teamSipLink, event.data.reason).subscribe(
                        () => {
                            this.Notifications.success('SIP Configuration Data successfully deleted!');
                            const val = this.teamSipLinksRes.value.filter(({ id }) => id !== teamSipLink.id);
                            this.teamSipLinksRes.next(val);
                            event.onSuccess();
                        }, ({ error }) => {
                            this.Notifications.error(error.message || 'SIP Configuration Data failed to delete. Please try again or contact Florence support.');
                            event.onError();
                        }
                    );
                }
            }
        });
    }

    updateTeamSecurity(event: UpdateEvent): void {

        const updateParams = {
            ...(event.mfaPolicyEnabled !== undefined && {
                mfaPolicy: {
                    isEnabled: event.mfaPolicyEnabled
                }
            }),
            ...(event.inactivityTimeout !== undefined && {
                inactivityTimeout: event.inactivityTimeout
            })
        };

        if (event.mfaPolicyEnabled) {
            this.teamMfaUpdateLoading = true;
        }
        if (event.inactivityTimeout) {
            this.teamTimeoutPeriodUpdateLoading = true;
        }
        this.Teams.update(this.team.id, updateParams).toPromise().then((updatedTeam) => {
            this.CurrentSession.setCurrentTeam({ ...this.team, ...updatedTeam });
            this.$onInit();
        }).finally(() => {
            this.teamTimeoutPeriodUpdateLoading = false;
            this.teamMfaUpdateLoading = false;
        });
    }

    private generateTabsHref(): void {
        Object.values(this.tabs).forEach((tab) => {
            tab.href = this.$state.href(tab.state, { teamId: this.team.id });
        });
    }
}

ManageTeamSettingsController.$inject = [
    'Team',
    'TeamSipLinks',
    'AuditTrail',
    'CurrentSession',
    '$state',
    'SingleSignOn',
    'Studies',
    'Notifications',
    'modalHelper',
    'ApiError',
    'ModalsService',
    'FeatureFlagService'
];

export default ManageTeamSettingsController;
