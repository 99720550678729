import marketingNotificationsComponent from './containers/marketing-notifications/marketing-notifications.component';
import templateTrackingBoss from './containers/marketing-notifications/template-tracking-boss.html';
import templateTrackingCoordinator from './containers/marketing-notifications/template-tracking-coordinator.html';

class MarketingNotificationsModalService {
    constructor(modalHelper) {
        this._modalHelper = modalHelper;
        this.templates = {
            templateTrackingBoss,
            templateTrackingCoordinator
        };
    }

    _openModal(notification) {
        this._modalHelper.open({
            animation: true,
            size: 'lg',
            keyboard: false,
            controller: marketingNotificationsComponent.controller,
            controllerAs: 'vm',
            bindToController: true,
            template: this._getTemplate(notification),
            resolve: {
                marketingNotification: () => notification
            }
        }).catch(() => {
            return undefined;
        });
    }

    _getTemplate(notification) {
        switch (notification.notificationName) {
            case 'monitor_boss_info':
                return this.templates.templateTrackingBoss;
            case 'monitor_coordinator_info':
                return this.templates.templateTrackingCoordinator;
            default:
                // noop
        }

    }
}


MarketingNotificationsModalService.$inject = [
    'modalHelper'
];

export default MarketingNotificationsModalService;
