import { TeamSigningPINPolicy, TeamPasswordPolicy, TeamFormFieldPlaceholders } from '../../../../shared/models';

// TODO: remove next line after tweaking es-lint rule 'import/prefer-default-export'
// eslint-disable-next-line import/prefer-default-export
export enum FormSignatureOptions {
    BOTH = 'both',
    ADDENDUM = 'addendum',
    ANNOTATION = 'annotation',
}

export enum MonitorReviewStatusOptions {
    BOTH = 'both',
    REVIEW = 'review',
    APPROVE = 'approve',
}

export type TeamProfileFormFields = {
    name: string;
    timezone: string;
    enablePasswordPolicy: boolean;
    passwordPolicy: Partial<TeamPasswordPolicy>;
    signingPINPolicy: SigningPINPolicyFormFields;
    teamSignatureOptions: FormSignatureOptions;
    monitorReviewStatusOptions: MonitorReviewStatusOptions;
    enablePlaceholders: boolean;
    signatureFieldPlaceholder: string;
    textFieldPlaceholder: string;
    automaticBinderOwnerPermissions: boolean;
    automaticDocumentOwnerPermissions: boolean;
    supportEmail: string;
    supportPhone: string;
}

export type SigningPINPolicyFormFields = {
    isEnabled: boolean;
    expiryDays: string;
    loginAlertsDays: string;
}

export type FormFieldPlaceholders = {
    enablePlaceholders: boolean;
    textFieldPlaceholder: string;
    signatureFieldPlaceholder: string;
}

export type TeamSupport = {
    email: string;
    phone: string;
}

export type TeamProfileFormErrors = {
    hasErrors: boolean;
    field: FormFieldErrors;
}

export type FormFieldErrors = {
    name?: string;
    passwordPolicy?: {
        expiryDays?: string;
        loginAlertsDays?: string;
    };
    signingPINPolicy?: {
        expiryDays?: string;
        loginAlertsDays?: string;
    };
    signatureFieldPlaceholder?: string;
    textFieldPlaceholder?: string;
    supportEmail?: string;
    supportPhone?: string;
    timezone?: string;
}

export type UpdatedTeamSettings = {
    name?: string;
    timezone?: string;
    enablePasswordPolicy?: boolean;
    passwordPolicy?: Partial<TeamPasswordPolicy>;
    signingPINPolicy?: Partial<TeamSigningPINPolicy>;
    teamSignatureOptions?: FormSignatureOptions;
    formFieldPlaceholders?: Partial<TeamFormFieldPlaceholders>;
    disableAddendum?: boolean;
    disableAnnotation?: boolean;
    disableMonitorReviewedStatus?: boolean;
    disableMonitorApprovedStatus?: boolean;
    automaticBinderOwnerPermissions?: boolean;
    automaticDocumentOwnerPermissions?: boolean;
    support?: TeamSupport;
}
