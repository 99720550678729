import template from './timeline-selection-dropdown.html';
import controller from './timeline-selection-dropdown.controller';

const timelineSelectionDropdownComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        /**
         * @type {String} label The label to describe the dropdown.  For example "Binder" or "Timeline"
         */
        label: '@',
        /**
         * @type {Object[]} options An array of options for the dropdown
         */
        options: '<',
        /**
         * @type {String} optionDisplayKey - The key to pull the display name from an item in options
         */
        optionDisplayKey: '@',
        /**
         * @type {String} iconClass The icon class to display
         */
        iconClass: '@',
        /**
         * @type {expression} onSelect - The expression to execute on selection, variable 'option' will be available to
         * expression
         */
        navigate: '&'
    }
};

export default timelineSelectionDropdownComponent;
