import {
    Component, ElementRef, Inject, Input, OnDestroy, OnInit,
    QueryList, ViewChildren, ChangeDetectionStrategy
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer, FormArray, FormBuilder, FormGroup, Validators
} from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import uuid from 'uuid';
import {
    ADD_ROLE_RESPONSIBILITY_BUTTON_PLACEMENT,
    DOA_LOG_TEMPLATE_FORM_CONTROLS,
    DOA_LOG_TEMPLATE_STEP_FORM_CONTROL_NAMES,
    DOA_LOG_TEMPLATE_STEP_NAMES,
    AddRoleResponsibilityButtonPlacement
} from '../doa-log-template.types';
import { AbstractWizardStep } from '../../../../../widgets/wizard/utils/abstract-wizard-step/abstract-wizard-step.component';
import template from './doa-log-template-study-responsibilities-step.component.html';
import stepStyles from '../doa-log-template-steps.style.scss';
import componentStyles from './doa-log-template-study-responsibilities-step.component.scss';
import { MESSAGES, REGEX } from '../../../../../core/constants';
import { notBlank } from '../../../../../core/form-validators';
import { StudyResponsibilities, StudyResponsibility, StudyRole } from '../../../../../shared/models';
import { FormUtil } from '../../../../../shared/forms/utils/form.util';
import { NotificationsService } from '../../../../../core/notifications/notifications.service';

@Component({
    selector: 'doa-log-template-study-responsibilities-step',
    template,
    styles: [String(stepStyles), String(componentStyles)],
    // eslint-disable-next-line no-use-before-define
    providers: [{ provide: AbstractWizardStep, useExisting: DoaLogTemplateStudyResponsibilitiesStepComponent }],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoaLogTemplateStudyResponsibilitiesStepComponent extends AbstractWizardStep implements OnInit, OnDestroy {
    constructor(
        @Inject(ControlContainer) controlContainer: ControlContainer,
        private formBuilder: FormBuilder,
        private notificationsService: NotificationsService
    ) {
        super(controlContainer);
    }

    readonly MIN_RESPONSIBILITIES_LENGTH: number = 2;

    readonly buttonPlacementAbove = ADD_ROLE_RESPONSIBILITY_BUTTON_PLACEMENT.ABOVE;
    readonly buttonPlacementBelow = ADD_ROLE_RESPONSIBILITY_BUTTON_PLACEMENT.BELOW;

    @Input() studyResponsibilities?: StudyResponsibilities;
    @Input() studyRoles: StudyRole[] = [];
    @Input() isLogDocument = false;
    @Input() addResponsibilityButtonPlacement: AddRoleResponsibilityButtonPlacement = this.buttonPlacementBelow;

    @ViewChildren('responsibilitiesItemsRef') responsibilitiesItemsRef: QueryList<ElementRef>;

    readonly stepFriendlyName = DOA_LOG_TEMPLATE_STEP_NAMES.STUDY_RESPONSIBILITIES;
    readonly stepFormControlName = DOA_LOG_TEMPLATE_STEP_FORM_CONTROL_NAMES.STUDY_RESPONSIBILITIES;
    readonly responsibilitiesFormControlName = DOA_LOG_TEMPLATE_FORM_CONTROLS.RESPONSIBILITIES;
    readonly duplicateResponsibilityNameErrorMessage = 'Responsibility name is already in use';

    studyResponsibilitiesFormArray: FormArray;
    isNumberIdentifierFormControl: AbstractControl;

    formUtil = new FormUtil();

    private readonly destroy$ = new Subject<void>();

    ngOnInit(): void {
        this.initFormControls();

        this.setDuplicateResponsibilityValidators();

        this.syncStepValidityWithFormGroupStatusChanges$().pipe(
            takeUntil(this.destroy$)
        ).subscribe();

        this.stepFormGroup.updateValueAndValidity();

        this.formUtil.scrollElementIntoView$().pipe(
            takeUntil(this.destroy$)
        ).subscribe();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onDrop(event: CdkDragDrop<string[]>) {
        FormUtil.reorderFormArrayFromDragDropEvent(event, this.studyResponsibilitiesFormArray);

        this.stepFormGroup.markAsDirty();
        this.studyResponsibilitiesFormArray.updateValueAndValidity();
    }

    onAddResponsibility(): void {
        this.addEmptyStudyResponsibility();

        this.formUtil.triggerScrollToElement(this.responsibilitiesItemsRef);
    }

    addEmptyStudyResponsibility(): void {
        const emptyStudyResponsibility = this.generateStudyResponsibilityFormGroup();

        this.studyResponsibilitiesFormArray.push(emptyStudyResponsibility);
    }

    onRemoveResponsibility(index: number, studyResponsibility: StudyResponsibility): void {
        const isConnectedToStudyRole = this.studyRoles.some((studyRole) => {
            return studyRole.responsibilityIds.some((responsibilityId) => responsibilityId === studyResponsibility._id);
        });

        if (isConnectedToStudyRole) {
            const toastMessage = `Cannot delete ${studyResponsibility.name} because it is connected to a Study Role(s).`;
            this.notificationsService.warning(toastMessage);
            return;
        }

        this.removeFormArrayItem(index);
    }

    setIsNumberIdentifier(identifierType: 'number' | 'letter') {
        const isNumberIdentifier = identifierType === 'number';

        this.isNumberIdentifierFormControl.patchValue(isNumberIdentifier);
    }

    private initFormControls(): void {
        const responsibilities = this.getInitialStudyResponsibilityFormArrayValue();

        this.studyResponsibilitiesFormArray = this.formBuilder.array(
            responsibilities,
            [Validators.minLength(2)]
        );

        this.isNumberIdentifierFormControl = this.formBuilder.control(
            this.getInitialIsNumberIdentifierValue(),
            [Validators.required]
        );

        this.parentForm.addControl(
            this.stepFormControlName,
            this.formBuilder.group({
                [this.responsibilitiesFormControlName]: this.formBuilder.group({
                    values: this.studyResponsibilitiesFormArray,
                    isNumberIdentifier: this.isNumberIdentifierFormControl
                })
            })
        );
    }

    canDeleteResponsibility$(responsibility): Observable<boolean> {
        if (!this.hasEnoughResponsibilities() || (this.isLogDocument && !responsibility.value.isNew)) {
            return of(false);
        }
        return of(true);
    }

    hasEnoughResponsibilities(): boolean {
        return this.studyResponsibilitiesFormArray.length > this.MIN_RESPONSIBILITIES_LENGTH;
    }

    getFormControlErrorMessage(
        formControl: AbstractControl
    ): { message: string } {
        let message = '';

        switch (true) {
            case formControl.errors?.required:
            case formControl.errors?.blank:
                message = MESSAGES.invalidBlankMessage;
                break;
            case formControl.errors?.isDuplicate:
                message = this.duplicateResponsibilityNameErrorMessage;
                break;
            default:
                message = MESSAGES.validShortNameMessage;
        }

        return { message };
    }

    private getInitialStudyResponsibilityFormArrayValue(): FormGroup[] {
        let initialStudyResponsibilityFormArrayValue: FormGroup[];

        if (this.studyResponsibilities?.values?.length) {
            // if studyResponsibilities @Input() was provided, use it as the starting value
            initialStudyResponsibilityFormArrayValue = this.studyResponsibilities.values.map(
                (responsibility) => this.generateStudyResponsibilityFormGroup(responsibility)
            );
        }
        else {
            // else use a single, empty value as the starting point
            initialStudyResponsibilityFormArrayValue = [
                this.generateStudyResponsibilityFormGroup(),
                this.generateStudyResponsibilityFormGroup()
            ];
        }

        return initialStudyResponsibilityFormArrayValue;
    }

    private getInitialIsNumberIdentifierValue(): boolean {
        return this.studyResponsibilities?.isNumberIdentifier === undefined
            ? true
            : this.studyResponsibilities.isNumberIdentifier;
    }

    private generateStudyResponsibilityFormGroup(
        existingStudyResponsibility?: StudyResponsibility
    ): FormGroup {
        const responsibility = existingStudyResponsibility ?? this.emptyStudyResponsibility;
        const isNew = !existingStudyResponsibility;

        return this.formBuilder.group({
            _id: responsibility._id,
            name: this.formBuilder.control(responsibility.name, [
                Validators.required,
                Validators.pattern(REGEX.names),
                notBlank,
                Validators.maxLength(50)
            ]),
            isNew
        });
    }

    private removeFormArrayItem(index: number): void {
        this.studyResponsibilitiesFormArray.removeAt(index);
        this.stepFormGroup.markAsDirty();
    }

    private get emptyStudyResponsibility(): StudyResponsibility {
        return {
            _id: uuid.v4(),
            name: ''
        };
    }

    private setDuplicateResponsibilityValidators(): void {
        this.studyResponsibilitiesFormArray.valueChanges.pipe(
            map((responsibilities) => ({
                nameCountsMap: this.getResponsibilityNameCountMap(responsibilities),
                responsibilities
            })),
            tap(({ responsibilities, nameCountsMap }) => {
                responsibilities.forEach(({ _id, name }) => {
                    const nameFormControl = this.studyResponsibilitiesFormArray.controls
                        .find((control) => control.value._id === _id)
                        .get('name');
                    const isDuplicate = nameCountsMap.get(name?.toLowerCase().trim()) > 1;

                    if (isDuplicate) {
                        FormUtil.addFormControlError(nameFormControl, 'isDuplicate');
                    }
                    else {
                        FormUtil.removeFormControlError(nameFormControl, 'isDuplicate');
                    }
                });
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    private getResponsibilityNameCountMap(
        responsibilities: StudyResponsibility[]
    ): Map<string, number> {
        const nameCountsMap = new Map();

        responsibilities.forEach((responsibility) => {
            const key = responsibility.name?.toLowerCase().trim();
            nameCountsMap.set(key, nameCountsMap.has(key) ? nameCountsMap.get(key) + 1 : 1);
        });

        return nameCountsMap;
    }
}
