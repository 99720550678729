import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { Binder, Folder, Team } from '@app/shared/models';
import * as moment from 'moment';
import { ArchivesService } from '../../../archives.service';

import template from './statements.component.html';
import styles from './statements.component.scss';

@Component({
    selector: 'statements',
    template,
    styles: [String(styles)]
})
export class StatementsComponent {

    @Input() statementsGroup: FormGroup;
    @Input() scheduleArchiveForm: FormGroup;
    @Input() item: Binder | Folder
    @Input() currentTeam: Team;

    @Output() increment: EventEmitter<number> = new EventEmitter<number>();

    constructor(
        private Archives: ArchivesService,
        private Notifications: NotificationsService
    ) {}

    public get archivalDateControl(): AbstractControl {
        return this.scheduleArchiveForm.get('archivalDate');
    }

    onPreviousPage() {
        this.increment.emit(-1);
    }

    scheduleArchive(): void {

        const closeoutDate = moment()
            .tz(this.currentTeam.settings.timezone)
            .format();

        const payload = {
            agreedToTerms: true,
            closeoutDate,
            archivalDate: this.scheduleArchiveForm.controls.archivalDate.value,
            items: [{
                type: this.item.type,
                id: this.item.id
            }]
        };
        this.Archives.createArchive(this.item.teamId, payload)
            .subscribe(() => {
                this.increment.emit(1);
            },
            ({ error }) => {
                this.Notifications.error(error.message);
            });
    }
}
