import { Page } from '@app/shared/models';
import { Auth0Service } from '@app/shared/sessions/auth0.service';

class DocumentViewerImageController {
    shouldDisplay = false;
    page: Page = null;
    pageLoaded = false;
    pageSrc: string = null;
    onErrorImage: ({ $event: { target: HTMLElement } }) => void;
    constructor(
        private Auth0: Auth0Service,
        private $window: Window
    ) { }

    $onChanges(): void {
        if (this.shouldDisplay) {
            this.pageLoaded = false;
            this.Auth0.getAccessTokenSilently()
                .toPromise()
                .then((token: string) => {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    return fetch(this.page.url, { headers: { Authorization: `Bearer ${token}` } });
                })
                .then((response) => {
                    if (!response.ok) {
                        this.onErrorImage({ $event: { target: this.getImgElement() } });
                        return;
                    }
                    return response.blob();
                })
                .then((blob) => {
                    return new Promise((onSuccess, onError) => {
                        try {
                            const reader = new FileReader();
                            reader.onload = function onLoadBlob() {
                                onSuccess(this.result);
                            };
                            reader.readAsDataURL(blob);
                        }
                        catch (e) {
                            onError(e);
                        }
                    });
                })
                .then((res: string) => {
                    this.pageSrc = res;
                    this.pageLoaded = true;
                })
                .catch(() => {
                    this.onErrorImage({ $event: { target: this.getImgElement() } });
                });
        }
    }

    private getImgElement(): HTMLElement {
        return this.$window.document.getElementById(`page-img-${this.page.pageIndex}`);
    }
}

DocumentViewerImageController.$inject = [
    'Auth0Service',
    '$window'
];

export default DocumentViewerImageController;
