import * as _ from 'lodash';
import uuid from 'uuid';
import { Transition } from '@uirouter/angularjs';

class ModifyCompletionModalController {
    constructor($scope, $transitions: Transition) {
        this._$scope = $scope;

        const deregister = $transitions.onExit({}, () => {
            this.cancel();
            deregister();
        });
    }

    $onInit(): void {
        this.id = uuid.v4();
        this.isProcessing = false;
        this.updateAllTimelines = false;
        this.timeline = this.resolve.timeline;
        this.item = this.resolve.item;
        this.attributes = this.resolve.attributes;
        this.onSave = this.resolve.onSave;
        this.newCompletionDate = this.item.completionDate || this.attributes.uploadedDate;

        this._$scope.$on('modal.closing', this._preventClosingIfProcessing.bind(this));
    }

    _preventClosingIfProcessing(event, reason, closed) {
        const notAllowedToClose = reason && reason !== 'cancel' && !closed;

        if (this.isProcessing && notAllowedToClose) {
            event.preventDefault();
        }
    }

    toggleUpdateAllTimelines() {
        this.updateAllTimelines = !this.updateAllTimelines;
    }

    setCompletion(value) {
        this.newCompletionDate = value;
    }

    cancel(): void {
        this.modalInstance.dismiss('cancel');
    }

    save() {
        if (!this.newCompletionDate) {
            this.error = 'Please set a valid date before saving.';
            return;
        }

        this.isProcessing = true;
        const updatePayload = _.pick(this.item, ['objectId', 'objectType']);
        updatePayload.completionDate = this.newCompletionDate;
        updatePayload.allItemOccurrences = this.updateAllTimelines;

        this.onSave(updatePayload, this.attributes.name)
            .then(this.cancel.bind(this))
            .catch(() => {
                this.isProcessing = false;
            });
    }
}

ModifyCompletionModalController.$inject = ['$scope', '$transitions'];

export default ModifyCompletionModalController;
