import * as angular from 'angular';
import documentToolbarButtonComponent from './document.toolbar.button.component';


const dependencies = [

];
const documentToolbarButton = angular.module('app.shared.documents.documentContentEditor.documentToolbar.documentToolbarButton', dependencies)
    .component('documentToolbarButton', documentToolbarButtonComponent);

export default documentToolbarButton;
