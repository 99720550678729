import * as angular from 'angular';
import formFieldComponent from './form.field.component';

const dependencies = [];

const formField = angular
    .module('app.formField', dependencies)
    .component('formField', formFieldComponent);

export default formField;
