import Actions from '../../../constants/document.content.actions';
import AbstractFormFieldController from '../abstract.form.field/abstract.form.field.controller';
import AnnotationType from '../../../constants/annotation.type';
import FieldType from '../../../constants/form.field.type';
import ToolMode from '../../../constants/tool.mode';

class CheckboxFormFieldController extends AbstractFormFieldController {
    $onChanges(changes: ng.IOnChangesObject): void {
        if (changes.isSelected && changes.isSelected.currentValue) {
            this._createAnnotation();
        }
    }

    toggleCheckbox() {
        const properties = {};
        const shouldCheck = !this.annotation.textValue.trim();
        properties.textValue = shouldCheck ? '✔' : '';
        this._deselectRadio(this.data);
        this._$scope.$emit(Actions.ANNOTATION_UPDATE_PROPERTIES, { page: this.page, annotation: this.annotation, properties });
    }

    onClick() {
        if (!this.doc.permissions.addTextToDocument) {
            return;
        }
        this.initialValue = '✔';

        this.onSelect({
            $event: {
                annotation: this.annotation,
                page: this.page,
                formField: this.data
            }
        });
    }

    isRequredAndEmpty() {
        if (this.data && this.data.type === FieldType.RADIO) {
            return this.data.required && !this._DocumentFormField
                .returnFieldGroupAnnotations(this.data.name)
                .some((ann) => ann && ann.textValue);
        }
        return this.data.required;
    }

    _createAnnotation() {
        if (this.isReadOnly || this.annotation) {
            return;
        }

        this.annotation = {
            id: this.data.id,
            ...this._DocumentViewerToolsOptions.getOptionValuesFor(ToolMode.ADD_TEXT, this.data),
            ...this.data.coordinates,
            textValue: '',
            resizable: false,
            type: AnnotationType.add_text,
            page: this.page
        };

        this.empty = false;
        if (this.initialValue) {
            this.annotation.textValue = this.initialValue;
            this.initialValue = undefined;
            this._deselectRadio(this.data);
        }

        if (this.annotation.fontSize === this._DocumentViewerToolsOptions._initialFontSizeOption.value.fontSize) {
            this.annotation.fontSize = this._DocumentFormField.calculateFontSizeForField(this.page, this.data);
            this._DocumentViewerToolsOptions.setOptionsFor(
                ToolMode.ADD_TEXT,
                this.data,
                { fontSize: this.annotation.fontSize }
            );
        }

        this._$scope.$emit(Actions.ANNOTATION_CREATE, {
            annotation: this.annotation,
            formField: this.data
        });
    }

    _deselectRadio(formField) {
        if (formField && formField.type === FieldType.RADIO) {
            this._DocumentFormField
                .returnFieldGroup(formField.name)
                .filter((field) => field.id !== this.data.id)
                .forEach(this._DocumentFormField.clearFormField);
        }
    }
}

CheckboxFormFieldController.$inject = [...AbstractFormFieldController.$inject];

export default CheckboxFormFieldController;
