import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import 'angular-loading-bar/src/loading-bar';
import './loading-bar.scss';

import authConfig from './auth.config';
import constants from './constants';
import SentryConfigModule from './sentry-config';
import csrfTokenConfig from './csrf-token.config';
import { CurrentSessionService } from './current-session.service';
import friendlyForwardConfig from './friendly-forward.config';
import sessionResumedToasterNotification from './session-resumed-notification';
import userEntersTeamAuditService from './user-enters-team-audit.service';
import idleDirective from './idle.directive';
import notifications from './notifications';
import loadingBarConfig from './loading-bar.config';
import routerConfig from './router.config';
import scrollConfig from './scroll.config';
import { FeatureFlagService } from './feature-flag.service';

const dependencies = [
    uiRouter,
    'angular-loading-bar',
    constants.name,
    notifications.name,
    SentryConfigModule.name
];

const coreModule = angular
    .module('app.core', dependencies)
    .directive('idle', idleDirective)
    .config(authConfig)
    .config(routerConfig)
    .config(csrfTokenConfig)
    .config(loadingBarConfig)
    .run(scrollConfig)
    .run(friendlyForwardConfig)
    .run(sessionResumedToasterNotification)
    .service('UserEntersTeamAuditService', userEntersTeamAuditService)
    .run(['UserEntersTeamAuditService', () => {
        return undefined;
    }]) // initiate service on bootstrap
    .service('CurrentSession', downgradeInjectable(CurrentSessionService))
    .service('FeatureFlagService', downgradeInjectable(FeatureFlagService));

export default coreModule;
