/* eslint-disable eqeqeq */
import {
    Component, OnDestroy, OnInit, Input
} from '@angular/core';
import { StateService } from '@uirouter/core';
import {
    Subject, BehaviorSubject
} from 'rxjs';

import {
    Team, Crumb, StudyEntity, Site
} from '@app/shared/models';
import { CurrentSessionService } from '@app/core/current-session.service';
import { StudiesService } from '@app/shared/studies/studies.service';
import { FolderizeTreeItem } from '@app/shared/folders/folders.service.types';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import template from './create-study.component.html';
import styles from './create-study.component.scss';
import {
    TeamConfiguration, StudyConfig, CreateForm, StudyStartupPayload
} from '../../study-manager.service.types';
import { CreateStudyModal } from '../../components/create-study-modal/create-study-modal.component';
@Component({
    selector: 'study-manager-create',
    template,
    styles: [String(styles)]
})

export class CreateStudyComponent implements OnInit, OnDestroy {
    @Input() team: Team;
    @Input() teamConfig: TeamConfiguration;
    studyTypes: string[];
    crumbs: Crumb[] = [
        {
            name: 'Study Manager',
            stateName: 'app.team.study-manager'
        },
        {
            name: 'Create Study'
        }
    ];

    readonly progressSteps = { 1: 'Study Information', 2: 'Review and Create Study' };
    progress = 1;
    $destroy = new Subject<void>();
    private uniqueProtocolIds = new BehaviorSubject<string[]>(undefined);
    public uniqueProtocolIds$ = this.uniqueProtocolIds.asObservable();
    site: Partial<Site>;
    study: CreateForm = null;
    tree: FolderizeTreeItem[] = undefined;
    studyEntities: StudyEntity[];
    studyConfig: StudyConfig;
    payload: Partial<StudyStartupPayload>;
    jobId: string;
    entityName: string;

    constructor(
        private CurrentSession: CurrentSessionService,
        private Studies: StudiesService,
        private $state: StateService,
        private Modals: ModalsService
    ) { }

    ngOnInit(): void {
        this.team = this.CurrentSession.getCurrentTeam();
        this.teamConfig = this.$state.params.teamConfig;
        this.getUniqueProtocolIds(this.team.id);
    }

    ngOnDestroy(): void {
        this.$destroy.next();
        this.$destroy.complete();
    }

    private getUniqueProtocolIds(teamId: string): void {
        this.Studies.getStudies(teamId, { withPagination: false })
            .subscribe(({ items: studies }) => {
                this.uniqueProtocolIds.next(studies.map((s) => s.uniqueProtocolId));
            });
    }

    getTreeForStructurePreview() {
        if (this.teamConfig !== null) {
            this.teamConfig.teamConfiguration.studies.forEach((study) => {
                if (study.type === this.study.studyType) {
                    this.tree = study.tree;
                    this.studyConfig = study;
                }
            });
        }
        else {
            console.log('Team Configuration not found');
        }

    }

    goToReviewCreateStudy() {
        this.getTreeForStructurePreview();
        this.progress = 2;
    }

    goToReviewPage({ study }
        : {study: CreateForm; }): void {
        this.study = study;
        this.payload = {
            studyId: this.study.uniqueProtocolId,
            studyType: this.study.studyType,
            rootContainerPath: this.study.rootContainerPath,
            studyName: this.study.nickname,
            createGlobalBinders: true,
            studyProfile: {
                sponsor: {
                    sponsorName: this.study.sponsor
                },
                conditionOrDiseaseArea: this.study.condition,
                deviceOrDrug: this.study.device,
                croName: this.study.cro,
                studySite: {
                    siteId: this.study.customSiteId,
                    siteName: this.study.siteName,
                    principalInvestigatorName: this.study.pi,
                    leadCoordinatorName: this.study.coordinator
                },
                facility: {
                    countryCode: this.study.siteCountry
                }
            }
        };
        this.goToReviewCreateStudy();
    }

    cancelCreateStudyFlow() {
        this.$state.go('app.team.study-manager', { teamId: this.team.id });
    }

    returnToForm() {
        this.progress = 1;
    }

    removeEmptyFields(obj): Partial<StudyStartupPayload> {
        return Object.entries(obj)
            .filter(([_, value]) => (value !== null && value !== ''))
            .reduce(
                (acc, [key, value]) => ({ ...acc, [key]: value === Object(value) ? this.removeEmptyFields(value) : value }),
                {}
            );
    }

    createStudy() {
        const reducedPayload = this.removeEmptyFields(this.payload);
        this.Modals.show(CreateStudyModal, {
            animated: true,
            initialState: {
                teamId: this.team.id,
                payload: reducedPayload,
                entityName: this.entityName
            }
        });
    }

    setEntityName(entityName: string): void {
        this.entityName = entityName;
    }

    backToFirstStepOfCreateStudy() {
        this.progress = 1;
    }

    setProgress(n: number): void {
        if (n == 1) {
            this.backToFirstStepOfCreateStudy();
        }
    }

}
