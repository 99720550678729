import * as angular from 'angular';
import highlightToolComponent from './highlight.tool.component';

const dependencies = [

];

const highlightTool = angular.module('app.shared.documents.documentContentEditor.documentViewer.submodules.tools.highlightTool', dependencies)
    .component('highlightTool', highlightToolComponent);

export default highlightTool;
