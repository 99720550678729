import * as _ from 'lodash';

const DocumentSignatureFormatServiceFactory = (CurrentSession) => {

    const service = {};

    service.getSignature = ({ timestamp = Date.now() } = {}) => {

        const curUser = CurrentSession.getCurrentUser() || {};
        const name = `${_.get(curUser, 'profile.firstName', '')} ${_.get(curUser, 'profile.lastName', '')}`;
        let textValue = '[Electronic Signature]';
        if (name.trim()) {
            textValue += `\n${name} - ${curUser.email}`;
        }
        else {
            textValue += `\n${curUser.email}`;
        }
        textValue += '\n[Date and time - set on save] [Reason - set on save]';
        return { textValue, timestamp };
    };

    return service;
};

DocumentSignatureFormatServiceFactory.$inject = ['CurrentSession'];

export default DocumentSignatureFormatServiceFactory;
