import './form.field.scss';
import template from './form.field.html';
import controller from './form.field.controller';

const formFieldComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        doc: '<',
        requestIsPastDue: '<',
        teamSignatureRestricted: '<',
        data: '<',
        page: '<',
        isReadOnly: '<',
        onSelectNext: '&',
        onSelectPrevious: '&'
    }
};

export default formFieldComponent;
