import controller from './access-dates-report.controller';
import template from './access-dates-report.html';
import './access-dates-report.scss';

const accessDatesReportComponent: ng.IComponentOptions = {
    controller,
    controllerAs: 'vm',
    template,
    bindings: {
        modalInstance: '<'
    }
};

export default accessDatesReportComponent;
