import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import reportsIndexComponent from './containers/reports-index/reports-index.component';
import reportsTableComponent from './components/reports-table/reports-table.component';
import { ReportsTableService } from './components/reports-table/reports-table.service';
import { ReportsIndexStateService } from './reports-index-state.service';
import reportsRoute from './reports.route';
import objectPickerWrapperComponent from './components/object-picker/object-picker.wrapper';
import chooseBindersFoldersWrapperComponent from './components/choose-binders-folders/choose-binders-folders.wrapper';
import { ObjectPickerComponent } from './components/object-picker/object-picker.component';
import { ChooseBindersFoldersComponent } from './components/choose-binders-folders/choose-binders-folders.component';
import { ReportService } from './report.service';

const dependencies = [uiRouter];

const reports = angular
    .module('reports', dependencies)
    .config(reportsRoute)
    .service('ReportsTable', ReportsTableService)
    .service('ReportsIndexState', ReportsIndexStateService)
    .component('reportsIndex', reportsIndexComponent)
    .component('reportsTable', reportsTableComponent)
    .factory('ReportService', downgradeInjectable(ReportService))
    .directive('objectPicker', downgradeComponent({ component: ObjectPickerComponent }) as ng.IDirectiveFactory)
    .component('objectPickerWrapper', objectPickerWrapperComponent)
    .directive('chooseBindersFolders', downgradeComponent({ component: ChooseBindersFoldersComponent }) as ng.IDirectiveFactory)
    .component('chooseBindersFoldersWrapper', chooseBindersFoldersWrapperComponent);

export default reports;
