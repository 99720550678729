import controller from './label-update.controller';
import template from './label-update.html';
import './label-update.scss';

const labelUpdateComponent: ng.IComponentOptions = {
    controller,
    controllerAs: 'vm',
    template,
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default labelUpdateComponent;
