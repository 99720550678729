const missingPermissionsWrapperComponent: ng.IComponentOptions = {
    controllerAs: 'vm',
    template: `
        <missing-permissions
        [missing-permissions]="vm.resolve.missingPermissions"
        [active-subject-name]="vm.resolve.activeSubjectName"
        (on-save)="vm.resolve.onSave($event)"
        (dismiss)="vm.modalInstance.close()">
        </missing-permissions>
        `,
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default missingPermissionsWrapperComponent;
