import * as angular from 'angular';
import documentContentActionsComponent from './document.tools.dropdown.component';


const dependencies = [

];
const documentContentActions = angular.module('app.shared.documents.documentContentEditor.documentToolbar.documentToolsDropdown', dependencies)
    .component('documentToolsDropdown', documentContentActionsComponent);

export default documentContentActions;
