import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import { REGEX } from '@app/core/constants';
import { notBlank } from '@app/core/form-validators';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { FoldersService } from '@app/shared/folders/folders.service';
import { Binder, Folder, LabeledEntity } from '@app/shared/models';
import { Label } from '@app/shared/labels/labels.service.types';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FolderizeTreeItem } from '@app/shared/folders/folders.service.types';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { sortFolderStructureLexicographically } from '@app/widgets/sort/sort-folder-structure-lexicographically';
import { BindersService } from '@app/shared/binders/binders.service';
import { StructureTemplateAssignedComponent } from '@app/widgets/structure-template/structure-template-assigned/structure-template-assigned.component';
import { StructureTemplateAppliedDueDatesComponent } from '@app/widgets/structure-template-applied-due-dates/structure-template-applied-due-dates.component';
import { TeamService } from '@app/shared/teams/team.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { hasPlaceholderInStructure } from '@app/widgets/structure-template/structure-template-apply-due-dates.util';
import template from './assign-structure-template-folder.component.html';
import styles from './assign-structure-template-folder.component.scss';
import { StructureTemplate } from '../../components/imported-structures-table/imported-structures-table.component.types';
import { StudyStartupService } from '../../study-startup.service';
import { AssignStructureTemplateParams, AssignStructureTemplateResponse } from '../../study-startup.service.types';

@Component({
    selector: 'assign-structure-template-folder',
    styles: [String(styles)],
    template
})

export class AssignStructureTemplateFolderComponent implements OnInit, OnDestroy {
    @Input() binders: Binder[] = [];
    @Input() teamId: string;
    @Input() availableLabels: Label[] = [];
    @Input() teamTimezone: string = null;
    @Input() templates: StructureTemplate[] = [];

    private nextButtonDisabled = new BehaviorSubject<boolean>(true);
    nextButtonDisabled$ = this.nextButtonDisabled.asObservable();
    private readonly destroy$ = new Subject<void>();
    binderSelectionInProcess = false;

    readonly progressSteps = {
        1: 'Identify Binder & Folder Options',
        2: 'Identify Folder',
        3: 'Apply Template'
    };

    assignStructureForm: FormGroup = new FormGroup({
        assignStructureOption: new FormControl('newFolder', [Validators.required])
    });

    progress = 1;

    readonly namePattern = REGEX.names;
    readonly folderNameMinLength = 1;
    readonly folderNameMaxLength = 250;

    folderForm: FormGroup = new FormGroup({
        name: new FormControl('',
            [
                Validators.required,
                Validators.minLength(this.folderNameMinLength),
                Validators.maxLength(this.folderNameMaxLength),
                Validators.pattern(this.namePattern),
                notBlank
            ])
    });

    selectedBinder: Binder;
    selectedExistingFolder: Folder;
    hasFoldersInBinder = false;
    folders: Folder[] = [];
    addedLabels: LabeledEntity[] = [];
    selectedTemplate: StructureTemplate;
    folderizeTreeItems: FolderizeTreeItem[] = [];
    isProcessing = false;
    templateStructureIsLoading = false;

    constructor(
        private Modals: ModalsService,
        private modalRef: BsModalRef,
        private Notifications: NotificationsService,
        private Folders: FoldersService,
        private StudyStartup: StudyStartupService,
        private Binders: BindersService,
        private Teams: TeamService,
        private CurrentSession: CurrentSessionService
    ) { }

    ngOnInit(): void {
        this.folderForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.nextButtonDisabled.next(this.progress === 2
                && this.assignStructureForm.controls.assignStructureOption.value === 'newFolder'
                && (this.folderForm.pristine || this.folderForm.invalid));
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    hideModal(): void {
        this.modalRef.hide();
    }

    setProgress(n: number): void {
        this.progress = Number(n);

        if (this.progress === 2 && this.assignStructureForm.controls.assignStructureOption.value === 'newFolder') {
            this.nextButtonDisabled.next(this.folderForm.pristine || this.folderForm.invalid);
        }
        else if (this.progress === 2 && this.assignStructureForm.controls.assignStructureOption.value === 'existingFolder') {
            this.nextButtonDisabled.next(this.selectedBinder
                && !this.selectedExistingFolder);
        }
        else {
            this.nextButtonDisabled.next(false);
        }
    }

    incrementProgress(n: number): void {
        if (this.progress === 2 && this.assignStructureForm.controls.assignStructureOption.value === 'newFolder' && n === 1) {
            this.Folders
                .checkFolderExists(this.teamId, this.selectedBinder.id, this.folderForm.controls.name.value.trim())
                .pipe(take(1))
                .subscribe((folder) => {
                    this.Notifications.error(`Folder name ${folder.name} is not available. Please choose another folder name.`);
                }, (error: HttpErrorResponse) => {
                    if (error.status === 404) {
                        this.setProgress(Number(this.progress) + n);
                    }
                    else if (error.error && error.error.message) {
                        this.Notifications.error(error.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                });
        }
        else {
            this.setProgress(Number(this.progress) + n);
        }
    }

    onBinderSelected(selectedBinder: Binder): void {
        this.binderSelectionInProcess = true;
        this.selectedBinder = selectedBinder;
        this.selectedExistingFolder = null;
        if (!selectedBinder) {
            this.folders = [];
            this.addedLabels = [];
            this.hasFoldersInBinder = false;
            this.nextButtonDisabled.next(true);
            this.binderSelectionInProcess = false;
            return;
        }

        this.Binders.getDirectLabels(selectedBinder.teamId, selectedBinder.id)
            .pipe(take(1))
            .subscribe(
                (labels) => {
                    this.addedLabels = labels;
                },
                (error: HttpErrorResponse) => {
                    this.addedLabels = [];
                    const message = (error && error.error && error.error.message);
                    message ? this.Notifications.error(message) : this.Notifications.unexpectedError();
                }
            );

        this.Folders.browse({
            teamId: this.teamId,
            binderId: this.selectedBinder.id,
            folderId: null,
            includeDecorations: false,
            includeDocs: false,
            objectId: null,
            objectType: null,
            includeArchived: false
        })
            .pipe(take(1))
            .subscribe(
                (data) => {
                    if (data && data.items) {
                        this.folders = data.items.map((item) => {
                            return {
                                id: item.id,
                                name: item.name,
                                binderId: item.binderId,
                                title: item.title,
                                teamId: item.teamId,
                                nameCanonical: '',
                                path: item.path as string,
                                pathCanonical: item.pathCanonical,
                                createdBy: item.createdBy,
                                createdAt: item.createdAt,
                                updatedBy: item.updatedBy,
                                updatedAt: item.updatedAt,
                                type: 'folder',
                                lineage: item.lineage,
                                labels: []
                            };
                        });
                    }
                    else {
                        this.folders = [];
                    }

                    this.nextButtonDisabled.next(false);
                    this.hasFoldersInBinder = Boolean(this.folders && this.folders.length);
                    this.binderSelectionInProcess = false;
                },
                (error: HttpErrorResponse) => {
                    this.folders = [];
                    this.hasFoldersInBinder = false;
                    const message = (error && error.error && error.error.message);
                    message ? this.Notifications.error(message) : this.Notifications.unexpectedError();
                    this.binderSelectionInProcess = false;
                }
            );
    }

    onLabelsChanged(addedLabels: LabeledEntity[]): void {
        this.addedLabels = addedLabels;
    }

    setSelectedFolder($event: Folder): void {
        this.selectedExistingFolder = $event;
        this.folderForm.controls.name.setValue('');
        this.nextButtonDisabled.next(!this.selectedExistingFolder);
    }

    onTemplateSelected(selectedTemplate: StructureTemplate): void {
        this.selectedTemplate = selectedTemplate;

        if (!selectedTemplate) {
            this.folderizeTreeItems = [];
            return;
        }

        this.templateStructureIsLoading = true;
        this.StudyStartup.getFolderStructureById(selectedTemplate.id, this.teamId)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    this.folderizeTreeItems = sortFolderStructureLexicographically(data.tree);
                    this.templateStructureIsLoading = false;
                },
                (error: HttpErrorResponse) => {
                    this.templateStructureIsLoading = false;
                    this.folderizeTreeItems = [];
                    const message = (error && error.error && error.error.message);
                    message ? this.Notifications.error(message) : this.Notifications.unexpectedError();
                }
            );
    }

    openConfirmationModal(assignStructureResponse: AssignStructureTemplateResponse): void {
        this.Teams.loadById(this.teamId).subscribe((team) => {
            this.CurrentSession.setCurrentTeam(team);

            const canManageDocumentDueAndExpirationDates = team.permissions.manageDocumentDates;

            if (canManageDocumentDueAndExpirationDates && hasPlaceholderInStructure(assignStructureResponse.structure)) {
                this.Modals.show(StructureTemplateAppliedDueDatesComponent, {
                    animated: true,
                    initialState: {
                        data: assignStructureResponse,
                        showStructureTemplateAssignedModal: true
                    }
                });
            }
            else {
                this.Modals.show(StructureTemplateAssignedComponent, {
                    animated: true,
                    initialState: {
                        data: assignStructureResponse.folder
                    }
                });
            }
        });
    }

    submit(): void {
        this.isProcessing = true;

        const params: AssignStructureTemplateParams = {
            objectName: this.folderForm.controls.name.value.trim(),
            objectType: 'folder',
            addedLabels: this.addedLabels.filter((label) => label.objectType === 'folder'),
            folderId: this.selectedExistingFolder ? this.selectedExistingFolder.id : null,
            binderId: this.selectedBinder.id,
            isFlat: false
        };

        this.StudyStartup.assignStructure(this.teamId, this.selectedTemplate.id, params)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    this.hideModal();
                    this.openConfirmationModal(data);
                },
                (error: HttpErrorResponse) => {
                    const message = (error && error.error && error.error.message);
                    message ? this.Notifications.error(message) : this.Notifications.unexpectedError();
                    this.isProcessing = false;
                }
            );
    }
}
