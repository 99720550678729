import * as _ from 'lodash';
import { Transition } from '@uirouter/angularjs';

class LabelUpdateController {
    constructor($transitions: Transition, $filter, REGEX, SORT) {
        this._$filter = $filter;
        this.namePattern = REGEX.labelNameValue;
        this.SORT = SORT;
        this.isProcessing = false;
        this.maxLength = 250;
        this.nameError = `Label name  must be less than ${this.maxLength} characters and cannot contain characters > : ! # ^`;
        this.valueError = `Label and values must be unique, less than ${this.maxLength} characters and cannot contain characters > : ! # ^`;

        const deregister = $transitions.onExit({}, () => {
            this.cancel();
            deregister();
        });
    }

    $onInit(): void {
        this.label = _.cloneDeep(this.resolve.label);
        this.onUpdateLabel = this.resolve.onUpdateLabel;
        this.originalLabelName = this.label.name;
        this.originalLabelDesc = this.label.description;
        this.originalValuesHash = _.keyBy(this.resolve.label.values, 'value');
        this.originalValuesIdHash = _.keyBy(this.resolve.label.values, 'id');
        this.removedValues = [];
        this.label.values = this._$filter('orderBy')(this.label.values, 'value', false, this.SORT.naturalSort);
    }

    cancel(): void {
        this.modalInstance.dismiss('cancel');
    }

    removeValue(v) {
        _.pullAllBy(this.label.values, [v], 'value');
        this.updateLabelForm.$setDirty();
        if (v.id) {
            const removedValue = this.originalValuesIdHash[v.id];
            this.removedValues.push(removedValue);
        }
    }

    countOccurancesOfValue(value) {
        return this.label.values.filter((v) => v.value === value).length;
    }

    validateRenamedValue(v, i) {
        this.updateLabelForm[i].$setValidity('unique', !(this.updateLabelForm[i].$error.pattern || this.countOccurancesOfValue(v.value) > 1));
    }

    checkValueDuplicate(value) {
        return this.label.values.some((v) => v.value === value);
    }

    validateAddedValue() {
        this.addValueForm.$setValidity('unique', !(this.addValueForm.$error.pattern || this.checkValueDuplicate(this.newValue)));
    }

    resetAddValueForm() {
        this.updateLabelForm.$setDirty();
        this.addValueForm.$setPristine();
        this.newValue = '';
    }

    addValue() {
        if (this.addValueForm.$error.pattern || this.addValueForm.$error.unique) {
            return;
        }

        const newValue = { value: this.newValue };
        const { id } = this.originalValuesHash[this.newValue] || {};
        if (id) {
            _.pullAllBy(this.removedValues, [{ id }], 'id');
            newValue.id = id;
        }
        this.label.values.push(newValue);
        this.resetAddValueForm();
    }

    getModalData() {
        const data = {};
        data.removedValues = this.removedValues;
        data.addedValues = [];
        data.updatedValues = [];
        this.label.values.forEach((v) => {
            const { value } = this.originalValuesIdHash[v.id] || {};
            if (v.id && v.value !== value) {
                data.updatedValues.push(v);
            }
            else if (!v.id) {
                data.addedValues.push(v);
            }
        });

        if (this.label.name !== this.originalLabelName) {
            data.name = this.label.name;
        }
        if (this.label.description !== this.originalLabelDesc) {
            data.description = this.label.description;
        }
        return data;
    }

    onSave() {
        this.isProcessing = true;

        const params = this.getModalData();

        this.onUpdateLabel(params)
            .finally(this.cancel.bind(this));
    }
}

LabelUpdateController.$inject = ['$transitions', '$filter', 'REGEX', 'SORT'];

export default LabelUpdateController;
