import './color.option.scss';
import template from './color.option.html';
import controller from './color.option.controller';

const colorOptionComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        // @type {Object} options.value Value is an object with color property and optional opacity property
        value: '<',
        // @type {Object} options - A bag of options to configure this component
        // @type {String} options.colorKey The key under which color is stored in the value object
        // @type {string} options.label A label to describe this property
        // @type {string} options.icon An optional icon to use as the button display
        // @type {boolean} options.includeColors - Whether to include saturated colors among the options
        // @type {boolean} options.includeNeutrals - Whether to include gray values among the options
        // Optional opacity config - only define if this option should track/modify opacity
        // @type {String} options.opacityKey The key under which opacity is stored in the value object
        // @type {number} options.defaultOpacity - The opacity to set when colors are selected
        // @type {boolean} options.includeTransparent - Whether to include an empty / clear option
        options: '<',
        onChange: '&'
    }
};

export default colorOptionComponent;
