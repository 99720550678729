import './permission-info-modal.scss';
import template from './permission-info-modal.html';
import controller from './permission-info-modal.controller';

/**
 * Expects resolve to pass in properties:
 * resolve.teamId
 * resolve.item
 * resolve.subject
 * resolve.onlyDirect
 * resolve.disableEdit
 * resolve.onUpdate
 */
const permissionInfoModalComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default permissionInfoModalComponent;
