import {
    Component, Input, OnInit
} from '@angular/core';
import { VirtualTreeFlatNode, VirtualTreeSelectionMode } from '@app/widgets/virtual-tree/virtual-tree.component.types';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { DocumentSetDateComponent } from '@app/widgets/structure-template/document-set-date/document-set-date.component';
import { StructureTree } from '@app/components/study-startup/study-startup.service.types';
import { assignDueDate, itemHasCheckbox } from '@app/widgets/structure-template/structure-template-apply-due-dates.util';
import { take, takeUntil } from 'rxjs/operators';
import {
    ApiError, Binder, DocumentSubTypes, Folder
} from '@app/shared/models';
import * as _ from 'lodash';
import { BulkSetDatesUpdate } from '@app/components/documents/components/document-bulk-set-dates/document-bulk-set-dates.component.types';
import { DocumentService } from '@app/shared/documents/document.service';
import { Subject } from 'rxjs';

import { NotificationsService } from '@app/core/notifications/notifications.service';
import { StateOrName, StateService } from '@uirouter/core';
import { StructureTemplateAssignedComponent } from '@app/widgets/structure-template/structure-template-assigned/structure-template-assigned.component';
import styles from './apply-due-dates.component.scss';
import template from './apply-due-dates.component.html';


@Component({
    selector: '/apply-due-dates',
    template,
    styles: [String(styles)]
})

export class ApplyDueDatesComponent implements OnInit {
    private readonly destroy$ = new Subject<void>();

    @Input() showStructureTemplateAssignedModal = false;
    @Input() assignedStructure;

    structure: StructureTree[];
    loadingRoot: false;
    selectionMode = VirtualTreeSelectionMode.HIERARCHICAL_MULTI_SELECT;
    selectedItems = [];
    addedDateStructure = [];
    initialStructureCopy = [];
    placeholdersToUpdate: BulkSetDatesUpdate[] = [];
    constructor(
        private modalRef: BsModalRef,
        private Modals: ModalsService,
        private DocumentService: DocumentService,
        private Notifications: NotificationsService,
        private $state: StateService
    ) {
    }

    ngOnInit(): void {
        this.structure = this.assignedStructure?.structure;
        this.initialStructureCopy = _.cloneDeep(this.structure);
    }

    save() {
        this.DocumentService.bulkUpdateDueAndExpirationDates(this.placeholdersToUpdate)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (res) => {
                    if (res) {
                        const errorsToPrint = [];
                        res.forEach((responseElem) => {
                            if (responseElem.error) {
                                if (!errorsToPrint.some((e) => e.error.message === responseElem.error.message)) {
                                    errorsToPrint.push(responseElem);
                                }
                            }
                        });
                        if (errorsToPrint.length) {
                            errorsToPrint.forEach((err) => {
                                this._handleErrorNotification(err);
                            });

                            return;
                        }
                    }
                    if (this.showStructureTemplateAssignedModal) {
                        this.Modals.show(StructureTemplateAssignedComponent, {
                            animated: true,
                            initialState: {
                                data: this.assignedStructure.folder ?? this.assignedStructure.binder,
                                dueDatesApplied: true
                            }
                        });
                    }
                    else {
                        this.Notifications.success('Reusable Structure Template successfully applied');
                        this.redirectToStructurePage(this.assignedStructure.folder ?? this.assignedStructure.binder);
                    }
                },
                (error) => {
                    this._handleErrorNotification(error);
                }
            );

        this.close();
    }

    onRemoveDates() {
        this.structure = this.initialStructureCopy;
        this.placeholdersToUpdate = [];
        this.selectedItems = [];
    }

    close() {
        this.modalRef.hide();
    }

    showItemCheckbox(item: VirtualTreeFlatNode): boolean {
        return itemHasCheckbox(item);
    }

    binderTreeSelectionChange($event: { selectedItems: StructureTree[]; }) {
        this.selectedItems = $event.selectedItems;
    }

    resetSelectedItems(): void {
        this.selectedItems = [];
    }

    formatSelectedPlaceholdes(selectedPlaceholders, res) {
        return selectedPlaceholders.map((selectedPlaceholder) => {
            return {
                id: selectedPlaceholder.id.documentId,
                dueDate: res.date,
                subType: selectedPlaceholder.subType
            };
        });
    }

    applyDueDates() {
        const modalRef = this.Modals.show(DocumentSetDateComponent, {
            class: 'modal-md background-layer',
            animated: true,
            initialState: {}
        });

        modalRef.content.save.pipe(take(1)).subscribe((res) => {
            let selectedPlaceholders = this.selectedItems.filter((el) => {
                return el.subType === DocumentSubTypes.placeholder;
            });

            selectedPlaceholders = this.formatSelectedPlaceholdes(selectedPlaceholders, res);

            this.addedDateStructure = _.cloneDeep(this.structure);

            for (let i = 0; i < selectedPlaceholders.length; i += 1) {
                this.addedDateStructure = assignDueDate(this.addedDateStructure, selectedPlaceholders[i], res.date);
            }

            if (!this.placeholdersToUpdate.length) {
                this.placeholdersToUpdate = selectedPlaceholders;
            }
            else {
                this.placeholdersToUpdate = selectedPlaceholders.concat(
                    this.placeholdersToUpdate.filter((plPrev) => !selectedPlaceholders.find((plCurr) => plCurr.id === plPrev.id))
                );
            }

            this.structure = this.addedDateStructure;
            this.resetSelectedItems();
        });
    }

    _handleErrorNotification({ error }: ApiError): void {
        const { message } = error;
        if (message) {
            return this.Notifications.error(message);
        }

        return this.Notifications.unexpectedError();
    }

    redirectToStructurePage(assignedStructure: Folder | Binder): void {
        if (!assignedStructure) {
            return;
        }
        const state: StateOrName = 'app.team.folder';
        const params: {[key: string]: string;} = { teamId: assignedStructure.teamId };
        if (assignedStructure.type === 'binder') {
            params.binderId = assignedStructure.id;
        }
        else {
            params.binderId = assignedStructure.binderId;
            params.folderId = assignedStructure.id;
        }
        this.$state.go(state, params);
    }

}
