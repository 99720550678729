import * as _ from 'lodash';
import AnnotationType from '../constants/annotation.type';

/**
 * This service transforms client-side annotations into a format consumable by the api
 */
const DocumentAnnotationAdapterServiceFactory = () => {

    const service = {};
    const internals = {};

    /**
     * Formats annotations to be sent to our api.
     * @param {object[]} annotations - Annotations to be formatted for the api
     * @param {number} pageCount - The total number of pages in the associated document
     * @returns {Object[]} Api-formatted annotations
     */
    service.adaptManyForApi = (annotations = [], pageCount) => {
        const formatted = annotations.map(internals.formatAnnotation);
        const deletedInstances = annotations.map((anno) => _.get(anno, 'propagatedInstances.deletedByPageIndex', {}));
        const updatedInstances = annotations.map((anno) => _.get(anno, 'propagatedInstances.modifiedByPageIndex', {}));
        // Handled propagated annotations with edited instances
        return internals.handlePropagatedPages(formatted, deletedInstances, updatedInstances, pageCount);
    };

    /**
     * Formats annotation to be sent to our api for saving form as draft.
     * @param {object} annotations - Annotation to be formatted for the api
     * @returns {Object} Api-formatted annotation
     */
    service.adaptForDraftApi = (annotation) => {
        if (annotation && annotation.type === AnnotationType.add_text) {
            return internals.moveAnnotationForPadding(annotation, { reverse: true });
        }
        return annotation;
    };

    /**
     * Translates an annotation from the api format, to a format the frontend understands
     * @param {object} annotation
     * @param {object} page
     * @param {object} page.width - The absolute width of the page this annotation is associated with
     * @param {object} page.height - The absolute height of the page this annotation is associated with
     * @returns {*}
     */
    service.adaptFromApi = (annotation, page) => {
        const adapted = _.clone(annotation);
        if (adapted.bgColor) {
            adapted.backgroundColor = adapted.bgColor;
            delete adapted.bgColor;
        }
        const { width: pageWidth, height: pageHeight } = page;
        // Transform coordinates to relative values
        const {
            x,
            y,
            w,
            h
        } = annotation.coordinates;
        adapted.x = x / pageWidth;
        adapted.y = y / pageHeight;
        adapted.w = w / pageWidth;
        adapted.h = h / pageHeight;
        delete adapted.coordinates;

        if (adapted.textStyle) {
            _.assign(adapted, adapted.textStyle);
            delete adapted.textStyle;
        }

        adapted.page = page;
        return adapted;
    };


    internals.formatAnnotation = (annotation) => {
        const formatter = internals.getFormatterByType(annotation.type);
        return formatter(annotation);
    };

    /**
     * Propagated annotations can be modified.
     * In this case, we need to falsify the isPropagated value and create an independent annotation for each page.
     * @param {object[]}formattedAnnotations
     * @param {int[][]} deletedInstances
     * @param {number} pageCount
     * @returns {object[]} The list of annotations modified to account for propagation
     */
    internals.handlePropagatedPages = (formattedAnnotations, deletedInstances, updatedInstances, pageCount) => {
        const annotations = [];
        _.each(formattedAnnotations, (annotation, index) => {
            const deleted = deletedInstances[index];
            const updated = updatedInstances[index];
            const hasUpdates = updated && _.size(updated) > 0;
            const hasModifications = (deleted && _.size(deleted) > 0) || hasUpdates;
            if (annotation.isPropagated && hasModifications) {
                // Break up annotation into individual instances
                const deletedPages = deleted ? _.keys(deleted).map((pageIndex) => parseInt(pageIndex, 10)) : [];
                const modifiedPages = updated ? _.keys(updated).map((pageIndex) => parseInt(pageIndex, 10)) : [];
                const annotatedPages = _.pullAll(_.range(0, pageCount), [...deletedPages, ...modifiedPages]);
                annotations.push(
                    ...annotatedPages.map((pageIndex) => {
                        return _.assign({}, annotation, { pageNumber: pageIndex + 1, isPropagated: false });
                    })
                );

                // Create new instances for each modified annotation
                if (hasUpdates) {
                    annotations.push(..._.map(updated, internals.formatAnnotation));
                }
            }
            else {
                annotations.push(annotation);
            }
        });

        const finalAnnotationList = annotations.filter((annotation) => !(annotation.type === AnnotationType.add_text && annotation.textValue === ''));
        return finalAnnotationList;
    };

    internals.getFormatterByType = (type) => {
        let formatter = (annotation) => annotation;
        switch (type) {
            case AnnotationType.add_text:
                formatter = internals.formatAddText;
                break;
            case AnnotationType.timestamp:
                formatter = internals.formatTimestamp;
                break;
            case AnnotationType.highlight:
                formatter = internals.formatHighlight;
                break;
            case AnnotationType.redaction:
                formatter = internals.formatRedaction;
                break;
            case AnnotationType.signature:
                formatter = internals.formatSignature;
                break;
            default:
                // do nothing
        }
        return formatter;
    };

    internals.extractCommonFields = (annotation) => {
        const standardFields = _.pick(annotation, ['type', 'color', 'opacity', 'isPropagated']);
        standardFields.bgColor = annotation.backgroundColor;
        // Transform rectangle to absolute scale with integer values
        const {
            x = 0,
            y = 0,
            w = 0,
            h = 0
        } = annotation;
        const { width: pageWidth, height: pageHeight } = annotation.page;
        standardFields.coordinates = {
            x: Math.round(x * pageWidth),
            y: Math.round(y * pageHeight),
            w: Math.round(w * pageWidth),
            h: Math.round(h * pageHeight)
        };
        standardFields.pageNumber = annotation.page.pageNumber;
        return standardFields;
    };

    internals.formatAddText = (annotation) => {
        let formatted = internals.extractCommonFields(annotation);
        formatted.rotation = annotation.rotation;
        formatted.textValue = annotation.textValue;
        formatted.textStyle = _.pick(annotation, ['font', 'fontSize', 'lineHeight']);
        formatted.textStyle.alignment = 'left';
        formatted = internals.moveAnnotationForPadding(formatted);
        return formatted;
    };

    internals.formatTimestamp = (annotation) => {
        const formatted = internals.formatAddText(annotation);
        formatted.type = AnnotationType.add_text;
        return formatted;
    };

    internals.formatHighlight = (annotation) => internals.extractCommonFields(annotation);

    internals.formatRedaction = (annotation) => internals.extractCommonFields(annotation);

    internals.formatSignature = (annotation) => {
        const formatted = internals.formatAddText(annotation);
        delete formatted.textValue;
        formatted.reason = annotation.reason;
        return formatted;
    };

    /**
     * Move anotation since pedding was applied in interface
     * web-client/client/app/shared/components/documents/document.content.editor/
     *   document.viewer/annotations/text.annotation/text.annotation.controller.js#88
     * @param {object[]} annotation - Annotation to be moved
     * @param {object[]} options
     * @param {object[]} options.reverse - If movement should be reversed
     * @returns {Object[]} Annotation
     */
    internals.moveAnnotationForPadding = (annotation, { reverse } = {}) => {
        const padding = annotation.textStyle.fontSize * 0.2;
        const operation = reverse ? _.subtract : _.add;

        annotation.coordinates.x = operation(annotation.coordinates.x, padding);
        annotation.coordinates.y = operation(annotation.coordinates.y, padding);
        return annotation;
    };

    return service;
};

DocumentAnnotationAdapterServiceFactory.$inject = [];

export default DocumentAnnotationAdapterServiceFactory;
