import * as _ from 'lodash';
import FieldType from '../constants/form.field.type';

/**
 * This service manages form fields for the document viewer.
 */
const DocumentFormFieldServiceFactory = (
    $rootScope, DocumentAnnotation, DocumentAnnotationAdapter,
    DocumentPageDisplay
) => {
    const service = {};
    const internals = {};

    /**
     * Sets up this service for the given doc
     * @param {object} doc
     */
    service.initialize = (doc) => {
        const originalFormFields = doc.formFields || [];
        internals.originalFormFields = _.keyBy(originalFormFields, 'id');
        internals.formFields = _.cloneDeep(internals.originalFormFields);
        internals.formFieldsByLocation = internals.sortFieldsByLocation(internals.formFields);
        internals.$scope = $rootScope.$new();
        internals.formFieldAnnotations = {};
        internals.clearedFormFieldAnnotations = {};
        internals.pageCount = doc.pageCount;
    };
    /**
     * Cleans up this service when we're done with it
     */
    service.destroy = () => {
        internals.$scope.$destroy();
        internals.formFields = null;
        internals.formFieldAnnotations = null;
        internals.clearedFormFieldAnnotations = null;
        internals.originalFormFields = null;
    };
    /**
     * Clears any changes that have been made to a document's formFields since initialize
     */
    service.reset = () => {
        internals.clearedFormFieldAnnotations = {};
        _.each(internals.formFields, (formField) => {

            service.resetFormField(formField);
        });
    };

    /**
     * Each form field that has content is associated with an annotation.
     * The service caches these annotations.  If the annotation hasn't yet been generated, it is created.
     * The annotation is formatted for the frontend.
     * @param {object} formField A form field object returned with the doc from out api
     * @param {object} page The page on which this field exists.  It's width and height properties are needed to
     * calculate relative coordinates for the annotation.
     * @returns {object|null} Returns the associated annotation object
     */
    service.getAnnotationForFormField = (formField, page) => {
        if (!internals.formFieldAnnotations[formField.id] && formField.value) {
            internals.formFieldAnnotations[formField.id] = internals.getAnnotationFromFormField(formField, page);
        }

        return internals.formFieldAnnotations[formField.id] || null;
    };

    /**
     * Returns an array of form fields that have been modified.
     * Form fields are formatted for the api.
     */
    service.getUpdatedFormFieldsForApi = () => {
        const updatedFormFields = internals.getUpdatedFormFields();
        return _.map(updatedFormFields, internals.adaptForApi);
    };

    service.assignAnnotationToFormField = (formField, annotation) => {
        internals.formFieldAnnotations[formField.id] = annotation;
        return internals.formFieldAnnotations[formField.id];
    };

    /**
     * Returns the formFields associated with the document passed in during initalize.
     * @returns {object} A hash from id to form field.
     */
    service.getFormFields = () => internals.formFields;

    /**
     * Resets a formField to its original state from the doc that this service was initialized with.
     * @param {object} formField
     */
    service.resetFormField = (formField) => {

        const originalField = internals.originalFormFields[formField.id];
        const currentAnnotation = internals.formFieldAnnotations[formField.id];

        // Reset formField.value (in case it was cleared)
        formField.value = _.cloneDeep(originalField.value);
        // Modify associated annotation
        if (currentAnnotation) {
            // If we have an existing annotation, manually update its properties so that
            // anyone listening for changes on the annotation will get notified
            const pageIndex = originalField.pageNumber - 1;
            const page = DocumentPageDisplay.getPage(pageIndex);
            const annotationProperties = internals.getAnnotationFromFormField(originalField, page);
            DocumentAnnotation.updateAnnotationProperties(currentAnnotation, page, annotationProperties);
        }
        else {
            // Otherwise, just clear the data so the annotation will get reset when getAnnotationForField is called
            delete internals.formFieldAnnotations[formField.id];
        }

        internals.dispatchFieldModifiedEvent(formField);
    };

    /**
     * Removes / empties out all content from the given form field.
     * @param {object} formField
     */
    service.clearFormField = (formField) => {
        internals.clearedFormFieldAnnotations[formField.id] = internals.formFieldAnnotations[formField.id];
        internals.formFieldAnnotations[formField.id] = null;
        formField.value = null;
        internals.dispatchFieldModifiedEvent(formField);
    };

    /**
     * Add a listener that will fire when the given field is modified.
     * @param {object} formField
     * @param {function} listener The listenr will be called with params $event, and data, where data contains
     *  a formField property holding the newly updated formField.
     * @returns {function} Returns a function that will clean up and remove the listener.
     */
    service.onFieldModified = (formField, listener) => {
        const eventName = internals.fieldModifiedEventName(formField);
        return internals.$scope.$on(eventName, listener);
    };

    /**
     * Creates a new form field object adapted to send to our api.
     * @param {object} formField
     */
    internals.adaptForApi = (formField) => {
        const original = internals.originalFormFields[formField.id];
        const adapted = _.cloneDeep(original);
        const annotation = internals.formFieldAnnotations[formField.id];
        const annotationWasIntentionallyCleared = internals.clearedFormFieldAnnotations[formField.id];
        if (annotation) {
            const [adaptedAnnotation] = DocumentAnnotationAdapter.adaptManyForApi([annotation], internals.pageCount);
            // Delete properties that are not relevant for form field values
            delete adaptedAnnotation.pageNumber;
            delete adaptedAnnotation.isPropagated;
            adapted.value = adaptedAnnotation;
        }
        else if (annotationWasIntentionallyCleared) {
            // otherwise it means that page hasn't been loaded yet, and the annotation for that form doesn't exist in memory
            // we want to avoid setting value to null in that case
            adapted.value = null;
        }

        return adapted;
    };

    /**
     * Get an array of form fields that have been modified
     * @return {object[]} A list of updated form fields
     */
    internals.getUpdatedFormFields = () => _.filter(internals.formFields, (formField) => {
        const originalFormField = internals.originalFormFields[formField.id];
        const currentAnnotation = internals.formFieldAnnotations[formField.id];

        return !!currentAnnotation || !!originalFormField.value;
    });

    /**
     * Generates an annotation based on the formField data.
     * Every formField that contains data will eventually be rendered as an annotation.
     * @param {object} formField
     * @param {object} page The page on which the form field exists.  Width and height properties are used to calculate
     *      relative coordinates for the annotation
     *
     * @returns {object|null} If the formField is not empty, returns an annotation.  Otherwise, null.
     */
    internals.getAnnotationFromFormField = (formField, page) => {
        let annotation = null;
        if (formField.value) {
            annotation = DocumentAnnotationAdapter.adaptFromApi(formField.value, page);
            annotation.resizable = formField.type === FieldType.SIGNATURE;
            annotation.id = formField.id;
        }
        return annotation;
    };

    /**
     * Run event listeners for the modified form field.
     * @param {object} newField - The updated form field
     */
    internals.dispatchFieldModifiedEvent = (newField) => {
        const eventName = internals.fieldModifiedEventName(newField);
        internals.$scope.$emit(eventName, { newField });
    };

    /**
     * Returns the event string for modified field event.
     * @param {object} formField
     * @returns {string}
     */
    internals.fieldModifiedEventName = (formField) => `${formField.id}-modified`;

    /**
     * Returns font size for the given field. Makes sure that rendered font size doesn't exceed height of the formField.
     * @param page
     * @param formField
     * @param fontSize
     * @returns {number}
     */
    service.calculateFontSizeForField = (page, formField, fontSize = 36) => {
        const zoomFactor = 100 / (page.height / page.element.clientHeight);
        const displayedFontSize = (fontSize / 100) * zoomFactor;
        // Convert percentage of form field heigth to pixels
        const displayedFieldHeight = (formField.coordinates.h * 100 * page.element.clientHeight) / 100;

        if (displayedFontSize > displayedFieldHeight) {
            // Font size when rendered will take 80% of field height
            return (100 * displayedFieldHeight * 0.80) / zoomFactor;
        }

        return fontSize;
    };

    internals.sortFieldsByLocation = (formFields) => {
        return Object.values(formFields).sort((a, b) => {
            if (a.pageNumber !== b.pageNumber) {
                return a.pageNumber - b.pageNumber;
            }

            if (a.coordinates.y === b.coordinates.y || Math.abs(a.coordinates.y - b.coordinates.y) < 0.01) {
                return a.coordinates.x - b.coordinates.x;
            }
            return a.coordinates.y - b.coordinates.y;
        });
    };

    service.getNext = (formField) => {
        const index = internals.formFieldsByLocation.findIndex((field) => field.id === formField.id);
        return internals.formFieldsByLocation[index + 1]
            || internals.formFieldsByLocation[0];
    };

    service.getPrevious = (formField) => {
        const index = internals.formFieldsByLocation.findIndex((field) => field.id === formField.id);
        return internals.formFieldsByLocation[index - 1]
            || internals.formFieldsByLocation[internals.formFieldsByLocation.length - 1];
    };

    service.checkValidity = () => {
        return _.every(internals.originalFormFields, (formField) => {
            const page = DocumentPageDisplay.getPage(formField.pageNumber - 1);
            const annotation = service.getAnnotationForFormField(formField, page);

            if (!formField.required) {
                return true;
            }

            if (formField && formField.type === FieldType.RADIO) {
                return service.returnFieldGroupAnnotations(formField.name)
                    .some((ann) => ann && ann.textValue);
            }

            return annotation && annotation.textValue;
        });
    };

    service.returnFieldGroup = (name) => {
        return internals.formFieldsByLocation.filter((field) => field.name === name);
    };

    service.returnFieldGroupAnnotations = (name) => {
        return service.returnFieldGroup(name).map((field) => {
            const page = DocumentPageDisplay.getPage(field.pageNumber - 1);
            return service.getAnnotationForFormField(field, page);
        });
    };

    return service;
};

DocumentFormFieldServiceFactory.$inject = [
    '$rootScope',
    'DocumentAnnotation',
    'DocumentAnnotationAdapter',
    'DocumentPageDisplay'
];

export default DocumentFormFieldServiceFactory;
