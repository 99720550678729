import controller from './delete-role.controller';
import template from './delete-role.html';

const deleteRoleComponent: ng.IComponentOptions = {
    controller,
    controllerAs: 'vm',
    template,
    bindings: {
        modalInstance: '<',
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
};

export default deleteRoleComponent;
