import * as angular from 'angular';
import tool from './tool';
import selectionTool from './selection.tool';
import highlightTool from './highlight.tool';
import textTool from './text.tool';
import redactTool from './redact.tool';
import signatureTool from './signature.tool';
import timestampTool from './timestamp.tool';

import primitives from '../primitives';

const dependencies = [
    primitives.name,
    tool.name,
    textTool.name,
    selectionTool.name,
    highlightTool.name,
    redactTool.name,
    signatureTool.name,
    timestampTool.name
];
const tools = angular.module('app.shared.documents.documentContentEditor.documentViewer.submodules.tools', dependencies);

export default tools;
