import './annotation.block.scss';
import template from './annotation.block.html';
import controller from './annotation.block.controller';

const annotationBlockComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        /**
         * @type { object } data
         * @type { number } data.x
         * @type { number } data.y
         * @type { number } data.w
         * @type { number } data.h
         * @type { string } data.backgroundColor
         * @type { number} data.opacity
         */
        data: '<',
        /**
         * @type {boolean} Whether the object can receive mouse events
         */
        interactive: '<?',
        /**
         * @type {object} Page - the page this annotation appears on
         */
        page: '<',
        // Whether width and height are enforced, or if the content defines width / height
        enforceBounds: '<',
        enableMove: '<?',
        isSelected: '<',
        onSelect: '&'
    },
    transclude: true
};

export default annotationBlockComponent;
