import { Transition } from '@uirouter/angularjs';

class DestroyItemController {
    constructor($scope, $transitions: Transition) {
        this._$scope = $scope;

        this.isProcessing = false;
        this.reason = '';

        const deregister = $transitions.onExit({}, () => {
            this.cancel();
            deregister();
        });
    }

    $onInit(): void {
        this.items = this.resolve.items;
        this.currentTimeline = this.resolve.timeline;
        this.onDestroy = this.resolve.onDestroy;

        this._$scope.$on('modal.closing', this._preventClosingIfProcessing.bind(this));
    }

    _preventClosingIfProcessing(event, reason, closed) {

        if (this.isProcessing && !closed) {
            event.preventDefault();
        }
    }

    cancel(): void {
        this.modalInstance.dismiss('cancel');
    }

    destroy() {
        this.isProcessing = true;
        const removeItems = [];

        this.items.forEach((item) => {
            removeItems.push({
                objectType: item.type,
                objectId: item.id
            });
        });
        const update = {
            projectId: this.currentTimeline.projectId,
            removeItems
        };

        let successMessage;
        if (this.items.length === 1) {
            successMessage = `Item "${this.items[0].name}" removed from timeline!`;
        }
        else {
            successMessage = 'Items removed from timeline!';
        }

        this.onDestroy(update, successMessage)
            .then(() => {
                this.modalInstance.close();
            }, () => {
                this.isProcessing = false;
                this.modalInstance.close();
            });
    }
}

DestroyItemController.$inject = ['$scope', '$transitions'];

export default DestroyItemController;
