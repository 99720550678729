import { LogTemplateType } from '../../components/log-templates/components/log-template-type-selector/log-template-type-selector.component.types';
import { DoaColumnTypesList } from './log-entry.model';
import { GetStudyRole, StudyRoleIdName } from '../study-roles/study-roles.types';
import { StudyResponsibilities } from './study.model';

export enum LogTemplateDetailType {
    TEXT = 'text',
    SELECT = 'select'
}

export interface LogTemplateDetail {
    name: string;
    type: LogTemplateDetailType;
    options?: string[];
}

export interface LogTemplateColumn {
    id?: string;
    name: string;
    type: string;
    doaType?: DoaColumnTypesList;
    headerOptions?: string[];
    multiselectColumnOptions?: string[];
    singleselectColumnOptions?: string[];
    studyRoleColumnOptions?: string[];
    isNumberIdentifier?: boolean | null;
}

export interface LogTemplate {
    details: LogTemplateDetail[];
    legend: string;
    information?: string;
    isLatestVersion: boolean;
    comment?: string;
    teamId: string;
    name: string;
    nameCanonical: string;
    columns: LogTemplateColumn[];
    createdBy: string;
    updatedBy: string;
    createdAt: string;
    updatedAt: string;
    id: {
        templateId: string;
        version: number;
    };
    isActive: boolean;
    type: 'log-template';
    templateType: LogTemplateType;
    referenceId?: string;
    responsibilities?: StudyResponsibilities;
    roles?: GetStudyRole[];
}

export type CreateLogTemplateStudyResponsibilities = Pick<
    StudyResponsibilities,
    'isNumberIdentifier'
> & {
    values: string[];
};

export type CreateLogTemplateStudyRole = {
    studyRole: StudyRoleIdName['_id'];
    responsibilities: string[];
};

export type CreateLogTemplateRequestBody = Pick<
    LogTemplate,
    'name'
    | 'details'
    | 'columns'
    | 'legend'
    | 'information'
    | 'comment'
> & {
    responsibilities?: CreateLogTemplateStudyResponsibilities;
    roles?: CreateLogTemplateStudyRole[];
    type?: LogTemplateType;
};
