class TimelineMetricsController {
    $onInit(): void {
        this.isExpired = !this.timeline.isFinished && this.timeline.metrics.remainingDays <= 0;
        this.inProgress = this.timeline.inProgress;
        this.isDone = this.timeline.isFinished;
    }
}

TimelineMetricsController.$inject = [];

export default TimelineMetricsController;
