import * as angular from 'angular';
import timestampToolComponent from './timestamp.component';

const dependencies = [
];

const timestampTool = angular.module('app.shared.documents.documentContentEditor.documentViewer.submodules.timestampTool', dependencies)
    .component('timestampTool', timestampToolComponent);

export default timestampTool;
