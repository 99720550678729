'use strict';

module.exports = {
    id: {
        $toString: '$_id.documentId'
    },
    name: '$title',
    folderId: 1,
    binderId: 1,
    subType: 1,
    teamId: 1,
    lineage: 1,
    path: 1,
    type: 1,
    ext: 1,
    conversionStatus: 1,
    formStatus: 1,
    _id: 0,
    isBrokenShortcut: 1,
    shortcutOf: 1
};
