import * as angular from 'angular';

/**
 * Number of additional elements to render above and below the visible area inside
 * of the virtual repeat container. A higher number results in less flicker when scrolling
 * very quickly in Safari, but comes with a higher rendering and dirty-checking cost.
 * @const {number}
 */
const NUM_EXTRA = 3;

/**
 * This VirtualRepeatModelArrayLike class enforces the interface requirements
 * for infinite scrolling within a mdVirtualRepeatContainer.
 *
 * @param {Object} model An object with this interface must implement the following interface with
 * two (2) methods:
 *
 * getItemAtIndex: function(index) -> item at that index or null if it is not yet
 *     loaded (It should start downloading the item in that case).
 *
 * getLength: function() -> number The data length to which the repeater container
 *     should be sized. Ideally, when the count is known, this method should return it.
 *     Otherwise, return a higher number than the currently loaded items to produce an
 *     infinite-scroll behavior.
 *
 * @usage
 * <hljs lang="html">
 *  <md-virtual-repeat-container md-orient-horizontal>
 *    <div md-virtual-repeat="i in items" md-on-demand>
 *      Hello {{i}}!
 *    </div>
 *  </md-virtual-repeat-container>
 * </hljs>
 *
 */
const VirtualRepeatModelArrayLike = function VirtualRepeatModelArrayLike(model) {
    if (!angular.isFunction(model.getItemAtIndex)
        || !angular.isFunction(model.getLength)) {
        throw Error('When md-on-demand is enabled, the Object passed to md-virtual-repeat must '
            + 'implement functions getItemAtIndex() and getLength().');
    }

    this.model = model;
};

/**
 * @param {number} start
 * @param {number} end
 */
VirtualRepeatModelArrayLike.prototype.$$includeIndexes = function $$includeIndexes(start, end) {
    for (let i = start; i < end; i += 1) {
        if (!Object.prototype.hasOwnProperty.call(this, i)) {
            this[i] = this.model.getItemAtIndex(i);
        }
    }
    this.length = this.model.getLength();
};

/** @ngInject */
const VirtualRepeatController = function VirtualRepeatController($scope, $element, $attrs, $browser, $document, $rootScope,
    $$rAF, $mdUtil) {
    this.$scope = $scope;
    this.$element = $element;
    this.$attrs = $attrs;
    this.$browser = $browser;
    this.$document = $document;
    this.$mdUtil = $mdUtil;
    this.$rootScope = $rootScope;
    this.$$rAF = $$rAF;

    /** @type {boolean} Whether we are in on-demand mode. */
    this.onDemand = $mdUtil.parseAttributeBoolean($attrs.mdOnDemand);
    /** @type {!Function} Backup reference to $browser.$$checkUrlChange */
    this.browserCheckUrlChange = $browser.$$checkUrlChange;
    /** @type {number} Most recent starting repeat index (based on scroll offset) */
    this.newStartIndex = 0;
    /** @type {number} Most recent ending repeat index (based on scroll offset) */
    this.newEndIndex = 0;
    /** @type {number} Most recent end visible index (based on scroll offset) */
    this.newVisibleEnd = 0;
    /** @type {number} Previous starting repeat index (based on scroll offset) */
    this.startIndex = 0;
    /** @type {number} Previous ending repeat index (based on scroll offset) */
    this.endIndex = 0;
    /** @type {?number} Height/width of repeated elements. */
    this.itemSize = $scope.$eval($attrs.mdItemSize) || null;

    /** @type {boolean} Whether this is the first time that items are rendered. */
    this.isFirstRender = true;

    /**
     * @private {boolean} Whether the items in the list are already being updated. Used to prevent
     *     nested calls to virtualRepeatUpdate_.
     */
    this.isVirtualRepeatUpdating_ = false;

    /** @type {number} Most recently seen length of items. */
    this.itemsLength = 0;

    /**
     * @type {!Function} Unwatch callback for item size (when md-items-size is
     *     not specified), or angular.noop otherwise.
     */
    this.unwatchItemSize_ = angular.noop;

    /**
     * Presently rendered blocks by repeat index.
     * @type {Object<number, !VirtualRepeatController.Block>}
     */
    this.blocks = {};
    /** @type {Array<!VirtualRepeatController.Block>} A pool of presently unused blocks. */
    this.pooledBlocks = [];

    $scope.$on('$destroy', angular.bind(this, this.cleanupBlocks_));
};

VirtualRepeatController.$inject = ['$scope', '$element', '$attrs', '$browser', '$document', '$rootScope', '$$rAF', '$mdUtil'];

/**
 * An object representing a repeated item.
 * @typedef {{element: !jqLite, new: boolean, scope: !angular.Scope}}
 */
VirtualRepeatController.Block;


/**
 * Called at startup by the md-virtual-repeat postLink function.
 * @param {!VirtualRepeatContainerController} container The container's controller.
 * @param {!Function} transclude The repeated element's bound transclude function.
 * @param {string} repeatName The left hand side of the repeat expression, indicating
 *     the name for each item in the array.
 * @param {!Function} repeatListExpression A compiled expression based on the right hand side
 *     of the repeat expression. Points to the array to repeat over.
 * @param {string|undefined} extraName The optional extra repeatName.
 */
VirtualRepeatController.prototype.link_ = function link(container, transclude, repeatName, repeatListExpression, extraName) {
    this.container = container;
    this.transclude = transclude;
    this.repeatName = repeatName;
    this.rawRepeatListExpression = repeatListExpression;
    this.extraName = extraName;
    this.sized = false;

    this.repeatListExpression = angular.bind(this, this.repeatListExpression_);

    this.container.register(this);
};


/** @private Cleans up unused blocks. */
VirtualRepeatController.prototype.cleanupBlocks_ = function cleanupBlocks() {
    angular.forEach(this.pooledBlocks, (block) => {
        block.element.remove();
    });
};


/** @private Attempts to set itemSize by measuring a repeated element in the dom */
VirtualRepeatController.prototype.readItemSize_ = function readItemSize() {
    if (this.itemSize) {
        // itemSize was successfully read in a different asynchronous call.
        return;
    }

    this.items = this.repeatListExpression(this.$scope);
    this.parentNode = this.$element[0].parentNode;
    const block = this.getBlock_(0);
    if (!block.element[0].parentNode) {
        this.parentNode.appendChild(block.element[0]);
    }

    this.itemSize = block.element[0][
        this.container.isHorizontal() ? 'offsetWidth' : 'offsetHeight'] || null;

    this.blocks[0] = block;
    this.poolBlock_(0);

    if (this.itemSize) {
        this.containerUpdated();
    }
};


/**
 * Returns the user-specified repeat list, transforming it into an array-like
 * object in the case of infinite scroll/dynamic load mode.
 * @param {!angular.Scope} scope The scope.
 * @return {!Array|!Object} An array or array-like object for iteration.
 */
VirtualRepeatController.prototype.repeatListExpression_ = function repeatListExpression(scope) {
    const repeatList = this.rawRepeatListExpression(scope);

    if (this.onDemand && repeatList) {
        const virtualList = new VirtualRepeatModelArrayLike(repeatList);
        virtualList.$$includeIndexes(this.newStartIndex, this.newVisibleEnd);
        return virtualList;
    }
    return repeatList;
};


/**
 * Called by the container. Informs us that the container's scroll or size has
 * changed.
 */
VirtualRepeatController.prototype.containerUpdated = function containerUpdated() {
    // If itemSize is unknown, attempt to measure it.
    if (!this.itemSize) {
        // Make sure to clean up watchers if we can (see #8178)
        if (this.unwatchItemSize_ && this.unwatchItemSize_ !== angular.noop) {
            this.unwatchItemSize_();
        }
        this.unwatchItemSize_ = this.$scope.$watchCollection(
            this.repeatListExpression,
            angular.bind(this, function readItemSize(items) {
                if (items && items.length) {
                    this.readItemSize_();
                }
            })
        );
        if (!this.$rootScope.$$phase) {
            this.$scope.$digest();
        }

        return;
    } if (!this.sized) {
        this.items = this.repeatListExpression(this.$scope);
    }

    if (!this.sized) {
        this.unwatchItemSize_();
        this.sized = true;
        this.$scope.$watchCollection(this.repeatListExpression,
            angular.bind(this, function virtualRepeatUpdate(items, oldItems) {
                if (!this.isVirtualRepeatUpdating_) {
                    this.virtualRepeatUpdate_(items, oldItems);
                }
            }));
    }

    this.updateIndexes_();

    if (this.newStartIndex !== this.startIndex
        || this.newEndIndex !== this.endIndex
        || this.container.getScrollOffset() > this.container.getScrollSize()) {
        if (this.items instanceof VirtualRepeatModelArrayLike) {
            this.items.$$includeIndexes(this.newStartIndex, this.newEndIndex);
        }
        this.virtualRepeatUpdate_(this.items, this.items);
    }
};


/**
 * Called by the container. Returns the size of a single repeated item.
 * @return {?number} size of a repeated item.
 */
VirtualRepeatController.prototype.getItemSize = function getItemSize() {
    return this.itemSize;
};


/**
 * Called by the container.
 * @return {?number} the most recently seen length of items.
 */
VirtualRepeatController.prototype.getItemCount = function getItemCount() {
    return this.itemsLength;
};


/**
 * Updates the order and visible offset of repeated blocks in response to scrolling
 * or updates to `items`.
 * @param {Array} items visible elements
 * @param {Array} oldItems previously visible elements
 * @private
 */
VirtualRepeatController.prototype.virtualRepeatUpdate_ = function virtualRepeatUpdate(items, oldItems) {
    this.isVirtualRepeatUpdating_ = true;

    const itemsLength = (items && items.length) || 0;
    let lengthChanged = false;

    // If the number of items shrank, keep the scroll position.
    if (this.items && itemsLength < this.items.length && this.container.getScrollOffset() !== 0) {
        this.items = items;
        const previousScrollOffset = this.container.getScrollOffset();
        this.container.resetScroll();
        this.container.scrollTo(previousScrollOffset);
    }

    if (itemsLength !== this.itemsLength) {
        lengthChanged = true;
        this.itemsLength = itemsLength;
    }

    this.items = items;
    if (items !== oldItems || lengthChanged) {
        this.updateIndexes_();
    }

    this.parentNode = this.$element[0].parentNode;

    if (lengthChanged) {
        this.container.setScrollSize(itemsLength * this.itemSize);
    }

    // Detach and pool any blocks that are no longer in the viewport.
    Object.keys(this.blocks).forEach(function poolBlock(blockIndex) {
        const index = parseInt(blockIndex, 10);
        if (index < this.newStartIndex || index >= this.newEndIndex) {
            this.poolBlock_(index);
        }
    }, this);

    // Add needed blocks.
    // For performance reasons, temporarily block browser url checks as we digest
    // the restored block scopes ($$checkUrlChange reads window.location to
    // check for changes and trigger route change, etc, which we don't need when
    // trying to scroll at 60fps).
    this.$browser.$$checkUrlChange = angular.noop;

    let i;
    let block;
    const newStartBlocks = [];
    const newEndBlocks = [];

    // Collect blocks at the top.
    for (i = this.newStartIndex; i < this.newEndIndex && this.blocks[i] == null; i += 1) { // eslint-disable-line no-eq-null
        block = this.getBlock_(i);
        this.updateBlock_(block, i);
        newStartBlocks.push(block);
    }

    // Update blocks that are already rendered.
    for (; this.blocks[i] != null; i += 1) { // eslint-disable-line no-eq-null
        this.updateBlock_(this.blocks[i], i);
    }
    const maxIndex = i - 1;

    // Collect blocks at the end.
    for (; i < this.newEndIndex; i += 1) {
        block = this.getBlock_(i);
        this.updateBlock_(block, i);
        newEndBlocks.push(block);
    }

    // Attach collected blocks to the document.
    if (newStartBlocks.length) {
        this.parentNode.insertBefore(
            this.domFragmentFromBlocks_(newStartBlocks),
            this.$element[0].nextSibling
        );
    }
    if (newEndBlocks.length) {
        this.parentNode.insertBefore(
            this.domFragmentFromBlocks_(newEndBlocks),
            this.blocks[maxIndex] && this.blocks[maxIndex].element[0].nextSibling
        );
    }

    // Restore $$checkUrlChange.
    this.$browser.$$checkUrlChange = this.browserCheckUrlChange;

    this.startIndex = this.newStartIndex;
    this.endIndex = this.newEndIndex;

    if (this.isFirstRender) {
        this.isFirstRender = false;
        const firstRenderStartIndex = this.$attrs.mdStartIndex
            ? this.$scope.$eval(this.$attrs.mdStartIndex)
            : this.container.topIndex;

        // The first call to virtualRepeatUpdate_ may not be when the virtual repeater is ready.
        // Introduce a slight delay so that the update happens when it is actually ready.
        this.$mdUtil.nextTick(() => {
            this.container.scrollToIndex(firstRenderStartIndex);
        });
    }

    this.isVirtualRepeatUpdating_ = false;
};


/**
 * @param {number} index Where the block is to be in the repeated list.
 * @return {!VirtualRepeatController.Block} A new or pooled block to place at the specified index.
 * @private
 */
VirtualRepeatController.prototype.getBlock_ = function getBlock(index) {
    if (this.pooledBlocks.length) {
        return this.pooledBlocks.pop();
    }

    let block;
    this.transclude(angular.bind(this, function appendClone(clone, scope) {
        block = {
            element: clone,
            new: true,
            scope
        };

        this.updateScope_(scope, index);
        this.parentNode.appendChild(clone[0]);
    }));

    return block;
};


/**
 * Updates and if not in a digest cycle, digests the specified block's scope to the data
 * at the specified index.
 * @param {!VirtualRepeatController.Block} block The block whose scope should be updated.
 * @param {number} index The index to set.
 * @private
 */
VirtualRepeatController.prototype.updateBlock_ = function updateBlock(block, index) {
    this.blocks[index] = block;

    if (!block.new
        && (block.scope.$index === index && block.scope[this.repeatName] === this.items[index])) {
        return;
    }
    block.new = false;

    // Update and digest the block's scope.
    this.updateScope_(block.scope, index);

    // Perform digest before reattaching the block.
    // Any resulting synchronous DOM mutations should be much faster as a result.
    // This might break some directives.
    if (!this.$rootScope.$$phase) {
        block.scope.$digest();
    }
};


/**
 * Updates scope to the data at the specified index.
 * @param {!angular.Scope} scope The scope which should be updated.
 * @param {number} index The index to set.
 * @private
 */
VirtualRepeatController.prototype.updateScope_ = function updateScope(scope, index) {
    scope.$index = index;
    scope[this.repeatName] = this.items && this.items[index];
    if (this.extraName) {
        scope[this.extraName(this.$scope)] = this.items[index];
    }
};


/**
 * Pools the block at the specified index (Pulls its element out of the dom and stores it).
 * @param {number} index The index at which the block to pool is stored.
 * @private
 */
VirtualRepeatController.prototype.poolBlock_ = function poolBlock(index) {
    this.pooledBlocks.push(this.blocks[index]);
    this.parentNode.removeChild(this.blocks[index].element[0]);
    delete this.blocks[index];
};


/**
 * Produces a dom fragment containing the elements from the list of blocks.
 * @param {!Array<!VirtualRepeatController.Block>} blocks The blocks whose elements
 *     should be added to the document fragment.
 * @return {DocumentFragment}
 * @private
 */
VirtualRepeatController.prototype.domFragmentFromBlocks_ = function domFragmentFromBlocks(blocks) {
    const fragment = this.$document[0].createDocumentFragment();
    blocks.forEach((block) => {
        fragment.appendChild(block.element[0]);
    });
    return fragment;
};


/**
 * Updates start and end indexes based on length of repeated items and container size.
 * @private
 */
VirtualRepeatController.prototype.updateIndexes_ = function updateIndexes() {
    const itemsLength = this.items ? this.items.length : 0;
    const containerLength = Math.ceil(this.container.getSize() / this.itemSize);

    this.newStartIndex = Math.max(0, Math.min(
        itemsLength - containerLength,
        Math.floor(this.container.getScrollOffset() / this.itemSize)
    ));
    this.newVisibleEnd = this.newStartIndex + containerLength + NUM_EXTRA;
    this.newEndIndex = Math.min(itemsLength, this.newVisibleEnd);
    this.newStartIndex = Math.max(0, this.newStartIndex - NUM_EXTRA);
};

/**
 * @ngdoc directive
 * @name mdVirtualRepeat
 * @module material.components.virtualRepeat
 * @restrict A
 * @priority 1000
 * @description
 * The `md-virtual-repeat` attribute is applied to a template that is repeated using virtual
 * scrolling. This provides smooth and performant scrolling through very large lists of elements.
 *
 * Virtual repeat is a limited substitute for `ng-repeat` that renders only
 * enough DOM nodes to fill the container, recycling them as the user scrolls.
 *
 * ### Notes
 *
 * - Arrays are supported for iteration by default.
 * - An object can used use if `md-on-demand` is specified and the object implements the interface
 *   described in the `md-on-demand` <a ng-href="#attributes">documentation</a>.
 * - `trackBy`, `as` alias, and `(key, value)` syntax from `ng-repeat` are not supported.
 *
 * ### On-Demand Async Item Loading
 *
 * When using the `md-on-demand` attribute and loading some asynchronous data,
 * the `getItemAtIndex` function will mostly return nothing.
 *
 * <hljs lang="js">
 *   DynamicItems.prototype.getItemAtIndex = function(index) {
 *     if (this.pages[index]) {
 *       return this.pages[index];
 *     } else {
 *       // This is an asynchronous action and does not return any value.
 *       this.loadPage(index);
 *     }
 *   };
 * </hljs>
 *
 * This means that the Virtual Repeat will not have any value for the given index.<br/>
 * After the data loading completes, the user expects the Virtual Repeat to recognize the change.
 *
 * To make sure that the Virtual Repeat properly detects any change, you need to run the operation
 * in another digest.
 *
 * <hljs lang="js">
 *   DynamicItems.prototype.loadPage = function(index) {
 *     var self = this;
 *
 *     // Trigger a new digest by using $timeout
 *     $timeout(function() {
 *       self.pages[index] = Data;
 *     });
 *   };
 * </hljs>
 *
 * > <b>Note:</b> Please also review the
 *   <a ng-href="api/directive/mdVirtualRepeatContainer">VirtualRepeatContainer</a> documentation
 *   for more information.
 *
 * @usage
 * <hljs lang="html">
 * <md-virtual-repeat-container>
 *   <div md-virtual-repeat="i in items">Hello {{i}}!</div>
 * </md-virtual-repeat-container>
 *
 * <md-virtual-repeat-container md-orient-horizontal>
 *   <div md-virtual-repeat="i in items" md-item-size="20">Hello {{i}}!</div>
 * </md-virtual-repeat-container>
 * </hljs>
 *
 * @param {expression=} md-extra-name Evaluates to an additional name to which the current iterated
 *   item can be assigned on the repeated scope (needed for use in `md-autocomplete`).
 * @param {number=} md-item-size Optional height or width of the repeated elements (which **must be
 *   identical for each element**). Virtual repeat will attempt to read the size from the DOM,
 *   if missing, but it still assumes that all repeated nodes have the **same height**
 *   (when scrolling vertically) or **same width** (when scrolling horizontally).
 * @param {boolean=} md-on-demand When present, treats the `md-virtual-repeat` argument as an object
 *   that can fetch rows rather than an array.
 *
 *   **NOTE:** This object **must** implement the following interface with two methods:
 *
 *   - `getItemAtIndex` - `{function(index): Object}`: The item at that `index` or `null` if it is
 *     not yet loaded (it should start downloading the item in that case).
 *   - `getLength` - `{function(): number}`: The data length to which the repeater container
 *     should be sized. Ideally, when the count is known, this method should return it.
 *     Otherwise, return a higher number than the currently loaded items to produce an
 *     infinite-scroll behavior.
 */
const VirtualRepeatDirective = function VirtualRepeatDirective($parse) {
    return {
        controller: VirtualRepeatController,
        priority: 1000,
        require: ['mdVirtualRepeat', '^^mdVirtualRepeatContainer'],
        restrict: 'A',
        terminal: true,
        transclude: 'element',
        compile: function VirtualRepeatCompile($element, $attrs) {
            const expression = $attrs.mdVirtualRepeat;
            const match = expression.match(/^\s*([\s\S]+?)\s+in\s+([\s\S]+?)\s*$/);
            const repeatName = match[1];
            const repeatListExpression = $parse(match[2]);
            const extraName = $attrs.mdExtraName && $parse($attrs.mdExtraName);

            return function VirtualRepeatLink($scope, $element, $attrs, ctrl, $transclude) { // eslint-disable-line no-shadow
                ctrl[0].link_(ctrl[1], $transclude, repeatName, repeatListExpression, extraName);
            };
        }
    };
};

VirtualRepeatDirective.$inject = ['$parse'];

export default VirtualRepeatDirective;
