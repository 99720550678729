import './modify-completion-modal.scss';
import template from './modify-completion-modal.html';
import controller from './modify-completion-modal.controller';

const modifyCompletionModalComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default modifyCompletionModalComponent;
