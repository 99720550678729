import template from './role-permissions-form.html';
import controller from './role-permissions-form.controller';
import './role-permissions-form.component.scss';

const rolePermissionsFormComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default rolePermissionsFormComponent;
