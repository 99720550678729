import { Transition } from '@uirouter/angularjs';

import { ApiError } from '@app/shared/models';

class TimelineDetailPageController {
    // bindings
    public $transition$: Transition;

    constructor(
        $state, $filter, modalHelper, Adapter, Team, Projects, MultiSelect, SORT, CurrentSession, $q, Notifications
    ) {
        this._$state = $state;
        this._$filter = $filter;
        this._modalHelper = modalHelper;
        this._Adapter = Adapter;
        this._Projects = Projects;
        this._Teams = Team;
        this._MultiSelect = MultiSelect;
        this._CurrentSession = CurrentSession;
        this._Notifications = Notifications;
        this._$q = $q;

        this.project = {};
        this.items = [];
        this.timeline = {};
        this.SORT = SORT;
        this.selectedItems = [];
        this.loadingData = false;
    }

    $onInit(): void {
        const stateParams = this.$transition$.params();
        this.currentTeam = this._CurrentSession.getCurrentTeam();

        if (!this._Teams.canViewProjectsAndTimelines()) {
            return this._$state.go('app.select-team');
        }
        this.loadingData = true;

        this._$q.all({
            project: this._Projects.getProject(this.currentTeam.id, stateParams.projectId).toPromise(),
            timeline: this._Projects.getFullTimeline(this.currentTeam.id, stateParams.timelineId).toPromise()
        }).then(
            (data) => {
                this.project = data.project;
                this.timeline = data.timeline;

                const items = this.timeline.items || [];
                this.adaptedItems = items.map((item) => this._Adapter.adaptBinderItem(item));

                this.isExpired = !this.timeline.isFinished && this.timeline.metrics.remainingDays <= 0;
                this.inProgress = this.timeline.inProgress;
                this.isDone = this.timeline.isFinished;

                this.multiSelect = this._MultiSelect.makeMultiSelect().select;
                this.crumbs = this._getCrumbs();
                this.loadingData = false;
            },
            ({ error }: ApiError) => {
                this._Notifications.error(error.message || 'Server Error: Please contact your administrator.');
            }
        );
    }

    goToItem(item) {
        if (item.type !== 'document') {
            return;
        }

        this._$state.go('app.team.document-show', {
            teamId: item.teamId,
            documentId: item.id,
            version: item.version
        });
    }

    select(event, index, items) {
        this.selectedItems = this.multiSelect(event, index, items);
    }

    toggleActions($event, index, items, item) {
        $event.stopPropagation();

        if (!item.selected) {
            this.selectedItems.forEach((i) => {
                i.selected = false;
            });
            this.select($event, index, items);
        }
    }

    canActOnSelection() {
        return this.selectedItems.length
            && (this.canModifyCompletionDate()
                || this.canRemove());
    }

    canModifyCompletionDate() {
        return this.selectedItems.length === 1 && this.currentTeam.permissions.manageTimelines;
    }

    canEditTimeline() {
        return this._Teams.canEditIndividualTimeline();
    }

    canRemove() {
        return this.selectedItems.length > 0 && this.currentTeam.permissions.assignToTimelines;
    }

    remove() {
        if (!this.canRemove()) {
            return;
        }

        this._modalHelper.open({
            component: 'destroy-item',
            animation: true,
            size: 'md',
            resolve: {
                items: () => this.selectedItems,
                timeline: () => this.timeline,
                onDestroy: () => (updatePayload, message) => {
                    return this._Projects
                        .editTimeline(this.currentTeam.id, this.timeline.id, updatePayload)
                        .toPromise()
                        .then(() => {
                            this._Notifications.success(message);
                            this._$state.reload();
                        }, ({ data }: { data: { message: string } }) => {
                            this._Notifications.error(data.message || 'Server Error: Please contact your administrator.');
                        });
                }
            }
        });
    }

    modifyCompletionDate() {
        if (!this.canModifyCompletionDate() || this.selectedItems.length !== 1) {
            return;
        }

        const selected = this.selectedItems[0];
        const item = {
            objectType: selected.type,
            objectId: selected.id,
            completionDate: selected.state.timelineItemStatus.completionDate
        };
        const attributes = {
            name: selected.name,
            uploadedDate: selected.state.timelineItemStatus.dateRawFileUploaded,
            path: selected.fullPath || selected.path
        };

        this._modalHelper.open({
            component: 'modify-completion-modal',
            animation: true,
            size: 'md',
            resolve: {
                timeline: () => this.timeline,
                item: () => item,
                attributes: () => attributes,
                onSave: () => (updatePayload, itemName) => {
                    return this._Projects
                        .editTimelineItem(this.currentTeam.id, this.timeline.id, updatePayload)
                        .toPromise()
                        .then(() => {
                            this._Notifications.success(`Completion date updated for "${itemName}".`);
                            this._$state.reload();
                        }, ({ data }: { data: { message: string } }) => {
                            this._Notifications.error(data.message || 'Server Error: Please contact your administrator.');
                        });
                }
            }
        });
    }

    editTimeline() {
        if (!this.canEditTimeline()) {
            return;
        }

        this._$state.go('app.team.timeline-update', {
            teamId: this.timeline.teamId,
            projectId: this.timeline.projectId,
            timelineId: this.timeline.id
        });
    }

    hasVisibleBinders() {
        return this.adaptedItems.length;
    }

    _getCrumbs() {
        const teamId = this.currentTeam.id;
        return [
            {
                name: 'Manage Projects',
                stateName: 'app.team.manage-projects',
                stateParams: { teamId }
            },
            {
                name: this.project.name,
                stateName: 'app.team.manage-project',
                stateParams: {
                    projectId: this.project.id,
                    teamId
                }
            },
            {
                name: this.timeline.name
            }
        ];
    }

    updateSort(sortName) {
        this.SORT.set(sortName);
        this.adaptedItems = this._$filter('orderBy')(this.adaptedItems, sortName, this.SORT.isReversed, this.SORT.naturalSort);
    }
}

TimelineDetailPageController.$inject = [
    '$state',
    '$filter',
    'modalHelper',
    'Adapter',
    'Team',
    'Projects',
    'MultiSelect',
    'SORT',
    'CurrentSession',
    '$q',
    'Notifications'
];

export default TimelineDetailPageController;
