import { StudyLinkEntitiesController as controller } from './study-link-entities.controller';
import template from './study-link-entities.html';

export const StudyLinkEntitiesComponent: ng.IComponentOptions = {
    controller,
    controllerAs: 'vm',
    template,
    bindings: {
        modalInstance: '<',
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
};
