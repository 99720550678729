import {
    forkJoin,
    from,
    Observable,
    of,
    Subject
} from 'rxjs';
import {
    catchError, filter, first, map, mergeMap, switchMap, take, takeUntil,
    tap
} from 'rxjs/operators';
import * as _ from 'lodash';

import { StateService } from '@uirouter/angularjs';
import {
    Document,
    Folder,
    Team,
    Study,
    DocumentSubTypes,
    ApiError,
    DocumentProject,
    BindersArchives,
    FoldersArchives,
    LabeledEntity,
    ApiErrorResponse,
    BrowseTree,
    Binder,
    AuditTrailEventType,
    browseNodeIsFolder,
    browseNodeIsDocument,
    Archive,
    Crumb,
    BrowseNode,
    SignatureRequest
} from '@app/shared/models';
import { ALLOWED_PREVIEW_FILE_TYPES } from '@florencehealthcare/florence-constants/lib/documents';
import { CurrentSessionService } from '@app/core/current-session.service';
import { FoldersService } from '@app/shared/folders/folders.service';
import { BindersService } from '@app/shared/binders/binders.service';
import { DownloadsService } from '@app/shared/downloads/downloads.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { BulkUpdateDueAndExpirationDatesResponseItem, SetPiiResponse, UpdateDocumentIsLockedItem } from '@app/shared/documents/documents.service.types';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { sortBrowseTreeLexicographically } from '@app/widgets/sort/sort-browse-tree-lexicographically.util';
import { TimelineAssignComponent } from '@app/widgets/timeline-assign/timeline-assign.component';
import { mapTimelinesToDocumentProjects } from '@app/shared/documents/map-timelines-to-document-projects.util';
import { BulkSetDatesUpdate } from '@app/components/documents/components/document-bulk-set-dates/document-bulk-set-dates.component.types';

import { LogCreateEvent } from '@app/components/documents/components/log-create/log-create.component.types';
import { ModalRef, ModalsService } from '@app/shared/modal-helper/modals.service';
import { JobTitleRequiredChangeEvent } from '@app/widgets/job-title-required-change/job-title-required-change.types';
import { TagsAssignComponent } from '@app/widgets/tags-assign/tags-assign.component';
import { DestroyDocumentComponent } from '@app/components/documents/components/destroy-document/destroy-document.component';
import { DocumentDestroyEvent } from '@app/components/documents/components/destroy-document/destroy-document.component.types';
import { DocumentBulkSetDatesComponent } from '@app/components/documents/components/document-bulk-set-dates/document-bulk-set-dates.component';
import { GetTimelinesResponseItem } from '@app/shared/projects/projects.service.types';
import { LabelsService } from '@app/shared/labels/labels.service';
import { Updates } from '@app/components/binders/components/binder-form/binder-form.component.types';
import { DocumentLockingModalComponent } from '@app/components/documents/components/document-locking-modal/document-locking-modal.component';
import { TogglePiiComponent } from '@app/widgets/documents/toggle-pii/toggle-pii.component';
import { ApplyReusableStructureTemplateComponent } from '@app/widgets/apply-reusable-structure-template/apply-reusable-structure-template.component';
import { StudyStartupService } from '@app/components/study-startup/study-startup.service';
import { StructureTemplate } from '@app/components/study-startup/components/imported-structures-table/imported-structures-table.component.types';

import { DocumentService } from '@app/shared/documents/document.service';
import { DocumentFormComponent } from '@app/components/documents/components/document-form/document-form.component';
import { AuditTrailModalComponent } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { DocumentDownloadComponent } from '@app/components/documents/components/document-download/document-download.component';
import { AssignCategoryComponent } from '@app/components/documents/components/assign-category/assign-category.component';
import { AssignCategorySubmitEvent } from '@app/components/documents/components/assign-category/assign-category.component.types';
import { TeamService } from '@app/shared/teams/team.service';
import {
    Component, Inject, OnDestroy, OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import { SORT } from '@app/core/constants';
import { AuditTrailService } from '@app/shared/audit-trail/audit-trail.service';
import { AdapterService } from '@app/shared/adapter/adapter.service';
import { SelectionModel } from '@angular/cdk/collections';
import { DuplicateComponent } from '@app/components/duplicate/containers/duplicate/duplicate.component';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { LogCreateComponent } from '@app/components/documents/components/log-create/log-create.component';
import { DocumentsSignatureRequestsComponent } from '@app/components/signature-requests/containers/documents-signature-requests/documents-signature-requests.component';
import { JobTitleRequiredChangeComponent } from '@app/widgets/job-title-required-change/job-title-required-change.component';
import { SubmitEvent } from '@app/components/documents/components/document-form/document-form.component.types';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';
import { TaskFormComponent } from '@app/components/documents/components/task-form/task-form.component';
import { TaskSaveEvent } from '@app/components/documents/components/task-form/task-form.component.types';
import { FolderizerComponent } from '@app/components/folderizer/folderizer.component';
import { PermissionsEditComponent } from '@app/components/permissions/containers/permissions-edit/permissions-edit.component';
import { TimelineBulkAssignComponent } from '../timeline-bulk-assign/timeline-bulk-assign.component';
import { FolderDownloadOptions } from '../../components/folder-download/folder-download.component.types';
import { DocumentDownloadOptions } from '../../../documents/components/document-download/document-download.component.types';
import {
    DatesUpdateTypes, EmailDestination, ItemCounts,
    StateParams
} from './folder-index.types';
import { FolderFormComponent } from '../../components/folder-form/folder-form.component';
import { ShortcutsImportComponent } from '../../components/shortcuts-import/shortcuts-import.component';
import { ImportShorcutsEvent } from '../../components/shortcuts-import/shortcuts-import.component.types';
import { FolderOptions } from '../../components/folder-form/folder-form.component.types';
import { MoveComponent } from '../../components/move/move.component';
import { BrowseParams } from '../../../../shared/teams/teams.service.types';
import { ShortcutsExportComponent } from '../../components/shortcuts-export/shortcuts-export.component';
import { FolderDownloadComponent } from '../../components/folder-download/folder-download.component';
import { LogTemplateType } from '../../../log-templates/components/log-template-type-selector/log-template-type-selector.component.types';
import { DestroyFolderComponent } from '../../components/destroy-folder/destroy-folder.component';
import { FolderDestroyEvent } from '../../components/destroy-folder/destroy-folder.component.types';
import styles from './folder-show.component.scss';
import template from './folder-show.component.html';
import { ResolvePermissionsService } from './resolve-permissions.service';
import { CreateShortcutsEvent } from '../../components/shortcuts-export/shortcuts-export.component.types';
import { MoveFolderParams } from '../../components/move/move.component.types';

@Component({
    selector: 'folder-show',
    template,
    styles: [String(styles)]
})
export class FolderShowComponent implements OnInit, OnDestroy {
    @ViewChild(CdkVirtualScrollViewport, { static: false }) virtualScroll: CdkVirtualScrollViewport;

    readonly COMFORTABLE = 'comfortable';
    readonly COMPACT = 'compact';

    folder: BrowseTree;
    _currentTeam: Team;
    itemSelectionHelper = new SelectionModel<string>(true);
    selectedItems = { folders: [], docs: [] };
    disableMove: boolean;
    disableDuplicate: boolean;
    disableAuditTrail: boolean;
    disableEdit: boolean;
    disableDelete: boolean;
    disableDownload: boolean;
    disableAssignTags: boolean;
    disableAssignCategory: boolean;
    disableFolderImportTemplates: boolean;
    disableCreateShortcut: boolean;
    disableCreateTask: boolean;
    disableSetDueExpDate: boolean;
    disableTogglePii: boolean;
    disableLock: boolean;
    disableUnlock: boolean;
    disableMoveLocked: boolean;
    isConverting: boolean;
    loadingFolder = false;
    loadedFolder = false;
    loadingDocumentCounts = false;
    topIndex = 0;
    folderId: string;
    parentIsBinder = false;
    tableDensity: 'comfortable' | 'compact';
    filter: string;
    sortName: string;
    canArchiveFolder: boolean;
    canCreateDocument = false;
    canCreateLog = false;
    isDoaLogTemplatesFeatureFlagEnabled = false;
    checkingPermissions = false;
    currentlyHoveredItem: BrowseNode;
    crumbs: Crumb[];
    _clamped: boolean[] = [];
    openedActions = null;
    userPermissionsOnSelected = {
        documents: {},
        folders: {}
    };

    currentlyHoveredFolder: BrowseNode;

    archives: BindersArchives | FoldersArchives;
    selectedFolder: BrowseNode | null = null;
    private readonly destroy$ = new Subject<void>();

    datesUpdateType: DatesUpdateTypes;

    isApplyStructureOpen = false;
    isLogsTemplateStructureOpen = false
    templates: StructureTemplate[] = [];

    newDocViewer: boolean;

    SORT = SORT;
    emailDestination: EmailDestination;
    newDocumentViewerEnabled: boolean;

    lastSelectedIndex: number = null;
    actionsLabelStyle = {};

    readonly noScrollBarStyle = { flex: '0 0 auto' };

    constructor(
        @Inject('Window') private window: Window,
        private $state: StateService,
        private ResolvePermissions: ResolvePermissionsService,
        private Folders: FoldersService,
        private Binders: BindersService,
        private Teams: TeamService,
        private AuditTrails: AuditTrailService,
        private Adapters: AdapterService,
        private CurrentSession: CurrentSessionService,
        private Downloads: DownloadsService,
        private Notifications: NotificationsService,
        private Modals: ModalsService,
        private Labels: LabelsService,
        private StudyStartup: StudyStartupService,
        private ApiErrors: ApiErrorsService,
        private FeatureFlags: FeatureFlagService,
        private DocumentsService: DocumentService,
        private renderer: Renderer2
    ) {
        this.isConverting = this.Adapters.getIsPendingConversionState.bind(this.Adapters);

        this._disableFeatures();
    }

    ngOnInit(): void {
        this.selectedItems = { folders: [], docs: [] };

        this.folderId = this.$state.params.folderId || undefined;

        this.parentIsBinder = !this.folderId;

        this._currentTeam = this.CurrentSession.getCurrentTeam();
        this.newDocumentViewerEnabled = this._currentTeam.settings?.features?.newDocumentViewer;

        this.newDocViewer = !!this._currentTeam.settings.features.newDocumentViewer;
        this.loadArchives(this._currentTeam.id);

        this.tableDensity = this._currentTeam.settings.tableDensity === this.COMPACT ? this.COMPACT : this.COMFORTABLE;
        this.loadingFolder = true;
        const stateParams: StateParams = {
            binderId: this.$state.params.binderId || '',
            folderId: this.$state.params.folderId || '',
            teamId: this.$state.params.teamId || ''
        };
        this.Folders.browse({
            ...stateParams, includeDocs: true, includeDecorations: true, includeArchived: false
        })
            .subscribe(
                (folder) => {
                    this.folder = _.cloneDeep(folder);
                    this.canCreateDocument = this._canCreateDocument();
                    this.canCreateLog = this._canCreateLog();
                    this.canArchiveFolder = this.canArchive();
                    this.loadedFolder = true;
                    this.crumbs = this._getCrumbs();
                    this.updateSort('name', false);
                    this._permissionCheck();
                    this._loadDocumentCounts();

                    if (this.parentIsBinder) {
                        this.emailDestination = {
                            teamId: this.folder.teamId,
                            binderId: this.folder.binderId,
                            crumbsEntity: this.folder
                        };
                    }
                    else {
                        this.emailDestination = {
                            teamId: this.folder.teamId,
                            binderId: this.folder.binderId,
                            folderId: this.folder.id,
                            crumbsEntity: this.folder
                        };
                    }
                    this.loadingFolder = false;
                },
                (error) => {
                    this.ApiErrors.handleError(error);
                },
                () => {
                    this.loadingFolder = false;
                }
            );

        if (this._currentTeam.permissions.createTeamStudies) {
            this.StudyStartup.getStructureTemplates(this._currentTeam.id)
                .subscribe(
                    (data) => {
                        this.templates = data;
                    },
                    (error: ApiError): void => {
                        if (error.error && error.error.message) {
                            this.Notifications.error(error.error.message);
                        }
                        else {
                            this.Notifications.unexpectedError();
                        }
                    }
                );
        }

        this.FeatureFlags.getFlag(FEATURE_FLAGS.DOA_LOG_TEMPLATES, true)
            .pipe(
                filter((value) => value !== undefined),
                takeUntil(this.destroy$)
            )
            .subscribe((value) => {
                this.isDoaLogTemplatesFeatureFlagEnabled = !!value;
            });
    }

    ngAfterViewInit(): void {
        this.adjustLabelPositions();
        this.renderer.listen('window', 'resize', () => {
            this.adjustLabelPositions();
        });
    }

    // Used to calculate the position of Count, Last Modified and Action labels depending on if the windows scroll bar is present
    adjustLabelPositions(): void {
        if (this.virtualScroll && this.virtualScroll.elementRef.nativeElement) {

            const width = this.virtualScroll.elementRef.nativeElement.clientWidth;
            const offset = this.virtualScroll.elementRef.nativeElement.offsetWidth;

            if (width === 0 || offset === 0) {
                return;
            }

            this.actionsLabelStyle = Math.abs(offset - width) > 0
                ? { 'margin-right': `${offset - width}px` }
                : this.noScrollBarStyle;
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    browserResize(): void {
        setTimeout(() => {
            let event: UIEvent;
            if (typeof (Event) === 'function') {
                // modern browsers
                event = new UIEvent('resize');
            }
            else {
                // for IE and other old browsers
                event = this.window.document.createEvent('UIEvents');
                event.initUIEvent('resize', true, false, window, 0);
            }
            this.window.dispatchEvent(event);
        });
    }

    _loadDocumentCounts(): void {

        const folderIds = this.folder.items.reduce((accumulator, currentItem) => {

            if (currentItem.subType === 'folder') {
                accumulator.push(currentItem.id);
            }

            return accumulator;
        }, []);

        if (!folderIds.length) {
            this._decorateFolderItems({});
            return;
        }

        this.loadingDocumentCounts = true;
        this.Folders.documentCounts({ teamId: this.folder.teamId, folderIds })
            .subscribe(
                (response) => {
                    const folderCounts: ItemCounts = response.reduce((accumulator, current) => {
                        accumulator[current._id] = current;
                        return accumulator;
                    }, {});

                    this._decorateFolderItems(folderCounts);
                    this.loadingDocumentCounts = false;
                    this.browserResize();
                },
                (error) => {
                    this._handleErrorNotification(error);
                    _.noop;
                }
            );
    }

    _decorateFolderItems(itemCounts: ItemCounts): void {
        this.folder.items.forEach((item) => {
            if (browseNodeIsFolder(item)) {
                const countData = itemCounts[item.id];
                if (countData) {
                    item.documentCount = countData.count;
                    item.updatedAt = _.max([item.updatedAt, countData.lastUpdated]);
                }
                else {
                    item.documentCount = 0;
                }

                this._decorateItemState(item);
                item.href = this.generateItemHref(item);
            }
            else if (browseNodeIsDocument(item)) {
                const countData = itemCounts[item.id];
                if (countData) {
                    item.updatedAt = _.max([item.updatedAt, countData.lastUpdated]);
                }

                this._decorateItemState(item);
                item.href = this.generateItemHref(item);

                if (item.formStatus === 'form-in-progress') {
                    item.sortType = 'form';
                }
                else if (item.subType !== 'content') {
                    item.sortType = item.subType;
                }
                else {
                    item.sortType = item.ext;
                }
            }
        });
    }


    _decorateItemState(item: BrowseNode): void {
        const state = {
            conversion: this.Adapters.getConversionState(item),
            due: this.Adapters.getDueState(item),
            expiration: this.Adapters.getExpirationState(item),
            signatures: this.Adapters.getSignatures(item)
        };
        Object.assign(item, { state });
    }

    private generateItemHref(item: BrowseNode): string {
        const params = { teamId: this._currentTeam.id };
        let href = '';

        if (item.type === 'folder') {
            href = this.$state.href('app.team.folder', {
                ...params,
                binderId: item.binderId,
                folderId: item.id
            });
        }
        else {
            href = this.$state.href('app.team.document-show', {
                ...params,
                documentId: item.id,
                version: item.version,
                contentVersion: item.originalDocument?.version
            });
        }

        return href;
    }

    private userHasRequestSignaturePermission = (document: Document): boolean => {
        const id = document.id as string;
        const documentPermissions = this.userPermissionsOnSelected.documents[id];
        if (document.hasPii) {
            return documentPermissions && documentPermissions.requestSignature && documentPermissions.viewPiiInDocument;
        }
        return documentPermissions && documentPermissions.requestSignature
            && (documentPermissions.viewNonPiiInDocument || documentPermissions.viewPiiInDocument);
    }

    private getUserPermissionsForSelection({ objectId, objectType }): Observable<{ [key: string]: boolean }> {
        return this.Teams.getUserObjectPermissions(objectId, objectType)
            .pipe(
                map((response) => {
                    this.userPermissionsOnSelected[objectType][objectId] = response;
                    return response;
                })
            );
    }

    _permissionCheck(): void {
        this.checkingPermissions = true;
        if (!(this.selectedItems.docs.length || this.selectedItems.folders.length)) {
            return;
        }

        this.userPermissionsOnSelected = {
            documents: {},
            folders: {}
        };

        this.ResolvePermissions.resolvePermissions({
            ...this.selectedItems,
            resolve: this.getUserPermissionsForSelection.bind(this)
        }).subscribe((permissions) => {
            Object.assign(this, permissions);
            this.canArchiveFolder = this.canArchive();
            this.checkingPermissions = false;
        });
    }

    _disableFeatures(): void {
        this.disableMove = true;
        this.disableDuplicate = true;
        this.disableAuditTrail = true;
        this.disableEdit = true;
        this.disableDelete = true;
        this.disableDownload = true;
        this.disableAssignTags = true;
        this.disableAssignCategory = true;
        this.disableFolderImportTemplates = true;
        this.disableCreateShortcut = true;
        this.disableCreateTask = true;
        this.disableSetDueExpDate = true;
        this.disableTogglePii = true;
        this.disableLock = true;
        this.disableUnlock = true;
        this.disableMoveLocked = true;
    }

    _clearSelectedItems(): void {
        this.itemSelectionHelper.clear();
        this.selectedItems.docs = [];
        this.selectedItems.folders = [];
        this.selectedFolder = null;
    }

    loadItemLabels(item: BrowseNode): void {
        this.currentlyHoveredItem = item;
        if (!browseNodeIsFolder(item) || item.directLabels) {
            return;
        }

        this.Folders.getDirectLabels({ teamId: this._currentTeam.id, binderId: item.binderId, folderId: item.id })
            .subscribe(
                (labels) => {
                    item.directLabels = labels;
                },
                (this._handleErrorNotification)
            );
    }

    loadItemStudyProfile(item: BrowseNode): void {
        this.currentlyHoveredItem = item;
        if (!browseNodeIsFolder(item) || !item.hasStudyProfile || item.studyProfiles) {
            return;
        }

        this.Folders.getFolderStudyProfile({ teamId: this._currentTeam.id, binderId: item.binderId, folderId: item.id })
            .subscribe(
                (allStudyProfiles: Study[]) => {
                    item.studyProfiles = (allStudyProfiles && allStudyProfiles.length === 1) ? allStudyProfiles : [];
                },
                (err) => {
                    if (err.status === 403) {
                        return [];
                    }
                    this._handleErrorNotification(err);
                }
            );
    }

    sortMonitorReviewData(item): void {
        if (!item.monitorReviewData || item.monitorReviewData._isSorted) {
            return;
        }
        item.monitorReviewData.approvals = _.sortBy(item.monitorReviewData.approvals, ['paywallName', 'studyLabelValueName', 'documentVersion']);
        item.monitorReviewData.reviews = _.sortBy(item.monitorReviewData.reviews, ['paywallName', 'studyLabelValueName', 'documentVersion']);
        item.monitorReviewData.openQueries = _.sortBy(item.monitorReviewData.openQueries, ['paywallName', 'studyLabelValueName']);
        item.monitorReviewData._isSorted = true;
    }

    teamEnableCategory(): boolean {
        return this._currentTeam && this._currentTeam.settings.features.documentCategories;
    }

    canAssignCategory(): boolean {
        return this.teamEnableCategory()
            && (this.selectedItems.docs.length === 1 && this.selectedItems.folders.length === 0)
            && !this.selectedItems.docs[0].isShortcut
            && !this.disableAssignCategory
            && !this.isAnySelectedDocumentLocked();
    }

    canAssignLabels(): boolean {
        return _.get(this, '_currentTeam.permissions.labelAssign') && _.get(this, '_currentTeam.settings.features.labels');
    }

    canCreateFolder(): boolean {
        return !!_.get(this, 'folder.permissions.createFolder', false);
    }

    _canCreateDocument(): boolean {
        return !!_.get(this, 'folder.permissions.createDocument', false);
    }

    private _canCreateLog(): boolean {
        return _.get(this, '_currentTeam.settings.features.logTemplates', false)
            && _.get(this, 'folder.permissions.createLog', false);
    }

    private isAnySelectedDocumentLocked(): boolean {
        return this.selectedItems.docs.some((doc: Document) => doc.isLocked);
    }

    private isAnySelectedDocumentUnlocked(): boolean {
        return this.selectedItems.docs.some((doc: Document) => !doc.isLocked);
    }

    private areAllSelectedDocumentUnlocked(): boolean {
        return this.selectedItems.docs.every((doc: Document) => !doc.isLocked);
    }

    private areAllSelectedDocumentLocked(): boolean {
        return this.selectedItems.docs.every((doc: Document) => doc.isLocked);
    }

    private isAnySelectedDocumentPlaceholderShortcut(): boolean {
        return this.selectedItems.docs
            .some((doc: Document) => doc.isShortcut && doc.originalDocumentSubType === DocumentSubTypes.placeholder);
    }

    private isAnySelectedDocumentPlaceholder(): boolean {
        return this.selectedItems.docs
            .some((doc: Document) => doc.subType === DocumentSubTypes.placeholder);
    }

    private isAnySelectedDocumentContentOrLog(): boolean {
        return this.selectedItems.docs
            .some((doc: Document) => doc.subType === DocumentSubTypes.content || doc.subType === DocumentSubTypes.log);
    }

    canCreatePlaceholder(): boolean {
        return !!_.get(this, 'folder.permissions.createDocument', false);
    }

    canImportShortcuts(): boolean {
        return !!_.get(this, 'folder.permissions.createDocument', false);
    }

    canAssignTags(): boolean {
        return this.selectedItems.docs.length === 1
            && this.selectedItems.folders.length === 0
            && !this.disableAssignTags
            && !this.isAnySelectedDocumentLocked();
    }

    canAssignToTimelines(): boolean {
        return this.selectedItems.docs.length > 0
            && this.selectedItems.folders.length === 0
            && this._currentTeam
            && this._currentTeam.permissions.assignToTimelines
            && !this.isAnySelectedDocumentLocked();
    }

    canVewAuditTrail(): boolean {
        return ((this.selectedItems.docs.length === 1 && this.selectedItems.folders.length === 0)
            || (this.selectedItems.docs.length === 0 && this.selectedItems.folders.length === 1))
            && !this.disableAuditTrail;
    }

    isShortcutSelected(): boolean {
        return this.selectedItems.docs.some((doc) => doc.subType === 'shortcut');
    }

    canCreateShortcuts(): boolean {
        const isFolderSelected = this.selectedItems.folders && this.selectedItems.folders.length;
        const isLogSelected = this.selectedItems.docs.some((doc) => doc.subType === 'log');

        return !(isFolderSelected || this.isShortcutSelected() || isLogSelected || this.disableCreateShortcut)
            && !this.isAnySelectedDocumentLocked();
    }

    canSetDueExpDates(): boolean {
        return this.selectedItems.docs.length > 0
            && this.selectedItems.folders.length === 0
            && !this.isShortcutSelected()
            && !this.disableSetDueExpDate
            && !this.isAnySelectedDocumentLocked();
    }

    canCreateTasks(): boolean {
        return this.selectedItems.docs.length === 1
            && this.selectedItems.folders.length === 0
            && this.selectedItems.docs[0].subType !== 'log'
            && !this.disableCreateTask
            && !this.isAnySelectedDocumentLocked();
    }

    canDestroy(): boolean {
        return ((this.selectedItems.docs.length > 0 && this.selectedItems.folders.length === 0)
            || (this.selectedItems.docs.length === 0 && this.selectedItems.folders.length === 1))
            && !this.disableDelete
            && !this.isAnySelectedDocumentLocked();
    }

    applyStructureClick($event: PointerEvent) {
        this.isApplyStructureOpen = !this.isApplyStructureOpen;
        $event.stopPropagation();
    }

    logsTemplateStructure($event: PointerEvent) {
        this.isLogsTemplateStructureOpen = !this.isLogsTemplateStructureOpen;
        $event.stopPropagation();
    }

    canImportFolderStructure(): boolean {
        const noDocumentsSelected = !this.selectedItems.docs || this.selectedItems.docs.length === 0;
        const oneFolderSelected = this.selectedItems.folders.length === 1;

        return noDocumentsSelected
            && oneFolderSelected
            && !this.disableFolderImportTemplates;
    }

    canApplyReusableStructureTemplate(): boolean {
        const noDocumentsSelected = !this.selectedItems.docs || this.selectedItems.docs.length === 0;
        const oneFolderSelected = this.selectedItems.folders.length === 1;

        return noDocumentsSelected
            && oneFolderSelected
            && this._currentTeam.permissions.createTeamStudies
            && this.templates.length > 0;
    }

    canDownload(): boolean {
        return ((this.selectedItems.docs.length > 0 && this.selectedItems.folders.length === 0)
            || (this.selectedItems.docs.length === 0 && this.selectedItems.folders.length > 0))
            && !this.disableDownload && !this.selectedItems.docs.some((doc) => doc.isBrokenShortcut);
    }

    canDuplicate(): boolean {
        return ((this.selectedItems.docs.length > 0 && this.selectedItems.folders.length === 0)
            || (this.selectedItems.docs.length === 0 && this.selectedItems.folders.length > 0))
            && this.selectedItems.docs.every((doc) => doc.subType !== 'shortcut' && doc.subType !== 'log')
            && !this.disableDuplicate;
    }

    canMove(): boolean {
        return (
            (this.selectedItems.docs.length > 0 && this.selectedItems.folders.length === 0)
            || (this.selectedItems.docs.length === 0 && this.selectedItems.folders.length > 0)
        )
            && (
                (!this.disableMove && this.areAllSelectedDocumentUnlocked())
                || (!this.disableMoveLocked && this.areAllSelectedDocumentLocked())
                || (
                    !this.disableMove
                    && !this.disableMoveLocked
                    && this.isAnySelectedDocumentLocked()
                    && this.isAnySelectedDocumentUnlocked())
            );
    }

    canTogglePii(): boolean {
        return (this.selectedItems.docs.length > 0 && this.selectedItems.folders.length === 0)
            && this.selectedItems.docs.every((doc) => doc.subType === 'content' || doc.subType === 'log')
            && !this.disableTogglePii
            && !this.isAnySelectedDocumentLocked();
    }

    canMarkPii(): boolean {
        return this.canTogglePii()
            && this.selectedItems.docs.some((doc) => doc.hasPii === false);
    }

    canUnmarkPii(): boolean {
        return this.canTogglePii()
            && this.selectedItems.docs.some((doc) => doc.hasPii === true);
    }

    canLock(): boolean {
        return this.selectedItems.docs.length > 0
            && !this.disableLock
            && this.selectedItems.docs.every((doc: Document) => {
                return doc.subType === DocumentSubTypes.log
                    || doc.subType === DocumentSubTypes.shortcut
                    || doc.subType === DocumentSubTypes.content;
            })
            && this.isAnySelectedDocumentUnlocked()
            && !this.isAnySelectedDocumentPlaceholderShortcut()
            && !(this.selectedItems.docs.length === 1 && this.selectedItems.docs[0].isBrokenShortcut);
    }

    canUnlock(): boolean {
        return this.selectedItems.docs.length > 0
            && !this.disableUnlock
            && this.selectedItems.docs.every((doc: Document) => {
                return doc.subType === DocumentSubTypes.log
                    || doc.subType === DocumentSubTypes.shortcut
                    || doc.subType === DocumentSubTypes.content;
            })
            && !this.isAnySelectedDocumentPlaceholderShortcut()
            && this.isAnySelectedDocumentLocked();
    }

    canEditPermissions(): boolean {
        return ((this.selectedItems.docs.length === 1 && this.selectedItems.folders.length === 0)
            || (this.selectedItems.docs.length === 0 && this.selectedItems.folders.length === 1))
            && this._currentTeam
            && this._currentTeam.permissions.manageAccessAndTeamPermissions;
    }

    canEdit(): boolean {
        let isEditable = false;
        if (this.selectedItems.docs.length === 1 && this.selectedItems.folders.length === 0) {
            isEditable = !this.disableEdit && !this.isAnySelectedDocumentLocked();
        }
        else if (this.selectedItems.docs.length === 0 && this.selectedItems.folders.length === 1) {
            isEditable = _.get(this, 'folder.permissions.renameFolder', false) || this.canAssignLabels();
        }
        return isEditable;
    }

    private getApprovalStatus(document: Document): string {
        return document
            && document.documentProperties
            && document.documentProperties.approval
            && document.documentProperties.approval.status;
    }

    private isCancelledOrRejected(document: Document): boolean {
        const currentStatus = this.getApprovalStatus(document);
        return currentStatus === 'cancelled' || currentStatus === 'rejected';
    }

    private canRequestSignatureFileType(): boolean {
        if (this.selectedItems.docs.length > 0
            && this.selectedItems.folders.length === 0
            && this.selectedItems.docs.every((d: Document) => d.subType === DocumentSubTypes.content
                || (d.subType === DocumentSubTypes.shortcut && d.originalDocumentSubType === DocumentSubTypes.content))
        ) {
            return (this.newDocViewer
                && this.selectedItems.docs.some((d: Document) => ALLOWED_PREVIEW_FILE_TYPES.includes(d.ext?.toLowerCase())))
                || (!this.newDocViewer && this.selectedItems.docs.some((d: Document) => d.pageCount));
        }
        return true;
    }

    canRequestSignature(): boolean {
        return this.selectedItems.docs.length > 0
            && this.selectedItems.folders.length === 0
            && this.selectedItems.docs.some(this.userHasRequestSignaturePermission)
            && !this.selectedItems.docs.some((d: Document) => {
                return d.isBrokenShortcut
                    || this.isCancelledOrRejected(d)
                    || (d.subType === DocumentSubTypes.placeholder)
                    || (d.subType === DocumentSubTypes.shortcut && d.originalDocumentSubType === DocumentSubTypes.placeholder);
            })
            && !this.isAnySelectedDocumentLocked()
            && this.canRequestSignatureFileType();
    }

    canActOnCreate(): boolean {
        return this.canCreateFolder()
            || this.canCreatePlaceholder()
            || this.canImportShortcuts()
            || this._canCreateLog();
    }

    canOpenGlobalView(): boolean {
        return this.selectedItems.folders.length === 1;
    }

    canActOnSelection(): boolean {
        if (!(this.selectedItems.docs.length + this.selectedItems.folders.length)) {
            return false;
        }
        if (this.checkingPermissions) {
            return false;
        }

        return this.canAssignCategory()
            || this.canAssignTags()
            || this.canAssignToTimelines()
            || this.canVewAuditTrail()
            || this.canCreateShortcuts()
            || this.canCreateTasks()
            || this.canImportFolderStructure()
            || this.canApplyReusableStructureTemplate()
            || this.canDestroy()
            || this.canDownload()
            || this.canDuplicate()
            || this.canMove()
            || this.canEditPermissions()
            || this.canEdit()
            || this.canMarkPii()
            || this.canUnmarkPii()
            || this.canOpenGlobalView()
            || this.canLock()
            || this.canUnlock();
    }

    canUseInlineActions(): boolean {
        return (this.selectedItems.folders.length + this.selectedItems.docs.length) <= 1;
    }

    createFolder(): void {
        if (!this.canCreateFolder()) {
            return;
        }
        const { teamId, binderId } = this.folder;
        const { folderId } = this;

        this._clearSelectedItems();
        if (this.canAssignLabels()) {
            forkJoin({
                availableLabels: this.Labels.getLabels(teamId),
                assignedLabels: folderId ? this.Folders.getDirectLabels({ teamId, binderId, folderId })
                    : this.Binders.getDirectLabels(teamId, binderId)
            }).subscribe(({ availableLabels, assignedLabels }) => {
                const modalRef = this.Modals.show(FolderFormComponent, {
                    animated: true,
                    initialState: {
                        availableLabels,
                        assignedLabels
                    }
                });

                this.createFolderModalSave(modalRef, teamId, binderId, folderId);
            });
        }
        else {
            const modalRef = this.Modals.show(FolderFormComponent, {
                animated: true,
                initialState: {}
            });

            this.createFolderModalSave(modalRef, teamId, binderId, folderId);
        }

    }


    createFolderModalSave(
        modalRef: ModalRef<FolderFormComponent>,
        teamId: string,
        binderId: string,
        folderId: string
    ): void {
        modalRef.content.save.subscribe(({
            name, addedLabels, onSave, onError
        }): void => {
            const nameTrimmed = name.trim();

            this.Folders.create({
                teamId,
                binderId,
                folderId,
                name: nameTrimmed
            }).pipe(
                switchMap((response) => of(this._insertItem(response))),
                tap(({ id }): void => {
                    if (addedLabels && addedLabels.length) {
                        addedLabels.forEach((label) => {
                            label.objectId = id;
                        });

                        this.Labels.assignLabels(teamId, addedLabels).subscribe(
                            (labels: LabeledEntity[]): void => {
                                const folder = _.find(this.folder.items, { id: labels[0].objectId });
                                if (browseNodeIsFolder(folder)) {
                                    folder.labels = labels;
                                }
                            }
                        );
                    }
                    this.Notifications.success('Folder created.');
                    if (onSave) {
                        onSave();
                    }
                    else {
                        modalRef.hide();
                    }
                }),
                catchError(({ error }: ApiError) => {
                    if (onError) {
                        onError();
                    }
                    if (error.message) {
                        this.Notifications.error(error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                    return of();
                })
            ).subscribe();
        });
    }


    createDocuments(files: FileList): void {
        if (!files) {
            return;
        }

        from(files).pipe(
            mergeMap((file) => this.DocumentsService.create(this.folder.teamId, this.folder.binderId, this.folderId, file))
        ).subscribe(
            (document) => this._insertItem(document)
        );
    }

    createPlaceholder(): void {
        if (!this.canCreatePlaceholder()) {
            return;
        }
        this._clearSelectedItems();
        const createPlaceholderForm = this.Modals.show(DocumentFormComponent, {
            animated: true,
            class: 'modal-md',
            initialState: {
                formType: 'create-placeholder'
            }
        });
        createPlaceholderForm.content.submit.subscribe((filename) => {
            const { data: { name } } = filename;

            this.Folders.createPlaceholder({
                teamId: this.folder.teamId,
                binderId: this.folder.binderId,
                folderId: this.folderId,
                filename: name
            }).pipe(
                switchMap((response) => of(this._insertItem(response))),
                tap(() => this.Notifications.success(`${name} placeholder created.`))
            ).subscribe();

            createPlaceholderForm.hide();
        });
    }

    createLog(templateType?: LogTemplateType): void {
        if (!this.canCreateLog) {
            return;
        }

        this._clearSelectedItems();

        const logCreateModal = this.Modals.show(LogCreateComponent, {
            animated: true,
            class: 'modal-md',
            initialState: {
                templateType: templateType || LogTemplateType.ELOG,
                team: this._currentTeam
            }
        });

        logCreateModal.content.dismiss.subscribe(() => {
            logCreateModal.hide();
        });

        logCreateModal.content.save.subscribe((event: LogCreateEvent) => {
            const params = {
                teamId: this.folder.teamId,
                binderId: this.folder.binderId,
                folderId: this.folderId,
                filename: event.data.name,
                logTemplateId: event.data.templateId,
                detailSelections: event.data.detailSelections,
                columnSelections: event.data.columnSelections
            };

            this.DocumentsService.createLog(params).subscribe(
                (doc) => {
                    this._insertItem(doc);
                    event.onSuccess();
                },
                () => {
                    event.onError();
                }
            );
        });
    }

    canCreateLogAndDoaFeatureFlagIsEnabled(): boolean {
        return this.canCreateLog && this.isDoaLogTemplatesFeatureFlagEnabled;
    }

    importShortcuts(): void {
        if (!this.canImportShortcuts()) {
            return;
        }

        const modalRef = this.Modals.show(ShortcutsImportComponent, {
            class: 'modal-lg',
            initialState: {
                entity: this.folder,
                loadRoot: () => this.Binders
                    .getBinders(this._currentTeam.id, { includeArchived: false })
                    .toPromise()
                    .then((binders) => sortByLexicographically(binders, 'name'))
                    .catch(this._handleErrorNotification),
                loadItem: (params = {} as BrowseParams) => {
                    return this.Teams
                        .browse(this._currentTeam.id, { ...params, includeDocs: true, includeArchived: false })
                        .toPromise()
                        .then((data) => sortBrowseTreeLexicographically(data, 'name'));
                }
            }
        });

        modalRef.content.save.subscribe((params: ImportShorcutsEvent) => {
            const targets = [this.folder];
            const documentIds = params.selectedItems.map((item) => item.id.toString());
            this.Teams.createShortcuts(this._currentTeam.id, documentIds, targets)
                .subscribe(
                    (result) => {
                        this._onShortcutsCreated(result);
                        params.onSuccess();
                    },
                    () => {
                        params.onError();
                    }
                );
        });
    }

    openGlobalView(): void {
        if (!this.canOpenGlobalView()) {
            return;
        }

        const folder = this.selectedItems.folders[0];
        this.$state.go('app.team.global-view', {
            teamId: folder.teamId,
            objectId: folder.id,
            objectType: folder.type,
            objectName: folder.name
        });
    }

    assignTags(): void {
        if (!this.canAssignTags()) {
            return;
        }
        const entity = this.selectedItems.docs[0];

        const modalRef = this.Modals.show(TagsAssignComponent, {
            initialState: {
                currentTags: entity.tags,
                entity
            }
        });

        modalRef.content.save.subscribe((event: SaveEventPayload) => {
            const tagIds = event.tagsToAssign.map((tag) => tag.id);
            modalRef.content.isProcessing = true;
            from(this.Teams.assignTags(entity.id, entity.type, tagIds))
                .subscribe(
                    () => {
                        event.onSuccess();
                        this.selectedItems.docs[0].tags = event.tagsToAssign;
                    },
                    () => event.onError()
                );
        });
    }

    assignToTimelines(): void {
        if (!this.canAssignToTimelines()) {
            return;
        }

        const selectedDocuments = this.selectedItems.docs as Document[];
        const folder = this.folder as unknown as Folder;
        if (selectedDocuments.length > 1) {
            const modalRef = this.Modals.show(TimelineBulkAssignComponent, {
                class: 'modal-md',
                initialState: {
                    documents: selectedDocuments,
                    parentEntity: folder
                }
            });

            modalRef.content.timelinesAssigned
                .pipe(take(1))
                .subscribe((timelines) => {
                    this.updateProjectsStateAfterBulkAssignToDocuments(selectedDocuments, timelines);
                });
            return;
        }

        const modalRef = this.Modals.show(TimelineAssignComponent, {
            initialState: {
                document: {
                    ...selectedDocuments[0],
                    binderName: folder.binderName
                }
            }
        });

        modalRef.content.documentTimelinesUpdated
            .pipe(take(1))
            .subscribe((timelines) => {
                selectedDocuments[0].timelinesCount = timelines.length;
                selectedDocuments[0].projects = mapTimelinesToDocumentProjects(timelines);
            });
    }

    assignCategory() {
        if (!this.canAssignCategory()) {
            return;
        }

        const document = this.selectedItems.docs[0];

        forkJoin({
            permission: this.Teams.getUserObjectPermissions(document.id, `${document.type}s`),
            categories: this.Teams.getCategories(this._currentTeam.id)
        }).subscribe((data) => {
            const assignCategoryForm = this.Modals.show(AssignCategoryComponent, {
                animated: true,
                class: 'modal-md',
                initialState: {
                    categories: data.categories,
                    document,
                    canUpdateCategory: data.permission.updateDocumentCategory,
                    canUpdateStatus: data.permission.updateDocumentStatus
                }
            });

            assignCategoryForm.content.onStatusUpdate.subscribe((event: AssignCategorySubmitEvent) => {
                this.DocumentsService.updateDocumentCategory(document.id, event)
                    .subscribe(
                        (doc: Document) => {
                            document.documentCategory = doc.documentCategory;
                            event.onSuccess();
                        },
                        () => {
                            event.onError();
                            return undefined;
                        }
                    );
            });
        });
    }

    viewAuditTrail(): void {
        if (!this.canVewAuditTrail()) {
            return;
        }

        const item = this.selectedItems.folders[0] || this.selectedItems.docs[0];
        this.Teams.getUserObjectPermissions(item.id, `${item.type}s`)
            .subscribe(
                (perms) => {
                    item.permissions = perms;

                    const params = {
                        subject: item.type,
                        teamId: item.teamId,
                        objectId: item.id,
                        overwrittenObjectId: item.overwrittenPlaceholderId || null,
                        limitToOverwritten: false,
                        ...this.AuditTrails.auditPagination
                    };

                    this.AuditTrails.getAudits(params).subscribe((audits) => {
                        this.Modals.show(AuditTrailModalComponent, {
                            class: 'modal-lg',
                            animated: true,
                            initialState: {
                                data: audits,
                                item,
                                subject: params.subject,
                                pagination: this.AuditTrails.auditPagination,
                                onPageChange: this.AuditTrails.getAudits.bind(this.AuditTrails)
                            }
                        });
                    }, (error) => {
                        this._handleErrorNotification(error);
                    });
                }
            );
    }

    createShortcuts(): void {
        if (!this.canCreateShortcuts()) {
            return;
        }

        const exportModal = this.Modals.show(ShortcutsExportComponent, {
            class: 'modal-lg',
            initialState: {
                loadRoot: () => this.Binders
                    .getBinders(this._currentTeam.id, { includeArchived: false })
                    .toPromise()
                    .then((binders) => sortByLexicographically(binders, 'name'))
                    .catch(this._handleErrorNotification),
                loadItem: (params = {} as BrowseParams) => this.Teams
                    .browse(this._currentTeam.id, { ...params, includeArchived: false })
                    .toPromise()
                    .then((data) => sortBrowseTreeLexicographically(data, 'name')),
                entity: this.folder,
                documents: this.selectedItems.docs
            }
        });

        exportModal.content.save
            .subscribe((event: CreateShortcutsEvent) => {
                const documentIds = this.selectedItems.docs.map((item) => item.id);
                const targets = [];
                if (Array.isArray(event.data)) {
                    event.data.forEach((document: Document) => {
                        targets.push({
                            id: document.id,
                            type: document.type
                        });
                    });
                }
                else {
                    targets.push({
                        id: event.data.id,
                        type: event.data.type
                    });
                }
                return this.Teams.createShortcuts(this._currentTeam.id, documentIds, targets)
                    .subscribe(
                        (result) => {
                            this._onShortcutsCreated(result);
                            event.onSuccess();
                        }
                    );
            });
    }

    togglePii(hasPii: boolean): void {
        const modalRef = this.Modals.show(TogglePiiComponent, {
            animated: true,
            initialState: {
                documents: this.selectedItems.docs,
                setPii: hasPii
            }
        });

        modalRef.content.onPiiUpdate.subscribe((setPiiResponse: SetPiiResponse) => {
            this.onPiiUpdate(setPiiResponse.documents, hasPii);
        });
    }

    onPiiUpdate(documents: Document[], hasPii: boolean): void {
        const docIds = documents.map((d) => d.id);
        this.folder.items.filter((item) => {
            if (browseNodeIsDocument(item)) {
                return docIds.includes(item.id)
                || (item.shortcutOf && !item.isLocked && docIds.includes(item.shortcutOf.documentId));
            }
            return false;
        }).forEach((doc) => {
            if (browseNodeIsDocument(doc)) {
                doc.hasPii = hasPii;
            }
        });
    }


    requestSignatures(): void {
        if (!this.canRequestSignature()) {
            return;
        }

        const documents = [];
        this.selectedItems.docs.forEach((d) => {
            const hasPermission = this.userHasRequestSignaturePermission(d);
            if (hasPermission) {
                d.binderName = this.folder.binderName;
                documents.push(d);
            }
        });

        const requestsSignatureModal = this.Modals.show(DocumentsSignatureRequestsComponent, {
            class: 'modal-lg',
            animated: true,
            initialState: {
                initiallySelectedDocuments: documents,
                userLacksRequestSignaturePermissions: this.selectedItems.docs.length > documents.length,
                newDocViewer: this.newDocViewer
            }
        });

        requestsSignatureModal.content.dismiss.subscribe(() => {
            requestsSignatureModal.hide();
        });
    }

    createTask(): void {
        if (!this.canCreateTasks()) {
            return;
        }
        const [document] = this.selectedItems.docs;

        this.Teams.getTaskUsers(document.teamId, document.id, document.type)
            .subscribe((users) => {
                const createTaskModal = this.Modals.show(TaskFormComponent, {
                    animated: true,
                    class: 'modal-md',
                    initialState: {
                        doc: document,
                        users,
                        isCreate: true
                    }
                });
                createTaskModal.content.onSave.subscribe((event: TaskSaveEvent) => {
                    const { task } = event;
                    this.Teams.createTask(document.teamId, task)
                        .subscribe(
                            () => {
                                event.onSuccess();
                                this.$state.reload();
                            },
                            () => {
                                event.onError();
                            }
                        );
                });
            });
    }

    openSetDateModal(): void {
        if (!this.canSetDueExpDates()) {
            return;
        }

        const modalRef = this.Modals.show(DocumentBulkSetDatesComponent, {
            class: 'modal-xl',
            initialState: {
                documents: this.selectedItems.docs,
                parentEntity: this.folder as unknown as Binder | Folder
            }
        });

        modalRef.content.update.subscribe((updates: BulkSetDatesUpdate[]) => {
            modalRef.content.isProcessing = true;

            this.DocumentsService.bulkUpdateDueAndExpirationDates(updates)
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                    (docsUpdated) => {
                        this.handleBulkUpdateDatesNotifications(docsUpdated);
                        modalRef.content.isProcessing = false;
                        modalRef.hide();
                        this.$state.reload();
                    },
                    (error) => {
                        modalRef.content.isProcessing = false;
                        this._handleErrorNotification(error);
                        modalRef.hide();
                        this.$state.reload();
                    }
                );
        });
    }

    private handleBulkUpdateDatesNotifications(updates: BulkUpdateDueAndExpirationDatesResponseItem[]): void {
        const notificationsMap = {
            success: {
                expirationDateAddedCounter: 0,
                expirationDateRemovedCounter: 0,
                dueDateAddedCounter: 0,
                dueDateRemovedCounter: 0
            },
            errors: {}
        };

        updates.forEach((item) => {
            if (item.statusCode === 200) {
                if (item.document.docEventName === AuditTrailEventType.DOCUMENT_DUE_DATE_SET) {
                    if (item.document.originalDoc.dueDate && !item.document.finalDoc.dueDate) {
                        notificationsMap.success.dueDateRemovedCounter += 1;
                    }
                    else {
                        notificationsMap.success.dueDateAddedCounter += 1;
                    }
                }

                if (item.document.docEventName === AuditTrailEventType.DOCUMENT_EXP_DATE_SET) {
                    if (item.document.originalDoc.expirationDate && !item.document.finalDoc.expirationDate) {
                        notificationsMap.success.expirationDateRemovedCounter += 1;
                    }
                    else {
                        notificationsMap.success.expirationDateAddedCounter += 1;
                    }
                }
            }
            else {
                if (!notificationsMap.errors[item.error.message]) {
                    notificationsMap.errors[item.error.message] = 1;
                }
                else {
                    notificationsMap.errors[item.error.message] += 1;
                }
            }
        });

        const {
            expirationDateAddedCounter,
            expirationDateRemovedCounter,
            dueDateAddedCounter,
            dueDateRemovedCounter
        } = notificationsMap.success;

        if (expirationDateAddedCounter) {
            this.Notifications.success(`Expiration Date added to ${expirationDateAddedCounter} document(s)`);
        }

        if (expirationDateRemovedCounter) {
            this.Notifications.success(`Expiration Date removed from ${expirationDateRemovedCounter} document(s)`);
        }

        if (dueDateAddedCounter) {
            this.Notifications.success(`Due Date added to ${dueDateAddedCounter} placeholder(s)`);
        }

        if (dueDateRemovedCounter) {
            this.Notifications.success(`Due Date removed from ${dueDateRemovedCounter} placeholder(s)`);
        }

        Object.keys(notificationsMap.errors).forEach((key) => {
            if (key === 'undefined') {
                return this.Notifications.unexpectedError();
            }

            this.Notifications.error(String(key));
        });
    }

    importFolderStructure(): void {
        if (!this.canImportFolderStructure()) {
            return;
        }

        this.Modals.show(FolderizerComponent, {
            animated: true,
            class: 'modal-lg',
            initialState: {
                item: this.selectedItems.folders[0],
                timezone: this._currentTeam.settings.timezone
            }
        });
    }

    applyReusableStructureTemplate(): void {
        if (!this.canApplyReusableStructureTemplate()) {
            return;
        }

        this.Modals.show(ApplyReusableStructureTemplateComponent, {
            initialState: {
                teamId: this._currentTeam.id,
                teamTimezone: this._currentTeam.settings.timezone,
                templates: this.templates,
                binderId: this.selectedItems.folders[0].binderId,
                folderId: this.selectedItems.folders[0].id,
                canManageDocumentDueAndExpirationDates: this._currentTeam.permissions.manageDocumentDates
            }
        });
    }

    destroy() {
        if (!this.canDestroy()) {
            return;
        }

        if (this.selectedItems.folders.length === 1 && this.selectedItems.docs.length === 0) {
            const { teamId, binderId } = _.clone(this.$state.params);

            this.Teams.hasOriginalDocuments(this._currentTeam.id, {
                objectType: 'folders',
                objectIds: this.selectedItems.folders[0].id
            }).subscribe((hasOriginalDocuments) => {

                const folderDestroyModal = this.Modals.show(DestroyFolderComponent, {
                    animated: true,
                    class: 'modal-md',
                    initialState: {
                        folder: this.selectedItems.folders[0],
                        archive: this.archives && this.archives[this.selectedItems.folders[0].id] as unknown as Archive,
                        hasOriginalDocuments
                    }
                });

                folderDestroyModal.content.onDestroy.subscribe((event: FolderDestroyEvent) => {
                    const { folder, reason } = event;

                    this.Folders.destroy({
                        teamId, binderId, id: folder.id, reason
                    }).subscribe((data) => {
                        if (data.errors.length > 0) {
                            data.errors.forEach((error) => {
                                this._handleErrorNotification(error);
                            });
                        }
                        if (data.result.deletedFolders.length) {
                            this.Notifications.success('Folder deleted.');
                            _.remove(this.folder.items, { id: folder.id });
                            this._clearSelectedItems();
                            this._disableFeatures();
                            this.updateSort(this.sortName, this.SORT.isReversed);
                        }

                    }, ({ error }) => {
                        const message = error.message || 'There was an unexpected error. Please contact your administrator.';
                        this.Notifications.error(message);
                    });
                    folderDestroyModal.hide();
                });
            });

            return;
        }

        if (this.selectedItems.docs.length > 0 && this.selectedItems.folders.length === 0) {
            this.Teams.hasOriginalDocuments(this._currentTeam.id, {
                objectType: 'documents',
                objectIds: this.selectedItems.docs.map((doc) => doc.id)
            }).subscribe(
                (hasOriginalDocuments) => {
                    const modal = this.Modals.show(DestroyDocumentComponent, {
                        initialState: {
                            documents: this.selectedItems.docs,
                            hasOriginalDocuments
                        }
                    });

                    modal.content.destroy.pipe(
                        switchMap(({ data: { documents, reason } }: DocumentDestroyEvent) => {
                            return forkJoin({
                                documentsDeleted: this.DocumentsService.destroy(documents, reason),
                                removedDocuments: of(documents)
                            });
                        })
                    ).subscribe(
                        ({ removedDocuments }) => {
                            const removedIds = removedDocuments.map((doc) => doc.id);
                            _.remove(this.folder.items, (item) => _.includes(removedIds, item.id));
                            this._clearSelectedItems();
                            this._disableFeatures();
                            this.updateSort(this.sortName, this.SORT.isReversed);
                            modal.hide();
                        },
                        ({ error }) => {
                            const message = error.message || 'There was an unexpected error.  Please contact your administrator.';
                            this.Notifications.error(message);
                        }
                    );
                }
            );

        }
    }

    download(): void {
        if (!this.canDownload()) {
            return;
        }

        const folder = this.folder as unknown as Folder;
        const folders: Folder[] = _.clone(this.selectedItems.folders);
        const documents: Document[] = _.clone(this.selectedItems.docs);
        if (documents && documents.length === 1) {
            documents[0].binderName = (this.folder as unknown as Folder).binderName;
        }

        if (folders && folders.length) {
            const options: FolderDownloadOptions = {
                ...this.selectedItems.folders.length && { folders },
                ...this.selectedItems.docs.length && { documents },
                binderId: folder.binderId,
                teamId: this._currentTeam.id
            };

            const modalRef = this.Modals.show(FolderDownloadComponent, {
                class: 'modal-md',
                animated: true,
                initialState: {
                    options,
                    team: this._currentTeam,
                    newDocViewerEnabled: this.newDocViewer,
                    parent: folder
                }
            });

            modalRef.content.download.subscribe((params: FolderDownloadOptions) => {
                this.Downloads
                    .downloadFile(params)
                    .subscribe(
                        () => {
                            const href = this.$state.href('app.team.downloads', { teamId: folder.teamId });
                            const message = `<p>Starting download now! We'll notify you when your download is ready.</p>
                            Go to <a class="page-action u-d-inline" href=${href}>MY DOWNLOADS</a> to view all downloads.`;
                            this.Notifications.info({ message, closeOnClick: false });
                        },
                        (err) => {
                            this._handleErrorNotification(err);
                        }
                    );
            });
        }
        else {
            const options: DocumentDownloadOptions = {
                ...this.selectedItems.folders.length && { folders },
                ...this.selectedItems.docs.length && { documents },
                binderId: folder.binderId,
                teamId: this._currentTeam.id
            };

            const modalRef = this.Modals.show(DocumentDownloadComponent, {
                class: 'modal-md',
                animated: true,
                initialState: {
                    options,
                    newDocViewerFeatureEnabled: this.newDocViewer,
                    team: this._currentTeam,
                    parent: folder
                }
            });

            modalRef.content.download.subscribe((params: DocumentDownloadOptions) => {
                this.Downloads
                    .downloadFile(params)
                    .subscribe(
                        () => {
                            const href = this.$state.href('app.team.downloads', { teamId: folder.teamId });
                            const message = `<p>Starting download now! We'll notify you when your download is ready.</p>
                            Go to <a class="page-action u-d-inline" href=${href}>MY DOWNLOADS</a> to view all downloads.`;
                            this.Notifications.info({ message, closeOnClick: false });
                        },
                        (err) => {
                            this._handleErrorNotification(err);
                        }
                    );
            });
        }


    }

    duplicate(): void {
        if (!this.canDuplicate()) {
            return;
        }

        const items = [...this.selectedItems.folders, ...this.selectedItems.docs];

        const duplicateModal = this.Modals.show(DuplicateComponent, {
            animated: true,
            class: 'modal-lg',
            initialState: {
                items,
                container: this.folder as unknown as Folder
            }
        });

        duplicateModal.content.dismiss.subscribe(() => {
            duplicateModal.hide();
        });
    }

    _moveFolder(
        selectedFolder,
        actingType: string,
        modalRef,
        items: BrowseNode[]
    ): void {
        if (actingType === 'folder') {
            const destination = selectedFolder;
            this.Folders
                .moveTo({
                    teamId: destination.teamId,
                    destination,
                    itemsBeingMoved: items as Folder[]
                })
                .pipe(
                    switchMap((data) => {
                        if (data.folders.length) {
                            const binderId = destination.binderId || destination.id;
                            const folderId = destination.type === 'folder' ? destination.id : null;
                            const urlRoute = this.$state.href('app.team.folder', { teamId: destination.teamId, binderId, folderId });
                            const targetName = destination.folderName || destination.name || destination.binderName;
                            const msg = 'Successfully moved to <%= targetName %>.<br> <strong><a href="<%= urlRoute %>">GO TO FOLDER</a></strong>';
                            const msgTemplate = _.template(msg);
                            const templateParams = { targetName, urlRoute };
                            const html = msgTemplate(templateParams);
                            this.Notifications.success(html);
                        }
                        if (data.errors.length > 0) {
                            data.errors.forEach((error) => {
                                const rawMsg = error.error.message;
                                const fullMessage = `An error occurred for Folder, ${error.folder.name}. ${rawMsg}`;
                                this.Notifications.error(fullMessage);
                            });
                        }
                        return of(data);
                    }),
                    map(() => {
                        modalRef.hide();
                        // in case the current view needs to update with newly moved folders
                        this.$state.go(this.$state.current, {}, { reload: true });
                    }),
                    catchError((error) => {
                        modalRef.hide();
                        this._handleErrorNotification(error);
                        return of(null);
                    })
                )
                .subscribe();
        }

        if (actingType === 'document') {
            this.DocumentsService.moveTo(selectedFolder, items as Document[])
                .subscribe(
                    () => {
                        modalRef.hide();
                        // in case the current view needs to update with newly moved documents
                        this.$state.go(this.$state.current, {}, { reload: true });
                    },
                    () => {
                        modalRef.hide();
                    }
                );
        }
    }

    move(): void {
        if (!this.canMove()) {
            return;
        }

        const filteredItems = [...this.selectedItems.folders, ...this.selectedItems.docs];

        const modalRef = this.Modals.show(MoveComponent, {
            class: 'modal-lg',
            initialState: {
                items: filteredItems,
                parentEntity: this.folder,
                loadRoot: () => this.Binders
                    .getBinders(this._currentTeam.id, { includeArchived: false })
                    .toPromise()
                    .then((binders) => sortByLexicographically(binders, 'name'))
                    .catch(this._handleErrorNotification),
                loadItem: (params = {} as BrowseParams) => this.Teams
                    .browse(this._currentTeam.id, { ...params, includeArchived: false })
                    .toPromise()
                    .then((data) => sortBrowseTreeLexicographically(data, 'name'))
            }
        });

        modalRef.content.move.subscribe((params: MoveFolderParams) => {
            this._moveFolder(params.selectedFolder, params.actingType, modalRef, filteredItems);
        });
    }

    toggleLock(isLocking: boolean): void {
        if (!this.canLock() && !this.canUnlock()) {
            return;
        }

        const documentCount = this.selectedItems.docs
            .filter((doc: Document) => doc.isLocked !== isLocking)
            .length;

        const modalRef = this.Modals.show(DocumentLockingModalComponent, {
            animated: true,
            initialState: {
                isLocking,
                documentCount,
                isShortcutSelected: this.isShortcutSelected()
            }
        });

        modalRef.content.confirm
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                const params: UpdateDocumentIsLockedItem[] = this.selectedItems.docs
                    .filter((doc: Document) => doc.isLocked !== isLocking)
                    .map((doc: Document) => {
                        return {
                            id: doc.id,
                            isLocked: isLocking
                        };
                    });

                this.DocumentsService.updateDocumentIsLocked(params)
                    .subscribe(
                        () => {
                            const toastMessage = `Document(s) successfully ${isLocking ? 'locked' : 'unlocked'}`;
                            this.Notifications.success(toastMessage);
                            modalRef.hide();
                            this.$state.reload();
                        },
                        ((error) => {
                            this._handleErrorNotification(error);
                            modalRef.hide();
                            // In case user's permissions were updated reload state
                            this.$state.go(this.$state.current, {}, { reload: true });
                        })
                    );
            });
    }

    editPermissions(): void {
        if (!this.canEditPermissions()) {
            return;
        }

        this.Modals.show(PermissionsEditComponent, {
            animated: true,
            class: 'modal-lg',
            initialState: {
                item: this.selectedItems.docs[0] || this.selectedItems.folders[0]
            }
        });
    }

    onSubmitCallback(item: Document) {
        _.merge(_.find(this.folder.items, { id: item.id }), item);
    }

    editSelected(): void {
        if (!this.canEdit()) {
            return;
        }

        const [selectedItem] = this.selectedItems.docs.concat(this.selectedItems.folders);
        if (selectedItem.type !== 'folder') {
            const formModal = this.Modals.show(DocumentFormComponent, {
                animated: true,
                class: 'modal-md',
                initialState: {
                    document: selectedItem,
                    formType: 'rename-document'
                }
            });

            formModal.content.submit.subscribe((event: SubmitEvent) => {
                const { data: { name, renameShortcuts } } = event;
                this.DocumentsService
                    .updateName(selectedItem.id, name, renameShortcuts)
                    .subscribe(
                        (results) => {
                            if (!(results instanceof HttpErrorResponse)) {
                                this.onSubmitCallback(results.document);
                                results.renamedShortcuts
                                    .forEach((shortcut) => {
                                        this.onSubmitCallback(shortcut);
                                    });
                                this.updateSort(this.sortName, this.SORT.isReversed);
                            }
                        },
                        () => {
                            return undefined;
                        }
                    );
                formModal.hide();
            });
        }
        else {
            const { teamId, binderId, id } = this.selectedFolder;
            const folder = this.folder as unknown as Folder;

            const options: FolderOptions = {
                folders: _.clone(this.selectedItems.folders),
                binderId: folder.binderId,
                teamId: this._currentTeam.id
            };

            this.selectedFolder.permissions = this.userPermissionsOnSelected.folders[id];
            if (this.canAssignLabels()) {
                forkJoin({
                    availableLabels: this.Labels.getLabels(teamId),
                    assignedLabels: this.Folders.getLabels({ teamId, binderId, folderId: id })
                        .pipe(
                            catchError(({ error }) => {
                                this._handleErrorNotification(error);
                                return of([]);
                            })
                        )
                })
                    .pipe(take(1))
                    .subscribe(({ availableLabels, assignedLabels }) => {
                        const modalRef = this.Modals.show(FolderFormComponent, {
                            animated: true,
                            initialState: {
                                folder: this.selectedFolder as Folder,
                                availableLabels,
                                assignedLabels,
                                options,
                                parent: folder
                            }
                        });
                        this.editFolderModalSave(modalRef);
                    });

            }
            else {
                const modalRef = this.Modals.show(FolderFormComponent, {
                    animated: true,
                    initialState: {
                        folder: this.selectedFolder as Folder,
                        options,
                        parent: folder
                    }
                });
                this.editFolderModalSave(modalRef);
            }
        }

    }

    private handleFolderRename(folderFormUpdates: Updates): Observable<Updates> {
        const {
            teamId, binderId, id
        } = this.selectedFolder;
        const updatedName = folderFormUpdates.name.trim();
        if (updatedName !== this.selectedFolder.name) {
            return this.Folders.rename({
                teamId, binderId, folderId: id, newName: updatedName
            }).pipe(
                map((data) => {
                    if (data && data.errors && data.errors[0] && data.errors[0].error) {
                        throw new Error(data.errors[0].error.message);
                    }
                    _.merge(_.find(this.folder.items, { id: this.selectedFolder.id }), data.folders[0]);
                    this.updateSort(this.sortName, this.SORT.isReversed);
                    return folderFormUpdates;
                }), catchError((error) => this.handleErrorOnFolderUpdate(error, folderFormUpdates.onError))
            );
        }
        return of(folderFormUpdates);
    }

    private removeLabels(folderFormUpdates: Updates): Observable<Updates> {
        const {
            teamId
        } = this.selectedFolder;
        if (folderFormUpdates && folderFormUpdates.removedLabels && folderFormUpdates.removedLabels.length) {
            return this.Labels.removeAssignedLabels(teamId, folderFormUpdates.removedLabels)
                .pipe(map(() => {
                    if (browseNodeIsFolder(this.selectedFolder)) {
                        delete this.selectedFolder.directLabels;
                        _.pullAllBy(this.selectedFolder.labels, folderFormUpdates.removedLabels, 'valueId');
                    }
                    return folderFormUpdates;
                }), catchError((error) => this.handleErrorOnFolderUpdate(error, folderFormUpdates.onError)));
        }
        return of(folderFormUpdates);
    }

    private assignLabels(folderFormUpdates: Updates): Observable<Updates> {
        const {
            teamId
        } = this.selectedFolder;
        if (folderFormUpdates && folderFormUpdates.addedLabels && folderFormUpdates.addedLabels.length) {
            return this.Labels.assignLabels(teamId, folderFormUpdates.addedLabels)
                .pipe(map(() => {
                    if (browseNodeIsFolder(this.selectedFolder)) {
                        delete this.selectedFolder.directLabels;
                        this.selectedFolder.labels = this.selectedFolder.labels.concat(folderFormUpdates.addedLabels);
                    }
                    return folderFormUpdates;
                }), catchError((error) => this.handleErrorOnFolderUpdate(error, folderFormUpdates.onError)));
        }
        return of(folderFormUpdates);
    }

    editFolderModalSave(modalRef: ModalRef<FolderFormComponent>): void {
        modalRef.content.save
            .pipe(
                switchMap((folderFormUpdates: Updates) => {
                    return this.handleFolderRename(folderFormUpdates);
                }),
                switchMap((folderRenameResult: Updates) => {
                    return this.removeLabels(folderRenameResult);
                }),
                switchMap((removeLabelsResult: Updates) => {
                    return this.assignLabels(removeLabelsResult);
                })
            ).subscribe((res) => {
                if (res) {
                    this.Notifications.success('Folder updated.');
                    if (res.onSave) {
                        res.onSave();
                    }
                    else {
                        modalRef.hide();
                    }
                }
            });
    }

    handleErrorOnFolderUpdate(error: ApiError | ApiErrorResponse, onError: () => void): Observable<null> {
        if (onError) {
            onError();
        }
        if (this.instanceOfApiError(error)) {
            this.handleApiError(error);
        }
        else {
            this.handleApiErrorResponse(error);
        }

        return of(null);
    }

    instanceOfApiError(object: ApiError | ApiErrorResponse): object is ApiError {
        return ('error' in (object as ApiErrorResponse));
    }

    handleApiError(error: ApiError) {
        if (error.error && error.error.message) {
            this.Notifications.error(error.error.message);
        }
        else {
            this.Notifications.unexpectedError();
        }
    }

    handleApiErrorResponse(error: ApiErrorResponse) {
        if (error.message) {
            this.Notifications.error(error.message);
        }
        else {
            this.Notifications.unexpectedError();
        }
    }

    onItemClick(event: MouseEvent, item: BrowseNode, index: number): void {
        const { shiftKey } = event;
        this.select(item, index, shiftKey);
    }


    getSelectionRange(start: number, end: number): number[] {
        const range = [];
        if (start <= end) {
            for (let i = start; i <= end; i += 1) {
                range.push(i);
            }
        }
        else {
            for (let i = start; i >= end; i -= 1) {
                range.push(i);
            }
        }
        return range;
    }


    updateSelectedItems(item: BrowseNode): void {
        if (item.type === 'folder') {
            if (this.selectedItems.folders.indexOf(item) === -1) {
                this.selectedItems.folders.push(item);
            }
            else {
                this.selectedItems.folders = this.selectedItems.folders.filter((f) => f !== item);
            }
        }
        else {
            if (this.selectedItems.docs.indexOf(item) === -1) {
                this.selectedItems.docs.push(item);
            }
            else {
                this.selectedItems.docs = this.selectedItems.docs.filter((d) => d !== item);
            }
        }
    }


    select(item: BrowseNode, index: number, shiftKey: boolean): void {

        if (!this.selectedItems) {
            this.selectedItems = { folders: [], docs: [] };
        }

        if (shiftKey && this.lastSelectedIndex !== null) {
            const range = this.getSelectionRange(this.lastSelectedIndex, index);
            this.itemSelectionHelper.clear();
            this.selectedItems.folders = [];
            this.selectedItems.docs = [];

            range.forEach((i) => {
                const rangeItem = this.folder.items[i];
                this.itemSelectionHelper.select(rangeItem.id);
                if (rangeItem.type === 'folder') {
                    this.selectedItems.folders.push(rangeItem);
                }
                else {
                    this.selectedItems.docs.push(rangeItem);
                }
            });
        }
        else {
            this.lastSelectedIndex = index;

            if (this.itemSelectionHelper.isSelected(item.id)) {
                if (item.type === 'folder') {
                    const indexToDelete = this.selectedItems.folders.indexOf(item);
                    this.selectedItems.folders.splice(indexToDelete, 1);

                    // Set the selected folder if two folders were previously selected together
                    if (this.selectedItems.folders.length === 1 && this.selectedItems.docs.length === 0) {
                        this.selectedFolder = this.selectedItems.folders[0] as unknown as BrowseNode;
                    }
                    else {
                        this.selectedFolder = null;
                    }
                }
                else {
                    const indexToDelete = this.selectedItems.docs.indexOf(item);
                    this.selectedItems.docs.splice(indexToDelete, 1);

                    // Set the selected folder if one folder and one doc were previously selected together
                    if (this.selectedItems.docs.length === 0 && this.selectedItems.folders.length === 1 && !this.selectedFolder) {
                        this.selectedFolder = this.selectedItems.folders[0] as unknown as BrowseNode;
                    }
                }
            }
            else {
                if (item.type === 'folder') {
                    this.selectedFolder = this.selectedItems.folders.length === 0 ? item : null;
                    this.selectedItems.folders.push(item);
                }
                else {
                    this.selectedItems.docs.push(item);
                }
            }
            this.itemSelectionHelper.toggle(item.id);
        }

        this._disableFeatures();
        this._permissionCheck();
        this.setDatesUpdateType();
    }

    private setDatesUpdateType() {
        if (this.isAnySelectedDocumentPlaceholder() && this.isAnySelectedDocumentContentOrLog()) {
            this.datesUpdateType = DatesUpdateTypes.EXPIRATION_AND_DUE;
        }

        if (this.isAnySelectedDocumentPlaceholder() && !this.isAnySelectedDocumentContentOrLog()) {
            this.datesUpdateType = DatesUpdateTypes.DUE;
        }

        if (!this.isAnySelectedDocumentPlaceholder() && this.isAnySelectedDocumentContentOrLog()) {
            this.datesUpdateType = DatesUpdateTypes.EXPIRATION;
        }
    }

    getColorClass(items: SignatureRequest[]): string {
        if (items && items.length) {
            const isDue = items.some((item) => item.isDue);
            const isAlmostDue = items.some((item) => item.isAlmostDue);

            if (isDue) {
                return 'text-danger';
            }
            if (isAlmostDue) {
                return 'text-warning';
            }
        }
        return '';
    }


    toggleActions($event: PointerEvent, item: BrowseNode, index: number): void {
        $event.preventDefault();
        if ((item.type === 'folder' && this.selectedFolder !== item)
            || !this.selectedItems.docs.includes(item)) {
            this._clearSelectedItems();
            this.select(item, index, null);
        }
        this.openedActions = null;
        this.openedActions = index;
    }

    updateSort(sortName: string, isReversed?: boolean): void {
        this.sortName = sortName;
        this.SORT.set(sortName, isReversed);

        const sortedItems = this.folder.items.sort((a, b) => {
            return this.SORT.naturalSort({ value: a[sortName] }, { value: b[sortName] });
        });

        if (this.SORT.isReversed) {
            sortedItems.reverse();
        }

        if (SORT.by === 'documentCount' && SORT.isReversed) {
            this.sortByDocumentCount(sortedItems);
            return;
        }

        if (sortName === 'sortType') {
            this.sortByType(sortedItems);
            return;
        }

        this.folder.items = [...sortedItems];
    }

    sortByType(sortedItems: BrowseNode[]) {
        const itemsWithouPreview = [];
        const indexesToDelete = [];
        sortedItems.forEach((item, index) => {
            if (
                browseNodeIsDocument(item)
                && item.type === 'document'
                && item.subType === 'content'
                && (
                    (!this.newDocumentViewerEnabled && item.conversionStatus === 'Failed')
                    || (this.newDocumentViewerEnabled && ALLOWED_PREVIEW_FILE_TYPES.indexOf(item.ext?.toLowerCase()) === -1)
                )
            ) {
                itemsWithouPreview.push(item);
                indexesToDelete.push(index);
            }
        });

        for (let i = indexesToDelete.length - 1; i >= 0; i -= 1) {
            sortedItems.splice(indexesToDelete[i], 1);
        }

        const sortedDocsWithoutPreview = itemsWithouPreview.sort((a, b) => {
            return this.SORT.naturalSort({ value: a.name }, { value: b.name });
        });

        if (this.SORT.isReversed) {
            sortedDocsWithoutPreview.reverse();
        }

        const finalSortOdrer = this.SORT.isReversed
            ? sortedDocsWithoutPreview.concat(...sortedItems)
            : sortedItems.concat(...sortedDocsWithoutPreview);

        this.folder.items = [...finalSortOdrer];
    }

    sortByDocumentCount(sortedItems: BrowseNode[]) {
        const folderWithDocuments = [];
        const indexesToDelete = [];
        sortedItems.forEach((item, index) => {
            if (browseNodeIsFolder(item) && item.documentCount > 0) {
                folderWithDocuments.push(item);
                indexesToDelete.push(index);
            }
        });

        for (let i = indexesToDelete.length - 1; i >= 0; i -= 1) {
            sortedItems.splice(indexesToDelete[i], 1);
        }

        const finalSortOdrer = folderWithDocuments.concat(...sortedItems);
        this.folder.items = [...finalSortOdrer];

    }

    onShortcutCreatedActions(createdShortcuts) {
        setTimeout(() => {
            this.topIndex = _.findIndex(this.folder.items, { id: createdShortcuts[0].id });
            this.virtualScroll.scrollToIndex(this.topIndex);
        });
        createdShortcuts.forEach((item) => {
            item.success = true;
        });
        setTimeout(() => {
            createdShortcuts.forEach((shortcut) => {
                delete shortcut.success;
            });
        }, 5000);
    }

    _onShortcutsCreated(results): void {
        const createdShortcuts = results
            .filter((r) => r.target.id === this.folder.id && r.target.type === this.folder.type)
            .map((r) => {
                return { ...r.shortcut, href: this.generateItemHref(r.shortcut) };
            });

        if (!createdShortcuts.length) {
            return;
        }

        this.folder.items.push(...createdShortcuts);
        this.updateSort(this.sortName, this.SORT.isReversed);
        this.onShortcutCreatedActions(createdShortcuts);
    }

    onInsertItemActions(item) {
        setTimeout(() => {
            this.topIndex = _.findIndex(this.folder.items, { id: item.id });
            this.virtualScroll.scrollToIndex(this.topIndex);
        });
        setTimeout(() => {
            item.success = false;
        }, 5000);
    }

    _insertItem(item) {
        if (!item) {
            return;
        }
        this.openedActions = null;

        item.href = this.generateItemHref(item);
        this.folder.items.push(item);
        this.updateSort(this.sortName, this.SORT.isReversed);
        item.success = true;
        this.onInsertItemActions(item);
        if (item.type === 'folder') {
            item.documentCount = 0;
        }
        return item;
    }


    _getCrumbs() {
        const lineageCrumbs = [];
        if (this.folder.lineage) {
            const friendlyLineage = this.folder.path.split('/');
            this.folder.lineage.forEach((folderId, index) => {
                lineageCrumbs.push({
                    name: friendlyLineage[index],
                    stateName: 'app.team.folder',
                    stateParams: {
                        teamId: this.folder.teamId,
                        binderId: this.folder.binderId,
                        folderId
                    },
                    icon: 'far fa-folder-open'
                });
            });
        }

        return [
            {
                name: 'Binders',
                stateName: 'app.team.binders',
                stateParams: {
                    teamId: this.folder.teamId
                },
                icon: 'fa-archive'
            },
            {
                name: this.folder.binderName,
                stateName: 'app.team.folder',
                stateParams: {
                    teamId: this.folder.teamId,
                    binderId: this.folder.binderId,
                    folderId: ''
                },
                icon: 'fa-book u-font-style-italic'
            },
            ...lineageCrumbs
        ];
    }

    updateFilter(text: string): void {
        this.filter = text;
    }

    changeJobTitleRequired(jobTitleRequired: boolean): void {
        const { teamId, binderId } = this.selectedItems.folders[0];
        const folderIds = this.selectedItems.folders.map((folder) => folder.id);

        const jobTitleModal = this.Modals.show(JobTitleRequiredChangeComponent, {
            animated: true,
            class: 'modal-md',
            initialState: {
                jobTitleRequired,
                isFolder: true
            }
        });

        jobTitleModal.content.dismiss.subscribe(() => {
            jobTitleModal.hide();
        });

        jobTitleModal.content.onConfirm.subscribe((event: JobTitleRequiredChangeEvent) => {
            this.Folders.updateJobTitleRequired({
                teamId, binderId, folderIds, jobTitleRequired
            }).subscribe(
                (result) => {
                    const errors = result.filter((folder) => folder.statusCode !== 200);
                    if (errors && errors.length) {
                        event.onError();
                        this.Notifications.error(`Job Title required update failed for ${errors.length} Folder(s)`);
                        if (errors.length === result.length) {
                            return;
                        }
                    }

                    event.onSuccess();
                    if (jobTitleRequired) {
                        this.Notifications.success('Job Title Signature was added to the Folder(s)');
                    }
                    else {
                        this.Notifications.success('Job Title Signature was removed from the Folder(s)');
                    }

                    this.selectedItems.folders.forEach((folder) => {
                        folder.jobTitleRequired = jobTitleRequired;
                    });
                },
                (error: ApiError): void => {
                    if (error.error && error.error.message) {
                        this.Notifications.error(error.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                    event.onError();
                }
            );
        });
    }

    canChangeJobTitleRequired(jobTitleRequired: boolean): boolean {
        return this._currentTeam.permissions.jobTitleRequired
            && this.selectedItems.docs.length === 0 && this.selectedItems.folders.length > 0
            && this.selectedItems.folders.some((folder) => !folder.jobTitleRequired === jobTitleRequired);
    }

    get isAncestorScheduledForArchiving() {
        if (!this.selectedFolder || !this.archives) {
            return false;
        }
        const archivesIds = Object.values(this.archives).map((archive) => archive.id);
        return [this.folder.binderId, ...this.selectedFolder.lineage].some((id) => archivesIds.includes(id));
    }

    canArchive(): boolean {
        return this._currentTeam.settings.features.longTermArchive
            && (this.selectedFolder
                && (this.archives && !this.archives[(this.selectedFolder as Folder).id])
                && !(_.get(this, 'disableManageArchive', true)))
            && !this.isAncestorScheduledForArchiving;
    }

    loadArchives(teamId: string): void {
        this.Folders
            .getArchives(teamId)
            .pipe(first())
            .subscribe(
                (archives) => {
                    this.archives = archives;
                    this.canArchiveFolder = this.canArchive();
                },
                ({ error }: { error: { message: string } }) => {
                    if (error.message) {
                        this.Notifications.error(error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                }
            );
    }

    openArchiveWizard(): void {
        const [folder] = this.selectedItems.folders;
        this.$state.go('app.team.archive-wizard', {
            objectId: folder.id,
            objectType: folder.type,
            objectName: folder.name
        });
    }

    setHoveredItem(folder: BrowseNode): void {
        this.currentlyHoveredFolder = folder;
    }

    _handleErrorNotification({ error }: ApiError): void {
        const { message } = error;
        if (message) {
            return this.Notifications.error(message);
        }

        return this.Notifications.unexpectedError();
    }

    private updateProjectsStateAfterBulkAssignToDocuments(documents: Document[], timelines: GetTimelinesResponseItem[]): void {
        timelines.forEach((timeline) => {
            const { projectId } = timeline;
            const [projectName, timelineName] = timeline.name.split('/');

            documents.forEach((doc, index) => {
                const docProjects = doc.projects || [];
                const docProjectIndex = docProjects.findIndex((p) => p.projectId === projectId);
                if (docProjectIndex > -1) {
                    const docProject = docProjects[docProjectIndex];
                    const timelineIndex = docProject.timelines.findIndex((t) => t.timelineId === timeline.id);
                    if (timelineIndex === -1) {
                        documents[index].projects[docProjectIndex].timelines = [
                            ...docProject.timelines,
                            {
                                timelineId: timeline.id,
                                timelineName
                            }
                        ];
                    }
                }
                else {
                    const docProject: DocumentProject = {
                        projectId,
                        projectName,
                        timelines: [{
                            timelineId: timeline.id,
                            timelineName
                        }]
                    };
                    documents[index].projects = [
                        ...docProjects,
                        docProject
                    ];
                }
            });
        });
    }

    onClamped(index: number, isClamped: boolean) {
        this._clamped[index] = isClamped;
    }

    closeApplyStructureMenu(): void {
        this.isApplyStructureOpen = false;
    }

    closeLogTemplatesStructureMenu(): void {
        this.isLogsTemplateStructureOpen = false;
    }
}
