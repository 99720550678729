import * as angular from 'angular';
import './virtual-repeat.scss';

/**
 * Number of additional elements to render above and below the visible area inside
 * of the virtual repeat container. A higher number results in less flicker when scrolling
 * very quickly in Safari, but comes with a higher rendering and dirty-checking cost.
 * @const {number}
 */
const NUM_EXTRA = 3;

/** @ngInject */
const VirtualRepeatContainerController = function VirtualRepeatContainerController(
    $$rAF, $mdUtil, $parse, $rootScope, $window, $scope, $element, $attrs
) {
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$element = $element;
    this.$attrs = $attrs;

    /** @type {number} The width or height of the container */
    this.size = 0;
    /** @type {number} The scroll width or height of the scroller */
    this.scrollSize = 0;
    /** @type {number} The scrollLeft or scrollTop of the scroller */
    this.scrollOffset = 0;
    /** @type {boolean} Whether the scroller is oriented horizontally */
    this.horizontal = Object.prototype.hasOwnProperty.call(this.$attrs, 'mdOrientHorizontal');
    /** @type {!VirtualRepeatController} The repeater inside of this container */
    this.repeater = null;
    /** @type {boolean} Whether auto-shrink is enabled */
    this.autoShrink = Object.prototype.hasOwnProperty.call(this.$attrs, 'mdAutoShrink');
    /** @type {number} Minimum number of items to auto-shrink to */
    this.autoShrinkMin = parseInt(this.$attrs.mdAutoShrinkMin, 10) || 0;
    /** @type {?number} Original container size when shrank */
    this.originalSize = null;
    /** @type {number} Amount to offset the total scroll size by. */
    this.offsetSize = parseInt(this.$attrs.mdOffsetSize, 10) || 0;
    /** @type {?string} height or width element style on the container prior to auto-shrinking. */
    this.oldElementSize = null;
    /** @type {!number} Maximum amount of pixels allowed for a single DOM element */
    this.maxElementPixels = 1533917;

    if (this.$attrs.mdTopIndex) {
        /** @type {function(angular.Scope): number} Binds to topIndex on AngularJS scope */
        this.bindTopIndex = $parse(this.$attrs.mdTopIndex);
        /** @type {number} The index of the item that is at the top of the scroll container */
        this.topIndex = this.bindTopIndex(this.$scope);

        if (!angular.isDefined(this.topIndex)) {
            this.topIndex = 0;
            this.bindTopIndex.assign(this.$scope, 0);
        }

        this.$scope.$watch(this.bindTopIndex, angular.bind(this, function (newIndex) {
            if (newIndex !== this.topIndex) {
                this.scrollToIndex(newIndex);
            }
        }));
    }
    else {
        this.topIndex = 0;
    }

    if (this.$attrs.isScrollVisible) {
        this.bindIsScrollVisible = $parse(this.$attrs.isScrollVisible);
    }

    this.scroller = $element[0].querySelector('.md-virtual-repeat-scroller');
    this.sizer = this.scroller.querySelector('.md-virtual-repeat-sizer');
    this.offsetter = this.scroller.querySelector('.md-virtual-repeat-offsetter');

    // After the DOM stabilizes, measure the initial size of the container and
    // make a best effort at re-measuring as it changes.
    const boundUpdateSize = angular.bind(this, this.updateSize);

    $$rAF(angular.bind(this, function onWindowSizeChange() {
        boundUpdateSize();

        const debouncedUpdateSize = $mdUtil.debounce(boundUpdateSize, 10, null, false);
        const jWindow = angular.element($window);

        // Make one more attempt to get the size if it is 0.
        // This is not by any means a perfect approach, but there's really no
        // silver bullet here.
        if (!this.size) {
            debouncedUpdateSize();
        }

        jWindow.on('resize', debouncedUpdateSize);
        $scope.$on('$destroy', () => {
            jWindow.off('resize', debouncedUpdateSize);
        });

        $scope.$emit('$md-resize-enable');
        $scope.$on('$md-resize', boundUpdateSize);
    }));
};

VirtualRepeatContainerController.$inject = ['$$rAF', '$mdUtil', '$parse', '$rootScope', '$window', '$scope', '$element', '$attrs'];

/** Called by the md-virtual-repeat inside of the container at startup. */
VirtualRepeatContainerController.prototype.register = function register(repeaterCtrl) {
    this.repeater = repeaterCtrl;

    angular.element(this.scroller)
        .on('scroll wheel touchmove touchend', angular.bind(this, this.handleScroll_));
};


/** @return {boolean} Whether the container is configured for horizontal scrolling. */
VirtualRepeatContainerController.prototype.isHorizontal = function isHorizontal() {
    return this.horizontal;
};


/** @return {number} The size (width or height) of the container. */
VirtualRepeatContainerController.prototype.getSize = function getSize() {
    return this.size;
};


/**
 * Resizes the container.
 * @private
 * @param {number} size The new size to set.
 */
VirtualRepeatContainerController.prototype.setSize_ = function setSize(size) {
    const dimension = this.getDimensionName_();

    this.size = size;
    this.$element[0].style[dimension] = `${size}px`;
};


VirtualRepeatContainerController.prototype.unsetSize_ = function unsetSize() {
    this.$element[0].style[this.getDimensionName_()] = this.oldElementSize;
    this.oldElementSize = null;
};


/** Instructs the container to re-measure its size. */
VirtualRepeatContainerController.prototype.updateSize = function updateSize() {
    this.setScrollVisibility_();

    // If the original size is already determined, we can skip the update.
    if (this.originalSize) {
        return;
    }

    const size = this.isHorizontal()
        ? this.$element[0].clientWidth
        : this.$element[0].clientHeight;

    if (size) {
        this.size = size;
    }

    // Recheck the scroll position after updating the size. This resolves
    // problems that can result if the scroll position was measured while the
    // element was display: none or detached from the document.
    this.handleScroll_();

    this.repeater && this.repeater.containerUpdated();
    this.setScrollVisibility_();
};


VirtualRepeatContainerController.prototype.setScrollVisibility_ = function setScrollVisibility_() {
    if (!this.bindIsScrollVisible) {
        return;
    }

    const scrollVisible = this.scroller.scrollHeight > this.scroller.clientHeight;
    const nonFloatingScroll = !!(this.scroller.offsetWidth - this.scroller.clientWidth);

    this.bindIsScrollVisible.assign(this.$scope, scrollVisible && nonFloatingScroll);
};

/** @return {number} The container's scrollHeight or scrollWidth. */
VirtualRepeatContainerController.prototype.getScrollSize = function getScrollSize() {
    return this.scrollSize;
};

/**
 * @returns {string} either width or height dimension
 * @private
 */
VirtualRepeatContainerController.prototype.getDimensionName_ = function getDimensionName() {
    return this.isHorizontal() ? 'width' : 'height';
};


/**
 * Sets the scroller element to the specified size.
 * @private
 * @param {number} size The new size.
 */
VirtualRepeatContainerController.prototype.sizeScroller_ = function sizeScroller(size) {
    const dimension = this.getDimensionName_();
    const crossDimension = this.isHorizontal() ? 'height' : 'width';

    // Clear any existing dimensions.
    this.sizer.innerHTML = '';

    // If the size falls within the browser's maximum explicit size for a single element, we can
    // set the size and be done. Otherwise, we have to create children that add up the the desired
    // size.
    if (size < this.maxElementPixels) {
        this.sizer.style[dimension] = `${size}px`;
    }
    else {
        this.sizer.style[dimension] = 'auto';
        this.sizer.style[crossDimension] = 'auto';

        // Divide the total size we have to render into N max-size pieces.
        const numChildren = Math.floor(size / this.maxElementPixels);

        // Element template to clone for each max-size piece.
        const sizerChild = document.createElement('div');
        sizerChild.style[dimension] = `${this.maxElementPixels}px`;
        sizerChild.style[crossDimension] = '1px';

        for (let i = 0; i < numChildren; i += 1) {
            this.sizer.appendChild(sizerChild.cloneNode(false));
        }

        // Re-use the element template for the remainder.
        sizerChild.style[dimension] = `${size - (numChildren * this.maxElementPixels)}px`;
        this.sizer.appendChild(sizerChild);
    }
};


/**
 * If auto-shrinking is enabled, shrinks or unshrinks as appropriate.
 * @private
 * @param {number} size The new size.
 */
VirtualRepeatContainerController.prototype.autoShrink_ = function autoShrink(size) {
    const shrinkSize = Math.max(size, this.autoShrinkMin * this.repeater.getItemSize());

    if (this.autoShrink && shrinkSize !== this.size) {
        if (this.oldElementSize === null) {
            this.oldElementSize = this.$element[0].style[this.getDimensionName_()];
        }

        const currentSize = this.originalSize || this.size;

        if (!currentSize || shrinkSize < currentSize) {
            if (!this.originalSize) {
                this.originalSize = this.size;
            }

            // Now we update the containers size, because shrinking is enabled.
            this.setSize_(shrinkSize);
        }
        else if (this.originalSize !== null) {
            // Set the size back to our initial size.
            this.unsetSize_();

            const _originalSize = this.originalSize;
            this.originalSize = null;

            // We determine the repeaters size again, if the original size was zero.
            // The originalSize needs to be null, to be able to determine the size.
            if (!_originalSize) {
                this.updateSize();
            }

            // Apply the original size or the determined size back to the container, because
            // it has been overwritten before, in the shrink block.
            this.setSize_(_originalSize || this.size);
        }

        this.repeater.containerUpdated();
    }
};


/**
 * Sets the scrollHeight or scrollWidth. Called by the repeater based on
 * its item count and item size.
 * @param {number} itemsSize The total size of the items.
 */
VirtualRepeatContainerController.prototype.setScrollSize = function setScrollSize(itemsSize) {
    const size = itemsSize + this.offsetSize;
    if (this.scrollSize === size) {
        return;
    }

    this.sizeScroller_(size);
    this.autoShrink_(size);
    this.scrollSize = size;
};


/** @return {number} The container's current scroll offset. */
VirtualRepeatContainerController.prototype.getScrollOffset = function getScrollOffset() {
    return this.scrollOffset;
};

/**
 * Scrolls to a given scrollTop position.
 * @param {number} position
 */
VirtualRepeatContainerController.prototype.scrollTo = function scrollTo(position) {
    this.scroller[this.isHorizontal() ? 'scrollLeft' : 'scrollTop'] = position;
    this.handleScroll_();
};

/**
 * Scrolls the item with the given index to the top of the scroll container.
 * @param {number} index
 */
VirtualRepeatContainerController.prototype.scrollToIndex = function scrollToIndex(index) {
    const itemSize = this.repeater.getItemSize();
    const { itemsLength } = this.repeater;
    if (index > itemsLength) {
        index = itemsLength - 1;
    }
    this.scrollTo(itemSize * index);
};

VirtualRepeatContainerController.prototype.resetScroll = function resetScroll() {
    this.scrollTo(0);
};


VirtualRepeatContainerController.prototype.handleScroll_ = function handleScroll_() {
    const ltr = document.dir !== 'rtl' && document.body.dir !== 'rtl';
    if (!ltr && !this.maxSize) {
        this.scroller.scrollLeft = this.scrollSize;
        this.maxSize = this.scroller.scrollLeft;
    }
    // eslint-disable-next-line no-nested-ternary
    const offset = this.isHorizontal()
        ? (ltr ? this.scroller.scrollLeft : this.maxSize - this.scroller.scrollLeft) : this.scroller.scrollTop;

    if (offset === this.scrollOffset || offset > this.scrollSize - this.size) {
        return;
    }

    const itemSize = this.repeater.getItemSize();
    if (!itemSize) {
        return;
    }

    const numItems = Math.max(0, Math.floor(offset / itemSize) - NUM_EXTRA);

    const transform = `${(this.isHorizontal() ? 'translateX(' : 'translateY(')
        + (!this.isHorizontal() || ltr ? (numItems * itemSize) : -(numItems * itemSize))}px)`;

    this.scrollOffset = offset;
    this.offsetter.style.webkitTransform = transform;
    this.offsetter.style.transform = transform;

    if (this.bindTopIndex) {
        const topIndex = Math.floor(offset / itemSize);
        if (topIndex !== this.topIndex && topIndex < this.repeater.getItemCount()) {
            this.topIndex = topIndex;
            this.bindTopIndex.assign(this.$scope, topIndex);
            if (!this.$rootScope.$$phase) {
                this.$scope.$digest();
            }
        }
    }

    this.repeater.containerUpdated();
};

const virtualRepeatContainerTemplate = function virtualRepeatContainerTemplate($element) {
    return `${'<div class="md-virtual-repeat-scroller" tabindex="">'
        + '<div class="md-virtual-repeat-sizer"></div>'
        + '<div class="md-virtual-repeat-offsetter">'}${
        $element[0].innerHTML
    }</div></div>`;
};

/**
 * @ngdoc directive
 * @name mdVirtualRepeatContainer
 * @module material.components.virtualRepeat
 * @restrict E
 * @description
 * `md-virtual-repeat-container` provides the scroll container for
 * <a ng-href="api/directive/mdVirtualRepeat">md-virtual-repeat</a>.
 *
 * VirtualRepeat is a limited substitute for `ng-repeat` that renders only
 * enough DOM nodes to fill the container, recycling them as the user scrolls.
 *
 * Once an element is not visible anymore, the Virtual Repeat recycles the element and reuses it
 * for another visible item by replacing the previous data set with the set of currently visible
 * elements.
 *
 * ### Common Issues
 *
 * - When having one-time bindings inside of the view template, the Virtual Repeat will not properly
 *   update the bindings for new items, since the view will be recycled.
 * - Directives inside of a Virtual Repeat will be only compiled (linked) once, because those
 *   items will be recycled and used for other items.
 *   The Virtual Repeat just updates the scope bindings.
 *
 *
 * ### Notes
 *
 * > The VirtualRepeat is a similar implementation to the Android
 * [RecyclerView](https://developer.android.com/reference/android/support/v7/widget/RecyclerView.html).
 *
 * <!-- This comment forces a break between blockquotes //-->
 *
 * > Please also review the <a ng-href="api/directive/mdVirtualRepeat">mdVirtualRepeat</a>
 * documentation for more information.
 *
 *
 * @usage
 * <hljs lang="html">
 *
 * <md-virtual-repeat-container md-top-index="topIndex">
 *   <div md-virtual-repeat="i in items" md-item-size="20">Hello {{i}}!</div>
 * </md-virtual-repeat-container>
 * </hljs>
 *
 * @param {boolean=} md-auto-shrink When present and the container will shrink to fit
 *     the number of items in the `md-virtual-repeat`.
 * @param {number=} md-auto-shrink-min Minimum number of items that md-auto-shrink
 *     will shrink to. Default: `0`.
 * @param {boolean=} md-orient-horizontal Whether the container should scroll horizontally.
 *     The default is `false` which indicates vertical orientation and scrolling.
 * @param {number=} md-top-index Binds the index of the item that is at the top of the scroll
 *     container to `$scope`. It can both read and set the scroll position.
 */
const VirtualRepeatContainerDirective = function VirtualRepeatContainerDirective() {
    return {
        controller: VirtualRepeatContainerController,
        template: virtualRepeatContainerTemplate,
        compile: function virtualRepeatContainerCompile($element, $attrs) {
            $element
                .addClass('md-virtual-repeat-container')
                .addClass(Object.prototype.hasOwnProperty.call($attrs, 'mdOrientHorizontal')
                    ? 'md-orient-horizontal'
                    : 'md-orient-vertical');
        }
    };
};

export default VirtualRepeatContainerDirective;
