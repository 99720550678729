import uuid from 'uuid';

import { VirtualTreeSelectionMode } from '@app/widgets/virtual-tree/virtual-tree.component.types';
import { Binder } from '@app/shared/models';

class TimelineFormController {
    binderTreeLabel: string;
    bindersList: Binder[] = [];
    constructor($scope) {
        this._$scope = $scope;
        this.selectionMode = VirtualTreeSelectionMode;
    }

    $onInit(): void {
        this.binderTreeLabel = this.hasVisibleBinders()
            ? 'Select Documents, Placeholders, and Shortcuts to include in Timeline:'
            : 'This user does not have permissions to view Binder/Documents';
        this.id = uuid.v4();
        this.name = this.timeline.name;
        this._addWatchers();
    }

    isItemSelectable = (item) => {
        return item.type === 'document';
    }

    isItemDisabled = (item) => {
        return item.type !== 'shortcut' && item.isLocked;
    }

    isItemDisplayable(itm) {
        const _hasSelectableContent = (item) => {
            if (item.type !== 'folder') {
                return true;
            }

            if (!item.items || item.items.length === 0) {
                return false;
            }

            return item.items.reduce((accum, child) => accum || _hasSelectableContent(child), false);
        };

        return _hasSelectableContent(itm);
    }

    showItemCheckbox(item) {
        return !item.isLocked;
    }

    isItemLoadableOnSelect(item) {
        return item.type === 'binder';
    }

    binderTreeSelectionChange = ({ selectedItems }) => {
        this.onSelectedItemsChange({
            data: { selectedItems }
        });
    }

    _addWatchers() {
        this._$scope.$watch('vm.timeline.name', () => {
            this.name = this.timeline.name;
        });
    }

    _onNameChange() {
        this.onNameChange({
            data: {
                name: this.name
            }
        });
    }

    _onIsCompleteChange(newValue) {
        this.onIsCompleteChange({
            data: {
                isComplete: newValue
            }
        });
    }

    private hasVisibleBinders(): boolean {
        return !!this.bindersList.length;
    }

    setStartDate(value: Date): void {
        this.timeline.projectedStart = value;
    }

    setEndDate(value: Date): void {
        this.timeline.projectedEnd = value;
    }
}

TimelineFormController.$inject = ['$scope'];

export default TimelineFormController;
