import * as angular from 'angular';
import documentSelection from './document.selection';
import pageRegion from './page.region';

const dependencies = [
    pageRegion.name,
    documentSelection.name
];
const primitives = angular.module('app.shared.documents.documentContentEditor.documentViewer.submodules.primitives', dependencies);

export default primitives;
