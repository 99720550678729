/**
 * This service handles calculating properties of propagated annotations.
 * This is necessary because annotation coordinates are relative to page dimensions
 * and pages may have variable dimensions.
 */
const PropagationServiceFactory = () => {
    const service = {};

    /**
     * Calculate the scale of a propagated annotation
     * @param originalPage
     * @param propagatedToPage
     * @returns {{xScale: number, yScale: number}}
     */
    service.getPropagationScale = (originalPage, propagatedToPage) => {
        const propagated = propagatedToPage.pageIndex !== originalPage.pageIndex;
        const xScale = propagated ? propagatedToPage.height / originalPage.height : 1;
        const yScale = propagated ? propagatedToPage.width / originalPage.width : 1;
        return { xScale, yScale };
    };

    /**
     * Calculate the bounds of an annotation on a propagated page
     * @param {{x,y,w,h}} originalBounds
     * @param originalPage
     * @param propagatedToPage
     * @returns {{x,y,w,h}}
     */
    service.getPropagatedBounds = (originalBounds, originalPage, propagatedToPage) => {
        const propagated = propagatedToPage.pageIndex !== originalPage.pageIndex;
        const {
            x,
            y,
            w,
            h
        } = originalBounds;
        if (!propagated) {
            return {
                x,
                y,
                w,
                h
            };
        }

        const { xScale, yScale } = service.getPropagationScale(originalPage, propagatedToPage);
        const bounds = {
            x: x * xScale,
            y: y * yScale,
            w: w * xScale,
            h: h * yScale
        };

        return bounds;
    };

    return service;
};

export default PropagationServiceFactory;
