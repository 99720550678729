import * as angular from 'angular';

import { downgradeComponent } from '@angular/upgrade/static';

import projectsRoute from './projects.route';

import dashboardTimelineDetailPageComponent from './containers/dashboard-timeline-detail-page/dashboard-timeline-detail-page.component';
import dashboardTimelinesComponent from './containers/dashboard-timelines/dashboard-timelines.component';
import projectsDetailPageComponent from './containers/projects-detail-page/projects-detail-page.component';
import projectsPageComponent from './containers/projects-page/projects-page.component';
import timelineDetailPageComponent from './containers/timeline-detail-page/timeline-detail-page.component';
import timelineUpdateComponent from './containers/timeline-update/timeline-update.component';
import component from './containers/projectizer/projectizer.component';

import createTimelineModal from './compontents/create-timeline-modal/create-timeline-modal.component';
import destroyItem from './compontents/destroy-item/destroy-item.component';
import { DestroyProjectOrTimelineComponent } from './compontents/destroy-project/destroy-project-or-timeline.component';
import duplicateTimelineModal from './compontents/duplicate-timeline-modal/duplicate-timeline-modal.component';
import { EditProjectModalComponent } from './compontents/edit-project-modal/edit-project-modal.component';
import modifyCompletionModal from './compontents/modify-completion-modal/modify-completion-modal.component';
import timelineFormComponent from './compontents/timeline-form/timeline-form.component';
import timelineMetricsComponent from './compontents/timeline-metrics/timeline-metrics.component';
import timelineSelectionDropdownComponent
    from './compontents/timeline-selection-dropdown/timeline-selection-dropdown.component';

import { TimelineVisibilitySettingsComponent } from './compontents/timeline-visibility-settings/timeline-visibility-settings.component';
import { ProjectizerItemComponent } from './containers/projectizer/projectizer-item/projectizer-item.component';

const dependencies = [
];

const projects = angular
    .module('app.projects', dependencies)
    .config(projectsRoute)
    .component('dashboardTimelineDetailPage', dashboardTimelineDetailPageComponent)
    .component('dashboardTimelines', dashboardTimelinesComponent)
    .component('projectsDetailPage', projectsDetailPageComponent)
    .component('projectsPage', projectsPageComponent)
    .component('timelineDetailPage', timelineDetailPageComponent)
    .component('timelineUpdate', timelineUpdateComponent)
    .component('projectizer', component)
    .directive('projectizerItem', downgradeComponent({ component: ProjectizerItemComponent }) as ng.IDirectiveFactory)
    .component('createTimelineModal', createTimelineModal)
    .component('destroyItem', destroyItem)
    .directive('destroyProjectOrTimeline', downgradeComponent({ component: DestroyProjectOrTimelineComponent }) as ng.IDirectiveFactory)
    .component('duplicateTimelineModal', duplicateTimelineModal)
    .directive('editProjectModal', downgradeComponent({ component: EditProjectModalComponent }) as ng.IDirectiveFactory)
    .component('modifyCompletionModal', modifyCompletionModal)
    .component('timelineFormComponent', timelineFormComponent)
    .component('timelineMetrics', timelineMetricsComponent)
    .component('timelineSelectionDropdown', timelineSelectionDropdownComponent)
    .directive('timelineVisibilitySettings', downgradeComponent({ component: TimelineVisibilitySettingsComponent }) as ng.IDirectiveFactory);

export default projects;
