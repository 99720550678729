import { distinctUntilChanged, filter } from 'rxjs/operators';
import { CurrentSessionService } from '@app/core/current-session.service';
import * as _ from 'lodash';

class UserEntersTeamAuditService {

    private readonly localStorageKey = 'teamsSession';
    private readonly endpoint = _.template('/api/teams/<%= teamId %>/session');

    constructor(
        private $http: ng.IHttpService,
        private $window: ng.IWindowService,
        private CurrentSession: CurrentSessionService
    ) {
        this.CurrentSession.currentTeam$
            .pipe(
                filter((team) => !!team),
                distinctUntilChanged((previousTeam, currentTeam) => {

                    return previousTeam.id === currentTeam.id;
                })
            )
            .subscribe(this.createTeamEnterAudit.bind(this));
    }

    private createTeamEnterAudit(team): void {
        const teamsSession = this.getItem();

        if (!team?.id) {
            return;
        }

        this.saveTeamEnterAudit(team.id);

        if (!teamsSession) {
            return this.setItem(JSON.stringify({ [team.id]: true }));
        }
        const teamsSessionObject = JSON.parse(teamsSession);
        if (!teamsSessionObject[team.id]) {
            return this.setItem(JSON.stringify({ ...teamsSessionObject, [team.id]: true }));
        }
    }

    private getItem(): string {
        return this.$window.localStorage.getItem(this.localStorageKey);
    }

    private setItem(item): void {
        this.$window.localStorage.setItem(this.localStorageKey, item);
    }

    private saveTeamEnterAudit(teamId: string): void {
        this.$http.post(this.endpoint({ teamId }), null).catch(() => {
            return undefined;
        });
    }
}

UserEntersTeamAuditService.$inject = [
    '$http',
    '$window',
    'CurrentSession'
];

export default UserEntersTeamAuditService;
