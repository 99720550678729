import template from './highlight.tool.html';
import controller from './highlight.tool.controller';

const highlightToolComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        page: '<',
        color: '<',
        opacity: '<'
    }
};

export default highlightToolComponent;
