import './annotation.dropdown.option.scss';
import template from './annotation.dropdown.option.html';
import controller from './annotation.dropdown.option.controller';

const annotationDropdownOptionComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        // @type {object} value A key / value mapping of annotation properties
        value: '<',
        // @type {string} valueKey
        valueKey: '<',
        // @type {object[]} options An array of options
        // @type {string} options.display The text to display in the dropdown
        // @type {string} options.value The value of this option
        options: '<',
        onChange: '&',
        label: '<'
    }
};

export default annotationDropdownOptionComponent;
