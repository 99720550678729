import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnDestroy, OnInit
} from '@angular/core';
import {
    AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators
} from '@angular/forms';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { BrowseTree, Folder, User } from '@app/shared/models';
import { StateService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { FeatureFlagService } from '@app/core/feature-flag.service';
import { ConfirmationModal } from '@app/shared/modals/confirmation-modal/confirmation-modal';
import * as moment from 'moment';
import { FEATURE_FLAGS } from '../../../../core/constants/feature-flags';
import { CurrentSessionService } from '../../../../core/current-session.service';
import { valueMatch } from '../../../../core/form-validators';
import { calculateEntityPath, EntityPathItem } from '../../../../shared/documents/calculate-entity-path.util';
import { TeamService } from '../../../../shared/teams/team.service';
import template from './archive-wizard.component.html';
import styles from './archive-wizard.component.scss';

export type ObjectType = 'binder' | 'folder'
export const dateTimeFormat = 'DD-MMM-YYYY [@] hh:mm A';

@Component({
    selector: 'archive-wizard',
    template,
    styles: [String(styles)]
})
export class ArchiveWizardComponent implements OnInit, OnDestroy {

    step = 1;
    hrefState: string;
    hrefParams: any;

    archiveForm: FormGroup;

    private archiveHTTPError?: HttpErrorResponse; // TODO should this be removed/implemented?
    private enableRetentionPeriod = false;

    public objectData: BrowseTree;
    public parentEntity?: EntityPathItem[];

    public closeoutStepsConfirmation: boolean;
    public noChangesConfirmation: boolean;
    public phiConfirmation: boolean;
    public actionsConfirmation: boolean;

    private readonly destroy$ = new Subject<void>();

    readonly currentUser: User = this.CurrentSession.getCurrentUser();
    readonly currentTeam = this.CurrentSession.getCurrentTeam();

    readonly DEFAULT_RETENTION_PERIOD = 25;
    public get scheduleArchiveGroup(): AbstractControl {
        return this.archiveForm.get('scheduleArchive');
    }

    public get archivalDateControl(): AbstractControl {
        return this.scheduleArchiveGroup.get('archivalDate');
    }

    public get archivalDateLocalDate(): string | null {
        const date = moment(this.archivalDateControl.value).tz(moment.tz.guess());
        return date.isValid() ? `${date.format(dateTimeFormat)} (${moment.tz.guess()})` : null;
    }

    public get archivalDateTeamDate(): string | null {
        const date = moment(this.archivalDateControl.value).tz(this.currentTeam.settings.timezone);
        return date.isValid() ? `${date.format(dateTimeFormat)} (${this.currentTeam.settings.timezone?.replace('_', ' ')})` : null;
    }

    public get statementsGroup(): AbstractControl {
        return this.archiveForm.get('statements');
    }

    constructor(
        private readonly $state: StateService,
        private readonly FormBuilderService: FormBuilder,
        private readonly CurrentSession: CurrentSessionService,
        private readonly Teams: TeamService,
        private Modals: ModalsService,
        private FeatureFlags: FeatureFlagService
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        const {
            teamId, objectId, objectType, objectName
        } = this.$state.params as Record<string, string>;

        this.FeatureFlags
            .getFlag(FEATURE_FLAGS.LTA_RETENTION_PERIOD, false)
            .pipe(
                filter((value) => value !== undefined),
                takeUntil(this.destroy$)
            )
            .subscribe((value) => {
                this.enableRetentionPeriod = value;
            });

        this.initForm(objectName);
        this.loadObject(teamId, (objectType as ObjectType), objectId);
    }

    initForm(objectName) {
        this.archiveForm = this.FormBuilderService.group({
            scheduleArchive: new FormGroup({
                objectName: new FormControl('', valueMatch(objectName)),
                archivalDate: new FormControl(null, Validators.required),
                selectedDate: new FormControl(null),
                archivalRetentionPeriod: new FormControl(this.DEFAULT_RETENTION_PERIOD, Validators.required),
                isRetentionPeriodSpecified: new FormControl(false)
            }),
            statements: new FormGroup({
                closeoutStepsConfirmation: new FormControl(this.closeoutStepsConfirmation, Validators.required),
                noChangesConfirmation: new FormControl(this.noChangesConfirmation, Validators.required),
                phiConfirmation: new FormControl(this.phiConfirmation, Validators.required),
                actionsConfirmation: new FormControl(this.actionsConfirmation, Validators.required)
            }, this.allCheckBoxesSelected())
        });
    }

    private loadObject(teamId: string, objectType: 'folder' | 'binder', objectId: string) {
        this.Teams.browse(teamId, {
            objectId,
            objectType
        }).subscribe((data) => {
            this.objectData = data;

            this.parentEntity = calculateEntityPath((this.objectData as unknown as Folder), this.currentTeam);
            [this.hrefState, this.hrefParams] = this.generateItemHref(this.objectData);
        });
    }

    private generateItemHref(objectData: BrowseTree): [string, any] {
        const params: any = {
            teamId: this.currentTeam.id,
            binderId: objectData.binderId
        };

        if (!objectData.lineage) {
            return ['app.team.binders', { ...params }];
        }

        params.folderId = objectData.lineage[objectData.lineage.length - 2];
        return ['app.team.folder', { ...params }];
    }

    allCheckBoxesSelected(): ValidatorFn {
        return (fg: FormGroup) => {
            return !(Object.values(fg.value).some((x) => !x)) ? null : { error: 'Not All Are Selected' };
        };
    }

    increment(event) {
        this.step += event;
    }

    openConfirmationModal(): void {
        this.Modals.show(ConfirmationModal, {
            animated: true,
            initialState: {
                showModalCloseButton: true,
                confirmationTitle: 'Are you sure you want to cancel?',
                confirmationMessage: `This ${this.objectData.type} will not be scheduled for archiving.`,
                cancelButtonText: 'Stay on this page',
                confirmButtonText: 'Cancel scheduling',
                handleConfirm: () => {
                    this.$state.go(this.hrefState, this.hrefParams);
                    this.Modals.hide(1);
                }
            }
        });
    }
}
