import * as angular from 'angular';
import annotationDropdownOptionComponent from './annotation.dropdown.option.component';


const dependencies = [

];
const annotationDropdownOption = angular.module('app.annotationDropdownOption', dependencies)
    .component('annotationDropdownOption', annotationDropdownOptionComponent);

export default annotationDropdownOption;
