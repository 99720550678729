import './text.annotation.scss';
import template from './text.annotation.html';
import controller from './text.annotation.controller';

const textAnnotationComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        /**
         * @type { object } data
         * @type { number } data.x
         * @type { number } data.y
         * @type { number } data.w
         * @type { number } data.h
         * @type { string } data.font The fontface
         * @type { string } data.color The color of the text
         * @type { number } data.lineHeight The lineHeight as a multiple of fontsize (e.g. 1.5 or 2)
         * @type { number } data.fontSize The size of the font as a percentage of page height
         * @type { string } data.backgroundColor - The color of the textbox
         * @type { string } data.textValue The string value of the text
         * @type { object } data.page The page this annotation is attached to
         */
        data: '<',
        /**
         * @type {boolean} Whether the object can receive mouse events
         */
        interactive: '<?',
        /**
         * @type {boolean} isDocumentLocked, whether the doc is locked
         */
        isDocumentLocked: '<?',
        /**
         * @type {object} Page - the page this annotation appears on
         */
        page: '<',
        isSelected: '<',
        isContentEditable: '<?',
        enforceBounds: '<?',
        enableMove: '<?',
        onSelect: '&',
        onSelectNext: '&',
        onSelectPrevious: '&'
    }
};

export default textAnnotationComponent;
