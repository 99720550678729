import {
    Component, Input, Output, EventEmitter, OnInit
} from '@angular/core';
import {
    FormBuilder, Validators, FormGroup
} from '@angular/forms';
import {
    PageManipulationActions,
    DocumentPage
} from '@florencehealthcare/doc-viewer';
import { CurrentSessionService } from '@app/core/current-session.service';
import { getDefaultSigningReason } from '@app/shared/documents/get-default-signing-reason.util';
import {
    Annotation, AnnotationType, Document, SigningReasons, User
} from '@app/shared/models';
import { PasswordOrPinExpiryService } from '@app/shared/password-or-pin-expiry/password-or-pin-expiry.service';

import { notBlank } from '@app/core/form-validators';
import template from './annotations-modal.component.html';
import { CreateAnnotationsEvent, CreateAnnotationsEventData } from './annotations-modal.component.types';

@Component({
    selector: 'annotations-modal',
    template
})
export class AnnotationsModalComponent implements OnInit {
    @Input() annotations: Annotation[];
    @Input() doc: Document;
    @Input() jobTitle: string;
    @Input() isNewDocViewer: boolean;
    @Input() numberOfSignaturesAdded: number;
    @Input() pages: Array<DocumentPage> = [];
    @Input() pageManipulationActions: PageManipulationActions = {
        rotated: false,
        moved: false,
        removed: false
    };

    @Output() save = new EventEmitter<CreateAnnotationsEvent>();
    @Output() dismiss = new EventEmitter<void>();

    isProcessing = false;
    steps = {};
    selectedStep: string;
    form: FormGroup;
    currentUser: User;
    progress: number;
    signaturesCount: number;
    regularAnnotationNames: string[];
    hasAnnotationsAndSignature: boolean;
    numberOfSteps: number;
    isCertifiedCopy: boolean;
    isSigningPasscodeExpired: boolean;
    annotationTypes: Array<keyof typeof AnnotationType>;
    passwordOrPasscode: 'Signing passcode' | 'Password';
    reasonLabelMessage = 'Please select a reason for this signature';
    performedPageManipulationActions: Array<string> = []

    constructor(
        private fb: FormBuilder,
        private CurrentSession: CurrentSessionService,
        private PasswordOrPinExpiry: PasswordOrPinExpiryService
    ) {
        this.currentUser = this.CurrentSession.getCurrentUser();
    }

    ngOnInit(): void {
        this.signaturesCount = this.isNewDocViewer ? this.numberOfSignaturesAdded : this.getSignaturesCount();
        this.annotationTypes = this.getAnnotationTypes();
        this.regularAnnotationNames = this.isNewDocViewer
            ? this.getRegularAnnotationNamesNewDocViewer()
            : this.getRegularAnnotationNames();
        this.hasAnnotationsAndSignature = this.resolveIfHasAnnotationsAndSignatures();
        this.numberOfSteps = this.resolveNumberOfSteps();
        this.progress = 1;
        this.isSigningPasscodeExpired = this.checkIfPasscodeIsExpired();
        this.passwordOrPasscode = this.currentUser.isRemote ? 'Signing passcode' : 'Password';
        this.setPerformedPageManipulationActions();
        this.initForm();
        this.resolveCertifiedCopy();
        this.updateSignatureReasonInAnnotations(this.form.controls.signatureReason && this.form.controls.signatureReason.value);
    }

    initForm(): void {
        this.form = this.fb.group({});

        if (this.hasSignatures()) {
            const signingReason = getDefaultSigningReason(this.doc, this.currentUser.id);
            this.form.addControl('signatureReason', this.fb.control(signingReason, Validators.required));
            this.form.addControl('signingPassphrase', this.fb.control('', [
                Validators.required
            ]));
            this.form.addControl('jobTitle', this.fb.control(this.jobTitle, [Validators.required]));
            this.form.controls.jobTitle.disable();
        }

        if (this.hasRegularAnnotations()) {
            this.form.addControl('annotationReason', this.fb.control('', [Validators.required, notBlank]));
        }

        if (this.hasPageManipulations()) {
            this.form.addControl('pageManipulationReason', this.fb.control('', [Validators.required, notBlank]));

        }
    }

    setProgress(progress: string | number): void {
        this.progress = Number(progress);
        this.selectedStep = this.steps[this.progress];
    }

    saveForm(): void {
        if (this.isProcessing || this.form.invalid) {
            return;
        }

        this.isProcessing = true;

        const password = this.form.controls.signingPassphrase && this.form.controls.signingPassphrase.value;
        const jobTitle = this.hasSignatures() ? this.form.controls.jobTitle.value : '';

        const data: CreateAnnotationsEventData = {
            email: this.currentUser.email,
            reason: this.form.controls.annotationReason && this.form.controls.annotationReason.value,
            pageManipulationReason: this.form.controls.pageManipulationReason && this.form.controls.pageManipulationReason.value,
            signingReason: this.form.controls.signatureReason && this.form.controls.signatureReason.value,
            ...this.currentUser.isRemote ? { signingPasscode: password } : { password },
            jobTitle,
            pages: this.pages
        };

        this.save.emit({
            data,
            onSuccess: () => {
                this.isProcessing = false;
                this.dismissModal();
            },
            onError: () => {
                this.isProcessing = false;
            }
        });
    }

    dismissModal(): void {
        this.dismiss.emit();
    }

    onReasonChanged(reason: SigningReasons): void {
        this.form.controls.signatureReason.setValue(reason);
        this.resolveCertifiedCopy();
        this.updateSignatureReasonInAnnotations(reason);
    }

    incrementProgress(direction: -1 | 1): void {
        const hasRegularAnnotations = this.hasRegularAnnotations();

        if (direction === 1) {
            if (this.selectedStep === 'Edits') {
                this.selectedStep = hasRegularAnnotations ? 'Annotation' : 'Signature';
            }
            else if (this.selectedStep === 'Annotation') {
                this.selectedStep = 'Signature';
            }
        }
        else if (direction === -1) {
            if (this.selectedStep === 'Signature') {
                this.selectedStep = hasRegularAnnotations ? 'Annotation' : 'Edits';
            }
            else if (this.selectedStep === 'Annotation') {
                this.selectedStep = 'Edits';
            }
        }
        this.progress += direction;
    }

    private getSignaturesCount(): number {
        const { pageCount } = this.doc;

        return this.annotations.reduce((total, annotation) => {
            if (annotation.type === 'signature') {
                return total + (annotation.isPropagated ? pageCount : 1);
            }

            return total;
        }, 0);
    }

    private getAnnotationTypes(): Array<keyof typeof AnnotationType> {
        return this.annotations.reduce((types, annotation) => {
            if (!types.includes(annotation.type)) {
                types.push(annotation.type);
            }

            return types;
        }, []);
    }

    private getRegularAnnotationNamesNewDocViewer(): string[] {
        const annotationTypeNames = {
            text: 'Text',
            highlight: 'Highlight',
            redaction: 'Redact',
            timestamp: 'Timestamp'
        };

        return this.annotationTypes.reduce((names, type) => {
            if (type !== 'signature') {
                names.push(annotationTypeNames[type]);
            }

            return names;
        }, []);
    }

    private getRegularAnnotationNames(): string[] {
        const annotationTypeNames = {
            add_text: 'Text',
            highlight: 'Highlight',
            redaction: 'Redact'
        };

        return this.annotationTypes.reduce((names, type) => {
            if (type !== 'signature') {
                names.push(annotationTypeNames[type]);
            }

            return names;
        }, []);
    }

    private resolveIfHasAnnotationsAndSignatures(): boolean {
        return this.annotationTypes.length > 1
            && this.hasSignatures()
            && this.hasRegularAnnotations();
    }

    private resolveNumberOfSteps(): number {
        const hasSignatures = this.hasSignatures();
        const hasRegularAnnotations = this.hasRegularAnnotations();
        const hasEditedPages = this.hasPageManipulations();

        let stepCounter = 1;

        if (hasEditedPages) {
            this.steps[stepCounter] = 'Edits';
            stepCounter += 1;
            this.selectedStep = 'Edits';
        }

        if (hasRegularAnnotations) {
            this.steps[stepCounter] = 'Annotation';
            if (stepCounter === 1) {
                this.selectedStep = 'Annotation';
            }
            stepCounter += 1;
        }

        if (hasSignatures) {
            this.steps[stepCounter] = 'Signature';
            if (stepCounter === 1) {
                this.selectedStep = 'Signature';
            }
        }

        return (hasSignatures ? 1 : 0) + (hasRegularAnnotations ? 1 : 0) + (hasEditedPages ? 1 : 0);
    }

    private setPerformedPageManipulationActions(): void {
        if (this.pageManipulationActions) {
            if (this.pageManipulationActions?.rotated) {
                this.performedPageManipulationActions.push('Pages rotated');
            }
            if (this.pageManipulationActions?.removed) {
                this.performedPageManipulationActions.push('Pages deleted');
            }
            if (this.pageManipulationActions?.moved) {
                this.performedPageManipulationActions.push('Pages reordered');
            }
        }
    }

    private hasSignatures(): boolean {
        return this.annotationTypes.includes('signature');
    }

    private hasRegularAnnotations(): boolean {
        return this.annotationTypes.some((type) => type !== 'signature');
    }

    private hasPageManipulations(): boolean {
        if (this.hasPageManipulations) {
            return this.pageManipulationActions?.rotated
            || this.pageManipulationActions?.moved
            || this.pageManipulationActions?.removed;
        }
    }

    private resolveCertifiedCopy(): void {
        this.isCertifiedCopy = this.form.controls.signatureReason
            && this.form.controls.signatureReason.value === SigningReasons.certifiedCopy;
    }

    private checkIfPasscodeIsExpired(): boolean {
        return this.currentUser.isRemote && this.PasswordOrPinExpiry.isExpired(
            this.CurrentSession.getCurrentTeam().settings.signingPINPolicy,
            this.currentUser.lastSigningPasscodeReset
        );
    }

    private updateSignatureReasonInAnnotations(reason: SigningReasons): void {
        this.annotations.forEach((annotation) => {
            if (annotation.type === 'signature') {
                annotation.reason = reason;
            }
        });
    }
}
