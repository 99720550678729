import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import downloadsRoute from './downloads.route';
import downloadsIndexComponent from './containers/downloads-index/downloads-index.component';

const dependencies = [
    uiRouter
];

const bindersModule = angular
    .module('app.downloads', dependencies)
    .config(downloadsRoute)
    .component('downloadsIndex', downloadsIndexComponent);

export default bindersModule;
