import './document.viewer.scss';
import template from './document.viewer.html';
import controller from './document.viewer.controller';

const documentViewerComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        doc: '<',
        requestIsPastDue: '<',
        teamSignatureRestricted: '<',
        annotations: '<',
        signatureData: '<',
        isReadOnly: '<'
    }
};

export default documentViewerComponent;
