import * as angular from 'angular';
import documentPageActionsComponent from './document-page-actions.component';


const dependencies = [];
const documentPager = angular
    .module('app.shared.documents.documentContentEditor.documentPageActions', dependencies)
    .component('documentPageActions', documentPageActionsComponent);

export default documentPager;
