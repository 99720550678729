import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BrowserModule } from '@angular/platform-browser';
import { AdapterService } from '@app/shared/adapter/adapter.service';

import { FloCheckboxComponent } from '@app/widgets/flo-checkbox/flo-checkbox.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { EntityModule } from './entity/entity.module';
import { ModalsModule } from './modals/modals.module';
import { PagesModule } from './pages/pages.module';

import { CheckboxTristateComponent } from './checkbox-tristate/checkbox-tristate.component';
import { TimezonePickerComponent } from './timezone-picker/timezone-picker.component';
import { ToggleComponent } from './toggle/toggle.component';
import { DateTimeComponent } from './date-time/date-time.component';
import { DynamicFilterComponent } from './dynamic-filter/dynamic-filter.component';
import { PageWarningComponent } from './page-warning/page-warning.component';
import { PageInfoComponent } from './page-info/page-info.component';
import { SubjectIconComponent } from './icons/subject-icon/subject-icon.component';
import { WarningWellComponent } from './warning-well/warning-well.component';
import { RequiredIconComponent } from './icons/required-icon/required-icon.component';
import { ProjectIconComponent } from './icons/projects-icon/projects-icon.component';
import { DeadlineIconComponent } from './icons/deadline-icon/deadline-icon.component';
import { ArchiveIconComponent } from './icons/archive-icon/archive-icon.component';
import { FileLockIconComponent } from './icons/file-lock-icon/file-lock-icon.component';
import { SortPipe } from './sort/sort.pipe';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { TableFilterComponent } from './table-filter/table-filter.component';
import { ScrollableBoxComponent } from './scrollable-box/scrollable-box.component';
import { UploadDropzoneComponent } from './upload/upload-dropzone/upload-dropzone.component';
import { UploadLinkComponent } from './upload/upload-link/upload-link.component';
import { ActionBarItemComponent } from './action-bar-item/action-bar-item.component';
import { DropdownInfoComponent } from './dropdown-info/dropdown-info.component';
import { NameEmailComponent } from './name-email/name-email.component';
import { BannerComponent } from './banner/banner.component';
import { FilteredSelectComponent } from './filtered-select/filtered-select.component';
import { FluidHeightDirective } from './fluid-height/fluid-height.directive';
import { ReasonModalComponent } from './reason/reason-modal.component';

// AJS Upgraded Components
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.wrapper.component';
import { DateTimePickerDirective } from './date-time-picker/date-time-picker.directive';
import { SearchDropdownComponent } from './search-dropdown/search-dropdown.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { ImportViaEmailComponent } from './import-via-email/import-via-email.component';
import { SignatureReasonPickerComponent } from './signatures/signature-reason-picker/signature-reason-picker.component';
import { ConfirmDestroyComponent } from './confirm-destroy/confirm-destroy.component';
import { TogglePiiComponent } from './documents/toggle-pii/toggle-pii.component';
import { CursorPaginationComponent } from './cursor-pagination/cursor-pagination.component';
import { GenericTableComponent } from './tables/generic-table/generic-table.component';
import { OldGenericTableComponent } from './tables/old-generic-table/old-generic-table.component';
import { VirtualTreeComponent } from './virtual-tree/virtual-tree.component';
import { FilterByPipe } from './filter/filter-by.pipe';
import { LineClampDirective } from './line-clamp/line-clamp-ng2.directive';
import { ManageAccessComponent } from './manage-access/manage-access.component';
import { JobTitleRequiredChangeComponent } from './job-title-required-change/job-title-required-change.component';
import { JobTitleRequiredComponent } from './job-title-required/job-title-required.component';
import { LabelsAssignComponent } from './labels-assign/labels-assign.component';
import { MissingPermissionsComponent } from './missing-permissions/missing-permissions.component';
import { TimelineAssignComponent } from './timeline-assign/timeline-assign.component';
import { WarningModalComponent } from './warning-modal/warning-modal.component';

// Services
import { GlobalSearchService } from './global-search/global-search.service';
import { FilterableListComponent } from './filterable-list/filterable-list.component';
import { TagsAssignComponent } from './tags-assign/tags-assign.component';
import { FolderStructureNodeComponent } from './folder-structure-node/folder-structure-node.component';
import { FolderStructureTreeComponent } from './folder-structure-tree/folder-structure-tree.component';
import { ApplyReusableStructureTemplateComponent } from './apply-reusable-structure-template/apply-reusable-structure-template.component';
import { ChooseStructureTemplateComponent } from './choose-structure-template/choose-structure-template.component';
import { StructureTemplateAppliedDueDatesComponent } from './structure-template-applied-due-dates/structure-template-applied-due-dates.component';
import { StructureTemplateAssignedComponent } from './structure-template/structure-template-assigned/structure-template-assigned.component';
import { DocumentSetDateComponent } from './structure-template/document-set-date/document-set-date.component';
import { TeamDeactivationMarkerComponent } from './deactivation-marker/deactivation-marker.component';
import { WizardComponent } from './wizard/wizard.component';
import { WizardStepComponent } from './wizard/wizard-step/wizard-step.component';
import { WizardSkeletonComponent } from './wizard/ui/wizard-skeleton.component';
import { NameEmailBlockComponent } from './name-email-block/name-email-block.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ScrollingModule,
        ModalsModule,
        PagesModule,
        UIRouterModule,
        EntityModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        TypeaheadModule.forRoot(),
        PaginationModule.forRoot(),
        BrowserAnimationsModule,
        BsDropdownModule.forRoot(),
        BrowserModule,
        HttpClientModule
    ],
    providers: [
        GlobalSearchService,
        AdapterService,
        { provide: 'Window', useValue: window }
    ],
    declarations: [
        CheckboxTristateComponent,
        TimezonePickerComponent,
        ToggleComponent,
        DateTimeComponent,
        PageWarningComponent,
        PageInfoComponent,
        SubjectIconComponent,
        WarningWellComponent,
        TableFilterComponent,
        RequiredIconComponent,
        ProjectIconComponent,
        BreadcrumbsComponent,
        DeadlineIconComponent,
        ArchiveIconComponent,
        FileLockIconComponent,
        SortPipe,
        ScrollableBoxComponent,
        UploadDropzoneComponent,
        UploadLinkComponent,
        DynamicFilterComponent,
        FilterableListComponent,
        ActionBarItemComponent,
        DropdownInfoComponent,
        NameEmailComponent,
        NameEmailBlockComponent,
        DateTimePickerComponent,
        BannerComponent,
        FilteredSelectComponent,
        DateTimePickerDirective,
        FluidHeightDirective,
        SearchDropdownComponent,
        GlobalSearchComponent,
        NavMenuComponent,
        ImportViaEmailComponent,
        VirtualTreeComponent,
        GenericTableComponent,
        SignatureReasonPickerComponent,
        ConfirmDestroyComponent,
        ReasonModalComponent,
        TogglePiiComponent,
        CursorPaginationComponent,
        OldGenericTableComponent,
        ManageAccessComponent,
        TimelineAssignComponent,
        FilterByPipe,
        LineClampDirective,
        JobTitleRequiredChangeComponent,
        JobTitleRequiredComponent,
        LabelsAssignComponent,
        TagsAssignComponent,
        MissingPermissionsComponent,
        FolderStructureNodeComponent,
        FolderStructureTreeComponent,
        WarningModalComponent,
        ApplyReusableStructureTemplateComponent,
        ChooseStructureTemplateComponent,
        StructureTemplateAppliedDueDatesComponent,
        StructureTemplateAssignedComponent,
        DocumentSetDateComponent,
        FloCheckboxComponent,
        TeamDeactivationMarkerComponent,
        WizardComponent,
        WizardStepComponent,
        WizardSkeletonComponent
    ],
    entryComponents: [
        CheckboxTristateComponent,
        TimezonePickerComponent,
        ToggleComponent,
        DateTimeComponent,
        PageWarningComponent,
        PageInfoComponent,
        SubjectIconComponent,
        WarningWellComponent,
        TableFilterComponent,
        RequiredIconComponent,
        ProjectIconComponent,
        BreadcrumbsComponent,
        DeadlineIconComponent,
        ArchiveIconComponent,
        FileLockIconComponent,
        ScrollableBoxComponent,
        UploadDropzoneComponent,
        UploadLinkComponent,
        DynamicFilterComponent,
        FilterableListComponent,
        ActionBarItemComponent,
        DropdownInfoComponent,
        NameEmailComponent,
        NameEmailBlockComponent,
        BannerComponent,
        FilteredSelectComponent,
        SearchDropdownComponent,
        GlobalSearchComponent,
        NavMenuComponent,
        ImportViaEmailComponent,
        VirtualTreeComponent,
        GenericTableComponent,
        OldGenericTableComponent,
        SignatureReasonPickerComponent,
        ConfirmDestroyComponent,
        ReasonModalComponent,
        TogglePiiComponent,
        CursorPaginationComponent,
        ManageAccessComponent,
        TimelineAssignComponent,
        JobTitleRequiredChangeComponent,
        JobTitleRequiredComponent,
        LabelsAssignComponent,
        TagsAssignComponent,
        MissingPermissionsComponent,
        FolderStructureNodeComponent,
        FolderStructureTreeComponent,
        WarningModalComponent,
        ApplyReusableStructureTemplateComponent,
        ChooseStructureTemplateComponent,
        StructureTemplateAppliedDueDatesComponent,
        StructureTemplateAssignedComponent,
        DocumentSetDateComponent,
        FloCheckboxComponent,
        TeamDeactivationMarkerComponent,
        WizardComponent,
        WizardStepComponent,
        WizardSkeletonComponent
    ],
    exports: [
        ModalsModule,
        PagesModule,
        ToggleComponent,
        DateTimeComponent,
        RequiredIconComponent,
        ActionBarItemComponent,
        DropdownInfoComponent,
        NameEmailComponent,
        NameEmailBlockComponent,
        DateTimePickerComponent,
        PageWarningComponent,
        PageInfoComponent,
        BannerComponent,
        ScrollableBoxComponent,
        TableFilterComponent,
        EntityModule,
        CheckboxTristateComponent,
        FilteredSelectComponent,
        DateTimePickerDirective,
        BreadcrumbsComponent,
        DynamicFilterComponent,
        FilterableListComponent,
        WarningWellComponent,
        SortPipe,
        DeadlineIconComponent,
        ArchiveIconComponent,
        FluidHeightDirective,
        SearchDropdownComponent,
        NavMenuComponent,
        VirtualTreeComponent,
        GenericTableComponent,
        OldGenericTableComponent,
        SignatureReasonPickerComponent,
        ManageAccessComponent,
        TimelineAssignComponent,
        FilterByPipe,
        LineClampDirective,
        LabelsAssignComponent,
        TagsAssignComponent,
        UploadDropzoneComponent,
        UploadLinkComponent,
        FolderStructureNodeComponent,
        FolderStructureTreeComponent,
        FileLockIconComponent,
        ApplyReusableStructureTemplateComponent,
        ChooseStructureTemplateComponent,
        StructureTemplateAppliedDueDatesComponent,
        StructureTemplateAppliedDueDatesComponent,
        StructureTemplateAssignedComponent,
        DocumentSetDateComponent,
        ProjectIconComponent,
        FloCheckboxComponent,
        TeamDeactivationMarkerComponent,
        CursorPaginationComponent,
        WizardComponent,
        WizardStepComponent,
        WizardSkeletonComponent,
        GlobalSearchComponent,
        SubjectIconComponent
    ]
})
export class WidgetsModule { }
