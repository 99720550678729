import * as _ from 'lodash';
import { Subject, forkJoin } from 'rxjs';
import { Transition } from '@uirouter/angularjs';
import { ConfirmDestroySubmitEvent } from '@app/widgets/confirm-destroy/confirm-destroy.component.types';
import { CursorPageChangedEvent } from '@app/widgets/cursor-pagination/cursor-pagination.component.types';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { MissingPermission, MissingPermissionSaveEvent } from '@app/widgets/missing-permissions/missing-permissions.types';
import { TeamPermissions } from '@app/shared/models';
import { StateService } from '@uirouter/core';
import { RolesService } from '../../roles.service';

class RolePermissionsController {
    // bindings
    public $transition$: Transition;
    roleId;
    currentTeam;
    role;
    permissionSets;
    hasMultiplePages;
    loadingRole;
    loadedRole;
    crumbs;
    _Roles: RolesService;
    _Notifications: NotificationsService;
    $state: StateService;

    constructor(
        $q,
        modalHelper,
        CurrentSession,
        Roles: RolesService,
        Permissions,
        Team,
        PermissionValidator,
        Notifications: NotificationsService,
        $state
    ) {
        this._$q = $q;
        this._modalHelper = modalHelper;
        this._CurrentSession = CurrentSession;
        this._Roles = Roles;
        this._Permissions = Permissions;
        this._Teams = Team;
        this._PermissionValidator = PermissionValidator;
        this._Notifications = Notifications;
        this._$state = $state;

        this.loadedRole = false;
        this.loadingRole = false;
        this.pageSize = 20;

        this.numValuesShow = 3;
        this._paginationReset = new Subject();

        this.paginationReset$ = this._paginationReset.asObservable();
    }

    $onInit(): void {
        this.roleId = this.$transition$.params().roleId;

        this.currentTeam = this._CurrentSession.getCurrentTeam();

        if (!this.currentTeam || !this._canManageRolePermissions(this.currentTeam.permissions)) {
            return this._$state.go('app.select-team');
        }

        this.loadingRole = true;

        this._$q.all({
            role: this._loadRole(),
            permissionSets: this._loadPermissions()
        }).then(({ role, permissionSets }) => {
            this.role = role;
            this.permissionSets = permissionSets.items;
            this.hasMultiplePages = !!permissionSets.next;
            this.loadingRole = false;
            this.loadedRole = true;
            this.crumbs = this._getCrumbs();
        });
    }

    private _canManageRolePermissions(perm: TeamPermissions): boolean {
        return perm.manageTeam || perm.viewTeamUsersRolesPermissions;
    }

    toggleActions($event, index, permissionSet) {
        $event.stopPropagation();
        if (this.selectedPermissionSet !== permissionSet) {
            this.select(permissionSet);
        }
        this.openedActions = {};
        this.openedActions[index] = true;
    }


    select(permissionSet) {
        if (permissionSet.selected) {
            permissionSet.selected = false;
            this.selectedPermissionSet = undefined;
        }
        else {
            this.permissionSets.forEach((set) => {
                set.selected = false;
            });

            permissionSet.selected = true;
            this.selectedPermissionSet = permissionSet;
        }
    }

    canActOnCreate() {
        return true;
    }

    canActOnSelection() {
        return !!this.selectedPermissionSet;
    }

    createPermissionSet(type) {
        return this._modalHelper.open({
            animation: true,
            size: 'lg',
            component: 'role-permissions-form',
            resolve: {
                permissionSetType: () => type,
                mode: () => 'create',
                roleId: () => this.role.id,
                selectedItems: () => {
                    return type === 'team' ? [{ type: 'team', id: this.currentTeam.id }] : [];
                },
                onSave: () => this._savePermissionSet.bind(this)
            }
        });
    }

    pageChanged(event: CursorPageChangedEvent): void {
        this.loadingPermissionSets = true;

        const next = event.cursor;

        this._loadPermissions({
            ...next && { next }
        }).then((permissionSets) => {
            if (!next) {
                this.hasMultiplePages = !!permissionSets.next;
            }
            this.permissionSets = permissionSets.items;
            this.loadingPermissionSets = false;
            event.onSuccess();
        });
    }

    updatePermissionSet(permissionSet) {
        return this._modalHelper.open({
            animation: true,
            size: 'lg',
            component: 'role-permissions-form',
            resolve: {
                permissionSetType: () => permissionSet.objectType.slice(0, -1),
                mode: () => 'update',
                permissionSet: () => permissionSet,
                roleId: () => this.role.id,
                selectedItems: () => [{ type: permissionSet.objectType.slice(0, -1), id: permissionSet.objectId }],
                onSave: () => this._savePermissionSet.bind(this)
            }
        });
    }

    removePermissionSet(permissionSet) {
        this._modalHelper.open({
            animation: true,
            component: 'confirm-destroy-wrapper',
            resolve: {
                onSubmit: () => (event: ConfirmDestroySubmitEvent): void => {
                    this._Roles.removeRolePermissions(
                        this.currentTeam.id,
                        this.role.id,
                        {
                            objectId: permissionSet.objectId,
                            objectType: permissionSet.objectType.slice(0, -1)
                        }
                    ).toPromise().then(() => {
                        this._Notifications.success('Permission deleted!');
                        this.selectedPermissionSet = undefined;
                        this._paginationReset.next();
                        event.onSuccess();
                    })
                        .catch(event.onError);
                },
                bodyText: () => `This action <b class="text-uppercase">cannot</b> be undone.
                    This will permanently remove permissions that role has on
                    ${permissionSet.objectType.slice(0, -1)}: <b>${_.escape(permissionSet.path)}</b>`,
                confirmVerb: () => 'Remove',
                confirmVerbProcessing: () => 'Removing'
            },
            size: 'md'
        });
    }

    openPermissionInfoModal(permissionSet) {
        this._modalHelper.open({
            component: 'permission-info',
            animation: true,
            size: 'lg',
            resolve: {
                effectivePermissions: () => {
                    return this._Permissions.getEffectivePermissions({
                        subjectId: this.role.id,
                        subjectType: this.role.type,
                        objectId: permissionSet.objectId,
                        objectType: permissionSet.objectType.slice(0, -1),
                        teamId: this.currentTeam.id
                    }).toPromise();
                },
                item: () => ({
                    type: permissionSet.objectType.slice(0, -1),
                    name: permissionSet.path.split('/').pop(),
                    path: permissionSet.path,
                    pathCanonical: permissionSet.path,
                    teamId: this.currentTeam.id
                }),
                subject: () => this.role,
                onlyDirect: (): boolean => false,
                editable: (): boolean => false,
                disableEdit: (): boolean => true
            }
        });
    }

    _getCrumbs() {
        return [
            {
                name: 'Manage Roles',
                stateName: 'app.team.manage-roles',
                stateParams: { teamId: this.currentTeam.teamId }
            },
            {
                name: this.role.name
            }
        ];
    }

    _loadRole() {
        return this._Roles.getRole(this.currentTeam.id, this.roleId).toPromise().then((data) => data);
    }

    _loadPermissions(params = {}) {
        return this._Roles
            .getRolePermissions(this.currentTeam.id, this.roleId, { pageSize: this.pageSize, ...params }).toPromise()
            .then((response) => {
                this.next = response.next;
                return response;
            });
    }

    _savePermissionSet(items, itemPermissions) {
        const missingPermissionsObservables = [];

        items.forEach((item) => {
            const observable = this._PermissionValidator
                .getMissingPermissions({ item: { teamId: this.currentTeam.id, ...item }, itemPermissions });
            missingPermissionsObservables.push(observable);
        });

        return forkJoin(missingPermissionsObservables)
            .toPromise()
            .then((permissions) => {
                const localPermissions = [];
                const externalPermissions = [];

                permissions.forEach((permission) => {
                    permission.localPermissions.forEach((localPermissionToAdd) => {
                        const localPermissionIndex = localPermissions.findIndex((localPermission) => {
                            return localPermission.permissionName === localPermissionToAdd.permissionName
                                && localPermission.objectInfo?.objectType === localPermissionToAdd.objectInfo?.objectType
                                && localPermission.objectInfo?.objectName === localPermissionToAdd.objectInfo?.objectName;
                        });
                        if (localPermissionIndex === -1) {
                            localPermissions.push(localPermissionToAdd);
                        }
                    });

                    permission.externalPermissions.forEach((externalPermissionToAdd) => {
                        const externalPermissionIndex = externalPermissions.findIndex((externalPermission) => {
                            return externalPermission.permissionName === externalPermissionToAdd.permissionName
                                && externalPermission.objectInfo?.objectType === externalPermissionToAdd.objectInfo?.objectType
                                && externalPermission.objectInfo?.objectName === externalPermissionToAdd.objectInfo?.objectName;
                        });
                        if (externalPermissionIndex === -1) {
                            externalPermissions.push(externalPermissionToAdd);
                        }
                    });
                });

                if (!localPermissions.length && !externalPermissions.length) {
                    return this._savePermissionTree(items, itemPermissions);
                }

                this._modalHelper.open({
                    animation: true,
                    component: 'missing-permissions-wrapper',
                    size: 'md',
                    resolve: {
                        activeSubjectName: (): string => this.role.name,
                        missingPermissions: (): MissingPermission[] => localPermissions.concat(externalPermissions)
                            .map((permission) => {
                                return {
                                    name: permission.permissionName,
                                    objectInfo: permission.objectInfo
                                };
                            }),
                        onSave: () => (event: MissingPermissionSaveEvent): void => {
                            event.onSave();
                            if (externalPermissions.length) {
                                return this._Permissions
                                    .saveMissing(this.currentTeam.teamId, {
                                        permissions: externalPermissions.map((p) => p.saveParams)
                                    })
                                    .toPromise().then(() => this._savePermissionTree(items, itemPermissions));
                            }
                            localPermissions.forEach((permission) => permission.fix());
                            return this._savePermissionTree(items, itemPermissions);
                        }
                    }
                });
            });
    }

    _savePermissionTree(items, itemPermissions) {
        itemPermissions.subjectId = itemPermissions.subject.id;
        itemPermissions.subjectType = itemPermissions.subject.type;

        const shouldRemoveSrc = !items.map((i) => i.id).includes(itemPermissions.tree.id);
        const srcObjectId = itemPermissions.tree.id;

        if (items.length > 1) {
            itemPermissions.tree.ids = items.map((i) => i.id);
            delete itemPermissions.tree.id;
        }
        else {
            itemPermissions.tree.id = items[0].id;
        }

        this.selectedPermissionSet = undefined;

        return this._Teams.updatePermissions(itemPermissions)
            .toPromise()
            .then(() => {
                if (shouldRemoveSrc) {
                    return this._Roles.removeRolePermissions(
                        this.currentTeam.id,
                        this.role.id,
                        {
                            objectId: srcObjectId,
                            objectType: itemPermissions.tree.type
                        }
                    ).toPromise();
                }
            })
            .then(() => {
                this._paginationReset.next();
            });
    }
}

RolePermissionsController.$inject = [
    '$q',
    'modalHelper',
    'CurrentSession',
    'Roles',
    'Permissions',
    'Team',
    'PermissionValidator',
    'Notifications',
    '$state'
];

export default RolePermissionsController;
