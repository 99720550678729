import template from './projects-detail-page.html';
import controller from './projects-detail-page.controller';

const projectsDetailPageComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        $transition$: '<'
    }
};

export default projectsDetailPageComponent;
