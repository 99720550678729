import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from '@app/shared/shared.module';
import { EntityModule } from '@app/widgets/entity/entity.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimelineVisibilitySettingsComponent } from './compontents/timeline-visibility-settings/timeline-visibility-settings.component';
import { DestroyProjectOrTimelineComponent } from './compontents/destroy-project/destroy-project-or-timeline.component';
import { ProjectizerItemComponent } from './containers/projectizer/projectizer-item/projectizer-item.component';
import { EditProjectModalComponent } from './compontents/edit-project-modal/edit-project-modal.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        DragDropModule,
        EntityModule,
        WidgetsModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        NotificationsService
    ],
    declarations: [
        TimelineVisibilitySettingsComponent,
        DestroyProjectOrTimelineComponent,
        ProjectizerItemComponent,
        EditProjectModalComponent
    ],
    entryComponents: [
        TimelineVisibilitySettingsComponent,
        DestroyProjectOrTimelineComponent,
        ProjectizerItemComponent,
        EditProjectModalComponent
    ],
    exports: []
})
export class ProjectsModule {
}
