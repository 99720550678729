import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import {
    Team, Binder, BrowseTree
} from '@app/shared/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import {
    VirtualTreeFlatNode,
    VirtualTreeItemSelectedEvent
} from '@app/widgets/virtual-tree/virtual-tree.component.types';
import { BrowseParams } from '@app/shared/teams/teams.service.types';
import { Document } from '@app/shared/models/document.model';
import { ConfirmationModal } from '@app/shared/modals/confirmation-modal/confirmation-modal';
import { Connection } from '../../services/document-sharing.types';
import styles from './share-documents.component.scss';
import template from './share-documents.component.html';

@Component({
    selector: 'share-documents',
    template,
    styles: [String(styles)]
})
export class ShareDocumentsComponent implements OnInit {
    @Input() modalStepsData: Record<string, any>[];
    @Input() allTeams$: Observable<Connection[]>;
    @Input() selectedTeams: Connection[];
    @Input() currentTeam: Team;
    @Input() binders: Observable<Binder[]>;
    @Input() exchangeName: string;
    @Input() loadItem: (params: BrowseParams) => Promise<BrowseTree>;
    @Input() onSelectionChange: ($event: VirtualTreeItemSelectedEvent<VirtualTreeFlatNode>) => void;
    @Input() selectedDocuments: Document[];

    @Output() selectTeams = new EventEmitter<Connection[]>();
    @Output() deselectTeams = new EventEmitter<Connection[]>();
    @Output() closeModal = new EventEmitter<boolean>();
    @Output() updateExchangeNameEvent = new EventEmitter<string>();
    @Output() deselectDocumentEvent = new EventEmitter<Document>();
    @Output() createExchangeEvent = new EventEmitter<boolean>();

    progress = 1;
    progressSteps;

    constructor(private modalRef: BsModalRef, private Modals: ModalsService) {}

    ngOnInit(): void {
        this.progressSteps = this.modalStepsData.reduce((accumulator, step) => {
            return { ...accumulator, [step.id]: step.title };
        }, {});
    }

    openConfirmationModal(): void {
        const confirmModalRef = this.Modals.show(ConfirmationModal, {
            animated: true,
            initialState: {
                confirmationTitle: 'Cancel Document Sharing?',
                confirmationMessage: 'Clicking Confirm below will not save your work or share any documents.',
                handleConfirm: () => {
                    confirmModalRef.hide();
                    this.closeModal.emit(true);
                }
            }
        });
    }

    shouldCloseModalWithoutConfirmation(): void {
        if ((!this.exchangeName && !this.selectedDocuments.length)) {
            this.closeModal.emit(true);
        }
        else {
            this.openConfirmationModal();
        }
    }

    onTeamsFilter(event) {
        console.log('Emit Filter Event');
    }

    getHeaderText(step: number) {
        const validStep = this.modalStepsData.find((currentStep) => currentStep.id === step);
        return validStep ? validStep.description : '';
    }

    setProgressStep(event) {
        this.progress = Number(event);
    }

    incrementProgress(num: number) {
        this.progress += num;
    }

    shareDocuments() {
        this.createExchangeEvent.emit(true);
    }

    propagateSelectTeamsEvent(event) {
        this.selectTeams.emit(event);
    }

    propagateDeselectTeamsEvent(event) {
        this.deselectTeams.emit(event);
    }

    propagateExchangeNameEvent(event) {
        this.updateExchangeNameEvent.emit(event);
    }

    propagateDeselectDocumentEvent(event) {
        this.deselectDocumentEvent.emit(event);
    }

    checkIsNextDisabled() {
        switch (this.progress) {
            case 1:
                return !(
                /*  WARNING: Uncomment this part of the code when set/edit exchangeName is available again

                    this.exchangeName && */
                    this.selectedDocuments && this.selectedDocuments.length);
            case 2:
                return !(this.selectedTeams && this.selectedTeams.length);
            default:
                return false;
        }
    }
}
