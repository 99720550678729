import template from './create-timeline-modal.html';
import controller from './create-timeline-modal.controller';

const createTimelineComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default createTimelineComponent;
