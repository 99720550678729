import './document.toolbar.button.scss';
import template from './document.toolbar.button.html';
import controller from './document.toolbar.button.controller';

const documentToolbarButtonComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        label: '@?',
        isSelected: '<'
    },
    transclude: true
};

export default documentToolbarButtonComponent;
