import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'table-filter',
    template: `
    <div class="input-group" [ngStyle]="{'width': expandInputField ? '100%' : 'none'}">
        <span class="input-group-addon" *ngIf="showIcon">
            <i class="fa fa-fw fa-lg fa-filter test-icon"></i>
        </span>
        <input class="form-control test-tableFilterInput"
            type="text"
            placeholder="{{ placeholder }}"
            #filter
            (keyup)="onFilterText(filter.value)" />
    </div>
    `,
    styles: [':host { flex-basis: 250px; }']
})
export class TableFilterComponent implements OnInit {
    @Input() placeholder = 'Filter by...';
    @Input() debounceTime = 0;
    @Input() showIcon = true;
    @Input() expandInputField = false;

    @Output() filter = new EventEmitter<string>();

    debouncer: Subject<string> = new Subject<string>();

    ngOnInit() {
        this.debouncer.pipe(debounceTime(this.debounceTime))
            .subscribe((value) => {
                this.filter.emit(value);
            });
    }

    onFilterText(value: string): void {
        this.debouncer.next(value);
    }
}
