import * as _ from 'lodash';
import { Subject } from 'rxjs';
import {
    takeUntil
} from 'rxjs/operators';
import {
    Annotation,
    Document,
    Page,
    Team,
    User,
    SignatureRequest,
    SignatureTypes
} from '@app/shared/models';
import { SimpleChanges } from '@angular/core';
import { CurrentSessionService } from '@app/core/current-session.service';
import { qcReviewStatuses } from '@florencehealthcare/florence-constants/lib/qc-reviews';
import ToolMode from '../constants/tool.mode';

class DocumentToolbarController {
    ToolMode = ToolMode;
    doc: Document;
    currentTextAnnotationValue: string;
    inFormMode: boolean;
    hasSignatureRequest: boolean;
    requestIsPastDue: boolean;
    teamSignatureRestricted: boolean;
    onSign: ({ decline: boolean }) => void;
    onClearAll: () => void;
    showClearAll: boolean;
    isDocumentLocked = false;
    isAnnotationSelected: boolean;
    isFormFieldSelected: boolean;
    selectedObject: { annotation: Annotation; page: Page };
    _currentUser: User;
    _currentTeam: Team;
    showQcAcceptButton = false;
    showQcDeclineButton = false;
    showQcReviewButton = false;
    selectedTool; // TODO
    toolOptions; // TODO
    isProcessingAccept = false;
    isProcessingDecline = false;
    qcReviewModal = false;
    review = null;
    comment = '';
    isProcessing: boolean;
    reviewToConduct;
    qcReviewAction;
    textAnnotationWarning = ' When propagating text annotation you must fill in the text before applying propagation!';
    qcAcceptDocument: ({ event }) => void;
    qcDeclineDocument: ({ event }) => void;
    approveQcReview: ({ event }) => void;
    rejectQcReview: ({ event }) => void;

    constructor(
        private $scope: ng.IScope,
        private DocumentSignatureFormat,
        private CurrentSession: CurrentSessionService,
        private DocumentViewerToolsOptions,
        private DocumentViewerStore,
        private DocumentAnnotation,
        private DocumentFormField
    ) {
        this.destroy$ = new Subject();
    }

    $onInit(): void {
        this.isProcessing = false;
        this.review = this.doc.qcReview;
        this._currentUser = this.CurrentSession.getCurrentUser();
        this._currentTeam = this.CurrentSession.getCurrentTeam();
        this.qcButtonAcceptVisible();
        this.qcButtonDeclineVisible();
        this.qcButtonReviewVisible();

        this.isDocumentLocked = this.doc.isLocked;

        this.DocumentViewerStore.selectedTool$
            .pipe(takeUntil(this.destroy$))
            .subscribe((tool) => {
                this.selectedTool = tool;
                this.toolOptions = this.DocumentViewerToolsOptions.getOptionsFor(
                    tool,
                    this.selectedObject && this.selectedObject.formField
                );
            });

        this.DocumentViewerStore.selectedObject$
            .pipe(takeUntil(this.destroy$))
            .subscribe((object) => {
                this.selectedObject = object;
                this.isAnnotationSelected = object && object.type === 'annotation';
                this.isFormFieldSelected = object && object.type === 'formField';

                if (this.inFormMode) {
                    // in form mode after selection/de-selection unset the tool and get options for field
                    this.DocumentViewerStore.setSelectedTool(undefined);
                }
            });
    }

    $onChanges(changes: SimpleChanges): void {
        if (changes.doc?.currentValue.qcReview && !changes.doc.isFirstChange()) {
            this.qcButtonAcceptVisible();
            this.qcButtonDeclineVisible();
            this.qcButtonReviewVisible();
            this.review = this.doc.qcReview;
        }
    }

    checkPreviousCycles(currentCycle) : boolean {
        for (let i = 0; i < currentCycle; i += 1) {
            if (this.doc.qcReview.reviews[i].status !== qcReviewStatuses.APPROVED) {
                return false;
            }
        }
        return true;
    }

    clearAll() {
        this.isProcessing = true;
        this.onClearAll();
    }

    qcButtonAcceptVisible() {
        if (this.doc.qcReview && this.doc.permissions.conductQCReview) {

            const nextReviewer = this.doc.qcReview.reviews.findIndex(
                (element) => element.reviewerIds.id === this._currentUser.id
                && element.status === qcReviewStatuses.PENDING_REVIEW
            );

            switch (nextReviewer) {
                case 0:
                    this.showQcAcceptButton = true;
                    break;
                case -1:
                    this.showQcAcceptButton = false;
                    break;
                default:
                    this.showQcAcceptButton = this.checkPreviousCycles(nextReviewer);
            }
        }
    }

    qcButtonDeclineVisible() {
        if (this.doc.qcReview && this.doc.permissions.conductQCReview) {

            const nextReviewer = this.doc.qcReview.reviews.findIndex(
                (element) => element.reviewerIds.id === this._currentUser.id
                && (element.status === qcReviewStatuses.PENDING_REVIEW || element.status === qcReviewStatuses.IN_REVIEW)
            );

            switch (nextReviewer) {
                case 0:
                    this.showQcDeclineButton = true;
                    break;
                case -1:
                    this.showQcDeclineButton = false;
                    break;
                default:
                    this.showQcDeclineButton = this.checkPreviousCycles(nextReviewer);
            }
        }
    }

    qcButtonReviewVisible() {
        if (this.doc.qcReview && this.doc.permissions.conductQCReview) {

            const nextReviewer = this.doc.qcReview.reviews.findIndex(
                (element) => element.reviewerIds.id === this._currentUser.id
                && (element.status === qcReviewStatuses.IN_REVIEW
                    || element.status === qcReviewStatuses.IN_REJECTION_REVIEW)
            );

            switch (nextReviewer) {
                case 0:
                    this.showQcReviewButton = true;
                    break;
                case -1:
                    this.showQcReviewButton = false;
                    break;
                default:
                    this.showQcReviewButton = this.checkPreviousCycles(nextReviewer);
            }

            if (nextReviewer >= 0) {
                this.reviewToConduct = this.doc.qcReview.reviews[nextReviewer];
                this.qcReviewAction = this.reviewToConduct.status === qcReviewStatuses.IN_REJECTION_REVIEW ? 'REVISIT QC REVIEW' : 'QC REVIEW';
            }
        }
    }


    acceptQcReview() {
        this.isProcessingAccept = true;
        const event = {
            docId: this.doc.id as string,
            docVersion: this.doc.version,
            onError: () => {
                this.isProcessingAccept = false;
            },
            onSuccess: () => {
                this.isProcessingAccept = false;
            }
        };
        this.qcAcceptDocument({ event });
    }

    declineQcReview() {
        const event = {
            docId: this.doc.id as string,
            docVersion: this.doc.version,
            onError: () => {
                this.isProcessingDecline = false;
            },
            onClose: () => {
                this.isProcessingDecline = false;
            }
        };

        this.isProcessingDecline = true;
        this.qcDeclineDocument({ event });
    }

    $onDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getPendingSignature(): SignatureRequest {
        return this.doc.pendingSignatures.find(({ userId }) => userId === this._currentUser.id);
    }

    isTextAnnotationWithoutText() {

        return this.isAnnotationSelected
            && this.selectedObject
            && this.selectedObject.annotation
            && !this.selectedObject.annotation.isPropagated
            && this.selectedObject.annotation.type === 'add_text'
            && (
                this.currentTextAnnotationValue === undefined
                || this.currentTextAnnotationValue === ''
                || this.selectedObject.annotation.textValue === ''
            );

    }

    getLabelMessage() {
        const propagationTogle = this.selectedObject && this.selectedObject.annotation.isPropagated ? 'undo propagation' : 'propagate';
        let message = `Double click to ${propagationTogle} to All Pages.`;
        if (!this.selectedObject.annotation.isPropagated
            && this.selectedObject.annotation.type === 'add_text'
            && (this.currentTextAnnotationValue === undefined || this.currentTextAnnotationValue === '')) {
            message += this.textAnnotationWarning;
        }

        return message;
    }

    handlePropagate() {
        if (!this.isAnnotationSelected || this.isTextAnnotationWithoutText()) {
            return;
        }

        const { annotation, page } = this.selectedObject;
        if (annotation.isPropagated) {
            this.DocumentAnnotation.undoPropagateAnnotation(annotation);
            if (annotation.page.pageIndex !== page.pageIndex) {
                this.DocumentViewerStore.setSelectedObject(undefined);
            }
        }
        else {
            this.DocumentAnnotation.propagateAnnotation(annotation);
        }
    }

    handleDelete() {
        if (!this.isAnnotationSelected) {
            return;
        }

        this.DocumentAnnotation.deleteAnnotation(this.selectedObject.annotation, this.selectedObject.page);
        this.DocumentViewerStore.setSelectedObject(undefined);
    }

    handleReset() {
        if (!this.isFormFieldSelected) {
            return;
        }

        this.DocumentFormField.resetFormField(this.selectedObject.formField);
        this.DocumentViewerStore.setSelectedObject({
            ...this.selectedObject,
            annotation: this.DocumentFormField.getAnnotationForFormField(this.selectedObject.formField, this.selectedObject.page)
        });
    }

    handleClear() {
        if (!this.isFormFieldSelected) {
            return;
        }

        this.DocumentFormField.clearFormField(this.selectedObject.formField);
        this.DocumentViewerStore.setSelectedObject(undefined);
    }

    onOptionsUpdate(value) {
        this.DocumentViewerToolsOptions.setOptionsFor(
            this.selectedTool,
            this.selectedObject && this.selectedObject.formField,
            value
        );

        if (this.selectedObject) {
            this.DocumentAnnotation.updateAnnotationProperties(
                this.selectedObject.annotation,
                this.selectedObject.page,
                value
            );
        }
    }

    isSelectedFormFieldEmpty() {
        return this.selectedObject && !this.selectedObject.annotation;
    }

    selectTool(tool) {
        this.DocumentViewerStore.setSelectedObject(undefined);
        this.DocumentViewerStore.setSelectedTool(tool);
    }

    signatureMethod() {
        if (!this.doc.pageCount) {
            if (this._currentTeam?.settings?.signatures?.disableAddendum) {
                return null;
            }
            return SignatureTypes.addendum;
        }
        if (this._currentTeam?.settings?.signatures?.disableAddendum) {
            return SignatureTypes.stamp;
        }
        if (this._currentTeam?.settings?.signatures?.disableAnnotation) {
            return SignatureTypes.addendum;
        }
        const pendingSignature = this.getPendingSignature();
        if (pendingSignature && pendingSignature.method) {
            return pendingSignature.method;
        }
        return SignatureTypes.any;
    }

    showToolbar(): boolean {

        return this.doc && (this.doc.permissions.signDocument
            || this.doc.permissions.addTextToDocument
            || this.doc.permissions.highlightDocument
            || this.doc.permissions.redactDocument
            || this.doc.permissions.timestampDocument
            || this.doc.permissions.conductQCReview
            || this.showQcAcceptButton
            || this.showQcDeclineButton)
            && !(!this.doc.pageCount && (this._currentTeam.settings.signatures.disableAddendum
                                        || this.doc.formStatus === 'checking-form'
                                        || this.doc.formStatus === 'form-in-progress'))
            && (this.doc.documentProperties && this.doc.documentProperties.approval
                ? this.doc.documentProperties.approval.status !== 'rejected' : true)
            && (this.doc.documentProperties && this.doc.documentProperties.approval
                ? this.doc.documentProperties.approval.status !== 'cancelled' : true);
    }

    handleDecline() {
        this.onSign({ decline: true });
    }

    isLatestVersion(): boolean {

        if (this.doc.originalDocument) {
            return this.doc.originalDocument.isLatestVersion;
        }
        return this.doc.isLatestVersion;
    }

    openCloseQcReview($event) {
        this.comment = $event ? $event.comment : this.comment;
        this.qcReviewModal = !this.qcReviewModal;
    }

    approveReview($event) {
        this.approveQcReview({ event: $event });
    }

    rejectReview($event) {
        this.rejectQcReview({ event: $event });
    }

    get isDocumentPreviewUnavailable() {
        return this.doc.conversionStatus === 'Pending'
            || this.doc.conversionStatus === 'Failed'
            || this.doc.conversionStatus === 'In Progress'
            || this.doc.conversionStatus === 'Too Large'
            || this.doc.conversionStatus === 'Unsupported File Type'
            || this.doc.isBrokenShortcut;
    }


}

DocumentToolbarController.$inject = [
    '$scope',
    'DocumentSignatureFormat',
    'CurrentSession',
    'DocumentViewerToolsOptions',
    'DocumentViewerStore',
    'DocumentAnnotation',
    'DocumentFormField'
];

export default DocumentToolbarController;
