import ToolMode from '../../constants/tool.mode';

class DocumentToolsDropdownController {
    constructor(Users, modalHelper) {
        this.ToolMode = ToolMode;
        this._Users = Users;
        this._modalHelper = modalHelper;
    }

    $onChanges(changes: ng.IOnChangesObject): void {
        if (changes.selectedTool) {
            this.displayMode = this._getDisplayMode();
        }
    }

    setSelectedTool(tool) {
        this.onSelectTool({ $event: tool });
    }

    _getDisplayMode() {
        switch (this.selectedTool) {
            case ToolMode.ADD_TEXT:
                return 'Text';
            case ToolMode.TIMESTAMP:
                return 'Timestamp';
            case ToolMode.HIGHLIGHT:
                return 'Highlight';
            case ToolMode.REDACT:
                return 'Redact';
            case ToolMode.NONE:
            case ToolMode.SIGNATURE:
            default:
                return 'Annotate';
        }
    }
}

DocumentToolsDropdownController.$inject = [
    'Users',
    'modalHelper'
];

export default DocumentToolsDropdownController;
