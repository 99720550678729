import template from './study-view.html';
import { StudyViewController as controller } from './study-view.controller';

export const StudyViewComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        $transition$: '<'
    }
};
