import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CommonModule } from '@angular/common';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { EntityModule } from '@app/widgets/entity/entity.module';
import { RolesTemplatesByStudyNumberComponent } from './components/roles-templates-by-study-number/roles-templates-by-study-number.component';
import { RolesService } from './roles.service';
import { RequestPreviewRoleTemplatesComponent } from './components/request-preview-role-templates/request-preview-role-templates.component';
import { AssignUsersToRolesComponent } from './components/assign-users-to-roles/assign-users-to-roles.component';
import { RolesTemplatesPreviewComponent } from './components/roles-templates-preview/roles-templates-preview.component';
import { RolesTemplatesByTemplateComponent } from './components/roles-templates-by-template/roles-templates-by-template.component';

@NgModule({
    imports: [
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        WidgetsModule,
        EntityModule
    ],
    providers: [
        RolesService,
        NotificationsService
    ],
    declarations: [
        RequestPreviewRoleTemplatesComponent,
        AssignUsersToRolesComponent,
        RolesTemplatesPreviewComponent,
        RolesTemplatesByStudyNumberComponent,
        RolesTemplatesByTemplateComponent
    ],
    entryComponents: [
        RequestPreviewRoleTemplatesComponent,
        AssignUsersToRolesComponent,
        RolesTemplatesPreviewComponent,
        RolesTemplatesByStudyNumberComponent,
        RolesTemplatesByTemplateComponent],
    exports: []
})
export class RolesModule {}
