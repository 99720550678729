import template from './day-picker.html';
import './day-picker.scss';

const dayPickerComponent: ng.IComponentOptions = {
    controller: () => {
        return undefined;
    },
    controllerAs: 'vm',
    template,
    bindings: {
        days: '=',
        validationError: '<'
    }
};

export default dayPickerComponent;
