import { Transition, StateService } from '@uirouter/angularjs';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { FILTERS } from '@florencehealthcare/florence-constants/lib/filters';
import ApiErrorFactory from '@app/shared/api-error/api-error.service';

class AccessDatesReportController {
    constructor(
        $transitions: Transition,
        CurrentSession,
        AccessDates,
        PaginationProperties,
        Downloads,
        private $state: StateService,
        private Notifications: NotificationsService,
        private ApiError: ApiErrorFactory,
        FeatureFlagService

    ) {
        this._CurrentSession = CurrentSession;
        this._Downloads = Downloads;
        this._AccessDates = AccessDates;
        this._$state = $state;
        this._FeatureFlag = FeatureFlagService;
        this.loading = true;
        this.currentDate = new Date();
        this._filters = FILTERS;

        this.textFilterTypes = [
            { label: 'Name', type: 'name' },
            { label: 'Role', type: 'role' }
        ];
        [this.textFilterSelected] = this.textFilterTypes;
        this.filterValue = '';
        this.items = [];

        this.showBoundaryLinkNumbers = PaginationProperties.showBoundaryLinkNumbers();
        this.maxSize = PaginationProperties.getMaxSize();
        this.pagination = {
            pageNum: 1,
            pageSize: 20,
            sortBy: 'end',
            sortDir: 'DESC'
        };
        const deregister = $transitions.onExit({}, () => {
            this.cancel();
            deregister();
        });

        this.debouncedRefreshParams = this.debounce(this.refreshReportParams, this._filters.DEBOUNCE_TIME);
    }

    $onInit(): void {
        this._FeatureFlag.getFlag(FEATURE_FLAGS.NEW_ACCESS_DATES_REPORT, true)
            .subscribe((value) => {
                this.isNewAccessDates = value;
            });

        this.team = this._CurrentSession.getCurrentTeam();
        this._AccessDates.getAccessDatesReport({ teamId: this.team.id, ...this.pagination })
            .toPromise()
            .then((res) => {
                if (!this.isNewAccessDates) {
                    this.items = res.items;
                    this.pagination.totalItems = res.recordCount;

                }
                else {
                    this.items = res.data;
                    this.pagination.totalItems = res.metadata.total;
                }
                this.loading = false;
            });
    }

    cancel(): void {
        this.modalInstance.dismiss('cancel');
    }

    downloadAccessDates(format) {
        const downloadParams = {
            teamId: this.team.id,
            name: 'Access Dates Report',
            format
        };
        this._Downloads.downloadAccessDatesReport(downloadParams)
            .toPromise()
            .then(() => {
                const href = this.$state.href('app.team.downloads', { teamId: this.team.id });
                const message = `<p>Starting download now! We'll notify you when your download is ready.</p>
                    Go to <a class="page-action u-d-inline" href=${href}>MY DOWNLOADS</a> to view all downloads.`;
                this.Notifications.info({ message, closeOnClick: false });
            })
            .catch(this.ApiError.handleError);
    }

    isOngoingDate(date) {
        return new Date(date) >= this._AccessDates.MAX_DATE;
    }

    daysSinceLastLoginWithRole(loginDate: string) {
        if (!loginDate) {
            return 'N/A';
        }
        const currentDate = new Date();
        const lastLoginDate = new Date(loginDate);
        const timeDifference = currentDate.getTime() - lastLoginDate.getTime();
        const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        return daysPassed;
    }

    debounce(func, delay) {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    }

    refreshReportParams() {
        this.loading = true;
        const params = {
            ...this.pagination,
            teamId: this.team.id
        };

        if (this.filterValue) {
            params.filterValue = this.filterValue;
            params.filterBy = this.textFilterSelected.type;
        }

        this._AccessDates.getAccessDatesReport(params)
            .toPromise()
            .then((res) => {
                if (!this.isNewAccessDates) {
                    this.items = res.items;
                    this.pagination.totalItems = res.recordCount;
                }
                else {
                    this.items = res.data;
                    this.pagination.totalItems = res.metadata?.total;
                }

                this.loading = false;
            });
    }

    filterKeyPressed(event) {
        if (event.keyCode === 13 || !this.textFilter) {
            this.applyFilter();
        }
    }

    applyFilter() {
        this.pagination.totalItems = 0;
        this.pagination.pageNum = 1;
        this.debouncedRefreshParams();
    }

    handleSort(type) {
        if (this.pagination.sortBy === type) {
            this.pagination.sortDir = this.pagination.sortDir === 'ASC' ? 'DESC' : 'ASC';
        }
        this.pagination.sortBy = type;
        this.debouncedRefreshParams();
    }

    determineStatusString(accessObject) {

        if (this.isNewAccessDates && !accessObject) {
            return 'N/A';
        }

        if (!accessObject.isActive) {
            return 'Inactive';
        }
        if (new Date(accessObject.end) < this.currentDate) {
            return 'Expired';
        }
        if (new Date(accessObject.start) > this.currentDate) {
            return 'Future';
        }

        return 'Active';
    }
}

AccessDatesReportController.$inject = [
    '$transitions',
    'CurrentSession',
    'AccessDates',
    'PaginationProperties',
    'Downloads',
    '$state',
    'Notifications',
    'ApiError',
    'FeatureFlagService'
];

export default AccessDatesReportController;
