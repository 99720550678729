import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';
import uiRouter from '@uirouter/angularjs';

import { NeedHelpComponent } from './containers/need-help/need-help.component';
import { NeedHelpModalWrapper } from './components/need-help-modal-wrapper/need-help-modal-wrapper.component';

const dependencies = [
    uiRouter
];

export const needHelpModule = angular
    .module('app.needHelp', dependencies)
    .component('needHelpModalWrapper', NeedHelpModalWrapper)
    .directive('needHelp', downgradeComponent({ component: NeedHelpComponent }) as ng.IDirectiveFactory);
