import * as angular from 'angular';
import AutoHeightDirective from './auto-height.directive';
import VirtualRepeatDirective from './virtual-repeat.directive';
import VirtualRepeatContainerDirective from './virtual-repeat-container.directive';
import UtilFactory from './md-util.service';

export default angular
    .module('app.widgets.virtualRepeat', [])
    .factory('$mdUtil', UtilFactory)
    .directive('mdVirtualRepeatContainer', VirtualRepeatContainerDirective)
    .directive('mdVirtualRepeat', VirtualRepeatDirective)
    .directive('mdAutoHeight', AutoHeightDirective);
