import {
    Component, OnInit, OnDestroy, ViewChild
} from '@angular/core';
import {
    BehaviorSubject, forkJoin, of, Subject
} from 'rxjs';
import {
    takeUntil, switchMap, tap, take,
    map,
    catchError
} from 'rxjs/operators';
import {
    Team, BindersArchives, ApiError, AuditTrailSubject,
    BinderPermissions,
    Archive
} from '@app/shared/models';
import { StructureTemplate } from '@app/components/study-startup/components/imported-structures-table/imported-structures-table.component.types';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { TeamService } from '@app/shared/teams/team.service';
import { BindersService } from '@app/shared/binders/binders.service';
import { AuditTrailService } from '@app/shared/audit-trail/audit-trail.service';
import { LabelsService } from '@app/shared/labels/labels.service';
import { DownloadsService } from '@app/shared/downloads/downloads.service';
import { ActionBarService } from '@app/shared/action-bar/action-bar.service';
import { ModalsService, ModalRef } from '@app/shared/modal-helper/modals.service';
import { ApplyReusableStructureTemplateComponent } from '@app/widgets/apply-reusable-structure-template/apply-reusable-structure-template.component';
import { AuditTrailModalComponent } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component';

import * as _ from 'lodash';
import { StateService } from '@uirouter/core';
import { StudyStartupService } from '@app/components/study-startup/study-startup.service';
import { AuditTrailTypes } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component.types';
import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';
import { JobTitleRequiredChangeComponent } from '@app/widgets/job-title-required-change/job-title-required-change.component';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SORT } from '@app/core/constants';
import { Item } from '@app/shared/permissions/permission-validator.service.types';
import { PermissionsEditComponent } from '@app/components/permissions/containers/permissions-edit/permissions-edit.component';
import { FolderizerComponent } from '@app/components/folderizer/folderizer.component';
import { BinderDownloadComponent } from '../binder-download/binder-download.component';
import { DestroyBinderComponent } from '../../components/destroy-binder/destroy-binder.component';
import { BinderFormComponent } from '../../components/binder-form/binder-form.component';
import { BinderIndexItem } from './binder-index.types';
import template from './binders-index.component.html';
import styles from './binders-index.component.scss';
import { DestroyEvent } from '../../components/destroy-binder/destroy-binder.component.types';

@Component({
    selector: 'binders-index',
    template,
    styles: [String(styles)]
})
export class BindersIndexComponent implements OnInit, OnDestroy {
  @ViewChild(CdkVirtualScrollViewport, { static: false }) virtualScroll: CdkVirtualScrollViewport;
  readonly COMFORTABLE = 'comfortable';
  readonly COMPACT = 'compact';
  private stateParams: { [paramName: string]: string };
  selectedBinders = new SelectionModel<string>(false);
  signatureQueue: any;
  loadingSignatureQueue = true;
  taskQueue: any;
  loadingTaskQueue = true;
  testBre = true;
  tableDensity: 'comfortable' | 'compact';
  topIndex = 0;
  crumbs = [{ name: 'Binders' }];
  loadingBinders = false;
  loadedBinders = false;
  loadingDocumentCounts = false;
  currentTeam: Team;
  currentlyHoveredBinder: BinderIndexItem;
  selectedBinder: BinderIndexItem;
  binders: BinderIndexItem[];
  sortName: string;
  filter: string;
  createdBinderId: string;
  openDropDown: string;
  archives: BindersArchives;
  isApplyStructureOpen = false;
  templates: StructureTemplate[] = [];
  private destroy$ = new Subject<void>();
  newDocViewerEnabled: boolean;
  SORT = SORT;
  filterEvent = new BehaviorSubject<string>('');

  constructor(
    private $state: StateService,
    private bindersService: BindersService,
    private teamService: TeamService,
    private auditTrailService: AuditTrailService,
    private labelsService: LabelsService,
    private downloadsService: DownloadsService,
    private notificationsService: NotificationsService,
    private currentSessionService: CurrentSessionService,
    private actionBarService: ActionBarService,
    private apiErrorFactory: ApiErrorsService,
    private studyStartupService: StudyStartupService,
    private modalsService: ModalsService
  ) { }

  ngOnInit(): void {
      this.stateParams = this.$state.params;
      const teamId = this.stateParams.teamId || undefined;
      this.currentTeam = this.currentSessionService.getCurrentTeam();
      this.tableDensity = this.currentTeam.settings.tableDensity === this.COMPACT ? this.COMPACT : this.COMFORTABLE;
      this.loadSignatureQueue(teamId);
      this.loadTaskQueue(teamId);
      this.loadArchives(teamId);
      this.loadingBinders = true;
      this.bindersService
          .getDecoratedBinders(teamId, { includeArchived: false })
          .subscribe((binders) => {
              this.binders = _.cloneDeepWith(binders, this.mapBindersToBinderIndexItems);
              this.loadedBinders = true;
              if (binders && binders.length) {
                  this._loadDocumentCounts(teamId);
                  this.updateSort('name', false);
              }
          }, ({ error }) => this.apiErrorFactory.handleError(error),
          () => {
              this.loadingBinders = false;
          });
      if (this.currentTeam.permissions.createTeamStudies) {
          this.studyStartupService.getStructureTemplates(this.currentTeam.id)
              .subscribe(
                  (data) => {
                      this.templates = data;
                  },
                  (error: ApiError): void => {
                      if (error.error && error.error.message) {
                          this.notificationsService.error(error.error.message);
                      }
                      else {
                          this.notificationsService.unexpectedError();
                      }
                  }
              );
      }
      this.newDocViewerEnabled = !!this.currentTeam.settings.features.newDocumentViewer;
  }

  ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
  }

  private generateBinderHref(binder: any): string {
      return this.$state.href('app.team.folder', {
          teamId: this.currentTeam.id,
          binderId: binder.id
      });
  }

  private mapBindersToBinderIndexItems = (binders: any[]): BinderIndexItem[] => {
      return binders.map((binder) => {
          return this.mapBinderToBinderIndexItemInitial(binder);
      });
  }

  private mapBinderToBinderIndexItemInitial = (binder: any): BinderIndexItem => {
      return {
          ...binder,
          permissions: null,
          documentCount: 0,
          selected: false,
          directLabels: null,
          href: this.generateBinderHref(binder)
      };
  }

  objectKeys(obj: any): string[] {
      return Object.keys(obj);
  }

  private loadSignatureQueue(teamId: string): void {
      this.loadingSignatureQueue = true;
      this.actionBarService
          .getSignatureQueue({ teamId })
          .subscribe((queue) => {
              this.signatureQueue = queue;
              this.loadingSignatureQueue = false;
          }, () => {
              this.signatureQueue = undefined;
              this.loadingSignatureQueue = false;
          });
  }

  private loadTaskQueue(teamId: string): void {
      this.loadingTaskQueue = true;
      this.actionBarService
          .getTaskQueue({ teamId })
          .subscribe((queue) => {
              this.taskQueue = queue;
              this.loadingTaskQueue = false;
          }, () => {
              this.taskQueue = undefined;
              this.loadingTaskQueue = false;
          });
  }

  applyStructureClick($event: MouseEvent): void {
      this.isApplyStructureOpen = !this.isApplyStructureOpen;
      $event.stopPropagation();
  }

  canAssignLabels(): boolean {
      return _.get(this, 'currentTeam.permissions.labelAssign') && _.get(this, 'currentTeam.settings.features.labels');
  }

  canFolderize(): boolean {
      return !!_.get(this, 'selectedBinder.permissions.folderImportTemplates', false);
  }

  canApplyReusableStructureTemplate(): boolean {
      return this.templates.length > 0 && this.currentTeam.permissions.createTeamStudies;
  }

  canViewAuditTrail(): boolean {
      return !!_.get(this, 'selectedBinder.permissions.viewBinderAuditTrail', false);
  }

  canEdit(): boolean {
      return this.selectedBinder
      && (_.get(this, 'selectedBinder.permissions.renameBinder', false) || this.canAssignLabels());
  }

  canDestroy(): boolean {
      return !!_.get(this, 'selectedBinder.permissions.destroyBinder', false);
  }

  canArchive() {
      return this.currentTeam.settings.features.longTermArchive
      && (this.selectedBinder && !this.archives[this.selectedBinder.id])
      && _.get(this, 'selectedBinder.permissions.scheduleArchive', false);
  }

  openArchiveWizard(): void {
      const binder = this.selectedBinder;
      this.$state.go('app.team.archive-wizard', {
          objectId: binder.id,
          objectType: binder.type,
          objectName: binder.name
      });
  }

  canDownload(): boolean {
      if (!this.selectedBinder) {
          return false;
      }
      const hasDocuments = this.selectedBinder.documentCount > 0;
      const isDownloadable = !!_.get(this.selectedBinder, 'permissions.downloadDocumentWithoutPii', false)
      || !!_.get(this.selectedBinder, 'permissions.downloadDocumentWithPii', false);
      return isDownloadable && hasDocuments;
  }

  canManagePermissions(): boolean {
      return this.selectedBinder && this.currentTeam.permissions.manageTeamRolesAndPermissions;
  }

  canCreateBinder(): boolean {
      return this.currentTeam.permissions.createBinder;
  }

  canOpenGlobalView(): boolean {
      return !!this.selectedBinder;
  }

  canActOnSelection() {
      if (!this.selectedBinder) {
          return false;
      }

      return this.canViewAuditTrail()
        || this.canDestroy()
        || this.canDownload()
        || this.canOpenGlobalView()
        || this.canFolderize()
        || this.canApplyReusableStructureTemplate()
        || this.canManagePermissions()
        || this.canEdit()
        || this.canArchive();
  }

  openAuditTrailModal(): void {
      if (
          this.selectedBinder
      && this.selectedBinder.permissions
      && this.selectedBinder.permissions.viewBinderAuditTrail
      ) {
          const auditItem = {
              id: this.selectedBinder.id,
              teamId: this.selectedBinder.teamId,
              permissions: this.selectedBinder.permissions,
              type: this.selectedBinder.type as AuditTrailTypes,
              name: this.selectedBinder.name
          };
          const params = {
              subject: AuditTrailSubject.BINDER,
              teamId: auditItem.teamId,
              objectId: auditItem.id,
              overwrittenObjectId: null,
              limitToOverwritten: false,
              ...this.auditTrailService.auditPagination
          };

          this.auditTrailService.getAudits(params).subscribe((audits) => {
              this.modalsService.show(AuditTrailModalComponent, {
                  animated: true,
                  class: 'modal-lg',
                  initialState: {
                      data: audits,
                      item: auditItem,
                      subject: params.subject,
                      pagination: this.auditTrailService.auditPagination,
                      onPageChange: this.auditTrailService.getAudits.bind(this.auditTrailService)
                  }
              });
          },
          ({ error }) => {
              const message = error.message || 'There was an unexpected error.  Please contact your administrator.';
              this.notificationsService.error(message);
          });
      }
  }

  openBinder({ teamId, id }): void {
      this.$state.go('app.team.folder', { teamId, binderId: id });
  }

  openFolderizerModal(): void {
      if (!this.canFolderize()) {
          return;
      }

      this.modalsService.show(FolderizerComponent, {
          animated: true,
          class: 'modal-lg',
          initialState: {
              item: this.selectedBinder,
              timezone: this.currentTeam.settings.timezone
          }
      });
  }

  applyReusableStructureTemplate(): void {
      if (!this.canApplyReusableStructureTemplate()) {
          return;
      }

      this.modalsService.show(ApplyReusableStructureTemplateComponent, {
          initialState: {
              teamId: this.currentTeam.id,
              teamTimezone: this.currentTeam.settings.timezone,
              templates: this.templates,
              binderId: this.selectedBinder.id,
              canManageDocumentDueAndExpirationDates: this.currentTeam.permissions.manageDocumentDates
          }
      });
  }

  openPermissionsModal(): void {
      if (!this.canManagePermissions()) {
          return;
      }

      this.modalsService.show(PermissionsEditComponent, {
          animated: true,
          class: 'modal-lg',
          initialState: {
              item: this.selectedBinder as unknown as Item
          }
      });
  }

  editSelected(): void {
      if (!this.canEdit()) {
          return;
      }
      const { id, teamId } = this.selectedBinder;

      let observables = [of([]), of([])];
      if (this.canAssignLabels()) {
          observables = [
              this.labelsService.getLabels(teamId),
              this.bindersService.getDirectLabels(teamId, id)
          ];
      }
      forkJoin(observables)
          .pipe(takeUntil(this.destroy$))
          .subscribe(([availableLabels, assignedLabels]) => {
              const modalRef = this.modalsService.show(BinderFormComponent, {
                  animated: true,
                  initialState: {
                      binder: this.selectedBinder,
                      availableLabels,
                      assignedLabels
                  }
              });
              this.editSelectedModalSave(modalRef, this.selectedBinder);
          }, ({ error } : ApiError) => {
              const message = error.message || 'There was an unexpected error. Please contact your administrator.';
              this.notificationsService.error(message);
          });
  }

  private renameBinder(params: any): any {
      return this.bindersService.renameBinder(params)
          .pipe(
              tap((binder) => {
                  _.merge(_.find(this.binders, { id: params.binderId }), binder);
                  this.updateSort(this.sortName, this.SORT.isReversed);
              })
          );
  }

  private removeLabels(teamId: string, removedLabels: any[]): any {
      return this.labelsService.removeAssignedLabels(teamId, removedLabels)
          .pipe(tap(() => {
              delete this.selectedBinder.directLabels;
              _.pullAllBy(this.selectedBinder.labels, removedLabels, 'valueId');
          }));
  }

  private assignLabels(teamId: string, addedLabels: any[]): any {
      return this.labelsService.assignLabels(teamId, addedLabels)
          .pipe(tap(() => {
              delete this.selectedBinder.directLabels;
              this.selectedBinder.labels = this.selectedBinder.labels.concat(addedLabels);
          }));
  }

  editSelectedModalSave(modalRef: ModalRef<BinderFormComponent>, oldBinder: any): void {

      const { id: binderId, teamId, name: oldBinderName } = oldBinder;

      modalRef.content.save.pipe(takeUntil(this.destroy$))
          .subscribe(({
              name: newBinderName, removedLabels, addedLabels, onSave, onError
          }) => {
              const observables = [];
              if (newBinderName !== oldBinderName) {
                  observables.push(this.renameBinder({ teamId, binderId, binderName: newBinderName }));
              }
              if (removedLabels && removedLabels.length) {
                  observables.push(this.removeLabels(teamId, removedLabels));
              }
              if (addedLabels && addedLabels.length) {
                  observables.push(this.assignLabels(teamId, addedLabels));
              }

              forkJoin(observables).subscribe(
                  () => {
                      this.notificationsService.success('Binder updated.');

                      if (onSave) {
                          onSave();
                      }
                      else {
                          modalRef.hide();
                      }
                  },
                  ({ error }: ApiError) => {
                      if (onError) {
                          onError();
                      }
                      if (error.message) {
                          this.notificationsService.error(error.message);
                      }
                      else {
                          this.notificationsService.unexpectedError();
                      }
                  }
              );
          });
  }

  removeSelected(): void {
      if (!this.canDestroy()) {
          return;
      }
      const modal = this.modalsService.show(DestroyBinderComponent, {
          initialState: {
              binder: this.selectedBinder,
              archive: this.archives && this.archives[this.selectedBinder.id] as unknown as Archive
          }
      });

      modal.content.destroy.pipe(
          switchMap(({ data: { binder, reason } }: DestroyEvent) => {
              return forkJoin({
                  isBinderDeleted: this.bindersService.removeBinder(binder, reason),
                  removedBinder: of(binder)
              });
          })
      ).subscribe(
          ({
              removedBinder
          }) => {
              _.remove(this.binders, { id: removedBinder.id });
              this.selectedBinder = undefined;
              this.updateSort(this.sortName, this.SORT.isReversed);
              this.notificationsService.success(`Binder ${removedBinder.name} deleted.`);
              modal.hide();
          },
          ({ error }: { error: string }) => {
              const message = JSON.parse(error).message || 'There was an unexpected error.  Please contact your administrator.';
              this.notificationsService.error(message);
              modal.hide();
          }
      );
  }

  createBinder(): void {
      if (!this.canCreateBinder()) {
          return;
      }

      const { teamId } = this.currentTeam;

      if (this.canAssignLabels()) {
          this.labelsService.getLabels(teamId)
              .pipe(take(1))
              .subscribe((availableLabels) => {
                  const modalRef = this.modalsService.show(BinderFormComponent, {
                      animated: true,
                      initialState: {
                          availableLabels
                      }
                  });

                  this.createBinderModalSave(modalRef, teamId);
              });
      }
      else {
          const modalRef = this.modalsService.show(BinderFormComponent, {
              animated: true,
              initialState: {
                  availableLabels: []
              }
          });

          this.createBinderModalSave(modalRef, teamId);
      }
  }

  createBinderModalSave(modalRef, teamId): void {
      modalRef.content.save
          .pipe(takeUntil(this.destroy$))
          .subscribe(({
              name, addedLabels, onSave, onError
          }) => {
              this.bindersService
                  .createBinder(teamId, name)
                  .pipe(
                      map(this.mapBinderToBinderIndexItemInitial),
                      tap((binder) => {
                          this.binders.push(binder);
                          this.updateSort(this.sortName, this.SORT.isReversed);
                          this.createdBinderId = binder.id;
                          setTimeout(() => {
                              this.topIndex = _.findIndex(this.binders, { id: binder.id });
                              this.virtualScroll.scrollToIndex(this.topIndex);
                          });
                          setTimeout(() => {
                              this.createdBinderId = undefined;
                          }, 5000);
                      }),
                      switchMap(({ id }) => {
                          if (addedLabels && addedLabels.length) {
                              addedLabels.forEach((label) => {
                                  label.objectId = id;
                              });
                              return this.labelsService.assignLabels(teamId, addedLabels).pipe(
                                  tap((labels) => {
                                      const binder = _.find(this.binders, { id: labels[0].objectId });
                                      binder.labels = labels;
                                  })
                              );
                          }
                          return of(null);

                      }),
                      tap(() => {
                          this.notificationsService.success('Binder created.');
                          if (onSave) {
                              onSave();
                          }
                          else {
                              modalRef.hide();
                          }
                      }),
                      catchError(({ error }: ApiError) => {
                          if (onError) {
                              onError();
                          }
                          if (error.message) {
                              this.notificationsService.error(error.message);
                          }
                          else {
                              this.notificationsService.unexpectedError();
                          }
                          return of();
                      })
                  )
                  .subscribe();
          });
  }

  download(options = {}): void {
      if (!this.canDownload()) {
          return;
      }

      const modalRef = this.modalsService.show(BinderDownloadComponent, {
          class: 'modal-md',
          animated: true,
          initialState: {
              options: {
                  ...options,
                  teamId: this.selectedBinder.teamId,
                  binderId: this.selectedBinder.id,
                  binderName: this.selectedBinder.name
              },
              newDocViewerEnabled: this.newDocViewerEnabled
          }
      });

      modalRef.content.download.subscribe((params) => {
          this.downloadsService
              .downloadFile(params)
              .subscribe(() => {
                  const href = this.$state.href('app.team.downloads', { teamId: this.selectedBinder.teamId });
                  const message = `<p>Starting download now! We'll notify you when your download is ready.</p>
                                Go to <a class="page-action u-d-inline" href=${href}>MY DOWNLOADS</a> to view all downloads.`;
                  this.notificationsService.info({ message, closeOnClick: false });
              },
              ({ error }) => this.apiErrorFactory.handleError(error));
      });
  }

  select(binder: BinderIndexItem): void {
      if (this.selectedBinders.isSelected(binder.id)) {
          this.selectedBinder = undefined;
      }
      else {
          this.selectedBinder = binder;
      }
      this.selectedBinders.toggle(binder.id);
      this._setBinderPermissions(this.selectedBinder);
  }

  toggleActions($event, binder: BinderIndexItem): void {
      $event.preventDefault();
      if (this.selectedBinder !== binder) {
          this.select(binder);
          this.openDropDown = binder.id;
      }
      this.openDropDown = undefined;
  }

  openGlobalView(): void {
      if (!this.selectedBinder) {
          return;
      }

      const {
          id: objectId,
          teamId,
          type: objectType,
          name: objectName
      } = this.selectedBinder;
      this.$state.go('app.team.global-view', {
          teamId,
          objectId,
          objectType,
          objectName
      });
  }

  _loadDocumentCounts(teamId): void {
      this.loadingDocumentCounts = true;
      this.bindersService
          .loadDocumentCounts(teamId, this.binders.map((binder) => binder.id))
          .subscribe((response) => {
              const binderCounts = response.reduce((accumulator, current) => {
                  accumulator[current._id] = current;
                  return accumulator;
              }, {});

              this.binders.forEach((binder) => {
                  const countData = binderCounts[binder.id];
                  if (countData) {
                      binder.documentCount = countData.count;
                      binder.updatedAt = _.max([binder.updatedAt, countData.lastUpdated]);
                  }
                  else {
                      binder.documentCount = 0;
                  }
              });
              this.loadingDocumentCounts = false;
          }, ({ error }) => this.apiErrorFactory.handleError(error));
  }

  _setBinderPermissions(binder: BinderIndexItem): void {

      if (!binder) {
          return;
      }

      if (binder.permissions) {
          return;
      }

      this.teamService.getUserObjectPermissions(binder.id, 'binders')
          .subscribe((permissions) => {
              binder.permissions = permissions as unknown as BinderPermissions;
          });
  }

  updateSort(sortName: string, isReversed?: boolean): void {
      this.sortName = sortName;
      this.SORT.set(sortName, isReversed);
      const sortedBinders = this.binders.sort((a, b) => {
          return this.SORT.naturalSort({ value: a[sortName] }, { value: b[sortName] });
      });
      if (this.SORT.isReversed) {
          sortedBinders.reverse();
      }
      // Create a new array reference to trigger change detection
      this.binders = [...sortedBinders];
  }

  updateFilter(text: string): void {
      this.filter = text;
  }

  trackByIndex(index: number): number {
      return index;
  }

  loadLabels(binder: BinderIndexItem): void {
      this.currentlyHoveredBinder = binder;
      if (binder.directLabels) {
          return;
      }
      this.bindersService
          .getDirectLabels(this.currentTeam.id, binder.id)
          .pipe(take(1))
          .subscribe(
              (labels) => {
                  binder.directLabels = labels;
              },
              ({ error }: { error: { message: string } }) => {
                  const message = error.message || 'There was an unexpected error.  Please contact your administrator.';
                  this.notificationsService.error(message);
              }
          );
  }

  loadItemStudyProfile(binder: BinderIndexItem): void {
      this.currentlyHoveredBinder = binder;
      if (!binder.hasStudyProfile || binder.studyProfiles) {
          return;
      }

      this.bindersService
          .getBinderStudyProfile(this.currentTeam.id, binder.id)
          .subscribe((allStudyProfiles: any[]) => {
              binder.studyProfiles = (allStudyProfiles && allStudyProfiles.length === 1) ? allStudyProfiles : [];
          },
          ({ error }: { error: { message: string; status: number } }) => {
              if (error.status === 403) {
                  return [];
              }
              if (error.message) {
                  this.notificationsService.error(error.message);
              }
              else {
                  this.notificationsService.unexpectedError();
              }
          });
  }

  loadArchives(teamId: string): void {
      this.bindersService
          .getArchives(teamId)
          .pipe(take(1))
          .subscribe(
              (archives: BindersArchives) => {
                  this.archives = archives;
              },
              ({ error }: { error: { message: string } }) => {
                  if (error.message) {
                      this.notificationsService.error(error.message);
                  }
                  else {
                      this.notificationsService.unexpectedError();
                  }
              }
          );
  }

  setHoveredBinder(binder: BinderIndexItem): void {
      this.currentlyHoveredBinder = binder;
  }

  canChangeJobTitleSignature(): boolean {
      return this.currentTeam.permissions.jobTitleRequired;
  }

  changeJobTitleSignature(): void {
      const jobTitleRequiredModal = this.modalsService.show(JobTitleRequiredChangeComponent, {
          animated: true,
          class: 'modal-md',
          initialState: {
              jobTitleRequired: !this.selectedBinder.jobTitleRequired
          }
      });

      jobTitleRequiredModal.content.onConfirm.subscribe(() => {
          this.bindersService.updateJobTitleRequired(
              this.currentTeam.id, this.selectedBinder.id, !this.selectedBinder.jobTitleRequired
          ).subscribe(
              () => {
                  jobTitleRequiredModal.hide();
                  if (!this.selectedBinder.jobTitleRequired) {
                      this.notificationsService.success('Job Title Signature was added to the Binder');
                  }
                  else {
                      this.notificationsService.success('Job Title Signature was removed from the Binder');
                  }
                  this.selectedBinder.jobTitleRequired = !this.selectedBinder.jobTitleRequired;
              }, (error: ApiError): void => {
                  if (error.error && error.error.message) {
                      this.notificationsService.error(error.error.message);
                  }
                  else {
                      this.notificationsService.unexpectedError();
                  }
              }
          );
      });
      jobTitleRequiredModal.content.dismiss.subscribe(() => jobTitleRequiredModal.hide());
  }

  closeApplyStructureMenu(): void {
      this.isApplyStructureOpen = false;
  }
}
