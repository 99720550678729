import { NotificationsService } from '@app/core/notifications/notifications.service';
import ApiErrorFactory from '@app/shared/api-error/api-error.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { User } from '@app/shared/models';
import { SigningPasscodeService } from '@app/shared/signing-passcode/signing-passcode.service';
import { UsersService } from '@app/shared/users/users.service';
import { ResetPasswordInfoComponent } from '../reset-password-info/reset-password-info.component';

export class UserSecuritySettingsController {
    user: User;
    isProcessing = false;

    constructor(
        private Modals: ModalsService,
        private SigningPasscode: SigningPasscodeService,
        private Notifications: NotificationsService,
        private Users: UsersService,
        private ApiError: ApiErrorFactory
    ) {}

    changeSignPin(event) {
        this.SigningPasscode.resetSigningPasscode().subscribe(() => {
            this.Notifications.success('Instructions for setting your Signing PIN has been sent to your email');
        });
        event.preventDefault();
        event.stopPropagation();
    }

    resetPassword(): void {
        this.isProcessing = true;
        this.Users.resetPasswordAuth0(this.user.emailCanonical).toPromise().then(() => {
            this.isProcessing = false;
            this.Modals.show(ResetPasswordInfoComponent, {
                animated: true,
                initialState: {}
            });
        }).catch((error) => {
            this.isProcessing = false;
            this.ApiError.handleError(error);
        });
    }
}

UserSecuritySettingsController.$inject = [
    'ModalsService',
    'SigningPasscode',
    'Notifications',
    'Users',
    'ApiError'
];
