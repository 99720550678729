import controller from './manage-roles-templates.controller';
import template from './manage-roles-templates.html';
import './manage-roles-templates.scss';

const manageRolesTemplatesComponent: ng.IComponentOptions = {
    controller,
    controllerAs: 'vm',
    template,
    bindings: {}
};

export default manageRolesTemplatesComponent;
