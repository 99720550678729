import * as angular from 'angular';
import checkboxFormFieldComponent from './checkbox.form.field.component';

const dependencies = [];

const checkboxFormField = angular
    .module('app.checkboxFormField', dependencies)
    .component('checkboxFormField', checkboxFormFieldComponent);

export default checkboxFormField;
