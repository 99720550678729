import * as angular from 'angular';
import redactionComponent from './redaction.component';


const dependencies = [

];
const redaction = angular.module('app.shared.documents.documentContentEditor.documentViewer.submodules.annotations.redaction', dependencies)
    .component('redaction', redactionComponent);

export default redaction;
