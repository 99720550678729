import './prefill.text.picker.scss';
import template from './prefill.text.picker.html';
import controller from './prefill.text.picker.controller';

const prefillTextPickerComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        value: '<',
        onChange: '&'
    }
};

export default prefillTextPickerComponent;
