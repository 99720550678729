import './document.selection.scss';
import template from './document.selection.html';
import controller from './document.selection.controller';

const documentSelectionComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        /**
         * @type {{start: {x: number, y:number}, end: {x:number, y:number}}} range
         */
        range: '<',
        page: '<'
    }
};

export default documentSelectionComponent;
