import * as angular from 'angular';
import documentViewerComponent from './document.viewer.component';
import annotations from './annotations';
import primitives from './primitives';
import tools from './tools';
import formFields from './form.fields';
import documentViewerImage from './document-viewer-image/document-viewer-image.component';

const dependencies = [
    annotations.name,
    primitives.name,
    formFields.name,
    tools.name
];

const documentViewer = angular.module('app.shared.documents.documentContentEditor.documentViewer', dependencies)
    .component('documentViewer', documentViewerComponent)
    .component('documentViewerImage', documentViewerImage);

export default documentViewer;
