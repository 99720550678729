import './page.region.scss';
import template from './page.region.html';
import controller from './page.region.controller';

const pageRegionComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        /**
         * Represents the expanse of this region, x and y are floats representing percentage values of the
         * width / height of the document.  The are in [0,1] inclusive.
         * @type { {start: {x:number, y:number}, end: {x: number, y: number}} } range
         */
        range: '<',
        page: '<',
        /**
         * @type {boolean} interactive, whether the region accepts mouse events
         */
        interactive: '<?',
        /**
         * @type {boolean} isDocumentLocked, whether the doc is locked
         */
        isDocumentLocked: '<?',
        // If false, right and bottom constraints are not added, content will define the bounds of the region
        enforceBounds: '<?',
        zIndex: '<?',
        contentStyle: '&?'
    },
    transclude: true
};

export default pageRegionComponent;
