import { Transition } from '@uirouter/angularjs';
import {
    Binder, Folder, Document, StudyEntity
} from '@app/shared/models';
import { UpdateLinkedEntitiesParams } from '@app/shared/studies/studies.service.types';
import { VirtualTreeFlatNode, VirtualTreeItemSelectedEvent, VirtualTreeSelectionMode } from '@app/widgets/virtual-tree/virtual-tree.component.types';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';

export class StudyLinkEntitiesController {
    public loadingRoot = false;
    public isProcessing = false;
    public items: Binder[];
    public SelectionMode = VirtualTreeSelectionMode;
    public selectedItems: VirtualTreeFlatNode[] = [];
    private initiallySelectedItems: VirtualTreeFlatNode[] = [];
    private unchangedInitialySelectedItems: VirtualTreeFlatNode[] = [];
    public actions: {
        added: VirtualTreeFlatNode[];
        removed: VirtualTreeFlatNode[];
    } = { added: [], removed: [] };

    // bindings
    private modalInstance: ng.ui.bootstrap.IModalServiceInstance;
    public resolve: {
        onSubmit: (updates: UpdateLinkedEntitiesParams) => Promise<void>;
        loadSelected: () => Promise<StudyEntity[]>;
        loadItem: () => Promise<(Binder | Document | Folder)[]>;
        loadRoot: () => Promise<Binder[]>;
        studyName: string;
    }

    constructor(
        $transitions: Transition,
        private $scope: ng.IScope,
        private $q: ng.IQService
    ) {
        const deregister = $transitions.onExit({}, () => {
            this.cancel();
            deregister();
        });

    }

    $onInit(): void {
        this.loadingRoot = true;
        this.$q.all({
            root: this.resolve.loadRoot(),
            selectedItems: this.resolve.loadSelected()
        }).then(({ root, selectedItems }) => {
            this.items = sortByLexicographically(root, 'name');
            this.selectedItems = selectedItems.map((e) => {
                return ({ type: e.entityType, id: e.entityId } as VirtualTreeFlatNode);
            });
            this.initiallySelectedItems = [...this.selectedItems];
            this.unchangedInitialySelectedItems = [...this.initiallySelectedItems];
            this.loadingRoot = false;
        });
        this.$scope.$on('modal.closing', this.preventClosingIfProcessing.bind(this));
    }

    cancel(): void {
        this.modalInstance.dismiss('cancel');
    }

    isItemSelectable(): boolean {
        return true;
    }

    isItemDisplayable(item): boolean {
        return item.subType !== 'document';
    }

    onItemSelected = ($event: VirtualTreeItemSelectedEvent<VirtualTreeFlatNode>): void => {
        const wasSelected = this.unchangedInitialySelectedItems.find((i) => i.id === $event.item.id);
        this.actions.removed = this.actions.removed.filter((i) => i.id !== $event.item.id);
        if (!wasSelected) {
            this.actions.added.push($event.item);
        }
    }

    onItemUnselected = ($event: VirtualTreeItemSelectedEvent<VirtualTreeFlatNode>): void => {
        const wasSelected = this.unchangedInitialySelectedItems.find((i) => i.id === $event.item.id);
        this.actions.added = this.actions.added.filter((i) => i.id !== $event.item.id);
        if (wasSelected) {
            this.initiallySelectedItems = this.initiallySelectedItems.filter((i) => i.id !== $event.item.id);
            this.selectedItems = this.initiallySelectedItems.filter((i) => i.id !== $event.item.id);
            this.actions.removed.push($event.item);
        }
    }

    submitDisabled(): boolean {
        return !(this.actions.added.length > 0 || this.actions.removed.length);
    }

    submit(): void {
        if (this.submitDisabled() || this.isProcessing) {
            return;
        }

        this.isProcessing = true;

        this.resolve.onSubmit({
            ...(this.actions.added.length && { add: this.transformActions(this.actions.added) }),
            ...(this.actions.removed.length && { remove: this.transformActions(this.actions.removed) })
        })
            .then(() => {
                this.modalInstance.close();
            })
            .catch(() => {
                this.isProcessing = false;
            });
    }

    private preventClosingIfProcessing(event, reason, closed): void {
        const notAllowedToClose = reason && reason !== 'cancel' && !closed;

        if (this.isProcessing && notAllowedToClose) {
            event.preventDefault();
        }
    }

    private transformActions(actions): {
        entityId: string;
        entityType: string;
    }[] {
        return actions.map((a) => {
            return {
                entityId: a.id,
                entityType: a.type
            };
        });
    }
}

StudyLinkEntitiesController.$inject = [
    '$transitions',
    '$scope',
    '$q'
];
