'use strict';

const { collections } = require('./entities');
const PrivilegeIds = require('./privilege-ids');

const privs = {};
// NOTE: Due to the way privileges are declared it is possible to
// code a circular reference by way of the parents array. Don't do it.
const userPrivs = {};
const teamPrivs = {};
const binderPrivs = {};
const folderPrivs = {};
const documentPrivs = {};
const downloadPrivs = {};
privs[collections.USER] = userPrivs;
privs[collections.TEAM] = teamPrivs;
privs[collections.BINDER] = binderPrivs;
privs[collections.FOLDER] = folderPrivs;
privs[collections.DOCUMENT] = documentPrivs;
privs[collections.DOWNLOAD] = downloadPrivs;

teamPrivs[PrivilegeIds.team_manage] = {
    id: PrivilegeIds.team_manage,
    clientId: 'manageTeam',
    name: 'Manage Team and its Contents',
    description: 'Full access to manage the Team and any contents (Binders, Folders, etc) within',
    bubblesUp: false,
    isHidden: false,
    parents: []
};
teamPrivs[PrivilegeIds.team_view_profile] = {
    id: PrivilegeIds.team_view_profile,
    clientId: 'viewTeamProfile',
    name: 'View Manage Team Settings',
    description: 'View the "Team Profile" page that includes the Team name, time zone, password, and form options',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.job_title_required_in_signature_assign] = {
    id: PrivilegeIds.job_title_required_in_signature_assign,
    clientId: 'jobTitleRequired',
    name: 'Designate Objects for Job Title Signature Requirement',
    description: 'Each user\'s Job Title will be included in all signatures at the Binder or Folder level',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.list_requested_signatures] = {
    id: PrivilegeIds.list_requested_signatures,
    clientId: 'listRequestedSignatures',
    name: 'List Requested Signatures',
    description: 'List Requested Signatures',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_update_profile] = {
    id: PrivilegeIds.team_update_profile,
    clientId: 'updateTeamProfile',
    name: 'Update Team Profile',
    description: 'Modify Team profile settings',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_invite] = {
    id: PrivilegeIds.team_invite,
    clientId: 'inviteUsers',
    name: 'Invite Users to the Team',
    description: 'Add new members to the Team',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_uninvite] = {
    id: PrivilegeIds.team_uninvite,
    clientId: 'uninviteUsers',
    name: 'Remove Users from the Team',
    description: 'Remove team members & their access from the Team',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_rename] = {
    id: PrivilegeIds.team_rename,
    clientId: 'renameTeam',
    name: 'Rename Team',
    description: 'Change the name of the Team',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_member] = {
    id: PrivilegeIds.team_member,
    clientId: 'teamMember',
    name: 'Team Member',
    bubblesUp: false,
    isHidden: true,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_member_limited] = {
    id: PrivilegeIds.team_member_limited,
    clientId: 'teamMemberLimited',
    name: 'Limited Team Member',
    bubblesUp: false,
    isHidden: true,
    parents: [PrivilegeIds.team_member]
};
teamPrivs[PrivilegeIds.team_manage_permissions] = {
    id: PrivilegeIds.team_manage_permissions,
    clientId: 'manageAccessAndTeamPermissions',
    name: 'Manage Access and Permissions for the Team',
    description: 'Full access to manage Access and Permissions for the Team',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_manage_tasks] = {
    id: PrivilegeIds.team_manage_tasks,
    clientId: 'manageTeamTasks',
    name: 'Manage Tasks on a Team',
    description: 'Create, modify, and assign Tasks within the Team',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_view_audittrail] = {
    id: PrivilegeIds.team_view_audittrail,
    clientId: 'viewTeamAuditTrail',
    name: 'View All Audit Trail Events for the Team',
    description: 'View Audit Trail events for the Team',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_download_audittrail] = {
    id: PrivilegeIds.team_download_audittrail,
    clientId: 'downloadTeamAuditTrail',
    name: 'Download All Audit Trail Events for the Team',
    description: 'Download a report of all Team-level Audit Trail events',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};

teamPrivs[PrivilegeIds.team_manage_sso] = {
    id: PrivilegeIds.team_manage_sso,
    clientId: 'manageSSO',
    name: 'Manage SSO',
    description: 'Manage SSO setup and updates for the Team',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_manage_sip_links] = {
    id: PrivilegeIds.team_manage_sip_links,
    clientId: 'teamManageSipLinks',
    name: 'Manage SIP Configurations',
    description: 'Manage SIP Configurations and connections for the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_manage_team_notifications] = {
    id: PrivilegeIds.team_manage_team_notifications,
    clientId: 'teamManageTeamNotifications',
    name: 'Manage Team Notifications',
    description: 'Manage email notification settings for any team members',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.create_binder] = {
    id: PrivilegeIds.create_binder,
    clientId: 'createBinder',
    name: 'Create Binders',
    description: 'Set up new Binders',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};

teamPrivs[PrivilegeIds.team_create_tags] = {
    id: PrivilegeIds.team_create_tags,
    clientId: 'createTags',
    name: 'Create Tags',
    description: 'Generate new Tags for the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_update_tags] = {
    id: PrivilegeIds.team_update_tags,
    clientId: 'updateTags',
    name: 'Update Tags',
    description: 'Edit or modify the Tags on the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_delete_tags] = {
    id: PrivilegeIds.team_delete_tags,
    clientId: 'deleteTags',
    name: 'Delete Tags',
    description: 'Remove Tags for the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_view_dashboard] = {
    id: PrivilegeIds.team_view_dashboard,
    clientId: 'viewDashboard',
    name: 'View Dashboard',
    description: 'View and access Dashboard Projects and Timelines',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_timelines]
};
teamPrivs[PrivilegeIds.team_manage_projects] = {
    id: PrivilegeIds.team_manage_projects,
    clientId: 'manageProjects',
    name: 'Manage Projects',
    description: 'Full access to manage Projects on the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_manage_study_startup] = {
    id: PrivilegeIds.team_manage_study_startup,
    clientId: 'manageTeamStudyStartup',
    name: 'Manage Structure Templates',
    description: 'Full access to manage Structure Templates on the Team - includes abilities to apply, delete, download, import, and view structure templates',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_create_studies] = {
    id: PrivilegeIds.team_create_studies,
    clientId: 'createTeamStudies',
    name: 'Apply Structure Templates',
    description: 'Apply and view Structure Templates saved to the Team into a Binder or Folder, and add or update Labels associated',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_study_startup]
};
teamPrivs[PrivilegeIds.team_import_study_structure_template] = {
    id: PrivilegeIds.team_import_study_structure_template,
    clientId: 'importTeamStudyStructureTemplate',
    name: 'Import Structure Templates',
    description: 'Import and view Study Structure Templates to save and reuse when creating new studies',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_study_startup]
};
teamPrivs[PrivilegeIds.team_delete_structure_templates] = {
    id: PrivilegeIds.team_delete_structure_templates,
    clientId: 'deleteTeamStructureTemplates',
    name: 'Delete Structure Templates',
    description: 'Remove and view any existing Structure Templates saved to the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_study_startup]
};
teamPrivs[PrivilegeIds.team_download_structure_templates] = {
    id: PrivilegeIds.team_download_structure_templates,
    clientId: 'downloadTeamStructureTemplates',
    name: 'Download Structure Templates',
    description: 'Download and view existing Structure Templates saved to the Team as an Excel file to your computer',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_study_startup]
};
teamPrivs[PrivilegeIds.team_manage_study_profiles] = {
    id: PrivilegeIds.team_manage_study_profiles,
    clientId: 'manageTeamStudyProfiles',
    name: 'Manage Study Profiles',
    description: 'Full access to manage Study Profiles on the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_create_edit_study_profiles] = {
    id: PrivilegeIds.team_create_edit_study_profiles,
    clientId: 'createEditTeamStudyProfiles',
    name: 'Create/Edit Study Profiles',
    description: 'Create and edit existing Study Profiles on the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_study_profiles]
};
teamPrivs[PrivilegeIds.team_view_study_profiles] = {
    id: PrivilegeIds.team_view_study_profiles,
    clientId: 'viewTeamStudyProfiles',
    name: 'View Study Profiles',
    description: 'View and access Study Profiles on the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_study_profiles]
};
teamPrivs[PrivilegeIds.paywalls_manage] = {
    id: PrivilegeIds.paywalls_manage,
    clientId: 'managePaywalls',
    name: 'Manage Monitor Groups',
    description: 'Access to create and edit Monitor Groups on the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_study_profiles]
};

teamPrivs[PrivilegeIds.team_manage_log_templates] = {
    id: PrivilegeIds.team_manage_log_templates,
    clientId: 'manageLogTemplates',
    name: 'Manage Log Templates',
    description: 'Manage Log Templates for the Team',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_archive_log_templates] = {
    id: PrivilegeIds.team_archive_log_templates,
    clientId: 'archiveLogTemplates',
    name: 'Archive Log Templates',
    description: 'Archive Log Templates for the Team',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_log_templates]
};
teamPrivs[PrivilegeIds.team_create_log_templates] = {
    id: PrivilegeIds.team_create_log_templates,
    clientId: 'createLogTemplates',
    name: 'Create Log Templates',
    description: 'Create Log Templates for the Team',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_log_templates]
};
teamPrivs[PrivilegeIds.team_edit_log_templates] = {
    id: PrivilegeIds.team_edit_log_templates,
    clientId: 'editLogTemplates',
    name: 'Edit Log Templates',
    description: 'Edit Log Templates for the Team',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_log_templates]
};
teamPrivs[PrivilegeIds.team_duplicate_log_templates] = {
    id: PrivilegeIds.team_duplicate_log_templates,
    clientId: 'duplicateLogTemplates',
    name: 'Duplicate Log Templates',
    description: 'Duplicate Log Templates for the Team',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_log_templates]
};
teamPrivs[PrivilegeIds.team_view_log_templates] = {
    id: PrivilegeIds.team_view_log_templates,
    clientId: 'viewLogTemplates',
    name: 'View Log Templates',
    description: 'View Log Templates for the Team',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_log_templates]
};

teamPrivs[PrivilegeIds.team_manage_access_control] = {
    id: PrivilegeIds.team_manage_access_control,
    clientId: 'manageTeamAccessControl',
    name: 'Manage Access Dates for the Team',
    description: 'Select the start/end dates and turn the status on/off for assigned Roles',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_permissions]
};
teamPrivs[PrivilegeIds.team_fix_support_user] = {
    id: PrivilegeIds.team_fix_support_user,
    clientId: 'teamFixSupportUser',
    name: 'Fix Team Support User',
    description: 'Fix Team Support User Role and Permission',
    bubblesUp: false,
    isHidden: true,
    parents: []
};
teamPrivs[PrivilegeIds.team_assign_roles] = {
    id: PrivilegeIds.team_assign_roles,
    clientId: 'assignTeamRoles',
    name: 'Assign Roles (Off) for the Team',
    description: 'Assign and remove Roles for team members. Role status will be auto-set to off when assigned',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_permissions]
};
teamPrivs[PrivilegeIds.team_manage_roles_and_permissions] = {
    id: PrivilegeIds.team_manage_roles_and_permissions,
    clientId: 'manageTeamRolesAndPermissions',
    name: 'Manage Roles and Permissions for the Team',
    description: 'Create, assign, and manage Roles and Permissions for the Team',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_permissions]
};
teamPrivs[PrivilegeIds.team_view_users_roles_permissions] = {
    id: PrivilegeIds.team_view_users_roles_permissions,
    clientId: 'viewTeamUsersRolesPermissions',
    name: 'View Users, Roles and Permissions for the Team',
    description: 'View all Users, Roles, and their Permissions for the Team',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_permissions]
};
teamPrivs[PrivilegeIds.team_run_role_manager] = {
    id: PrivilegeIds.team_run_role_manager,
    clientId: 'runTeamRoleManager',
    name: 'Run Role Manager for the Team',
    description: 'Run the Role Manager to create and assign Roles for the Team',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_permissions]
};

teamPrivs[PrivilegeIds.password_policy_manage] = {
    id: PrivilegeIds.password_policy_manage,
    clientId: 'managePasswordPolicy',
    name: 'Manage Password Policy',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.team_update_profile]
};
teamPrivs[PrivilegeIds.signing_pin_policy_manage] = {
    id: PrivilegeIds.signing_pin_policy_manage,
    clientId: 'manageSigningPINPolicy',
    name: 'Manage Signing PIN Policy',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.team_update_profile]
};
teamPrivs[PrivilegeIds.signature_options_manage] = {
    id: PrivilegeIds.signature_options_manage,
    clientId: 'manageSignatureOptions',
    name: 'Manage Signature Options',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.team_update_profile]
};
teamPrivs[PrivilegeIds.document_monitor_review_status_manage] = {
    id: PrivilegeIds.document_monitor_review_status_manage,
    clientId: 'manageMonitorReviewStatus',
    name: 'Manage Document Monitor Review Status',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.team_update_profile]
};
teamPrivs[PrivilegeIds.reset_failed_or_timedout_conversions] = {
    id: PrivilegeIds.reset_failed_or_timedout_conversions,
    clientId: 'resetFailedOrTimedOutConversion',
    name: 'Reset Failed Or Timedout Conversions',
    bubblesUp: true,
    isHidden: true,
    parents: []
};
teamPrivs[PrivilegeIds.team_digest_run] = {
    id: PrivilegeIds.team_digest_run,
    clientId: 'runTeamDigest',
    name: 'Run Team Digests',
    bubblesUp: true,
    isHidden: true,
    parents: []
};
teamPrivs[PrivilegeIds.formfield_placeholders_manage] = {
    id: PrivilegeIds.formfield_placeholders_manage,
    clientId: 'manageFormFieldPlaceholders',
    name: 'Manage Form Field Placeholders',
    description: 'Edit or update the text fields in the Signature Fields and Text Fields (under Team Profile tab) for the Team',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.team_import_projects] = {
    id: PrivilegeIds.team_import_projects,
    clientId: 'importProjects',
    name: 'Import Projects Structure',
    description: 'Import Projects structure via Excel spreadsheet',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_projects]
};
teamPrivs[PrivilegeIds.team_create_projects] = {
    id: PrivilegeIds.team_create_projects,
    clientId: 'createProjects',
    name: 'Create Projects',
    description: 'Set up a new Dashboard Project via "Manage Projects" page',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_projects]
};
teamPrivs[PrivilegeIds.team_update_projects] = {
    id: PrivilegeIds.team_update_projects,
    clientId: 'updateProjects',
    name: 'Update Projects',
    description: 'Edit a Dashboard Project and information associated',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_projects]
};
teamPrivs[PrivilegeIds.team_delete_projects] = {
    id: PrivilegeIds.team_delete_projects,
    clientId: 'deleteProjects',
    name: 'Delete Projects',
    description: 'Remove a Dashboard Project and all of its included Timelines',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_projects]
};
teamPrivs[PrivilegeIds.team_duplicate_projects] = {
    id: PrivilegeIds.team_duplicate_projects,
    clientId: 'duplicateProjects',
    name: 'Duplicate Projects',
    description: 'Create a copy of an existing Dashboard Project',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_projects]
};
teamPrivs[PrivilegeIds.team_manage_timelines] = {
    id: PrivilegeIds.team_manage_timelines,
    clientId: 'manageTimelines',
    name: 'Manage Timelines',
    description: 'Full acess to manage Timelines',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_projects]
};
teamPrivs[PrivilegeIds.team_build_timelines] = {
    id: PrivilegeIds.team_build_timelines,
    clientId: 'buildTimelines',
    name: 'Build Timelines',
    description: 'Full access to build Timelines',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_timelines]
};
teamPrivs[PrivilegeIds.team_create_timelines] = {
    id: PrivilegeIds.team_create_timelines,
    clientId: 'createTimeline',
    name: 'Create Timeline',
    description: 'Add a new Timeline to a Dashboard Project',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_build_timelines]
};
teamPrivs[PrivilegeIds.team_update_timelines] = {
    id: PrivilegeIds.team_update_timelines,
    clientId: 'updateTimeline',
    name: 'Update Timeline',
    description: 'Edit a Dashboard Project Timeline and information associated',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_build_timelines]
};
teamPrivs[PrivilegeIds.team_delete_timelines] = {
    id: PrivilegeIds.team_delete_timelines,
    clientId: 'deleteTimelines',
    name: 'Delete Timelines',
    description: 'Remove a Dashboard Project Timeline',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_timelines]
};
teamPrivs[PrivilegeIds.team_duplicate_timelines] = {
    id: PrivilegeIds.team_duplicate_timelines,
    clientId: 'duplicateTimelines',
    name: 'Duplicate Timelines',
    description: 'Create a copy of an existing Dashboard Project Timeline',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_timelines]
};
teamPrivs[PrivilegeIds.team_assign_to_timelines] = {
    id: PrivilegeIds.team_assign_to_timelines,
    clientId: 'assignToTimelines',
    name: 'Assign To Timelines',
    description: 'Add Documents and Placeholders to Dashboard Project Timelines',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_timelines]
};
teamPrivs[PrivilegeIds.team_export_docs_for_integration] = {
    id: PrivilegeIds.team_export_docs_for_integration,
    clientId: 'teamExportDocsForIntegration',
    name: 'Export Team Docs for Integration',
    bubblesUp: false,
    isHidden: true,
    parents: []
};
teamPrivs[PrivilegeIds.team_create_integrated_audit] = {
    id: PrivilegeIds.team_create_integrated_audit,
    clientId: 'teamCreateIntegratedAudit',
    name: 'Create Integrated Audit',
    bubblesUp: false,
    isHidden: true,
    parents: []
};

teamPrivs[PrivilegeIds.document_update_external_status] = {
    id: PrivilegeIds.document_update_external_status,
    clientId: 'docUpdateExternalStatus',
    name: 'Update document external status',
    bubblesUp: false,
    isHidden: true,
    parents: []
};

teamPrivs[PrivilegeIds.announcement_send] = {
    id: PrivilegeIds.announcement_send,
    clientId: 'sendAnnouncements',
    name: 'Send Announcement',
    description: 'Send Announcements to the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.announcement_view] = {
    id: PrivilegeIds.announcement_view,
    clientId: 'viewAnnouncements',
    name: 'View Announcement',
    description: 'View any Team Announcements sent on the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};

teamPrivs[PrivilegeIds.label_manage] = {
    id: PrivilegeIds.label_manage,
    clientId: 'labelManage',
    name: 'Manage Labels',
    description: 'Full access to manage Labels',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
teamPrivs[PrivilegeIds.label_list] = {
    id: PrivilegeIds.label_list,
    clientId: 'labelList',
    name: 'List Labels',
    description: 'List Labels of the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.label_manage]
};
teamPrivs[PrivilegeIds.label_assign] = {
    id: PrivilegeIds.label_assign,
    clientId: 'labelAssign',
    name: 'Assign Labels',
    description: 'Assign Labels to different Binders or Folders on the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.label_manage]
};
teamPrivs[PrivilegeIds.label_create] = {
    id: PrivilegeIds.label_create,
    clientId: 'labelCreate',
    name: 'Create Labels',
    description: 'Create Labels for the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.label_manage]
};
teamPrivs[PrivilegeIds.label_edit] = {
    id: PrivilegeIds.label_edit,
    clientId: 'labelEdit',
    name: 'Edit Labels',
    description: 'Edit Labels for the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.label_manage]
};
teamPrivs[PrivilegeIds.label_delete] = {
    id: PrivilegeIds.label_delete,
    clientId: 'labelDelete',
    name: 'Delete Labels',
    description: 'Delete or remove Labels for the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.label_manage]
};
teamPrivs[PrivilegeIds.label_value_manage] = {
    id: PrivilegeIds.label_value_manage,
    clientId: 'labelValueManage',
    name: 'Manage Label Values',
    description: 'Full access to manage Label Values',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.label_manage]
};
teamPrivs[PrivilegeIds.label_value_add] = {
    id: PrivilegeIds.label_value_add,
    clientId: 'labelValueAdd',
    name: 'Add Label Values',
    description: 'Add Label Values',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.label_value_manage]
};
teamPrivs[PrivilegeIds.label_value_update] = {
    id: PrivilegeIds.label_value_update,
    clientId: 'labelValueUpdate',
    name: 'Update Label Values',
    description: 'Update Label Values',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.label_value_manage]
};
teamPrivs[PrivilegeIds.label_value_delete] = {
    id: PrivilegeIds.label_value_delete,
    clientId: 'labelValueDelete',
    name: 'Delete Label Values',
    description: 'Delete Label Values',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.label_value_manage]
};
teamPrivs[PrivilegeIds.team_manage_features] = {
    id: PrivilegeIds.team_manage_features,
    clientId: 'manageTeamFeatures',
    name: 'Manage Team Features',
    bubblesUp: true,
    isHidden: true,
    parents: []
};

teamPrivs[PrivilegeIds.document_sharing_view] = {
    id: PrivilegeIds.document_sharing_view,
    clientId: 'documentSharingView',
    name: 'View Document Sharing',
    description: 'Full access to Document Sharing',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_sharing_manage]
};

teamPrivs[PrivilegeIds.archive_view] = {
    id: PrivilegeIds.archive_view,
    clientId: 'viewArchives',
    name: 'View Archives',
    description: 'View Archives List',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.archive_manage]
};

userPrivs[PrivilegeIds.user_update_profile] = {
    id: PrivilegeIds.user_update_profile,
    clientId: 'updateUserProfile',
    name: 'Update User Profile',
    bubblesUp: false,
    isHidden: false,
    parents: []
};
userPrivs[PrivilegeIds.user_manage_alias] = {
    id: PrivilegeIds.user_manage_alias,
    clientId: 'manageUserAlias',
    name: 'Update User Email Alias',
    bubblesUp: false,
    isHidden: true,
    parents: []
};
userPrivs[PrivilegeIds.user_view_user_mfa_policy] = {
    id: PrivilegeIds.user_view_user_mfa_policy,
    clientId: 'viewUserMfaPolicy',
    name: 'View User MFA Policy',
    bubblesUp: false,
    isHidden: true,
    parents: []
};
userPrivs[PrivilegeIds.user_view_profile] = {
    id: PrivilegeIds.user_view_profile,
    clientId: 'viewUserProfile',
    name: 'View User Profile',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
userPrivs[PrivilegeIds.user_view_teams] = {
    id: PrivilegeIds.user_view_teams,
    clientId: 'viewUsersTeams',
    name: 'View Users Teams',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
userPrivs[PrivilegeIds.user_view_user_on_team] = {
    id: PrivilegeIds.user_view_user_on_team,
    clientId: 'viewUserOnTeam',
    name: 'View User as a Team Member',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
userPrivs[PrivilegeIds.user_update_users_subscriptions] = {
    id: PrivilegeIds.user_update_users_subscriptions,
    clientId: 'updateUserSubscriptions',
    name: 'Update User Subscriptions',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
userPrivs[PrivilegeIds.user_view_marketing_notifications] = {
    id: PrivilegeIds.user_view_marketing_notifications,
    clientId: 'viewMarketingNotifications',
    name: 'View Marketing Notifications',
    bubblesUp: false,
    isHidden: true,
    parents: []
};
userPrivs[PrivilegeIds.user_update_marketing_notifications] = {
    id: PrivilegeIds.user_update_marketing_notifications,
    clientId: 'updateMarketingNotifications',
    name: 'Update Marketing Notifications',
    bubblesUp: false,
    isHidden: true,
    parents: []
};
userPrivs[PrivilegeIds.user_view_system_notifications] = {
    id: PrivilegeIds.user_view_system_notifications,
    clientId: 'viewSystemNotifications',
    name: 'View System Notifications',
    bubblesUp: false,
    isHidden: true,
    parents: []
};
userPrivs[PrivilegeIds.user_manage_system_notifications] = {
    id: PrivilegeIds.user_manage_system_notifications,
    clientId: 'manageSystemNotifications',
    name: 'Manage System Notifications',
    bubblesUp: false,
    isHidden: true,
    parents: []
};
userPrivs[PrivilegeIds.user_view_user_profile_audit_trails] = {
    id: PrivilegeIds.user_view_user_profile_audit_trails,
    clientId: 'viewUserNameChangeAuditTrails',
    name: 'View User Name Change Audit Trail Events',
    bubblesUp: false,
    isHidden: true,
    parents: []
};
userPrivs[PrivilegeIds.user_download_user_profile_audit_trails] = {
    id: PrivilegeIds.user_download_user_profile_audit_trails,
    clientId: 'downloadUserNameChangeAuditTrails',
    name: 'Download User Name Change Audit Trail Events',
    bubblesUp: false,
    isHidden: true,
    parents: []
};
teamPrivs[PrivilegeIds.act_on_behalf_of] = {
    id: PrivilegeIds.act_on_behalf_of,
    clientId: 'actOnBehalfOf',
    name: 'Act on behalf of subject',
    bubblesUp: false,
    isHidden: true,
    parents: []
};

binderPrivs[PrivilegeIds.binder_manage] = {
    id: PrivilegeIds.binder_manage,
    clientId: 'manageBinder',
    name: 'Manage Binder',
    description: 'Full access to manage Binder and content within',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.team_manage]
};
binderPrivs[PrivilegeIds.binder_update] = {
    id: PrivilegeIds.binder_update,
    clientId: 'updateBinder',
    name: 'Update Binder',
    description: 'Update Tasks and Properties of a Binder',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.binder_manage]
};
binderPrivs[PrivilegeIds.binder_view] = {
    id: PrivilegeIds.binder_view,
    clientId: 'viewBinder',
    name: 'View Binder',
    description: 'View the Binder name and contents within where user has permission to access',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.binder_manage]
};
binderPrivs[PrivilegeIds.binder_view_binder] = {
    id: PrivilegeIds.binder_view_binder,
    clientId: 'viewBinderInTeam',
    name: 'View Binder in Team',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.binder_view]
};
binderPrivs[PrivilegeIds.binder_read_properties] = {
    id: PrivilegeIds.binder_read_properties,
    clientId: 'viewBinderProperties',
    name: 'View Binder Properties',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.binder_view]
};
binderPrivs[PrivilegeIds.binder_update_properties] = {
    id: PrivilegeIds.binder_update_properties,
    clientId: 'updateBinderProperties',
    name: 'Update Binder Properties',
    description: 'Update Properties on a Binder',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.binder_update]
};
binderPrivs[PrivilegeIds.binder_rename] = {
    id: PrivilegeIds.binder_rename,
    clientId: 'renameBinder',
    name: 'Rename Binder',
    description: 'Change the name of a Binder',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.binder_manage]
};
binderPrivs[PrivilegeIds.binder_duplicate] = {
    id: PrivilegeIds.binder_duplicate,
    clientId: 'duplicateBinder',
    name: 'Duplicate Binder',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.binder_manage]
};
binderPrivs[PrivilegeIds.binder_destroy] = {
    id: PrivilegeIds.binder_destroy,
    clientId: 'destroyBinder',
    name: 'Delete Binder',
    description: 'Remove a Binder and all of its contents from the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.binder_manage]
};
binderPrivs[PrivilegeIds.binder_manage_tasks] = {
    id: PrivilegeIds.binder_manage_tasks,
    clientId: 'manageBinderTasks',
    name: 'Manage Tasks on a Binder',
    description: 'Create, modify, and assign Tasks within a Binder',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.binder_update]
};
binderPrivs[PrivilegeIds.binder_view_audittrail] = {
    id: PrivilegeIds.binder_view_audittrail,
    clientId: 'viewBinderAuditTrail',
    name: 'View All Audit Trail Events for the Binder',
    description: 'View Audit Trail events for Binders where permission granted',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.binder_manage]
};
binderPrivs[PrivilegeIds.binder_download_audittrail] = {
    id: PrivilegeIds.binder_download_audittrail,
    clientId: 'downloadBinderAuditTrail',
    name: 'Download All Audit Trail Events for the Binder',
    description: 'Download a report of all Binder-level Audit Trail events',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.binder_manage]
};

folderPrivs[PrivilegeIds.archive_manage] = {
    id: PrivilegeIds.archive_manage,
    clientId: 'manageArchive',
    name: 'Manage Long-term Archive',
    description: 'Full access to Long-term Archive (will include PHI documents)',
    bubblesUp: true,
    isHidden: false,
    parents: []
};
folderPrivs[PrivilegeIds.archive_schedule] = {
    id: PrivilegeIds.archive_schedule,
    clientId: 'scheduleArchive',
    name: 'Schedule Long-term Archive',
    description: 'Allows you to Schedule Long-term Archive',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.archive_manage]
};
folderPrivs[PrivilegeIds.archive_download] = {
    id: PrivilegeIds.archive_download,
    clientId: 'downloadArchive',
    name: 'Download Long-term Archive',
    description: 'Allows you to Download Long-term Archive (will include PHI documents)',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.archive_manage]
};
folderPrivs[PrivilegeIds.archive_update] = {
    id: PrivilegeIds.archive_update,
    clientId: 'updateArchive',
    name: 'Update Long-term Archive',
    description: 'Allows you to Update an Archive',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.archive_manage]
};
folderPrivs[PrivilegeIds.folder_manage] = {
    id: PrivilegeIds.folder_manage,
    clientId: 'manageFolder',
    name: 'Manage Folder',
    description: 'Full access to manage Folder and content within',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.binder_manage]
};
folderPrivs[PrivilegeIds.create_folder] = {
    id: PrivilegeIds.create_folder,
    clientId: 'createFolder',
    name: 'Create Folder',
    description: 'Set up new Folders within assigned Binders',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.folder_manage]
};
folderPrivs[PrivilegeIds.folder_update] = {
    id: PrivilegeIds.folder_update,
    clientId: 'updateFolder',
    name: 'Update Folder',
    description: 'Update Tasks and Properties of a Folder',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.folder_manage]
};
folderPrivs[PrivilegeIds.folder_view] = {
    id: PrivilegeIds.folder_view,
    clientId: 'viewFolder',
    name: 'View Folder',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.folder_manage]
};
folderPrivs[PrivilegeIds.folder_view_folder] = {
    id: PrivilegeIds.folder_view_folder,
    clientId: 'viewFolderInParent',
    name: 'View Folder in Parent',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.folder_view, PrivilegeIds.folder_update]
};
folderPrivs[PrivilegeIds.folder_read_properties] = {
    id: PrivilegeIds.folder_read_properties,
    clientId: 'viewFolderProperties',
    name: 'View Folder Properties',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.folder_view, PrivilegeIds.folder_update]
};
folderPrivs[PrivilegeIds.folder_update_properties] = {
    id: PrivilegeIds.folder_update_properties,
    clientId: 'updateFolderProperties',
    name: 'Update Folder Properties',
    description: 'Update Properties on a Folder',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.folder_update]
};
folderPrivs[PrivilegeIds.folder_rename] = {
    id: PrivilegeIds.folder_rename,
    clientId: 'renameFolder',
    name: 'Rename Folder',
    description: 'Change the name of a Folder',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.folder_manage]
};
folderPrivs[PrivilegeIds.folder_duplicate] = {
    id: PrivilegeIds.folder_duplicate,
    clientId: 'duplicateFolder',
    name: 'Duplicate Folder',
    description: 'Create a new copy of a Folder and all of its contents',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.folder_manage]
};
folderPrivs[PrivilegeIds.folder_move] = {
    id: PrivilegeIds.folder_move,
    clientId: 'moveFolder',
    name: 'Move Folder',
    description: 'Transfer a Folder to a different location on the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.folder_manage]
};
folderPrivs[PrivilegeIds.folder_destroy] = {
    id: PrivilegeIds.folder_destroy,
    clientId: 'destroyFolder',
    name: 'Delete Folder',
    description: 'Remove a Folder and all of its contents from assigned Binders',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.folder_manage]
};
folderPrivs[PrivilegeIds.folder_manage_tasks] = {
    id: PrivilegeIds.folder_manage_tasks,
    clientId: 'manageFolderTasks',
    name: 'Manage Tasks on a Folder',
    description: 'Create, modify, and assign Tasks within a Folder',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.folder_update]
};
folderPrivs[PrivilegeIds.folder_view_audittrail] = {
    id: PrivilegeIds.folder_view_audittrail,
    clientId: 'viewFolderAuditTrail',
    name: 'View All Audit Trail Events for the Folder',
    description: 'View Audit Trail events for Folders where permission granted',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.folder_manage]
};
folderPrivs[PrivilegeIds.folder_download_audittrail] = {
    id: PrivilegeIds.folder_download_audittrail,
    clientId: 'downloadFolderAuditTrail',
    name: 'Download All Audit Trail Events for the Folder',
    description: 'Download a report of all Folder-level Audit Trail events',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.folder_manage]
};
folderPrivs[PrivilegeIds.create_document] = {
    id: PrivilegeIds.create_document,
    clientId: 'createDocument',
    name: 'Import/Upload Document',
    description: 'Upload a Document via email, drag and drop, or browsing from local computer',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.folder_manage]
};
folderPrivs[PrivilegeIds.document_create_log] = {
    id: PrivilegeIds.document_create_log,
    clientId: 'createLog',
    name: 'Create Log',
    description: 'Set up new logs within assigned locations',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.folder_manage]
};
folderPrivs[PrivilegeIds.binder_import_templates] = {
    id: PrivilegeIds.binder_import_templates,
    clientId: 'folderImportTemplates',
    name: 'Apply Single-Use Structure',
    description: 'Upload and apply a single-use structure template into a Binder or Folder',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.folder_manage]
};

documentPrivs[PrivilegeIds.document_manage] = {
    id: PrivilegeIds.document_manage,
    clientId: 'manageDocument',
    name: 'Manage Document',
    description: 'Full access to manage Document(s)',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.folder_manage]
};
documentPrivs[PrivilegeIds.document_update] = {
    id: PrivilegeIds.document_update,
    clientId: 'updateDocument',
    name: 'Update Document',
    description: 'Full access to update information on Documents and create/assign tasks',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.create_document_shortcut] = {
    id: PrivilegeIds.create_document_shortcut,
    clientId: 'createDocumentShortcut',
    name: 'Create Document Shortcut',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.document_assign_tags] = {
    id: PrivilegeIds.document_assign_tags,
    clientId: 'assignTags',
    name: 'Assign Tags',
    description: 'Select Documents to include in pre-determined Tag category',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.document_manage_document_locking] = {
    id: PrivilegeIds.document_manage_document_locking,
    clientId: 'manageLockedDocuments',
    name: 'Manage Locked Documents',
    description: 'Full access to manage locked documents - includes the abilities to lock and unlock document(s) and move locked document(s)',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.document_lock] = {
    id: PrivilegeIds.document_lock,
    clientId: 'lockDocument',
    name: 'Lock Document',
    description: 'Ability to mark document(s) as locked, which will restrict the majority of actions available on document(s), except Downloading, Duplicating, and Viewing Audit Trails',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage_document_locking]
};
documentPrivs[PrivilegeIds.document_unlock] = {
    id: PrivilegeIds.document_unlock,
    clientId: 'unlockDocument',
    name: 'Unlock Document',
    description: 'Ability to unlock document(s) marked as locked which will remove the lock restrictions and allow normal document actions to be available',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage_document_locking]
};
documentPrivs[PrivilegeIds.document_move_locked] = {
    id: PrivilegeIds.document_move_locked,
    clientId: 'moveLockedDocument',
    name: 'Move Locked Document',
    description: 'Ability to move locked document(s) or folders containing locked document(s)',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage_document_locking]
};
documentPrivs[PrivilegeIds.document_replace] = {
    id: PrivilegeIds.document_replace,
    clientId: 'replaceDocument',
    name: 'Replace a Document or Fill a Placeholder',
    description: 'Upload a new version of a Document or upload into previously created Placeholder',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.document_send_integrated_system] = {
    id: PrivilegeIds.document_send_integrated_system,
    clientId: 'sendDocIntegratedSystem',
    name: 'Send Document to Integrated System',
    description: 'Full access to send Document(s) to Integrated System',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.document_send_team] = {
    id: PrivilegeIds.document_send_team,
    clientId: 'sendDocTeam',
    name: 'Send to Florence Team',
    description: 'Send Document(s) to Florence Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_send_integrated_system]
};
documentPrivs[PrivilegeIds.document_send_sip] = {
    id: PrivilegeIds.document_send_sip,
    clientId: 'sendDocSIP',
    name: 'Send to SIP',
    description: 'Send Document(s) to SIP',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_send_integrated_system]
};

documentPrivs[PrivilegeIds.document_update_properties] = {
    id: PrivilegeIds.document_update_properties,
    clientId: 'updateDocumentProperties',
    name: 'Update Document Properties',
    description: 'Update properties on Document(s)',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_update]
};
documentPrivs[PrivilegeIds.document_view_pii] = {
    id: PrivilegeIds.document_view_pii,
    clientId: 'viewPiiInDocument',
    name: 'View Documents With PHI',
    description: 'View Documents flagged as PHI or in original pre-redacted versions',
    bubblesUp: true,
    isHidden: false,
    parents: []
};
documentPrivs[PrivilegeIds.document_view_non_pii] = {
    id: PrivilegeIds.document_view_non_pii,
    clientId: 'viewNonPiiInDocument',
    name: 'View Documents Without PHI',
    description: 'View Documents that have not been redacted or flagged as PHI',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.document_view_deleted_pii_doc] = {
    id: PrivilegeIds.document_view_deleted_pii_doc,
    clientId: 'viewDeletedDocWithPii',
    name: 'View Archived Documents With PHI',
    bubblesUp: true,
    isHidden: true,
    parents: []
};
documentPrivs[PrivilegeIds.document_view_deleted_non_pii_doc] = {
    id: PrivilegeIds.document_view_deleted_non_pii_doc,
    clientId: 'viewDeletedDocWithNoPii',
    name: 'View Archived Documents Without PHI',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.document_destroy] = {
    id: PrivilegeIds.document_destroy,
    clientId: 'destroyDocument',
    name: 'Delete Document',
    description: 'Remove Document and any of its versions from the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.document_download_pii_doc] = {
    id: PrivilegeIds.document_download_pii_doc,
    clientId: 'downloadDocumentWithPii',
    name: 'Download Documents with PHI',
    description: 'Download Documents flagged as PHI or in original pre-redacted versions',
    bubblesUp: true,
    isHidden: false,
    parents: []
};
documentPrivs[PrivilegeIds.document_download_non_pii_doc] = {
    id: PrivilegeIds.document_download_non_pii_doc,
    clientId: 'downloadDocumentWithoutPii',
    name: 'Download Documents without PHI',
    description: 'Download Documents that have not been redacted or flagged as PHI',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.document_duplicate] = {
    id: PrivilegeIds.document_duplicate,
    clientId: 'duplicateDocument',
    name: 'Duplicate Document',
    description: 'Create a new copy of a Document',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.document_move] = {
    id: PrivilegeIds.document_move,
    clientId: 'moveDocument',
    name: 'Move Document',
    description: 'Transfer a Document to a different location on the Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.document_rename] = {
    id: PrivilegeIds.document_rename,
    clientId: 'renameDocument',
    name: 'Rename Document',
    description: 'Change the name of a Document',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.document_manage_approval_workflow] = {
    id: PrivilegeIds.document_manage_approval_workflow,
    clientId: 'manageDocumentApprovalWorkflow',
    name: 'Manage Document Approval Workflow',
    description: 'Full access to manage Document Approval Workflow',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.document_approve] = {
    id: PrivilegeIds.document_approve,
    clientId: 'approveDocument',
    name: 'Approve Document',
    description: 'Mark Document as Approved and reflect back to integrated system',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage_approval_workflow]
};
documentPrivs[PrivilegeIds.document_reject] = {
    id: PrivilegeIds.document_reject,
    clientId: 'rejectDocument',
    name: 'Reject Document',
    description: 'Mark Document as Rejected and reflect back to integrated system',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage_approval_workflow]
};
documentPrivs[PrivilegeIds.document_cancel] = {
    id: PrivilegeIds.document_cancel,
    clientId: 'cancelDocument',
    name: 'Cancel Document',
    description: 'Mark Document as Cancelled and reflect back to integrated system',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage_approval_workflow]
};
documentPrivs[PrivilegeIds.document_initiate_approval_workflow] = {
    id: PrivilegeIds.document_initiate_approval_workflow,
    clientId: 'initiateDocumentApprovalWorkflow',
    name: 'Initiate Document Approval Workflow',
    description: 'Trigger Document Approval Workflow',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage_approval_workflow]
};
teamPrivs[PrivilegeIds.team_manage_team_security] = {
    id: PrivilegeIds.team_manage_team_security,
    clientId: 'manageTeamSecurity',
    name: 'Manage Team Security',
    description: 'Full access to Manage Team Security',
    bubblesUp: true,
    isHidden: false,
    parents: []
};
teamPrivs[PrivilegeIds.team_update_mfa] = {
    id: PrivilegeIds.team_update_mfa,
    clientId: 'updateTeamMFA',
    name: 'Manage Two Factor Authentication',
    description: 'Ability to enable and disable Two Factor Authentication for all team members',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_team_security]
};
teamPrivs[PrivilegeIds.team_manage_team_timeout] = {
    id: PrivilegeIds.team_manage_team_timeout,
    clientId: 'manageTeamTimeoutPeriod',
    name: 'Manage Team Timeout Period',
    description: 'Ability to change the timeout period for your entire team (all users)',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.team_manage_team_security]
};
documentPrivs[PrivilegeIds.document_manage_monitor_reviews] = {
    id: PrivilegeIds.document_manage_monitor_reviews,
    clientId: 'manageMonitorReviews',
    name: 'Manage Monitor Reviews',
    description: 'Full access to manage Monitor Reviews in assigned location(s)',
    bubblesUp: true,
    isHidden: false,
    parents: []
};
documentPrivs[PrivilegeIds.document_approve_monitor_review] = {
    id: PrivilegeIds.document_approve_monitor_review,
    clientId: 'documentApproveMonitorReview',
    name: 'Approve Monitor Reviews',
    description: 'Approve Documents',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage_monitor_reviews]
};
documentPrivs[PrivilegeIds.document_reviewed_monitor_review] = {
    id: PrivilegeIds.document_reviewed_monitor_review,
    clientId: 'documentReviewedMonitorReview',
    name: 'Review Monitor Reviews',
    description: 'Mark Documents as Reviewed',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage_monitor_reviews]
};
documentPrivs[PrivilegeIds.document_comment_on_monitor_review] = {
    id: PrivilegeIds.document_comment_on_monitor_review,
    clientId: 'documentCommentOnMonitorReview',
    name: 'Comment on Monitor Reviews',
    description: 'Comment on Monitor Review on a Document',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage_monitor_reviews]
};
documentPrivs[PrivilegeIds.document_create_close_query] = {
    id: PrivilegeIds.document_create_close_query,
    clientId: 'documentCreateCloseQuery',
    name: 'Create/Close Queries',
    description: 'Open or close queries on a Document',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage_monitor_reviews]
};
documentPrivs[PrivilegeIds.document_view_monitor_review] = {
    id: PrivilegeIds.document_view_monitor_review,
    clientId: 'documentViewMonitorReview',
    name: 'View Monitor Reviews',
    description: 'View all Monitor Reviews on a Document',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage_monitor_reviews]
};
documentPrivs[PrivilegeIds.document_annotate] = {
    id: PrivilegeIds.document_annotate,
    clientId: 'annotateDocument',
    name: 'Annotate Document',
    description: 'Full Document annotation access',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.document_sign] = {
    id: PrivilegeIds.document_sign,
    clientId: 'signDocument',
    name: 'Sign Document',
    description: 'Sign the Document and select applicable reasoning',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_annotate]
};
documentPrivs[PrivilegeIds.document_request_signature] = {
    id: PrivilegeIds.document_request_signature,
    clientId: 'requestSignature',
    name: 'Request Signatures',
    description: 'Send notifications to other team members to request signatures on a Document',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_annotate]
};
documentPrivs[PrivilegeIds.document_stamp] = {
    id: PrivilegeIds.document_stamp,
    clientId: 'timestampDocument',
    name: 'Timestamp Document',
    description: 'Add timestamp to a Document',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_annotate]
};
documentPrivs[PrivilegeIds.document_redact] = {
    id: PrivilegeIds.document_redact,
    clientId: 'redactDocument',
    name: 'Redact Document',
    description: 'Censor part or all of the Document. Note: a redaction will mark Document with PHI',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_annotate]
};
documentPrivs[PrivilegeIds.document_add_text] = {
    id: PrivilegeIds.document_add_text,
    clientId: 'addTextToDocument',
    name: 'Add Text to Document',
    description: 'Add wording to the Document via a text box',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_annotate]
};
documentPrivs[PrivilegeIds.document_highlight] = {
    id: PrivilegeIds.document_highlight,
    clientId: 'highlightDocument',
    name: 'Highlight Document',
    description: 'Highlight area(s) of a Document',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_annotate]
};
documentPrivs[PrivilegeIds.document_copy_text] = {
    id: PrivilegeIds.document_copy_text,
    clientId: 'copyDocumentText',
    name: 'Copy Text from Document',
    bubblesUp: true,
    isHidden: true,
    parents: []
};
documentPrivs[PrivilegeIds.document_finalize_form] = {
    id: PrivilegeIds.document_finalize_form,
    clientId: 'documentFinalizeForm',
    name: 'Finalize Form',
    description: 'Finalize a form after making annotations or signing a form',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_annotate]
};
documentPrivs[PrivilegeIds.document_log_manage] = {
    id: PrivilegeIds.document_log_manage,
    clientId: 'manageLogEntries',
    name: 'Manage Log',
    description: 'Full access to manage Logs',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.document_log_update_details] = {
    id: PrivilegeIds.document_log_update_details,
    clientId: 'updateLogDetails',
    name: 'Update Log Details',
    description: 'Edit and update log details information',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.document_log_manage]
};
documentPrivs[PrivilegeIds.document_log_entries_annotate] = {
    id: PrivilegeIds.document_log_entries_annotate,
    clientId: 'annotateLogEntries',
    name: 'Annotate Log Entries',
    description: 'Full Log Entry annotation access',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.document_log_manage]
};
documentPrivs[PrivilegeIds.document_log_entries_create] = {
    id: PrivilegeIds.document_log_entries_create,
    clientId: 'createLogEntry',
    name: 'Create Log Entry',
    description: 'Create new entries within Log(s)',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.document_log_entries_annotate]
};
documentPrivs[PrivilegeIds.document_log_entries_edit] = {
    id: PrivilegeIds.document_log_entries_edit,
    clientId: 'editLogEntry',
    name: 'Edit Log Entry',
    description: 'Edit existing entries within Log(s)',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.document_log_entries_annotate]
};
documentPrivs[PrivilegeIds.document_log_entries_sign] = {
    id: PrivilegeIds.document_log_entries_sign,
    clientId: 'signLogEntry',
    name: 'Sign Log Entry',
    description: 'Sign the Log entry assigned and requested',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.document_log_entries_annotate]
};
documentPrivs[PrivilegeIds.document_log_entries_striketrough] = {
    id: PrivilegeIds.document_log_entries_striketrough,
    clientId: 'strikethroughLogEntry',
    name: 'Strikethrough Log Entry',
    description: 'Ability to Strikethrough a log entry in case an error was made',
    bubblesUp: false,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.document_manage_doc_dates] = {
    id: PrivilegeIds.document_manage_doc_dates,
    clientId: 'manageDocumentDates',
    name: 'Manage Document Due and Expiration Dates',
    description: 'Set or clear Due and Expiration Dates on Documents and Placeholders',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_update]
};
documentPrivs[PrivilegeIds.document_update_due_date] = {
    id: PrivilegeIds.document_update_due_date,
    clientId: 'updateDocDueDate',
    name: 'Change Document Due Date',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.document_manage_doc_dates]
};
documentPrivs[PrivilegeIds.document_update_exp_date] = {
    id: PrivilegeIds.document_update_exp_date,
    clientId: 'updateDocExpDate',
    name: 'Change Document Expiration Date',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.document_manage_doc_dates]
};
documentPrivs[PrivilegeIds.document_set_due_date] = {
    id: PrivilegeIds.document_set_due_date,
    clientId: 'setDocDueDate',
    name: 'Set Document Due Date',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.document_manage_doc_dates]
};
documentPrivs[PrivilegeIds.document_set_exp_date] = {
    id: PrivilegeIds.document_set_exp_date,
    clientId: 'setDocExpDate',
    name: 'Set Document Expiration Date',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.document_manage_doc_dates]
};
documentPrivs[PrivilegeIds.document_manage_tasks] = {
    id: PrivilegeIds.document_manage_tasks,
    clientId: 'manageDocTasks',
    name: 'Manage Tasks on a Document',
    description: 'Create, modify, and assign Tasks on a Document',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_update]
};
documentPrivs[PrivilegeIds.document_view_audittrail] = {
    id: PrivilegeIds.document_view_audittrail,
    clientId: 'viewDocumentAuditTrail',
    name: 'View All Audit Trail Events for the Document',
    description: 'View Audit Trail events for Documents where permission granted',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.document_download_audittrail] = {
    id: PrivilegeIds.document_download_audittrail,
    clientId: 'downloadDocumentAuditTrail',
    name: 'Download All Audit Trail Events for the Document',
    description: 'Download a report of all Document-level Audit Trail events',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.create_document_shortcut] = {
    id: PrivilegeIds.create_document_shortcut,
    clientId: 'createDocumentShortcut',
    name: 'Create Document Shortcut',
    description: 'Create a shortcut of a Document in other Folders or Binders within Team',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};
documentPrivs[PrivilegeIds.document_update_category_and_status] = {
    id: PrivilegeIds.document_update_category_and_status,
    clientId: 'updateDocumentCategoryAndStatus',
    name: 'Update Document Type And Status',
    description: 'Full access to update Document Type and Status',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_update]
};
documentPrivs[PrivilegeIds.document_update_doc_category] = {
    id: PrivilegeIds.document_update_doc_category,
    clientId: 'updateDocumentCategory',
    name: 'Update Document Type',
    description: 'Update Document Type from Team\'s listing',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_update_category_and_status]
};
documentPrivs[PrivilegeIds.document_update_doc_status] = {
    id: PrivilegeIds.document_update_doc_status,
    clientId: 'updateDocumentStatus',
    name: 'Update Document Status',
    description: 'Select Document Status based on Document\'s Type',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_update_category_and_status]
};
documentPrivs[PrivilegeIds.document_mark_unmark_phi] = {
    id: PrivilegeIds.document_mark_unmark_phi,
    clientId: 'documentMarkUnmarkPhi',
    name: 'Mark/Unmark PHI',
    description: 'Mark and Unmark PHI on Documents and Logs',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};

documentPrivs[PrivilegeIds.document_qc_review_manage] = {
    id: PrivilegeIds.document_qc_review_manage,
    clientId: 'manageQCreview',
    name: 'Manage QC Review',
    description: 'Full access to Manage QC Review on a document version. Includes ability to create, edit, and conduct a QC Review',
    bubblesUp: true,
    isHidden: false,
    parents: []
};

documentPrivs[PrivilegeIds.document_qc_review_create_edit] = {
    id: PrivilegeIds.document_qc_review_create_edit,
    clientId: 'documentCreateEditQCReview',
    name: 'Create/ Edit QC Review',
    description: 'Ability to create, manage, and update a QC Review on a document version. Includes ability to update number of reviews, reviewers, and cancel the entire QC Review. Only the user who creates the review will be able to update the review',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_qc_review_manage]
};

documentPrivs[PrivilegeIds.document_conduct_qc_review] = {
    id: PrivilegeIds.document_conduct_qc_review,
    clientId: 'conductQCReview',
    name: 'Conduct QC Review',
    description: 'Ability to accept or decline a request to review a document version, reject or approve a document version, and leave comments',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_qc_review_manage]
};

documentPrivs[PrivilegeIds.document_qc_review_administrator] = {
    id: PrivilegeIds.document_qc_review_administrator,
    clientId: 'qcReviewAdministrator',
    name: 'QC Review Administrator',
    description: 'Ability to create, manage and update any QC Reviews on a document version, even if not originally created by the user',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_qc_review_manage]
};

documentPrivs[PrivilegeIds.document_create_training_assignment] = {
    id: PrivilegeIds.document_create_training_assignment,
    clientId: 'documentCreateTrainingAssignment',
    name: 'Create Training Assignment',
    description: 'Ability to create a training assignment for bulk assignment of tasks or signature requests',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};

documentPrivs[PrivilegeIds.document_manipulate_pages] = {
    id: PrivilegeIds.document_manipulate_pages,
    clientId: 'documentManipulatePages',
    name: 'Manipulate pages of the document',
    description: 'Rotate, reorder and delete pages of the document',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_manage]
};

teamPrivs[PrivilegeIds.manage_team_inbox] = {
    id: PrivilegeIds.manage_team_inbox,
    clientId: 'manageTeamInbox',
    name: 'Manage Team Inbox',
    description: 'Full access to Team Inbox, including the ability to view and perform actions on documents in the Team Inbox',
    bubblesUp: true,
    isHidden: false,
    parents: []
};

teamPrivs[PrivilegeIds.view_team_inbox] = {
    id: PrivilegeIds.view_team_inbox,
    clientId: 'viewTeamInbox',
    name: 'View Team Inbox',
    description: 'Gives the ability to view documents in the Team Inbox',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.manage_team_inbox]
};

teamPrivs[PrivilegeIds.perform_action_in_team_inbox] = {
    id: PrivilegeIds.perform_action_in_team_inbox,
    clientId: 'performActionInTeamInbox',
    name: 'Perform Action in Team Inbox',
    description: 'Gives the ability to perform an action in the Team Inbox',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.manage_team_inbox]
};

folderPrivs[PrivilegeIds.document_sharing_manage] = {
    id: PrivilegeIds.document_sharing_manage,
    clientId: 'documentSharingManage',
    name: 'Manage Document Sharing',
    description: 'Full access to Document sharing, including the ability to share all documents in the Team',
    bubblesUp: true,
    isHidden: false,
    parents: []
};

folderPrivs[PrivilegeIds.document_sharing_share_docs] = {
    id: PrivilegeIds.document_sharing_share_docs,
    clientId: 'documentSharingShareDocuments',
    name: 'Share Document via Document Sharing',
    description: 'Team documents will be available to share in Document Sharing',
    bubblesUp: true,
    isHidden: false,
    parents: [PrivilegeIds.document_sharing_manage]
};

documentPrivs[PrivilegeIds.document_sharing_create_audit_trail] = {
    id: PrivilegeIds.document_sharing_create_audit_trail,
    clientId: 'shareViaDocumentSharing',
    name: 'Document Sharing Create Audit Trail',
    description: 'Create Audit Trail when sharing documents via Document Sharing',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.document_manage]
};

downloadPrivs[PrivilegeIds.download_manage] = {
    id: PrivilegeIds.download_manage,
    clientId: 'manageDownloads',
    name: 'Manage User\'s Downloads',
    bubblesUp: true,
    isHidden: true,
    parents: []
};
downloadPrivs[PrivilegeIds.download_view] = {
    id: PrivilegeIds.download_view,
    clientId: 'viewDownloads',
    name: 'View User\'s Downloads',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.download_manage]
};
downloadPrivs[PrivilegeIds.download_update] = {
    id: PrivilegeIds.download_update,
    clientId: 'updateDownloads',
    name: 'Update User\'s Downloads',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.download_manage]
};
downloadPrivs[PrivilegeIds.download_delete] = {
    id: PrivilegeIds.download_delete,
    clientId: 'deleteDownloads',
    name: 'Delete User\'s Downloads',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.download_manage]
};
downloadPrivs[PrivilegeIds.download_download] = {
    id: PrivilegeIds.download_download,
    clientId: 'downloadDownloads',
    name: 'Download User\'s Downloads',
    bubblesUp: true,
    isHidden: true,
    parents: [PrivilegeIds.download_manage]
};

module.exports = {
    privs,
    userPrivs,
    teamPrivs,
    binderPrivs,
    folderPrivs,
    documentPrivs,
    downloadPrivs
};
