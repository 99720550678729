import * as _ from 'lodash';
import uuid from 'uuid';

class FontSizeOptionController {
    constructor($scope, $window) {
        this._$scope = $scope;
        this._$window = $window;

        this.round = _.round;
    }

    $onInit(): void {
        this.id = uuid.v4();
        this._setInputValueFromValue();
        this.options = [8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72];
        this._$scope.$watch('vm.value', () => {
            this._setInputValueFromValue();
        });
    }

    _setInputValueFromValue() {
        this.inputValue = _.round(this.value, 1);
    }

    onOutsideMouseup() {
        // Close dropdown
        this.toggleDropdown(false);
    }

    _getElement() {
        return this._$window.document.getElementById(this.id);
    }

    toggleDropdown(shouldOpen) {
        this.isOpen = shouldOpen;
    }

    forceBlur($event) {
        $event.target.blur();
    }

    onBlur() {
        this.handleOptionUpdate(this.inputValue);
    }

    handleOptionUpdate(value) {
        const newValue = parseFloat(value);
        // Perform loose equality check to make sure valid number was submitted
        if (_.isNaN(newValue) || newValue != value) { // eslint-disable-line eqeqeq
            // Reset the input value
            this._setInputValueFromValue();
        }
        else {
            // Update our value when input is valid
            this.value = _.clamp(newValue, 5, 500);
        }
        this.toggleDropdown(false);
        this.onChange({ value: { fontSize: this.value } });
    }
}

FontSizeOptionController.$inject = ['$scope', '$window'];

export default FontSizeOptionController;
