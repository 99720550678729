const warningModalWrapperComponent: ng.IComponentOptions = {
    controllerAs: 'vm',
    template: `
    <warning-modal
        [header]="vm.resolve.header"
        [content]="vm.resolve.content"
        [primary-button]="vm.resolve.primaryButton"
        [secondary-button]="vm.resolve.secondaryButton"
        (dismiss)="vm.modalInstance.close()"
        (save)="vm.resolve.onSubmit($event)"
    ></warning-modal>
    `,
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default warningModalWrapperComponent;
