import template from './user-security-settings.html';
import { UserSecuritySettingsController as controller } from './user-security-settings.controller';

const userSecuritySettingsComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        user: '<'
    }
};

export default userSecuritySettingsComponent;
