import { Transition } from '@uirouter/angularjs';

class CreateRoleController {
    constructor($scope, $transitions: Transition, REGEX, MESSAGES) {
        this._$scope = $scope;

        this.isProcessing = false;
        this.name = '';
        this.namePattern = REGEX.names;
        this.nameMessage = MESSAGES.validNameMessage;

        const deregister = $transitions.onExit({}, () => {
            this.cancel();
            deregister();
        });
    }

    $onInit(): void {
        this.onCreate = this.resolve.onCreate;

        this._$scope.$on('modal.closing', this._preventClosingIfProcessing.bind(this));
    }

    _preventClosingIfProcessing(event, reason, closed) {
        const notAllowedToClose = reason && reason !== 'cancel' && !closed;

        if (this.isProcessing && notAllowedToClose) {
            event.preventDefault();
        }
    }

    cancel(): void {
        this.modalInstance.dismiss('cancel');
    }

    create() {
        this.isProcessing = true;
        const _roleObjToSave = { name: this.name };

        this.onCreate(_roleObjToSave)
            .then(() => {
                this.modalInstance.close();
            }, () => {
                this.isProcessing = false;
            });
    }
}

CreateRoleController.$inject = [
    '$scope',
    '$transitions',
    'REGEX',
    'MESSAGES'
];

export default CreateRoleController;
