import { BehaviorSubject } from 'rxjs';

import ToolMode from '../constants/tool.mode';

class DocumentViewerStoreService {

    constructor() {
        this._selectedTool = new BehaviorSubject(ToolMode.NONE);
        this.selectedTool$ = this._selectedTool.asObservable();

        this._selectedObject = new BehaviorSubject();
        this.selectedObject$ = this._selectedObject.asObservable();
    }

    reset() {
        this.setSelectedObject(undefined);
        this.setSelectedTool(undefined);
    }

    setSelectedTool(tool = ToolMode.NONE) {
        this._selectedTool.next(tool);
    }

    setSelectedObject(obj) {
        let next;

        if (obj) {
            next = {
                type: obj.formField ? 'formField' : 'annotation',
                page: obj.page,
                annotation: obj.annotation,
                formField: obj.formField
            };
        }

        this._selectedObject.next(next);
    }
}


DocumentViewerStoreService.$inject = [];

export default DocumentViewerStoreService;
