import { Document } from '@app/shared/models';

export interface DocumentDownloadOptions {
    teamId: string;
    binderId: string;
    documents: Document[];
    logDownloadFileType?: 'pdf' | 'csv';
    version?: number;
    contentVersion?: number;
    includeSignature?: boolean;
    includeReduction?: boolean;
    includeAllVersionsOfDocuments?: boolean;
}

export const optionsAreDocumentDownloadOptions = (options): options is DocumentDownloadOptions => {
    return 'documents' in options;
};
