import template from './selection.tool.html';
import controller from './selection.tool.controller';

const Component: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        page: '<'
    }
};

export default Component;
