import {
    ChangeDetectionStrategy,
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import * as moment from 'moment';
import styles from './archive-form.component.scss';
import template from './archive-form.component.html';
import { ObjectType } from '../archive-wizard.component';
import { Team } from '../../../../../shared/models';

@Component({
    selector: 'schedule-form',
    template,
    styles: [String(styles)],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArchiveFormComponent implements OnInit {
    @Input() currentTeam: Team;

    @Input() scheduleArchiveForm: FormGroup
    @Input() archivalDateLocalDate: string | null;
    @Input() archivalDateTeamDate: string | null;

    @Input() objectType: ObjectType;
    @Input() objectName: string;

    @Input() enableRetentionPeriod: boolean;
    @Output() increment: EventEmitter<number> = new EventEmitter();

    public minArchiveDate?: string;
    public selectedRetentionPeriod = 25;
    public retentionPeriodOpts = Array.from({ length: 25 }, (v, i) => i + 1);

    public get archivalDateControl(): AbstractControl {
        return this.scheduleArchiveForm.get('archivalDate');
    }

    public get selectedDateControl(): AbstractControl {
        return this.scheduleArchiveForm.get('selectedDate');
    }

    public get objectNameControl(): AbstractControl {
        return this.scheduleArchiveForm.get('objectName');
    }

    ngOnInit(): void {
        this.minArchiveDate = moment().tz(this.currentTeam.settings.timezone).add(1, 'day').format();
    }

    setArchivalDate({ value }) {
        this.selectedDateControl.setValue(value);

        const date = moment(value).format('DD-MMM-YYYY');

        const archivalDate = moment(date, 'DD-MMM-YYYY')
            .set({
                hour: 0,
                minute: 0,
                second: 0
            })
            .tz(this.currentTeam.settings.timezone, true)
            .format();

        this.archivalDateControl.setValue(archivalDate);
    }

    onNext() {
        this.removePopup();
        this.increment.emit(1);
    }

    /**
     * If the date-picker dropdown is open, it will remove it before proceeding to the next page.
     * Otherwise, the dropdown will stay open on the next page, and it'll be unresponsive.
     */

    removePopup() {
        if (window.document.getElementsByClassName('datetime-picker-dropdown').length) {
            const element = window.document.getElementsByClassName('datetime-picker-dropdown')[0];
            element.remove();
        }
    }
}
