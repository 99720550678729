import './font.size.option.scss';
import template from './font.size.option.html';
import controller from './font.size.option.controller';

const fontSizeOptionComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        value: '<',
        onChange: '&'
    }
};

export default fontSizeOptionComponent;
