const UtilFactory = function UtilFactory($timeout, $rootScope) {
    const $mdUtil = {
        dom: {},

        // Returns a function, that, as long as it continues to be invoked, will not
        // be triggered. The function will be called after it stops being called for
        // N milliseconds.
        // @param wait Integer value of msecs to delay (since last debounce reset); default value 10 msecs
        // @param invokeApply should the $timeout trigger $digest() dirty checking
        debounce: function debounce(func, wait, scope, invokeApply) {
            let timer;

            return function debounced(...args) {
                const context = scope;
                const arg = Array.prototype.slice.call(args);

                $timeout.cancel(timer);
                timer = $timeout(() => {

                    timer = undefined;
                    func.apply(context, arg);

                }, wait || 10, invokeApply);
            };
        },

        /**
         * Alternative to $timeout calls with 0 delay.
         * nextTick() coalesces all calls within a single frame
         * to minimize $digest thrashing
         *
         * @param {Function} callback function to be called after the tick
         * @param {boolean} digest true to call $rootScope.$digest() after callback
         * @param scope scope associated with callback. If the scope is destroyed, the callback will
         *  be skipped.
         * @returns {*}
         */
        nextTick: function $mdUtilNextTick(callback, digest, scope) {
            // grab function reference for storing state details
            const { nextTick } = $mdUtil;
            const { timeout } = nextTick;
            const queue = nextTick.queue || [];

            /**
             * Grab a copy of the current queue
             * Clear the queue for future use
             * Process the existing queue
             * Trigger digest if necessary
             */
            const processQueue = function processQueue() {
                const { queue, digest } = nextTick; // eslint-disable-line no-shadow

                nextTick.queue = [];
                nextTick.timeout = null;
                nextTick.digest = false;

                queue.forEach((queueItem) => {
                    const skip = queueItem.scope && queueItem.scope.$$destroyed;
                    if (!skip) {
                        queueItem.callback();
                    }
                });

                if (digest) {
                    $rootScope.$digest();
                }
            };

            // add callback to the queue
            queue.push({ scope, callback });

            // set default value for digest
            if (digest === null) {
                digest = true;
            }

            // store updated digest/queue values
            nextTick.digest = nextTick.digest || digest;
            nextTick.queue = queue;

            // either return existing timeout or create a new one
            const _timeout = timeout || (nextTick.timeout = $timeout(processQueue, 0, false));
            return _timeout;

        },

        /**
         * Parses an attribute value, mostly a string.
         * By default checks for negated values and returns `false´ if present.
         * Negated values are: (native falsy) and negative strings like:
         * `false` or `0`.
         * @param value Attribute value which should be parsed.
         * @param negatedCheck When set to false, won't check for negated values.
         * @returns {boolean}
         */
        parseAttributeBoolean: function parseAttributeBoolean(value, negatedCheck) {
            return value === '' || !!value && (negatedCheck === false || value !== 'false' && value !== '0'); // eslint-disable-line no-mixed-operators
        }
    };

    return $mdUtil;
};

UtilFactory.$inject = ['$timeout', '$rootScope'];

export default UtilFactory;
