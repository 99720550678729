import * as _ from 'lodash';
import { Transition } from '@uirouter/angularjs';

class AddUsersController {
    constructor($scope, $transitions: Transition, $timeout, $window, Team, REGEX) {

        this._$timeout = $timeout;
        this._$scope = $scope;
        this._$window = $window;
        this._Teams = Team;
        this._REGEX = REGEX;

        let emailRegex = REGEX.email.toString();
        emailRegex = emailRegex.slice(emailRegex.indexOf('/') + 1, emailRegex.lastIndexOf('/'));
        this.emailRegex = emailRegex;

        this.separatorsPattern = REGEX.listSeparators;
        this.baseSeparator = ',';
        this.currentText;
        this.isProcessing = false;
        this.pendingInvites = [];
        this.hasErrors = false;
        this.maxEmails = 100;
        this.sendNotifications = true;

        const deregister = $transitions.onExit({}, () => {
            this.cancel();
            deregister();
        });
    }

    $onInit(): void {
        this._$timeout(() => {
            document.getElementsByClassName('ng-pristine')[0].focus();
        }, 1);

        this._$scope.$on('modal.closing', this._preventClosingIfProcessing.bind(this));
    }

    _preventClosingIfProcessing(event, reason, closed) {
        const notAllowedToClose = reason && reason !== 'cancel' && !closed;

        if (this.isProcessing && notAllowedToClose) {
            event.preventDefault();
        }
    }

    _emailAlreadyAdded(email) {
        return !!_.find(this.pendingInvites, { text: email });
    }

    toogleSendNotifications() {
        this.sendNotifications = !this.sendNotifications;
    }

    displayError() {
        this.hasErrors = true;
    }

    removeError() {
        this.hasErrors = false;
    }

    hasInvalidTags() {
        return this.hasErrors && this.currentText.length > 0;
    }

    hasDuplicatedTags() {
        return this.hasInvalidTags() && this._emailAlreadyAdded(this.currentText);
    }

    isSendDisabled() {
        return !this.pendingInvites.length || this.isProcessing || this.hasInvalidTags();
    }

    divideEmailTags(event) {
        event.preventDefault();

        const data = event.clipboardData ? event.clipboardData.getData('text/plain') : this._$window.clipboardData.getData('Text');
        const pastingString = data.replace(/\s+/g, ' ').replace(this.separatorsPattern, this.baseSeparator);

        const emails = pastingString.split(this.baseSeparator);
        _.each(emails, (email) => {
            email = email.trim();
            if (email.length > 0 && !this._emailAlreadyAdded(email) && this._REGEX.email.test(email)) {
                this.pendingInvites.push({
                    text: email
                });
            }
        });
    }

    cancel(): void {
        this.modalInstance.dismiss('cancel');
    }

    send() {
        const emailsToInvite = _.map(this.pendingInvites, (invite) => invite.text);
        this.isProcessing = true;

        this._Teams.inviteUsersToTeam(emailsToInvite, this.sendNotifications)
            .toPromise()
            .then(() => {
                this.modalInstance.close();
            }, () => {
                this.isProcessing = false;
            });
    }
}

AddUsersController.$inject = ['$scope', '$transitions', '$timeout', '$window', 'Team', 'REGEX'];

export default AddUsersController;
