import {
    ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    FormArray,
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
    map, pairwise, startWith, takeUntil, tap
} from 'rxjs/operators';
import { Subject } from 'rxjs';
import uuid from 'uuid';
import { FORM_CONTROL_STATUS } from '@app/shared/forms/constants/form-control-status';
import template from './doa-log-columns.component.html';
import styles from './doa-log-columns.component.scss';
import {
    LogColumnTypes,
    LogEntryTypes,
    LogTemplateColumn,
    StudyResponsibilities,
    StudyRole
} from '../../../../../shared/models';
import { DOA_LOG_TEMPLATE_FORM_CONTROLS } from '../doa-log-template.types';
import { MESSAGES, REGEX } from '../../../../../core/constants';
import { notBlank } from '../../../../../core/form-validators';
import { FormUtil } from '../../../../../shared/forms/utils/form.util';
import { DEFAULT_DOA_LOG_COLUMN_NAMES, DEFAULT_DOA_LOG_COLUMN_NAMES_WITH_METADATA } from './doa-log-columns.types';
import { StudyRolesMap } from '../../../../../shared/study-roles/study-roles.types';

type LogColumnType = typeof LogColumnTypes[number];
type ExcludeLogColumnType<ExcludeType> = LogColumnType extends { value: ExcludeType } ? never : LogColumnType;
type SpecificFilteredLogColumnTypes = ExcludeLogColumnType<LogEntryTypes>[];

@Component({
    selector: 'doa-log-columns',
    template,
    styles: [String(styles)],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoaLogColumnsComponent implements OnInit, OnChanges, OnDestroy {
    constructor(
        private controlContainer: ControlContainer,
        private formBuilder: FormBuilder
    ) {}

    @Input() logColumns?: LogTemplateColumn[];
    @Input() studyResponsibilities: StudyResponsibilities;
    @Input() logTemplateStudyRoles: StudyRole[];
    @Input() studyRolesMap: StudyRolesMap;
    @Input() canEdit = true;

    readonly logColumnsFormControlName = DOA_LOG_TEMPLATE_FORM_CONTROLS.COLUMNS;
    readonly MAX_COLUMNS = 20;
    readonly singleSelectDropdownlogEntryType = LogEntryTypes.singleSelect;
    readonly multiSelectDropdownlogEntryType = LogEntryTypes.multiSelect;
    readonly studyRoleLogColumnName = DEFAULT_DOA_LOG_COLUMN_NAMES.studyRole;
    readonly studyResponsibilitiesLogColumnName = DEFAULT_DOA_LOG_COLUMN_NAMES.responsibilities;
    readonly singleSelectColumnOptionsFormControlName = DOA_LOG_TEMPLATE_FORM_CONTROLS.SINGLE_SELECT_COLUMN_OPTIONS;
    readonly multiSelectColumnOptionsFormControlName = DOA_LOG_TEMPLATE_FORM_CONTROLS.MULTI_SELECT_COLUMN_OPTIONS;

    logColumnsFormArray: FormArray;
    valueToExclude = LogEntryTypes.teamMember
    filteredLogColumnTypes: SpecificFilteredLogColumnTypes

    isFormArrayInvalid = false;

    get parentForm() {
        return <FormGroup> this.controlContainer.control;
    }

    readonly logColumnTypes = LogColumnTypes;
    private readonly duplicateColumnNameErrorMessage = 'Column name is already in use';
    private readonly defaultNewLogColumnType = LogEntryTypes.text;
    private readonly destroy$ = new Subject<void>();
    private readonly defaultDoaLogColumns = this.generateDefaultDoaLogColumns();

    readonly singleChoiceColumnOptionValidators = [
        Validators.required,
        Validators.maxLength(100),
        notBlank
    ];

    readonly multiChoiceColumnOptionValidators = [
        Validators.required,
        Validators.maxLength(100),
        notBlank
    ];

    readonly studyRolesListType = '1';

    get studyResponsibilitiesListType(): string {
        return this.studyResponsibilities.isNumberIdentifier ? '1' : 'A';
    }

    ngOnInit(): void {
        this.initFormControls();

        this.setDuplicateColumnNamesValidators();

        this.filteredLogColumnTypes = this.logColumnTypes.filter((item) => {
            return item.value !== this.valueToExclude && item;
        }) as SpecificFilteredLogColumnTypes;

        this.logColumnsFormArray.statusChanges.subscribe((status) => {
            this.isFormArrayInvalid = status === FORM_CONTROL_STATUS.invalid;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const isNumberIdentifierChanged = changes.studyResponsibilities?.previousValue !== undefined
            && (changes.studyResponsibilities?.currentValue?.isNumberIdentifier
                !== changes.studyResponsibilities?.previousValue?.isNumberIdentifier);

        if (isNumberIdentifierChanged) {
            const responsibilitiesColumnFormGroup = this.getLogColumnFormGroup({ name: this.studyResponsibilitiesLogColumnName });

            responsibilitiesColumnFormGroup.patchValue({
                isNumberIdentifier: this.studyResponsibilities.isNumberIdentifier
            });
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    isDefaultLogColumn(columnName: string): boolean {
        return this.defaultDoaLogColumns.some((logColumn) => logColumn.name.toLowerCase() === columnName.toLowerCase());
    }

    canRemoveLogColumn(columnName: string): boolean {
        if (!this.canEdit) {
            return false;
        }
        const defaultLogColumn = this.defaultDoaLogColumns
            .find((logColumn) => logColumn.name.toLowerCase() === columnName.toLowerCase());

        if (defaultLogColumn === undefined) {
            return true;
        }

        return defaultLogColumn.canDelete === true;
    }

    addEmptyLogColumn(): void {
        const emptyLogColumn = this.generateLogTemplateColumnFormGroup();

        this.logColumnsFormArray.push(emptyLogColumn);
    }

    onDrop(event: CdkDragDrop<string[]>) {
        FormUtil.reorderFormArrayFromDragDropEvent(event, this.logColumnsFormArray);

        this.parentForm.markAsDirty();
    }

    onSingleSelectColumnOptionsFormUpdated(singleSelectColumnOptions: string[], logColumnFormGroupIndex: number): void {
        const logColumnFormGroup = this.getLogColumnFormGroup({ index: logColumnFormGroupIndex });
        const singleSelectColumnOptionsFormArray = <FormArray>logColumnFormGroup
            .get(this.singleSelectColumnOptionsFormControlName);

        FormUtil.setFormArrayToNewValue(
            singleSelectColumnOptionsFormArray,
            singleSelectColumnOptions,
            this.singleChoiceColumnOptionValidators
        );

        this.logColumnsFormArray.markAsDirty();
    }

    onMultiSelectColumnOptionsFormUpdated(multiSelectColumnOptions: string[], logColumnFormGroupIndex: number): void {
        const logColumnFormGroup = this.getLogColumnFormGroup({ index: logColumnFormGroupIndex });
        const multiSelectColumnOptionsFormArray = <FormArray>logColumnFormGroup
            .get(this.multiSelectColumnOptionsFormControlName);

        FormUtil.setFormArrayToNewValue(
            multiSelectColumnOptionsFormArray,
            multiSelectColumnOptions,
            this.multiChoiceColumnOptionValidators
        );

        this.logColumnsFormArray.markAsDirty();
    }

    onMultiSelectColumnIdentifierUpdated(newValue: boolean, logColumnFormGroupIndex: number): void {
        const logColumnFormGroup = this.getLogColumnFormGroup({ index: logColumnFormGroupIndex });

        const isNumberIdentifierFormControl = logColumnFormGroup.get('isNumberIdentifier');

        isNumberIdentifierFormControl.setValue(newValue);
    }

    getLogColumnNameFormControlErrorMessage(
        formControl: AbstractControl
    ): { message: string } {
        let message = '';

        switch (true) {
            case formControl.errors?.required:
            case formControl.errors?.blank:
                message = MESSAGES.invalidBlankMessage;
                break;
            case formControl.errors?.isDuplicate:
                message = this.duplicateColumnNameErrorMessage;
                break;
            default:
                message = MESSAGES.validShortNameMessage;
        }

        return { message };
    }

    removeFormArrayItem(index: number): void {
        this.logColumnsFormArray.removeAt(index);
        this.logColumnsFormArray.markAsDirty();
    }

    getStudyRoleNameById(studyRoleId: string): string {
        const selectedStudyRoleName = this.studyRolesMap.get(studyRoleId);

        return selectedStudyRoleName;
    }

    private generateDefaultDoaLogColumns() {
        return Object.entries(DEFAULT_DOA_LOG_COLUMN_NAMES_WITH_METADATA)
            .map(([columnName, { columnType, canDelete }]) => ({
                id: uuid.v4(),
                name: columnName,
                type: columnType,
                canDelete
            }));
    }

    private initFormControls(): void {
        const initialLogColumnsFormArrayValue = this.getInitialLogColumnsFormArrayValue();

        this.logColumnsFormArray = this.formBuilder.array(
            initialLogColumnsFormArrayValue,
            [
                Validators.minLength(1),
                Validators.maxLength(this.MAX_COLUMNS)
            ]
        );

        this.parentForm.addControl(this.logColumnsFormControlName, this.logColumnsFormArray);
    }

    private getInitialLogColumnsFormArrayValue(): FormGroup[] {
        let initialLogColumnsFormArrayValue: FormGroup[];

        if (this.logColumns?.length) {
            // if logColumns @Input() was provided, use it as the starting value
            initialLogColumnsFormArrayValue = this.logColumns.map(
                (logColumn) => this.generateLogTemplateColumnFormGroup(logColumn)
            );
        }
        else {
            // else use the default columns
            initialLogColumnsFormArrayValue = this.generateDefaultLogColumnsFormGroups();
        }

        return initialLogColumnsFormArrayValue;
    }

    private generateDefaultLogColumnsFormGroups(): FormGroup[] {
        const logColumnsFormArrayValue = this.defaultDoaLogColumns.map((logColumn) => {
            return this.generateLogTemplateColumnFormGroup(logColumn);
        });

        return logColumnsFormArrayValue;
    }

    private generateLogTemplateColumnFormGroup(
        logColumn: LogTemplateColumn = this.emptyLogColumn
    ): FormGroup {
        const logTemplateColumnFormGroup = this.formBuilder.group({
            id: this.formBuilder.control(logColumn.id),
            name: this.formBuilder.control(logColumn.name, [
                Validators.required,
                Validators.pattern(REGEX.names),
                notBlank,
                Validators.maxLength(50)
            ]),
            type: this.formBuilder.control(logColumn.type, [
                Validators.required
            ]),
            multiselectColumnOptions: this.formBuilder.array(logColumn.multiselectColumnOptions || []),
            singleselectColumnOptions: this.formBuilder.array(logColumn.singleselectColumnOptions || []),
            isNumberIdentifier: this.formBuilder.control(logColumn.isNumberIdentifier ?? true)
        });

        if (this.isDefaultLogColumn(logColumn.name) || !this.canEdit) {
            logTemplateColumnFormGroup.disable();
        }

        this.setValidatorsOnLogColumnTypeChange(logTemplateColumnFormGroup);

        return logTemplateColumnFormGroup;
    }

    private setValidatorsOnLogColumnTypeChange(
        logTemplateColumnFormGroup: FormGroup
    ): void {
        logTemplateColumnFormGroup.controls.type.valueChanges.pipe(
            startWith(this.defaultNewLogColumnType),
            pairwise(),
            tap(([previousColumnType, newColumnType]: [LogEntryTypes, LogEntryTypes]) => {
                this.setMultiSelectValidatorsOnColumnTypeChange(
                    logTemplateColumnFormGroup,
                    [previousColumnType, newColumnType]
                );
                this.setSingleSelectValidatorsOnColumnTypeChange(
                    logTemplateColumnFormGroup,
                    [previousColumnType, newColumnType]
                );
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    private setMultiSelectValidatorsOnColumnTypeChange(
        logTemplateColumnFormGroup: FormGroup,
        [previousColumnType, newColumnType]: [LogEntryTypes, LogEntryTypes]
    ): void {
        const multiselectColumnOptionsFormArray = <FormArray>logTemplateColumnFormGroup.controls.multiselectColumnOptions;

        if (previousColumnType === LogEntryTypes.multiSelect) {
            FormUtil.clearValidatorsFromFormArrayAndControls(multiselectColumnOptionsFormArray);
        }
        if (newColumnType === LogEntryTypes.multiSelect) {
            this.setValidatorsOnMultiSelectFormArrayAndControls(multiselectColumnOptionsFormArray);
        }
    }

    private setSingleSelectValidatorsOnColumnTypeChange(
        logTemplateColumnFormGroup: FormGroup,
        [previousColumnType, newColumnType]: [LogEntryTypes, LogEntryTypes]
    ): void {
        const singleSelectColumnOptionsFormArray = <FormArray>logTemplateColumnFormGroup.controls.singleselectColumnOptions;

        if (previousColumnType === LogEntryTypes.singleSelect) {
            FormUtil.clearValidatorsFromFormArrayAndControls(singleSelectColumnOptionsFormArray);
        }
        if (newColumnType === LogEntryTypes.singleSelect) {
            this.setValidatorsOnSingleSelectFormArrayAndControls(singleSelectColumnOptionsFormArray);
        }
    }

    private setValidatorsOnMultiSelectFormArrayAndControls(
        multiselectColumnOptionsFormArray: FormArray
    ): void {
        const multiselectColumnFormArrayValidators = [
            Validators.required,
            Validators.minLength(1)
        ];

        multiselectColumnOptionsFormArray.setValidators(multiselectColumnFormArrayValidators);

        multiselectColumnOptionsFormArray.controls.forEach((control) => {
            control.setValidators(this.multiChoiceColumnOptionValidators);
            control.updateValueAndValidity();
        });

        multiselectColumnOptionsFormArray.updateValueAndValidity();
    }

    private setValidatorsOnSingleSelectFormArrayAndControls(
        singleSelectColumnOptionsFormArray: FormArray
    ): void {
        const singleSelectColumnFormArrayValidators = [
            Validators.required,
            Validators.minLength(1)
        ];

        singleSelectColumnOptionsFormArray.setValidators(singleSelectColumnFormArrayValidators);

        singleSelectColumnOptionsFormArray.controls.forEach((control) => {
            control.setValidators(this.singleChoiceColumnOptionValidators);
            control.updateValueAndValidity();
        });

        singleSelectColumnOptionsFormArray.updateValueAndValidity();
    }

    private getLogColumnFormGroup({ index, name }: { index?: number, name?: string }): FormGroup {
        if (index !== undefined) {
            return <FormGroup> this.logColumnsFormArray.at(index);
        }
        return <FormGroup> this.logColumnsFormArray.controls.find((control) => control.get('name').value === name);
    }

    private get emptyLogColumn(): LogTemplateColumn {
        return {
            id: uuid.v4(),
            name: '',
            type: this.defaultNewLogColumnType,
            multiselectColumnOptions: [],
            singleselectColumnOptions: [],
            isNumberIdentifier: true
        };
    }

    private setDuplicateColumnNamesValidators(): void {
        this.logColumnsFormArray.valueChanges.pipe(
            map((columns) => ({
                nameCountsMap: this.getColumnNameCountMap(this.logColumnsFormArray.getRawValue()),
                columns
            })),
            tap(({ columns, nameCountsMap }) => {
                columns.forEach(({ id, name }) => {
                    const nameFormControl = this.logColumnsFormArray.controls
                        .find((control) => control.value.id === id)
                        .get('name');
                    const isDuplicate = nameCountsMap.get(name?.toLowerCase().trim()) > 1;

                    if (isDuplicate) {
                        FormUtil.addFormControlError(nameFormControl, 'isDuplicate');
                    }
                    else {
                        FormUtil.removeFormControlError(nameFormControl, 'isDuplicate');
                    }
                });
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    private getColumnNameCountMap(
        columns: LogTemplateColumn[]
    ): Map<string, number> {
        const nameCountsMap = new Map();

        columns.forEach((column) => {
            const key = column.name?.toLowerCase().trim();
            nameCountsMap.set(key, nameCountsMap.has(key) ? nameCountsMap.get(key) + 1 : 1);
        });

        return nameCountsMap;
    }
}
