import controller from './manage-roles.controller';
import template from './manage-roles.html';

const manageRolesComponent: ng.IComponentOptions = {
    controller,
    controllerAs: 'vm',
    template,
    bindings: {}
};

export default manageRolesComponent;
