import { take } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';

import { CurrentSessionService } from '@app/core/current-session.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import {
    Team,
    Crumb,
    LogTemplate,
    AuditTrailSubject,
    CreateLogTemplateRequestBody
} from '@app/shared/models';
import { LogTemplatesService } from '@app/shared/log-templates/log-templates.service';
import { AuditTrailService } from '@app/shared/audit-trail/audit-trail.service';
import { AuditTrailModalComponent } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import ApiErrorFactory from '@app/shared/api-error/api-error.service';
import { AuditTrailTypes } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component.types';
import { ROUTES } from '@app/core/constants';
import {
    LogTemplateInactiveWarningComponent
} from '../../components/log-template-inactive-warning/log-template-inactive-warning.component';

import { messages } from './log-template-view-notifications';
import template from './log-template-view.component.html';

@Component({
    selector: 'log-template-view',
    template
})
export class LogTemplateViewComponent implements OnInit {
    private logTemplate: LogTemplate;
    public logTemplateVersion = 1;
    public crumbs: Crumb[] = [];
    public currentTeam: Team;
    public isLoading = true;
    public clampLength = 50;

    constructor(
        private $state: StateService,
        private CurrentSession: CurrentSessionService,
        private LogTemplates: LogTemplatesService,
        private AuditTrail: AuditTrailService,
        private Modals: ModalsService,
        private Notifications: NotificationsService,
        private ApiError: ApiErrorFactory
    ) { }

    ngOnInit(): void {
        const stateParams = this.$state.params;
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        if (!this.currentTeam.settings.features.logTemplates || !this.currentTeam.permissions.viewLogTemplates) {
            this.$state.go('app.select-team');
        }
        if (stateParams.logTemplateId) {
            if (stateParams.version) {
                this.getLogTemplateVersion(
                    this.currentTeam.id,
                    stateParams.logTemplateId,
                    stateParams.version
                );
            }
            else {
                this.getLogTemplate(this.currentTeam.id, stateParams.logTemplateId);
            }
        }
        else {
            this.crumbs = this.getCrumbs(this.logTemplate);
            this.isLoading = false;
            this.redirectIfLogTemplateIsInactive(this.logTemplate);
        }
    }

    handleSetVersion(version: number): void {
        this.$state.go('app.team.log-template-view-version', {
            logTemplateId: this.logTemplate.id.templateId,
            version
        });
    }

    onSubmit(logTemplate: CreateLogTemplateRequestBody): void {
        if (this.logTemplate && this.logTemplate.id && this.logTemplate.id.templateId) {
            this.LogTemplates.updateLogTemplate(this.currentTeam.id, this.logTemplate.id.templateId, logTemplate).subscribe(
                (logTemplateData: LogTemplate) => {
                    this.Notifications.success(messages.logTemplateUpdated);
                    this.handleSetVersion(logTemplateData.id.version);
                },
                ({ error }) => {
                    this.Notifications.error(error.message || error.unknownErr);
                }
            );
        }
        else {
            this.LogTemplates.createLogTemplate(this.currentTeam.id, logTemplate).subscribe(
                () => {
                    this.Notifications.success(messages.logTemplateCreated);
                    this.$state.go(ROUTES.elogs);
                },
                ({ error }) => {
                    error.validation
                        ? this.Notifications.error('Log template creation failed.')
                        : this.Notifications.error(error.message || 'There was an unexpected error.  Please contact your administrator.');
                }
            );
        }
    }

    openAuditTrail(): void {
        if (this.currentTeam.permissions.viewTeamAuditTrail) {
            const auditTrailParams = {
                permissions: this.currentTeam.permissions,
                name: this.logTemplate.name,
                id: this.logTemplate.id.templateId,
                teamId: this.currentTeam.id,
                type: AuditTrailTypes.logTemplate
            };

            this.AuditTrail.getAudits({
                subject: AuditTrailSubject.LOG_TEMPLATE,
                teamId: this.currentTeam.id,
                objectId: auditTrailParams.id,
                overwrittenObjectId: null,
                limitToOverwritten: false,
                ...this.AuditTrail.auditPagination
            }).toPromise().then((audits) => {
                this.Modals.show(AuditTrailModalComponent, {
                    class: 'modal-lg',
                    animated: true,
                    initialState: {
                        data: audits,
                        item: auditTrailParams,
                        subject: AuditTrailSubject.LOG_TEMPLATE,
                        pagination: this.AuditTrail.auditPagination,
                        onPageChange: this.AuditTrail.getAudits.bind(this.AuditTrail)
                    }
                });

            }).catch(this.ApiError.handleError);
        }
    }

    checkIfLogTemplateHasReferenceId(): boolean {
        return !this.logTemplate?.referenceId;
    }

    private getCrumbs(logTemplate?: Partial<LogTemplate>): Crumb[] {
        const crumbs: Crumb[] = [
            {
                name: 'Log Templates',
                stateName: ROUTES.logTemplatesSelector
            },
            { name: 'eLog Templates', stateName: ROUTES.elogs }
        ];
        crumbs.push({
            name: logTemplate ? logTemplate.name : 'Create Log Template'
        });
        return crumbs;
    }

    private getLogTemplate(teamId: string, logTemplateId: string): void {
        this.LogTemplates.getLogTemplate(teamId, logTemplateId)
            .subscribe((logTemplate) => {
                this.onLogTemplateLoaded(logTemplate);
            });
    }

    private getLogTemplateVersion(teamId: string, logTemplateId: string, version?: number): void {
        this.LogTemplates.getLogTemplateVersion(teamId, logTemplateId, version)
            .subscribe((logTemplate) => {
                this.onLogTemplateLoaded(logTemplate);
            });
    }

    private onLogTemplateLoaded(logTemplate: LogTemplate): void {
        this.redirectIfLogTemplateIsInactive(logTemplate);
        this.crumbs = this.getCrumbs(logTemplate);
        this.logTemplate = logTemplate;
        this.logTemplateVersion = this.logTemplate.id.version;
        this.isLoading = false;
    }

    private redirectIfLogTemplateIsInactive(logTemplate: LogTemplate): void {
        if (!logTemplate || logTemplate.isActive) {
            return;
        }

        const modalRef = this.Modals.show(LogTemplateInactiveWarningComponent, {
            animated: true,
            initialState: {}
        });

        modalRef.content.dismiss
            .pipe(take(1))
            .subscribe(() => {
                modalRef.hide();
            });

        this.$state.go('app.team.log-templates');
    }
}
