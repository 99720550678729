import * as _ from 'lodash';
import { Transition } from '@uirouter/angularjs';

import { User } from '@app/shared/models';
import { REGEX } from '@app/core/constants';
import { CurrentSessionService } from '@app/core/current-session.service';
import { PasswordOrPinExpiryService } from '@app/shared/password-or-pin-expiry/password-or-pin-expiry.service';

// this component is soley used for old doc viewer when saving the form
// it will be deleted along with the old doc viewer
// ndv-form-save is used exclusively by NDV for the same purpose
class DocumentFormSaveController {
    currentUser: User;
    isSigningPasscodeExpired = false;
    isProcessing = false;
    jobTitle = '';

    entity;
    updatedFormFields;
    originalFormFields;
    onSubmit;
    emailPattern;
    email;
    signatureCount;
    mustFinalize;
    defaultSigningReason;
    isFormValid;
    requiredTooltipText;
    validTooltipText;
    password;
    isReasonSet;

    isNewDocViewer;

    constructor(
        private $scope: ng.IScope,
        private $transitions: Transition,
        private CurrentSession: CurrentSessionService,
        private DefaultSigningReason,
        private DocumentFormField,
        private PasswordOrPinExpiry: PasswordOrPinExpiryService
    ) {

        const deregister = this.$transitions.onExit({}, () => {
            this.cancel();
            deregister();
        });
    }

    $onInit(): void {
        this.isNewDocViewer = this.resolve.isNewDocViewer;
        this.entity = this.resolve.entity;
        this.updatedFormFields = this.resolve.updatedFormFields;
        this.originalFormFields = this.resolve.originalFormFields;
        this.onSubmit = this.resolve.onSubmit;
        this.jobTitle = this.resolve.jobTitle;

        this.emailPattern = REGEX.email;
        this.currentUser = this.CurrentSession.getCurrentUser();
        this.email = _.get(this.currentUser, 'email', null);

        this.isSigningPasscodeExpired = this.currentUser.isRemote && this.PasswordOrPinExpiry.isExpired(
            this.CurrentSession.getCurrentTeam().settings.signingPINPolicy,
            this.currentUser.lastSigningPasscodeReset
        );

        if (!this.isNewDocViewer) {
            this.signatureCount = _.reduce(this.updatedFormFields, (accum, formField) => {

                if (formField.type === 'signature' && formField.value) {
                    accum += 1;
                }
                return accum;
            }, 0);
            this.mustFinalize = this.signatureCount > 0 || _.size(this.updatedFormFields) === 0;
            this.isFormValid = this.DocumentFormField.checkValidity();
        }
        else {
            this.signatureCount = this.resolve.numberOfSignaturesAddedToTheForm;
            this.mustFinalize = this.resolve.hasToFinalizeForm;
            this.isFormValid = this.resolve.canFinalizeForm;
        }

        this._setPassphraseDetails();

        this.defaultSigningReason = this.DefaultSigningReason.getDefaultSigningReasonFromPending({
            doc: this.entity,
            userId: this.currentUser.id
        });

        this._isDefaultReasonSet();

        this.$scope.$on('modal.closing', this._preventClosingIfProcessing.bind(this));
    }

    _isDefaultReasonSet() {

        if (this.defaultSigningReason !== null) {
            this.onReasonChanged(this.defaultSigningReason);
        }
    }

    _preventClosingIfProcessing(event, reason, closed) {

        const notAllowedToClose = reason && reason !== 'cancel' && !closed;

        if (this.isProcessing && notAllowedToClose) {
            event.preventDefault();
        }
    }

    _setPassphraseDetails() {

        if (this.currentUser.isRemote) {
            this.requiredTooltipText = 'Signing PIN required.';
            this.validTooltipText = 'Please enter a valid Signing PIN.';
        }
        else {
            this.requiredTooltipText = 'Password required.';
            this.validTooltipText = 'Please enter a valid password.';
        }
    }

    cancel(): void {
        this.modalInstance.dismiss('cancel');
    }

    getModalData() {
        if (this.currentUser.isRemote) {
            return {
                signingPasscode: this.password,
                email: this.email
            };
        }
        return {
            password: this.password,
            email: this.email
        };
    }

    handleSaveDraft() {

        const data = this.getModalData();
        data.shouldFinalize = false;
        this._handleSave(data);
    }

    handleFinalize() {

        const data = this.getModalData();
        data.shouldFinalize = true;
        this._handleSave(data);
    }

    isValidForm() {

        if (this.signatureCount > 0) {
            const form = this.DocumentFormSaveModalForm;
            return !form.$invalid && this.isReasonSet;
        }
        return true;
    }

    _handleSave(data) {

        if (!this.isValidForm()) {
            return;
        }
        data.jobTitle = this.jobTitle;
        data.reason = this.isReasonSet;

        this.isProcessing = true;
        this.onSubmit(data)
            .then((returnValue) => {

                this.modalInstance.close(returnValue);
            })
            .catch(() => {

                this.isProcessing = false;
            });
    }

    onReasonChanged(reason) {

        if (!this.isNewDocViewer) {
            this.updatedFormFields.forEach((annotation) => {
                if (annotation.type !== 'signature') {
                    return;
                }
                annotation.value.reason = reason;
            });
        }

        this.isReasonSet = reason;
    }

    shouldShowCertifiedCopyInfo() {
        if (!this.isNewDocViewer) {
            return _.some(this.updatedFormFields, (formField) => formField.type === 'signature' && formField.value.reason === 'Certified Copy');
        }

        return this.isReasonSet === 'Certified Copy';
    }
}

DocumentFormSaveController.$inject = [
    '$scope',
    '$transitions',
    'CurrentSession',
    'DefaultSigningReason',
    'DocumentFormField',
    'PasswordOrPinExpiry'
];

export default DocumentFormSaveController;
