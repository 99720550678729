import template from './text.tool.html';
import controller from './text.tool.controller';

const textToolComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        page: '<'
    }
};

export default textToolComponent;
