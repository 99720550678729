import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {
    forkJoin,
    from,
    Observable,
    Subject,
    of,
    combineLatest
} from 'rxjs';
import {
    switchMap,
    take,
    map,
    tap,
    catchError
} from 'rxjs/operators';
import { StateService } from '@uirouter/core';

import { ModalRef, ModalsService } from '@app/shared/modal-helper/modals.service';
import { TeamService } from '@app/shared/teams/team.service';
import {
    Document,
    SignatureTypes,
    SigningReasons,
    Team,
    User
} from '@app/shared/models';
import { CurrentSessionService } from '@app/core/current-session.service';
import { isDateInThePast } from '@app/shared/date-time/is-date-in-the-past.util';
import { FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';
import { GetDocumentsPendingSignatureRequestsResponse, GetNoSignPermissionsResponse } from '@app/shared/documents/documents.service.types';

import { DocumentService } from '@app/shared/documents/document.service';
import { NoSigningPermissionsComponent } from '../../components/no-signing-permissions/no-signing-permissions.component';
import { SelectDocumentsComponent } from '../../components/select-documents/select-documents.component';
import { DocumentsSignatureRequestsStore } from '../../services/documents-signature-requests.store.service';
import { DocumentsTabDataRow, isDocumentsTabDataRow } from '../../components/documents-selected-tab/documents-selected-tab.types';
import { DocumentsSignersTabDataRow } from '../../components/documents-signers-tab/documents-signers-tab.types';
import { DocumentsPendingSignatureTabDataRow, isPendingTabDataRow } from '../../components/documents-pending-signatures-tab/documents-pending-signatures-tab.types';

import template from './documents-signature-requests.component.html';

enum Tabs {
    documents = 'documents',
    signers = 'signers',
    pending = 'pending',
}
@Component({
    selector: 'documents-signature-requests',
    template
})
export class DocumentsSignatureRequestsComponent implements OnInit, OnDestroy {
    @Input() initiallySelectedDocuments: Document[] = [];
    @Input() userLacksRequestSignaturePermissions: boolean;
    @Input() newDocViewer: boolean;
    @Output() dismiss = new EventEmitter<void>();

    tabs = Tabs;
    currentTab = Tabs.documents;
    currentTeam: Team;
    selectDocumentsModalRef: ModalRef<SelectDocumentsComponent>;
    private readonly destroy$ = new Subject<void>();
    readonly commentMaxLength = 2000;
    comment = '';
    showRequestorPermissionsWarning = false;
    loadingPotentialSigners = false;
    loadingPendingRequests = false;
    loadingNoSigningPermissions = false;
    loadingUserPermissions = false;
    isSubmitting = false;

    selectedDocuments$: Observable<Document[]>;
    selectedDocumentsTabData$: Observable<DocumentsTabDataRow[]>;

    signersTabData$: Observable<DocumentsSignersTabDataRow[]>;
    selectedSigners$: Observable<User[]>;
    potentialSigners: User[] = [];
    allPotentialSignersCached: User[] = [];

    pendingRequestsCount$: Observable<number>;
    pendingTabData$: Observable<DocumentsPendingSignatureTabDataRow[]>;
    noSigningPermissionsData$: Observable<GetNoSignPermissionsResponse>;

    canSubmit$: Observable<boolean>;

    constructor(
        private $state: StateService,
        private Modals: ModalsService,
        private SignatureRequestsStore: DocumentsSignatureRequestsStore,
        private Teams: TeamService,
        private DocumentService: DocumentService,
        private CurrentSession: CurrentSessionService
    ) {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.selectedDocuments$ = this.SignatureRequestsStore.selectedDocuments$;
        this.selectedDocumentsTabData$ = this.SignatureRequestsStore.documentsTabData$;

        this.signersTabData$ = this.SignatureRequestsStore.signersTabData$;
        this.selectedSigners$ = this.SignatureRequestsStore.selectedSigners$;

        this.pendingTabData$ = this.SignatureRequestsStore.pendingTabData$;
        this.pendingRequestsCount$ = this.SignatureRequestsStore.pendingRequests$
            .pipe(map((data) => {
                let count = 0;
                data.forEach((d) => {
                    (count += d.pendingSignatureRequests.length);
                });
                return count;
            }));
        this.noSigningPermissionsData$ = this.SignatureRequestsStore.noSigningPermissionsData$;
    }

    ngOnInit(): void {
        this.SignatureRequestsStore.init(this.newDocViewer);

        this.canSubmit$ = combineLatest([
            this.getIsPendingTabChanged(),
            this.getIsDocumentsTabChanged(),
            this.getAllDocumentsTabRowsHaveReasonSet(),
            this.getIsSignersTabSignByDateValid(),
            this.getIsPendingTabSignByDateValid(),
            this.selectedSigners$.pipe(map((signers) => !!signers.length)),
            this.selectedDocuments$.pipe(map((docs) => !!docs.length))
        ]).pipe(map(([
            pendingTabChanged,
            documentsTabChanged,
            allDocumentsHaveReasonSet,
            signersTabSignByDateIsValid,
            pendingTabSignByDateIsValid,
            signersAreSelected,
            documentsAreSelected
        ]) => {
            let canSubmit = false;
            if (documentsAreSelected) {
                if (documentsTabChanged && !pendingTabChanged) {
                    canSubmit = signersAreSelected
                        && signersTabSignByDateIsValid
                        && allDocumentsHaveReasonSet;
                }
                if (!documentsTabChanged && pendingTabChanged) {
                    canSubmit = !signersAreSelected && pendingTabSignByDateIsValid;
                }
                if (documentsTabChanged && pendingTabChanged) {
                    canSubmit = signersAreSelected
                        && signersTabSignByDateIsValid
                        && allDocumentsHaveReasonSet
                        && pendingTabSignByDateIsValid;
                }
            }
            return canSubmit;
        }));

        if (this.userLacksRequestSignaturePermissions) {
            this.showRequestorPermissionsWarning = true;
        }
        this.onAddDocuments(this.initiallySelectedDocuments).pipe(
            take(1),
            catchError(this.handleApiError)
        ).subscribe();
    }

    private getAllDocumentsTabRowsHaveReasonSet = (): Observable<boolean> => {
        return this.selectedDocumentsTabData$.pipe(
            map((rows) => {
                let dataRowsCount = 0;
                let invalidRowsCount = 0;
                let validRowsCount = 0;
                let withReasonSetCount = 0;
                rows.forEach((row) => {
                    if (isDocumentsTabDataRow(row)) {
                        dataRowsCount += 1;
                        if (!row.rowDataType) {
                            validRowsCount += 1;
                            withReasonSetCount = row.reason ? withReasonSetCount + 1 : withReasonSetCount;
                        }
                        else {
                            invalidRowsCount += 1;
                        }
                    }
                });
                if (!rows.length) {
                    return false;
                }
                if (dataRowsCount === invalidRowsCount) {
                    return false;
                }
                return withReasonSetCount === validRowsCount;
            })
        );
    }

    private getIsDocumentsTabChanged = (): Observable<boolean> => {
        return this.selectedDocumentsTabData$.pipe(
            map((rows) => rows.some((row) => {
                if (isDocumentsTabDataRow(row)) {
                    return !!row.changed;
                }
                return false;
            }))
        );
    }

    private getIsSignersTabSignByDateValid = (): Observable<boolean> => {
        return this.signersTabData$.pipe(
            map((rows) => rows.every((row) => {
                if (row.signByDate) {
                    return !isDateInThePast(row.signByDate);
                }
                return true;
            }))
        );
    }

    private getIsPendingTabSignByDateValid = (): Observable<boolean> => {
        return this.pendingTabData$.pipe(
            map((rows) => rows.every((row) => {
                if (isPendingTabDataRow(row) && row.pendingSignatureRequest.isChanged && row.pendingSignatureRequest.signByDate) {
                    return !isDateInThePast(row.pendingSignatureRequest.signByDate);
                }
                return true;
            }))
        );
    }

    private getIsPendingTabChanged = (): Observable<boolean> => {
        return this.pendingTabData$.pipe(
            map((rows) => rows.some((row) => {
                if (isPendingTabDataRow(row)) {
                    return row.pendingSignatureRequest.isChanged
                        || row.pendingSignatureRequest.isMarkedForCancellation;
                }
                return false;
            }))
        );
    }

    get isRefreshingData(): boolean {
        return this.loadingUserPermissions
            || this.loadingPendingRequests
            || this.loadingNoSigningPermissions;
    }

    get isProcessing(): boolean {
        return this.isSubmitting || this.isRefreshingData;
    }

    onSelectTab(tab: Tabs): void {
        const timer = setTimeout(() => {
            this.currentTab = tab;
            clearTimeout(timer);
        }, 0);
    }

    dismissRequestorPermissionWarning = (): void => {
        this.showRequestorPermissionsWarning = false;
    }

    dismissModal(): void {
        if (!this.isProcessing) {
            this.dismiss.emit();
        }
    }

    private handleApiError = (): Observable<[]> => {
        this.SignatureRequestsStore.flushData();
        this.dismiss.emit();
        return of(null);
    }

    private getUserPermissionsForDocument = (document: Document): Observable<{
        permissions: { [key: string]: boolean };
        document: Document;
    }> => {
        return from(this.Teams.getUserObjectPermissions(document.id.toString(), 'documents'))
            .pipe(
                map((permissions) => ({ permissions, document }))
            );
    }

    openSelectDocuments(): void {
        this.selectDocumentsModalRef = this.Modals.show(SelectDocumentsComponent, {
            initialState: {
                initiallySelectedDocuments: [
                    ...this.SignatureRequestsStore.getCurrentlySelectedDocuments()
                ]
            },
            class: 'select-documents-modal'
        });

        this.selectDocumentsModalRef.content.save.pipe(
            take(1),
            tap(({ removedIds }) => {
                if (!removedIds.length) {
                    return;
                }
                this.SignatureRequestsStore.removeSelectedDocuments(removedIds);
            }),
            switchMap(({ added }) => {
                if (!added || !added.length) {
                    return of([]);
                }
                this.loadingUserPermissions = true;
                return forkJoin(added.map(this.getUserPermissionsForDocument));
            }),
            map((results) => {
                this.loadingUserPermissions = false;
                if (!results.length) {
                    this.selectDocumentsModalRef.hide();
                    return [];
                }

                const documentsToAddToSelection: Document[] = [];
                results.forEach(({ permissions, document }) => {
                    if (permissions.requestSignature) {
                        documentsToAddToSelection.push(document);
                    }
                });
                this.showRequestorPermissionsWarning = results.length > documentsToAddToSelection.length;
                return documentsToAddToSelection;
            }),
            switchMap((documents) => this.onAddDocuments(documents)),
            catchError(this.handleApiError)
        ).subscribe(() => {
            this.selectDocumentsModalRef.hide();
        });
    }

    onAddDocuments(addedDocuments: Document[]): Observable<void> {
        if (!(addedDocuments && addedDocuments.length)) {
            return of(null);
        }
        const signers = this.SignatureRequestsStore.getCurrentlySelectedSigners();
        const existingDocuments = this.SignatureRequestsStore.getCurrentlySelectedDocuments();
        return forkJoin([
            this.loadPendingSignatureRequests(addedDocuments),
            this.loadPotentialSigners({ documents: addedDocuments.concat(existingDocuments) })
        ]).pipe(
            switchMap(([pendingRequests]) => {
                let noSigningPermissionsData$: Observable<GetNoSignPermissionsResponse> = of([]);
                if (signers && signers.length) {
                    noSigningPermissionsData$ = this.loadNoSigningPermissionsData(
                        this.currentTeam.id,
                        addedDocuments,
                        signers
                    );
                }
                return forkJoin([
                    of(pendingRequests),
                    noSigningPermissionsData$
                ]);
            }),
            map(([pendingRequests, permsData]) => {
                this.SignatureRequestsStore.addDocumentsData(
                    addedDocuments,
                    pendingRequests,
                    permsData
                );
            })
        );
    }

    onRemoveDocuments(ids: string[]): void {
        const documents = this.SignatureRequestsStore.getCurrentlySelectedDocuments()
            .filter((d) => !ids.includes(d.id as string));
        this.loadPotentialSigners({ documents }).pipe(
            take(1),
            catchError(this.handleApiError)
        ).subscribe();
        this.SignatureRequestsStore.removeSelectedDocuments(ids);
    }

    onSelectSignatureTypes({ ids, type }): void {
        this.SignatureRequestsStore.updateDocumentsData({
            method: type
        }, ids);
    }

    onSelectReasons({ ids, reason }): void {
        this.SignatureRequestsStore.updateDocumentsData({
            reason
        }, ids);
    }

    openNoSigningPermissions(): void {
        this.Modals.show(NoSigningPermissionsComponent, {
            initialState: {
                data: this.SignatureRequestsStore.getNoSigningPermissionModalData()
            },
            class: 'no-signing-permissions'
        });
    }

    loadPotentialSigners(params: { filter?: string; documents?: Document[] }): Observable<User[]> {
        this.loadingPotentialSigners = true;

        let documentsCollection = params.documents;
        if (!(documentsCollection && documentsCollection.length)) {
            documentsCollection = this.SignatureRequestsStore.getCurrentlySelectedDocuments();
        }
        const documentIds = documentsCollection.map((d) => d.id as string);

        return this.DocumentService.getPotentialSigners({
            teamId: this.currentTeam.id,
            filter: params.filter,
            documentIds
        }).pipe(
            tap((data: User[]) => {
                this.potentialSigners = data;
                this.loadingPotentialSigners = false;
            })
        );
    }

    loadPendingSignatureRequests(documents: Document[]):Observable<GetDocumentsPendingSignatureRequestsResponse> {
        this.loadingPendingRequests = true;
        const documentIds = documents.map((d) => d.id as string);
        return this.DocumentService.getDocumentsPendingSignatureRequests(documentIds)
            .pipe(tap(() => {
                this.loadingPendingRequests = false;
            }, this.handleApiError));
    }

    loadNoSigningPermissionsData(
        teamId: string,
        documents: Document[],
        signers: User[]
    ): Observable<GetNoSignPermissionsResponse> {
        this.loadingNoSigningPermissions = true;
        const documentIds = documents.map((d) => d.id as string);
        const userIds = signers.map((d) => d.id);
        return this.DocumentService.getNoSignPermissions({
            teamId,
            documentIds,
            userIds
        }).pipe(
            map((data: GetNoSignPermissionsResponse) => {
                this.loadingNoSigningPermissions = false;
                return data.filter((d) => d.usersWithoutSignPermissions.length);
            })
        );
    }

    onSignersFilterChange(filterValue: string): void {
        this.loadPotentialSigners({ filter: filterValue }).pipe(
            take(1),
            catchError(this.handleApiError)
        ).subscribe();
    }

    onSelectSigners($event: FilteredSelectEvent<User>): void {
        const { added, removedIds } = $event;
        const documents = this.SignatureRequestsStore.getCurrentlySelectedDocuments();

        let updatedSelectedSigners = this.SignatureRequestsStore.getCurrentlySelectedSigners();
        let permissionData$: Observable<GetNoSignPermissionsResponse>;
        if (removedIds && removedIds.length) {
            updatedSelectedSigners = updatedSelectedSigners.filter((s) => !removedIds.includes(s.id));
            const permissionData = this.SignatureRequestsStore.getNoSigningPermissionData()
                .map((dataItem) => {
                    const filteredSignerIds = dataItem.usersWithoutSignPermissions.filter((uid) => !removedIds.includes(uid));
                    return {
                        ...dataItem,
                        usersWithoutSignPermissions: filteredSignerIds
                    };
                })
                .filter((dataItem) => !!dataItem.usersWithoutSignPermissions.length);
            permissionData$ = of(permissionData);
        }
        if (added && added.length) {
            updatedSelectedSigners = updatedSelectedSigners.concat(added);
            permissionData$ = documents.length
                ? this.loadNoSigningPermissionsData(this.currentTeam.id, documents, updatedSelectedSigners)
                : of([]);
        }
        permissionData$.pipe(
            take(1)
        ).subscribe((data) => {
            this.SignatureRequestsStore.selectSigners(updatedSelectedSigners, $event, data);
        },
        () => {
            this.SignatureRequestsStore.flushData();
            this.dismiss.emit();
            return of(null);
        });
    }

    onRemoveSigners(removedIds: string[]): void {
        this.onSelectSigners({ added: [], removedIds });
    }

    onChangeSignByDate({ value, ids }): void {
        this.SignatureRequestsStore.updateSignersData({ signByDate: value }, ids);
    }

    onChangeNotifyMe({ value, ids }): void {
        this.SignatureRequestsStore.updateSignersData({ notifyMe: value }, ids);
    }

    onChangeEmailSigner({ value, ids }): void {
        this.SignatureRequestsStore.updateSignersData({ emailSigner: value }, ids);
    }

    onPendingCancelRequests({ ids }: { ids: string[] }): void {
        this.SignatureRequestsStore.updatePendingTabData({ isMarkedForCancellation: true }, ids);
    }

    onPendingUndoChanges({ ids }: { ids: string[] }): void {
        this.SignatureRequestsStore.undoPendingTabDataChanges(ids);
    }

    onPendingChangesSignByDate({ date, id }: { date: Date; id: string }): void {
        this.SignatureRequestsStore.updatePendingTabData({ signByDate: date }, [id]);
    }

    onPendingChangesNotifyRequestor({ notify, id }: { notify: boolean; id: string }): void {
        this.SignatureRequestsStore.updatePendingTabData({ notifyRequestorWhenSigned: notify }, [id]);
    }

    onPendingChangesRemindSigner({ remind, id }: { remind: boolean; id: string }): void {
        this.SignatureRequestsStore.updatePendingTabData({ remindSigner: remind }, [id]);
    }

    onPendingChangesReason({ reason, id }: { reason: SigningReasons; id: string }): void {
        this.SignatureRequestsStore.updatePendingTabData({ reason }, [id]);
    }

    onPendingChangesSignatureType({ type, id }: { type: SignatureTypes; id: string }): void {
        this.SignatureRequestsStore.updatePendingTabData({ method: type }, [id]);
    }

    onSubmit(): void {
        const payload = this.SignatureRequestsStore.getBulkSignatureRequestPayload();
        if (!payload) {
            this.dismissModal();
            return;
        }
        this.isSubmitting = true;
        payload.comment = this.comment;
        this.DocumentService.signatureRequestsBulkUpdate(payload)
            .pipe(
                take(1),
                tap(() => {
                    this.isSubmitting = false;
                    this.$state.go(this.$state.current, {}, { reload: true });
                    this.dismissModal();
                }),
                catchError(this.handleApiError)
            ).subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.SignatureRequestsStore.flushData();
    }
}
