import * as angular from 'angular';
import annotationComponent from './annotation.component';
import highlight from '../highlight';

const dependencies = [
    highlight.name
];

const annotation = angular.module('app.shared.documents.documentContentEditor.documentViewer.submodules.annotations.annotation', dependencies)
    .component('annotation', annotationComponent);

export default annotation;
