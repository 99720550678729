import * as _ from 'lodash';

class PrefillTextPickerController {

    constructor() {

        this.prefillOption = [
            { value: '', optionCssClass: 'fa-font' },
            { value: '\u2713', optionCssClass: 'fa-check' },
            { value: 'x', optionCssClass: 'fa-times' }
        ];
    }

    getActiveLabel(value) {

        const currentOption = _.find(this.prefillOption, ['value', value]);
        if (currentOption) {
            return currentOption.activeLabel;
        }
        return '/';
    }

    setPrefill(value) {

        this.value.textValue = value;
        this.onChange({ value: { textValue: value } });
    }
}

PrefillTextPickerController.$inject = [];

export default PrefillTextPickerController;
