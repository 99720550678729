import './timeline-metrics.scss';
import template from './timeline-metrics.html';
import controller from './timeline-metrics.controller';

const timelineMetricsComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        timeline: '='
    }
};

export default timelineMetricsComponent;
