import * as _ from 'lodash';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { RequestPreviewRoleTemplatesComponent } from '../../components/request-preview-role-templates/request-preview-role-templates.component';

class ManageRolesTemplatesController {
    constructor(Team, CurrentSession, $interval, $scope, modalHelper, PaginationProperties, private Modals: ModalsService) {

        this.MANAGE_OPTIONS = {
            byStudyNumber: 'Roles by Study Number',
            byTemplates: 'Audit Roles',
            assignUsers: 'Assign Users to Roles'
        };
        this._Team = Team;
        this._CurrentSession = CurrentSession;
        this._$interval = $interval;
        this._$scope = $scope;
        this._modalHelper = modalHelper;

        this.manageType = this.MANAGE_OPTIONS.byStudyNumber;
        this.loadingResults = false;
        this.showingAuditDetails = false;

        this.pagination = {
            pageNum: 1,
            pageSize: 20
        };
        this.maxSize = PaginationProperties.getMaxSize();
        this.showBoundaryLinkNumbers = PaginationProperties.showBoundaryLinkNumbers();

        this.STATES = {
            pending: {
                id: 'pending',
                text: 'Pending'
            },
            inProgress: {
                id: 'inProgress',
                text: 'In progress'
            },
            completed: {
                id: 'completed',
                text: 'Complete'
            },
            failed: {
                id: 'failed',
                text: 'Failed'
            }
        };
    }

    $onInit(): void {
        this.currentTeam = this._CurrentSession.getCurrentTeam();
        this.currentUser = this._CurrentSession.getCurrentUser();
        this._getAudits(this.currentUser.id);

        this._$scope.$on('$destroy', () => {
            this._$interval.cancel(this.timer);
        });
    }

    setManageType(type) {
        this.manageType = type;
        this.auditResults = null;
        this.pagination.pageNum = 1;

        if (!this.isManageType(this.MANAGE_OPTIONS.assignUsers) && this.previewInfoData) {
            this._getRolesTemplatesAuditResults();
        }
    }

    isManageType(type) {
        return this.manageType === type;
    }

    alreadyAssignedEmails(audit) {
        const role = _.find(this.submitedParams.roles, { roleName: audit.roleName });
        if (audit.usersAssignments && audit.usersAssignments.length && audit.usersAssignments.length !== role.userEmails.length) {
            return role.userEmails.length - audit.usersAssignments.length;
        }
        return 0;
    }

    preview(auditParams) {
        const action = 'preview';

        if (this.isManageType(this.MANAGE_OPTIONS.assignUsers)) {
            const assignUsersToRolesParams = {
                action,
                ...auditParams
            };

            this._previewAssignUsersToRoles(assignUsersToRolesParams);
        }
        else {
            this._createAudit(action, auditParams);
        }
    }

    create(params) {
        const action = 'create';

        if (this.isManageType(this.MANAGE_OPTIONS.assignUsers)) {
            const assignUsersToRolesParams = {
                action,
                ...params
            };

            this._createAssignUsersToRoles(assignUsersToRolesParams);
        }
        else {
            this._createAudit(action, this.previewInfoData);
        }
    }

    _createAudit(type, params) {

        const previewParams = {
            ...params,
            action: type,
            requestedOn: new Date(),
            userId: this.currentUser.id
        };

        this.resultType = type;
        this.submitedParams = { ...previewParams };

        if (type === 'preview' && this.previewInfoData) {

            const previewModal = this.Modals.show(RequestPreviewRoleTemplatesComponent, {
                class: 'modal-lg',
                animated: true,
                initialState: {}
            });

            previewModal.content.onPreviewEvent.subscribe(() => {
                this._createRolesTemplatesAudit(previewParams);
                previewModal.hide();
            });
            return;
        }

        this._createRolesTemplatesAudit(previewParams);
    }

    _createRolesTemplatesAudit(params) {
        this._Team.createRolesTemplatesAudit(params)
            .toPromise()
            .then(() => {
                this.previewInfoDataError = null;
            })
            .catch(({ error }) => {
                this.previewInfoData = null;
                this.previewInfoDataError = _.cloneDeep(error);
            });
    }

    _previewAssignUsersToRoles(params) {
        this.resultType = params.action;
        this.pagination.pageNum = 1;

        const previewParams = {
            ...params,
            userId: this.currentUser.id
        };
        this.submitedParams = { ...previewParams };

        this._Team.rolesAndUsersCreate(previewParams)
            .toPromise()
            .then((data) => {
                this.auditResults = _.cloneDeep(data.roles || data.rolesTemplatesAuditResults);
                this.auditError = null;
            })
            .catch(({ data }) => {
                this.auditResults = null;
                this.auditError = _.cloneDeep(data);
            });
    }

    _createAssignUsersToRoles(params) {
        this.resultType = params.action;
        this.pagination.pageNum = 1;

        const createParams = {
            ...params,
            userId: this.currentUser.id
        };

        this._Team.rolesAndUsersCreate(createParams)
            .toPromise()
            .then((data) => {
                this.auditResults = _.cloneDeep(data.roles || data.rolesTemplatesAuditResults);
                this.auditError = null;
            })
            .catch(({ data }) => {
                this.auditResults = null;
                this.auditError = _.cloneDeep(data);
            });
    }

    _getAudits(userId) {
        this._getRolesTemplatesAudit(userId);
        this.timer = this._$interval(this._getRolesTemplatesAudit.bind(this, userId), 5000);
    }

    _getRolesTemplatesAudit(userId) {
        this._Team.getRolesTemplatesAudits(userId)
            .toPromise()
            .then((data) => {
                if (!this.previewInfoData
                    || this.previewInfoData.id !== data.rolesTemplatesAudits[0].id
                    || this.previewInfoData.status !== data.rolesTemplatesAudits[0].status) {

                    [this.previewInfoData] = data.rolesTemplatesAudits;
                    this.previewInfoDataError = null;

                    this._getRolesTemplatesAuditResults();
                }
            })
            .catch((data) => {
                this.previewInfoData = null;
                this.previewInfoDataError = _.cloneDeep(data);
            });
    }

    _getRolesTemplatesAuditResults() {
        this._Team.getRolesTemplatesAuditsResults(this.previewInfoData)
            .toPromise()
            .then((resultsData) => {
                this.resultType = this.previewInfoData.action;
                this.pagination.pageNum = 1;
                this.auditResults = resultsData.rolesTemplatesAuditResults;
                this.auditError = null;
            })
            .catch((resultsData) => {
                this.auditResults = null;
                this.auditError = _.cloneDeep(resultsData);
            });
    }

    clear() {
        this.auditResults = null;
        this.submitedParams = null;
        this.pagination.pageNum = 1;
    }

    noEntitiesMissing(entries) {
        return !_.find(entries, ['missing', true]);
    }

    showAuditForm() {
        this.showingAuditDetails = false;
    }

    showPracticeNames() {

        const { practiceNames } = this.previewInfoData.filters;

        if (practiceNames.length === 1) {
            return `${practiceNames[0]}`;
        }
        if (practiceNames.length > 1) {

            const listPracticeNames = practiceNames.map((name) => ` ${name}`);
            this.practiceNamesPretty = `${listPracticeNames}`;

            return `${practiceNames[0]}, and ${practiceNames.length - 1} others`;
        }
        return 'No practice name specified';
    }

    showAuditDetails() {
        this.showingAuditDetails = true;
    }

    capitalizeText(text) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    switchColumnNames() {
        if (this.previewInfoData.filters.rolesTemplateName) {
            return 'Role Template Name';
        }
        return 'Study Number';
    }

}

ManageRolesTemplatesController.$inject = [
    'Team',
    'CurrentSession',
    '$interval',
    '$scope',
    'modalHelper',
    'PaginationProperties',
    'ModalsService'
];

export default ManageRolesTemplatesController;
