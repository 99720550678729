import uuid from 'uuid';
import { Transition } from '@uirouter/angularjs';

class CreateTimelineModalController {
    constructor($scope, $transitions: Transition, REGEX, MESSAGES) {
        this.namePattern = REGEX.names;
        this.nameMessage = MESSAGES.validNameMessage;
        $scope.$on('modal.closing', this._preventClosingIfProcessing.bind(this));

        const deregister = $transitions.onExit({}, () => {
            this.cancel();
            deregister();
        });
    }

    $onInit(): void {
        this.isProcessing = false;
        this.onCreate = this.resolve.onCreate;
        this.project = this.resolve.project;
        this.timeline = { projectId: this.project.id, name: '' };
        this.id = uuid.v4();
    }

    _preventClosingIfProcessing(event, reason, closed) {
        const notAllowedToClose = reason && reason !== 'cancel' && !closed;

        if (this.isProcessing && notAllowedToClose) {
            event.preventDefault();
        }
    }

    cancel(): void {
        this.modalInstance.dismiss('cancel');
    }

    create() {
        this.isProcessing = true;
        this.onCreate(this.timeline)
            .then(this.cancel.bind(this))
            .catch(() => {
                this.isProcessing = false;
            });
    }
}

CreateTimelineModalController.$inject = ['$scope', '$transitions', 'REGEX', 'MESSAGES'];

export default CreateTimelineModalController;
