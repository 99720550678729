import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as Moment from 'moment-timezone';

import {
    MonitorReview,
    Annotation,
    Document,
    DocumentSubTypes,
    SendToSipSiteInput,
    StudyWithTeamSipIntegrationLink,
    Team,
    User,
    SignatureTypes,
    AppConfig
} from '@app/shared/models';
import { StudiesService } from '@app/shared/studies/studies.service';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { ALLOWED_PREVIEW_FILE_TYPES } from '@florencehealthcare/florence-constants/lib/documents';
import {
    FormSaveEvent, AnnotationSaveEvent, ClearAllEvent, Config
} from '@florencehealthcare/doc-viewer';
import { qcReviewStatuses } from '@florencehealthcare/florence-constants/lib/qc-reviews';
import { SimpleChanges } from '@angular/core';
import ToolMode from './constants/tool.mode';
import Actions from './constants/document.content.actions';
import signatureData from './_userSignature';
import { QcAcceptEvent, QcDeclineEvent } from '../components/log-toolbar/log-toolbar.component.types';

class DocumentEditController {
    // Component Bindings
    doc: Document;
    appConfig: AppConfig;
    documentUrl: string;
    token: string;
    currentDisplayVersion: number;
    hasSignatureRequest: boolean;
    signatureRequestType: SignatureTypes;
    requestIsPastDue: boolean;
    teamSignatureRestricted: boolean;
    monitorReviewsEnabled: boolean;
    monitorReviews: MonitorReview[];
    fhcDocViewerAnnotations: Annotation[];
    currentTeam: Team;
    currentUser: User;
    newDocViewerFeatureEnabled = true;
    allowFilePreview = true;
    currentTextAnnotationValue = '';
    replaceDocument: () => void;
    openTask: () => void;
    onMonitorReviewsUpdate: () => void;
    onRequestDownload: () => void;
    onSign: ({ decline }: { decline?: boolean }) => void;
    // onReject TODO
    onSetVersion: () => void;
    onSave: ({ data }) => ng.IPromise<void>;
    onQcAcceptDocument: ({ event }) => void;
    onQcDeclineDocument: ({ event }) => void;
    onApproveQcReview: ({ event }) => void;
    onRejectQcReview: ({ event }) => void;
    onClearAll: ({ event }) => void;
    onSaveAnnotationChanges: ({ event }) => void;
    onFormSave: ({ event }) => void;

    // DocumentEditController Bindings
    ToolMode = ToolMode;
    Modes = {
        NOT_VIEWABLE: 'notViewable',
        VIEW_ONLY: 'readOnly',
        FORM_EDIT: 'formEdit',
        ANNOTATION_EDIT: 'annotationEdit'
    };

    showQcAcceptButton = false;
    showQcDeclineButton = false;
    showQcReviewButton = false;
    qcReviewModal = false;
    qcReviewAction;
    isProcessingAccept = false;
    isProcessingDecline = false;
    review = null;
    comment = '';
    isProcessing: boolean;
    reviewToConduct;
    mode;
    pageLoadError = '';
    showMonitorDropdown: boolean;
    showManageDropdown: boolean;
    showSendDropdown: boolean;
    signatureData = signatureData;
    latestVersion;
    annotations: Annotation[];
    selectedTool;
    isRejected: boolean;
    isCancelled: boolean;
    showWarnings: boolean;
    isStudyLoading = true;
    Studies: StudiesService;
    sitesWithSipLink: SendToSipSiteInput[];

    jobTitleRequired = false;
    saveBtnClickable = true;

    docViewerToolsConfig: Config;

    constructor(
        $scope,
        $state,
        DocumentAnnotationAdapter,
        DocumentAnnotation,
        DocumentFormField,
        DocumentPageDisplay,
        DocumentViewerStore,
        Studies: StudiesService,
        $timeout,
        private FeatureFlags: FeatureFlagService

    ) {
        this._$scope = $scope;
        this._$state = $state;
        this._$timeout = $timeout;
        this._DocumentAnnotationAdapter = DocumentAnnotationAdapter;
        this._DocumentAnnotation = DocumentAnnotation;
        this._DocumentFormField = DocumentFormField;
        this._DocumentPageDisplay = DocumentPageDisplay;
        this._DocumentViewerStore = DocumentViewerStore;
        this.Studies = Studies;

        this.destroy$ = new Subject();
    }

    $onInit(): void {
        this.mode = this._getEditorMode(this.doc);

        this.newDocViewerFeatureEnabled = !!this.currentTeam.settings.features.newDocumentViewer
            && !this.doc.shouldBeDisplayedInODV;
        if (this.newDocViewerFeatureEnabled) {
            this.allowFilePreview = this.isFileTypePreviewAllowed();
            this.setNewDocViewerConfig();
        }

        const onPageLoadError = this._onPageLoadError.bind(this);
        this._$scope.$on('pageLoadError', onPageLoadError);

        this.latestVersion = Math.max(...this.doc.versions.map((v) => v.number));

        this._DocumentAnnotation.initialize(this.doc);
        this.annotations = this._DocumentAnnotation.getAnnotations();
        this._DocumentFormField.initialize(this.doc);
        this._DocumentPageDisplay.initialize(this.doc);
        this._setupListeners();

        this.calculateShowManageDropdown();

        this._DocumentViewerStore.selectedTool$
            .pipe(takeUntil(this.destroy$))
            .subscribe((tool) => {
                this.selectedTool = tool;
            });
        this.getSitesWithSipLink();

        this.review = this.doc.qcReview;
        this.qcButtonAcceptVisible();
        this.qcButtonDeclineVisible();
        this.qcButtonReviewVisible();
    }

    setNewDocViewerConfig(): void {
        this.docViewerToolsConfig = {
            annotationsFeatureConfig: {
                highlight: this.canHighlight(),
                timestamp: this.canTimestamp(),
                freeTextTool: this.canAddText()
            },
            signaturesFeatureConfig: {
                enabled: this.canSign(),
                stampSignatureText: this.formatStampSignature(),
                addendum: this.canSignAddendum(),
                stamp: this.canSignStamp()
            },
            initialDocument: {
                isPDFForm: this.doc.formStatus === 'form-in-progress',
                url: this.documentUrl,
                filename: this.doc.name + this.doc.ext,
                locked: this.doc.isLocked,
                hasPendingSignatureRequest: this.hasSignatureRequest,
                hasPendingSignatureExpired: this.hasSignatureRequest && this.requestIsPastDue
            },
            pendo: {
                apiKey: this.appConfig.pendoApiKey,
                initData: {
                    visitor: {
                        id: this.currentUser.id
                    },
                    account: {
                        id: this.currentTeam.id,
                        teamName: this.currentTeam.name,
                        environment: this.appConfig.environment
                    }
                }
            },
            redact: this.canRedact(),
            disableSignatureFieldsReadOnly: this.currentTeam.settings.features.disableSignatureFieldsReadOnly,
            previewOnly: this.isCancelled || this.isRejected || this.doc.isLocked || this.doc.formStatus === 'checking-form',
            manipulatePages: false,
            timezone: Moment().tz(Moment.tz.guess()).format('z'),
            token: this.token,
            licenseKey: this.appConfig.docViewerLicenseKey,
            webviewerServerUrl: this.appConfig.webviewerServerUrl,
            disableVirtualDisplayMode: this.doc.formStatus === 'form-in-progress',
            webviewerServerRangeRequests: this.doc.formStatus !== 'form-in-progress'
        };
    }

    $onDestroy(): void {
        this._DocumentAnnotation.destroy();
        this._DocumentFormField.destroy();
        this._DocumentViewerStore.reset();

        this.destroy$.next();
        this.destroy$.complete();
    }

    $onChanges(changes: SimpleChanges): void {
        if (this.doc) {
            this.checkWarnings();
        }
        if (changes.doc?.currentValue.qcReview && !changes.doc.isFirstChange()) {
            this.qcButtonAcceptVisible();
            this.qcButtonDeclineVisible();
            this.qcButtonReviewVisible();
            this.review = this.doc.qcReview;
        }
        if (this.newDocViewerFeatureEnabled) {
            this.setNewDocViewerConfig();
        }
    }

    private canHighlight(): boolean {
        return this.doc.isLatestVersion
            && !this.doc?.isShortcut
            && this.doc?.permissions?.highlightDocument;
    }

    private canRedact(): boolean {
        return this.doc.isLatestVersion
            && !this.doc?.isShortcut
            && this.doc?.permissions?.redactDocument;
    }

    private canAddText(): boolean {
        return this.doc.isLatestVersion
            && !this.doc?.isShortcut
            && this.doc?.permissions?.addTextToDocument;
    }

    private canTimestamp(): boolean {
        return this.doc.isLatestVersion
            && !this.doc?.isShortcut
            && this.doc?.permissions?.timestampDocument;
    }

    private canSign(): boolean {
        return !this.isOldDocVersion()
            && this.currentTeam?.settings?.signatures
            && this.doc?.permissions?.signDocument;
    }

    private canSignSignatureRequestType(signatureRequestType: string): boolean {
        if (this.hasSignatureRequest) {
            return (
                this.signatureRequestType === SignatureTypes.any
                || this.signatureRequestType === signatureRequestType
            );
        }
        return true;
    }

    private canSignAddendum(): boolean {
        return this.canSign()
            && !this.currentTeam?.settings?.signatures?.disableAddendum
            && this.canSignSignatureRequestType(SignatureTypes.addendum)
            && !this.requestIsPastDue;
    }

    private canSignStamp(): boolean {
        return this.canSign()
            && !this.doc?.isShortcut
            && !this.currentTeam?.settings?.signatures?.disableAnnotation
            && this.canSignSignatureRequestType(SignatureTypes.stamp)
            && !this.requestIsPastDue;
    }

    private formatStampSignature(): string {
        let result = '[Electronic Signature]';

        if (
            this.currentUser.fullName
            && this.currentUser.fullName !== this.currentUser.email
        ) {
            result += `\n${this.currentUser.fullName} - ${this.currentUser.email}`;
        }
        else {
            result += `\n${this.currentUser.email}`;
        }

        result += '\n[Date and time - set on save]';
        result += '\n[Reason - set on save]';

        if (this.jobTitleRequired) {
            if (this.currentUser.profile.jobTitle) {
                result += `\nJob Title - ${this.currentUser.profile.jobTitle}`;
            }
            else {
                result += '\n[Job Title - set on save]';
            }
        }

        return result;
    }

    private getSitesWithSipLink(): void {
        const { teamId } = this.doc;
        const documentId = this.getDocumentId();

        this.Studies.getDocumentStudyProfile(teamId, documentId).subscribe(
            (studies) => {
                if (studies && studies.length) {
                    this.sitesWithSipLink = this.getSitesDataWithSipLink(studies);
                    this.isStudyLoading = false;
                    this.canBeExported();
                }
            }
        );
    }

    private getDocumentId(): string {
        return typeof this.doc.id !== 'string'
            ? this.doc.id.documentId
            : this.doc.id;
    }

    private getDocumentVersion(): number {
        return typeof this.doc.id !== 'string'
            ? this.doc.id.version
            : this.doc.version;
    }

    private getSitesDataWithSipLink(studies: StudyWithTeamSipIntegrationLink[]): SendToSipSiteInput[] {
        const sitesWithSipLink: SendToSipSiteInput[] = [];

        studies && studies.forEach((study) => {
            study.sites && study.sites.forEach((site) => {
                if (site.teamSipIntegrationLink) {
                    sitesWithSipLink.push({
                        studyId: study.id,
                        siteId: site.id,
                        uniqueProtocolId: study.uniqueProtocolId,
                        siteName: site.siteName
                    });
                }
            });
        });
        return sitesWithSipLink;
    }

    qcButtonAcceptVisible() {
        if (this.doc.qcReview && this.doc.permissions.conductQCReview) {

            const nextReviewer = this.doc.qcReview.reviews.findIndex(
                (element) => element.reviewerIds.id === this.currentUser.id
                    && element.status === qcReviewStatuses.PENDING_REVIEW
            );

            switch (nextReviewer) {
                case 0:
                    this.showQcAcceptButton = true;
                    break;
                case -1:
                    this.showQcAcceptButton = false;
                    break;
                default:
                    this.showQcAcceptButton = this.checkPreviousCycles(nextReviewer);
            }
        }
    }

    qcButtonDeclineVisible() {
        if (this.doc.qcReview && this.doc.permissions.conductQCReview) {

            const nextReviewer = this.doc.qcReview.reviews.findIndex(
                (element) => element.reviewerIds.id === this.currentUser.id
                    && (element.status === qcReviewStatuses.PENDING_REVIEW || element.status === qcReviewStatuses.IN_REVIEW)
            );

            switch (nextReviewer) {
                case 0:
                    this.showQcDeclineButton = true;
                    break;
                case -1:
                    this.showQcDeclineButton = false;
                    break;
                default:
                    this.showQcDeclineButton = this.checkPreviousCycles(nextReviewer);
            }
        }
    }

    qcButtonReviewVisible() {
        if (this.doc.qcReview && this.doc.permissions.conductQCReview) {

            const nextReviewer = this.doc.qcReview.reviews.findIndex(
                (element) => element.reviewerIds.id === this.currentUser.id
                    && (element.status === qcReviewStatuses.IN_REVIEW
                        || element.status === qcReviewStatuses.IN_REJECTION_REVIEW)
            );

            switch (nextReviewer) {
                case 0:
                    this.showQcReviewButton = true;
                    break;
                case -1:
                    this.showQcReviewButton = false;
                    break;
                default:
                    this.showQcReviewButton = this.checkPreviousCycles(nextReviewer);
            }

            if (nextReviewer >= 0) {
                this.reviewToConduct = this.doc.qcReview.reviews[nextReviewer];
                this.qcReviewAction = this.reviewToConduct.status === qcReviewStatuses.IN_REJECTION_REVIEW ? 'REVISIT QC REVIEW' : 'QC REVIEW';
            }
        }
    }

    checkPreviousCycles(currentCycle): boolean {
        for (let i = 0; i < currentCycle; i += 1) {
            if (this.doc.qcReview.reviews[i].status !== qcReviewStatuses.APPROVED) {
                return false;
            }
        }
        return true;
    }

    acceptQcReview() {
        this.isProcessingAccept = true;
        const event = {
            docId: this.doc.id as string,
            docVersion: this.doc.version,
            onSuccess: () => {
                this.isProcessingAccept = false;
            },
            onError: () => {
                this.isProcessingAccept = false;
            }
        };
        this.handleQcAcceptDocument(event);
    }

    declineQcReview() {
        const event = {
            docId: this.doc.id as string,
            docVersion: this.doc.version,
            onError: () => {
                this.isProcessingDecline = false;
            },
            onClose: () => {
                this.isProcessingDecline = false;
            }
        };

        this.isProcessingDecline = true;
        this.onQcDeclineDocument({ event });
    }

    handleSign(decline) {
        this.onSign({ decline });
    }

    handleAddendumSign() {
        this.onSign({});
    }

    handleDecline() {
        this.onSign({ decline: true });
    }

    handleQcAcceptDocument(event: QcAcceptEvent) {
        this.onQcAcceptDocument({ event });
    }

    handleQcDeclineDocument(event: QcDeclineEvent) {
        this.onQcDeclineDocument({ event });
    }

    handleApproveQcReview(event) {
        this.onApproveQcReview({ event });
    }

    handleRejectQcReview(event) {
        this.onRejectQcReview({ event });
    }

    handleClearAll(event: ClearAllEvent) {
        this.onClearAll({ event });
    }

    handleSaveAnnotationChanges(event: AnnotationSaveEvent) {
        this.onSaveAnnotationChanges({ event });
    }

    handleSaveForm(event: FormSaveEvent) {
        this.onFormSave({ event });
    }

    openCloseQcReview($event) {
        this.comment = $event ? $event.comment : this.comment;
        this.qcReviewModal = !this.qcReviewModal;
    }

    approveReview($event) {
        this.handleApproveQcReview($event);
    }

    rejectReview($event) {
        this.handleRejectQcReview($event);
    }

    _onPageLoadError(event, data) {
        this.pageLoadError = data.message;
        this.checkWarnings();
    }

    private isOldDocVersion() {
        return this.doc.isShortcut
            ? (!this.doc.isLocked || this.doc.shortcutOf.lockedToDocumentVersion !== this.currentDisplayVersion)
                && this.doc.originalDocument
                && !this.doc.originalDocument.isLatestVersion
            : !this.doc.isLatestVersion;
    }

    private checkWarnings(): void {
        const status = this.doc.documentProperties?.approval?.status;
        this.isRejected = status === 'rejected';
        this.isCancelled = status === 'cancelled';

        const isOldVersion = this.isOldDocVersion();

        this.showWarnings = this.isRejected
            || this.isCancelled
            || isOldVersion
            || !!this.pageLoadError;
        if (this.newDocViewerFeatureEnabled) {
            this.showWarnings = this.showWarnings || this.doc.formStatus === 'checking-form';
        }
    }

    calculateShowManageDropdown(): void {
        const { permissions, isLatestVersion, isDownloadable } = this.doc;
        const canAssignTags = permissions.assignTags;
        const canManageTasks = isLatestVersion && permissions.manageDocTasks;
        const canDestroyDoc = permissions.destroyDocument;
        const canDuplicate = isLatestVersion && permissions.duplicateDocument;
        const canMove = isLatestVersion && permissions.moveDocument;
        const canRequestSignatures = permissions.requestSignature;
        const canSign = permissions.signDocument;
        const hasQcReview = !!this.doc.qcReview && !this.doc.isLocked;
        const canRequestQCReview = (this.doc.permissions.documentCreateEditQCReview
            || this.doc.permissions.manageQCreview
            || this.doc.permissions.qcReviewAdministrator)
            && this.doc.qcReview === undefined
            && this.canQcReviewDocBySubtypeAndStatus()
            && (this.doc.documentProperties?.approval?.status !== 'rejected')
            && !this.doc.isLocked;

        this.showManageDropdown = canAssignTags
            || canManageTasks
            || canDestroyDoc
            || isDownloadable
            || canDuplicate
            || canMove
            || canRequestSignatures
            || canSign
            || hasQcReview
            || canRequestQCReview;
    }

    canQcReviewDocBySubtypeAndStatus() {

        switch (this.doc.subType) {
            case 'content':
                if (this.doc.conversionStatus === 'Complete' && (this.doc.formStatus === 'no-form' || this.doc.formStatus === 'form-finalized')) {
                    return true;
                }
                return false;
            case 'log':
                return true;
            case 'shortcut':
                return false;
            case 'placeholder':
                return false;
            default:
                return false;
        }

    }

    canAddendumSign(): boolean {
        return !_.get(this._currentTeam, 'settings.signatures.disableAddendum', false);
    }

    canBeExported(): void {
        this.showSendDropdown = this.doc
            && this.doc.subType === DocumentSubTypes.content
            && this.doc.permissions.sendDocSIP
            && this.doc.hasPii === false
            && !!this.sitesWithSipLink.length;
    }

    onReject() {
        this.reloadDocument();
    }

    setToLatestVersion() {
        this.setToVersion(this.latestVersion);
    }

    setToVersion(version) {
        this.onSetVersion({ version });
    }

    _resetDocState() {
        this._DocumentAnnotation.reset(this.doc);
        this._DocumentFormField.reset(this.doc);
        this._DocumentViewerStore.reset();
        this.notification = null;
    }

    _setupListeners() {
        this._$scope.$on(Actions.ANNOTATION_CREATE, this._onAnnotationCreate.bind(this));
        this._$scope.$on(Actions.ANNOTATION_DELETE, this._onAnnotationDelete.bind(this));
        this._$scope.$on(Actions.ANNOTATION_UPDATE_PROPERTIES, this.onAnnotationPropertiesUpdate.bind(this));
        this._$scope.$on(Actions.TEXT_ANNOTATION_UPDATE_VALUE, this.emitCurrentTextAnnotationValue.bind(this));
    }

    emitCurrentTextAnnotationValue($event, { textAnnotationValue }) {
        const { textValue } = textAnnotationValue;
        this.currentTextAnnotationValue = textValue;
    }

    _onAnnotationCreate($event, { annotation, formField }) {
        if (formField) {
            this._DocumentFormField.assignAnnotationToFormField(formField, annotation);
        }
        else {
            this._DocumentAnnotation.createAnnotation(annotation);
        }

        this._DocumentViewerStore.setSelectedObject({ annotation, page: annotation.page, formField });
    }

    _onAnnotationDelete($event, data) {
        const { annotation, page } = data;
        this._DocumentAnnotation.deleteAnnotation(annotation, page);

        this._DocumentViewerStore.setSelectedObject(undefined);
    }


    onAnnotationPropertiesUpdate($event, { annotation, page, properties }) {
        this._DocumentAnnotation.updateAnnotationProperties(annotation, page, properties);
    }

    /**
     * Formats and returns annotations for persistence to the backend
     */
    getAnnotationsForApi() {
        const clientAnnotations = this._DocumentAnnotation.getAnnotations();
        const annotations = this._DocumentAnnotationAdapter.adaptManyForApi(clientAnnotations, this.doc.pages.length);
        return annotations;
    }

    getFormFieldsForApi() {
        return this._DocumentFormField.getUpdatedFormFieldsForApi();
    }

    reset() {
        // Clear annotation data
        this._$state.reload();
    }

    handleOnSave() {
        if (!this.saveBtnClickable) {
            return;
        }

        this.saveBtnClickable = false;
        this._DocumentViewerStore.setSelectedObject(undefined);

        this._$timeout(() => {
            const data = {};
            if (this.mode === this.Modes.ANNOTATION_EDIT) {
                data.annotations = this.getAnnotationsForApi();
            }
            else {
                data.formFields = this.getFormFieldsForApi();
            }

            this.onSave({ data }).then(this._resetDocState.bind(this));

            // this must be outside of the promise
            // because if it is inside if we close the modal on X or CLOSE
            // SAVE button on document will remain disabled
            this.saveBtnClickable = true;
        });
    }

    shouldShowSaveAndCancel() {
        return (this.mode === this.Modes.FORM_EDIT
            || (this.mode === this.Modes.ANNOTATION_EDIT
                && this.selectedTool !== this.ToolMode.NONE && this.annotations.length))
            && !(this.isCancelled || this.isRejected)
            && !this.doc.isLocked
            && !this.newDocViewerFeatureEnabled;
    }

    _getEditorMode(doc) {
        const isLatestVersion = doc.versions[doc.versions.length - 1].number === doc.version;
        const isContentDoc = doc.subType === 'content';
        const isCheckingForm = doc.formStatus === 'checking-form';
        const hasPages = doc.pageCount > 0;
        const rejectOrCancelStatus = (doc.documentProperties
            && doc.documentProperties.approval
            && doc.documentProperties.approval.status === 'rejected')
            || (doc.documentProperties
                && doc.documentProperties.approval
                && doc.documentProperties.approval.status === 'cancelled');
        const hasFormFields = doc.formFields && doc.formFields.length > 0;

        if (!hasPages || isCheckingForm) {
            return this.Modes.NOT_VIEWABLE;
        }
        if (!isLatestVersion || !isContentDoc || rejectOrCancelStatus) {
            return this.Modes.VIEW_ONLY;
        }
        if (hasFormFields) {
            return this.Modes.FORM_EDIT;
        }
        return this.Modes.ANNOTATION_EDIT;
    }

    isDocumentWithoutPreview(): boolean {
        return (this.mode === this.Modes.NOT_VIEWABLE && !this.newDocViewerFeatureEnabled)
            || (this.newDocViewerFeatureEnabled && !this.allowFilePreview);
    }

    private isFileTypePreviewAllowed(): boolean {
        return ALLOWED_PREVIEW_FILE_TYPES.includes(this.doc.ext?.toLowerCase());
    }
}

DocumentEditController.$inject = [
    '$scope',
    '$state',
    'DocumentAnnotationAdapter',
    'DocumentAnnotation',
    'DocumentFormField',
    'DocumentPageDisplay',
    'DocumentViewerStore',
    'Studies',
    '$timeout',
    'FeatureFlagService'
];

export default DocumentEditController;
