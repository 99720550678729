import controller from './label-create.controller';
import template from './label-create.html';

const labelCreateComponent: ng.IComponentOptions = {
    controller,
    controllerAs: 'vm',
    template,
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default labelCreateComponent;
