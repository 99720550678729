import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    Annotation,
    AnnotationType,
    Page
} from '@app/shared/models';

class AnnotationController {
    interactive: boolean;
    data: Annotation;
    page: Page;
    isSelected: boolean;

    constructor(DocumentViewerStore) {
        this.AnnotationType = AnnotationType;
        this._DocumentViewerStore = DocumentViewerStore;

        this.destroy$ = new Subject();
    }

    $onInit(): void {
        this._DocumentViewerStore.selectedObject$
            .pipe(takeUntil(this.destroy$))
            .subscribe((obj) => {
                this.isSelected = obj && obj.annotation.id === this.data.id;
            });
    }

    $onDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onSelect($event): void {
        this._DocumentViewerStore.setSelectedObject({
            annotation: $event.annotation,
            page: $event.page
        });
    }
}

AnnotationController.$inject = [
    'DocumentViewerStore'
];

export default AnnotationController;
