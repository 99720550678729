import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityModule } from '@app/widgets/entity/entity.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ObjectPickerComponent } from './components/object-picker/object-picker.component';
import { ChooseBindersFoldersComponent } from './components/choose-binders-folders/choose-binders-folders.component';
import { SharedModule } from '../../shared/shared.module';
import { ReportService } from './report.service';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        TooltipModule.forRoot(),
        CommonModule,
        WidgetsModule,
        EntityModule,
        SharedModule
    ],
    providers: [
        ReportService
    ],
    declarations: [
        ObjectPickerComponent,
        ChooseBindersFoldersComponent
    ],
    entryComponents: [
        ObjectPickerComponent,
        ChooseBindersFoldersComponent
    ],
    exports: []
})
export class ReportsModule {}
