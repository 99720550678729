import * as _ from 'lodash';
import * as Sentry from '@sentry/browser';
import {
    BehaviorSubject, Observable, Subject, interval, merge, of
} from 'rxjs';
import { Transition, StateService } from '@uirouter/angularjs';
import {
    Team,
    Crumb,
    Document,
    DocumentId,
    MonitorReview,
    Study,
    User,
    SignatureRequest,
    AuditTrailSubject,
    LogEntry,
    DocumentPathArray,
    LogDetail,
    Annotation,
    Task,
    AppConfig as AppConfigValue
} from '@app/shared/models';
import { GetJobTitleRequiredResponse } from '@app/shared/documents/documents.service.types';
import { CurrentSessionService } from '@app/core/current-session.service';
import { ModalHelperService } from '@app/shared/modal-helper/modal-helper.service';
import { StudiesService } from '@app/shared/studies/studies.service';
import { ActionBarService } from '@app/shared/action-bar/action-bar.service';
import { ActionBarConfig } from '@app/shared/action-bar/action-bar.service.types';
import { UsersService } from '@app/shared/users/users.service';
import { SigningPasscodeService } from '@app/shared/signing-passcode/signing-passcode.service';
import { AuditTrailService } from '@app/shared/audit-trail/audit-trail.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { DocumentService } from '@app/shared/documents/document.service';
import { StudyRolesService } from '@app/shared/study-roles/study-roles.service';
import { DocumentLogEntriesService } from '@app/shared/documents-log-entries/document-log-entries.service';
import {
    catchError,
    filter,
    map, switchMap, take, takeUntil, tap
} from 'rxjs/operators';
import {
    FormSaveEvent, AnnotationSaveEvent, ClearAllEvent, PageManipulationActions
} from '@florencehealthcare/doc-viewer';

import { AppConfigService } from '@app/shared/app-config/app-config.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { BulkSignBody, BulkSignOrDeclineBody, SignLogEntriesResponse } from '@app/shared/documents-log-entries/document-log-entries.service.types';
import { CloseReason, ModalRef } from '@app/shared/modal-helper/modals.service.types';
import { DownloadsService } from '@app/shared/downloads/downloads.service';
import { MissingSigningPasscodeSubmitEvent } from '@app/components/documents/components/missing-signing-passcode/missing-signing-passcode.component.types';
import { JobTitleRequiredEvent } from '@app/widgets/job-title-required/job-title-required.types';
import ApiErrorFactory from '@app/shared/api-error/api-error.service';
import { DocumentDeclineQcReviewComponent } from '@app/components/qc-reviews/components/document-decline-qc-review/document-decline-qc-review.component';
import { AuditTrailModalComponent } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component';
import { DocumentHistoryService } from '@app/shared/document-history/document-history.service';
import {
    GetDocumentHistoriesPathParams, GetDocumentHistoriesQueryParams
} from '@app/shared/document-history/document-history.service.types';
import { HttpErrorResponse } from '@angular/common/http';
import { SessionsService } from '@app/shared/sessions/sessions.service';
import { TeamService } from '@app/shared/teams/team.service';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { LogEntrySignature, SignLogEntriesEvent, DeclineLogEntryEvent } from '../document-log-entries/document-log-entries.component.types';
import { DocumentDeclineData, DocumentDeclineEventEmitter } from '../document-decline/document-decline.component.types';
import { DocumentSignData, SignDocumentCloseReason, SignDocumentSaveEvent } from '../../components/sign-document/sign-document.component.types';
import { SignLogEntriesCloseReason, SignLogEntriesSaveEvent } from '../../components/sign-log-entries/sign-log-entries.component.types';
import { EditDetailsEvent } from '../../components/document-edit-details/document-edit-details.component.types';
import { DocumentMonitorReviewService } from '../../document-monitor-review.service';
import { CreateAnnotationsEvent } from '../../components/annotations-modal/annotations-modal.component.types';
import { LogEditMetadataEvent } from '../../components/log-edit-metadata/log-edit-metadata.component.types';
import { DocumentEditDetailsComponent } from '../../components/document-edit-details/document-edit-details.component';
import { QCReviewService } from '../../qc-review.service';
import { DocumentAnnotationsService } from '../../document-annotation.service';
import { DocumentFormService } from '../../document-form.service';
import { QcReview, QcReviewDeclineParam } from '../../qc-review.services.types';
import { QcAcceptEvent, QcDeclineEvent } from '../../components/log-toolbar/log-toolbar.component.types';
import { DocumentReplaceComponent } from '../../components/document-replace/document-replace.component';
import { OnSubmitDocumentReplace } from '../../components/document-replace/document-replace.component.types';
import { AnnotationsClearAllModalComponent } from '../../components/annotations-clear-all-modal/annotations-clear-all-modal.component';
import { DocumentDownloadComponent } from '../../components/document-download/document-download.component';
import { SignatureCompleteComponent } from '../../components/signature-complete/signature-complete.component';
import { DeclineDocumentComponent } from '../document-decline/document-decline.component';
import { DocumentHistoryModalComponent } from '../../components/document-history-modal/document-history-modal.component';
import { NdvFormSaveComponent } from '../../components/ndv-form-save/ndv-form-save.component';
import { SaveFormModalEvent } from '../../components/ndv-form-save/ndv-form-save.component.types';
import { DoaLogTemplateRolesResponsibilitiesEditComponent } from '../../../log-templates/components/doa-log-template/doa-log-template-roles-responsibilities-edit/doa-log-template-roles-responsibilities-edit.component';
import { TaskFormComponent } from '../../components/task-form/task-form.component';
import { TaskSaveEvent, TaskDestroyEvent } from '../../components/task-form/task-form.component.types';


class DocumentsShowController {
    // bindings
    public $transition$: Transition;
    private stateParams: { [paramName: string]: string };

    signatureQueue: ActionBarConfig;
    loadingSignatureQueue = true;
    taskQueue: ActionBarConfig;
    loadingTaskQueue = true;
    loadedDocument: Document;
    doc: Document;
    currentUser: User;
    currentTeam: Team;
    crumbs: Crumb[] = [];
    timer: ng.IPromise<void>;
    monitorReviews: MonitorReview[];
    monitorReview: MonitorReview;
    private viewableStudy = new BehaviorSubject<Study>(undefined);
    public viewableStudy$ = this.viewableStudy.asObservable();
    hasUnviewableStudies: boolean;
    monitorReviewsEnabled: boolean;
    pendingSignatureRequest: SignatureRequest;
    requestIsPastDue: boolean;
    teamSignatureRestricted: boolean;
    isPlaceholderOrPlaceholderShortcut = false;
    isLogDocument = false;
    currentDisplayVersion; // todo
    jobTitleRequired = false;
    jobTitleLoaded = false;
    isNewDocViewer: boolean;
    appConfigValue: AppConfigValue;
    documentUrl: string;
    ebindersApiUrl: string;
    annotations;
    successfulSign = false;
    token: Promise<string>;
    webviewerServerRangeRequests: boolean;
    webviewerServerRangeRequestsLoaded = false;
    logEntryToUpdate: string | string[];
    pageManipulationFF = false;
    private editDetailsModal: ModalRef<DocumentEditDetailsComponent>;
    private isSigning = false;

    readonly formStatusCheckInterval = 5000;
    private formStatusResolved$ = new Subject();
    private destroy$ = new Subject();

    constructor(
        private AppConfig: AppConfigService,
        private $scope: ng.IScope,
        private LogEntries: DocumentLogEntriesService,
        private MonitorReviews: DocumentMonitorReviewService,
        private Studies: StudiesService,
        private Teams: TeamService,
        private Users: UsersService,
        private SigningPasscode: SigningPasscodeService,
        private $state: StateService,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService,
        private CurrentSession: CurrentSessionService,
        private modalHelper: ModalHelperService,
        private ActionBar: ActionBarService,
        private AuditTrail: AuditTrailService,
        private DocumentHistory: DocumentHistoryService,
        private DocumentAnnotationAdapter,
        private Downloads: DownloadsService,
        private Notifications: NotificationsService,
        private ApiError: ApiErrorFactory,
        private Modals: ModalsService,
        private QCReviewService: QCReviewService,
        private DocumentAnnotationsService: DocumentAnnotationsService,
        private DocumentFormService: DocumentFormService,
        private Sessions: SessionsService,
        private $window: ng.IWindowService,
        private DocumentService: DocumentService,
        private StudyRolesService: StudyRolesService,
        private FeatureFlags: FeatureFlagService
    ) { }

    $onInit(): void {
        this.webviewerServerRangeRequests = this.CurrentSession.getCanUsePartialRequests();
        this.webviewerServerRangeRequestsLoaded = true;
        this.token = this.Sessions.heartbeat();
        this.appConfigValue = this.AppConfig.config;
        this.ebindersApiUrl = this.AppConfig.config.ebindersApiUrl;
        this.stateParams = this.$transition$.params();
        this.doc = this.loadedDocument;
        this.documentUrl = this.constructDocumentFileUrl();
        this.isPlaceholderOrPlaceholderShortcut = this.doc.subType === 'placeholder' || this.doc.originalDocumentSubType === 'placeholder';
        this.isLogDocument = this.doc.subType === 'log';

        this.$scope.$on('documentUpdated', () => {

            this.reloadDocument();
        });

        this.$scope.$on('$destroy', () => {

            this.$timeout.cancel(this.timer);
        });

        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.currentUser = this.CurrentSession.getCurrentUser();

        this.isNewDocViewer = !!this.currentTeam.settings.features.newDocumentViewer
            && !this.doc.shouldBeDisplayedInODV;
        if (!this.isNewDocViewer && this.isConverting(this.doc)) {
            this.checkStatus();
        }
        if (this.isNewDocViewer && this.doc.formStatus === 'checking-form') {
            this.watchForFormStatusChanges();
        }

        this.getMonitorReviews();
        this.getStudies();
        this.checkPendingSignatures();
        this.crumbs = this.getCrumbs();

        if (this.stateParams.taskId) {
            const task = this.doc.tasks.find((singleTask: Task) => singleTask.id === this.stateParams.taskId);
            this.openTask(task);
        }
        else {
            this.openDetailsIfMissing();
        }
        this.loadSignatureQueue().subscribe();
        this.loadTaskQueue();
        this.currentDisplayVersion = this.DocumentService.getCurrentDocDisplayVersion(this.doc);

        this.DocumentService.getDocumentJobTitleRequired(this.getDocumentId()).subscribe(
            (result: GetJobTitleRequiredResponse) => {
                if (result && result.jobTitleRequired) {
                    const { jobTitleRequired } = result;
                    this.jobTitleRequired = jobTitleRequired;
                }
                this.jobTitleLoaded = true;
            },
            () => this.Notifications.unexpectedError()
        );

        this.FeatureFlags.getFlag(FEATURE_FLAGS.NDV_PAGE_MANIPULATION, false).pipe(
            filter((flag) => flag !== undefined)
        ).subscribe((value) => {
            this.pageManipulationFF = value;
        });

        this.modifyURLForPendo(this.doc.subType);
    }

    private watchForFormStatusChanges(): void {
        if (this.doc.formStatus !== 'checking-form') {
            return;
        }

        interval(this.formStatusCheckInterval)
            .pipe(
                takeUntil(
                    merge(this.formStatusResolved$, this.destroy$)
                ),
                switchMap(() => this.DocumentService.poll(
                    this.stateParams.documentId,
                    this.stateParams.version || this.currentDisplayVersion
                )),
                tap((doc) => {
                    if (doc.formStatus !== 'checking-form') {
                        this.doc.formStatus = doc.formStatus;
                        this.formStatusResolved$.next();
                        this.formStatusResolved$.complete();
                        this.$state.reload();
                    }
                })
            ).subscribe();
    }

    modifyURLForPendo(type: string): void {
        const currentPath = this.$window.location?.href;
        const adjustPathForDocType = function determinePendoPath() {
            const newPath = type !== 'content'
                ? currentPath.replace('/documents/', `/${type}s/`)
                : currentPath;
            return newPath;
        };
        // ideally, this should be a service
        // if you're clonning, please don't just c/p
        this.$window.pendo?.location?.addTransforms([{
            attr: 'href',
            action: 'Replace',
            data: function transform() {
                return adjustPathForDocType();
            }
        }]);
    }

    documentViewerHandleError(message: string): void {
        if (typeof message !== 'string') {
            return;
        }
        if (message) {
            this.Notifications.error(message);
        }
        else {
            this.Notifications.unexpectedError();
        }
        Sentry.captureMessage(`
            [ebinder-client:Apryse-message]:
            D ID: ${this.doc.id}, extension: ${this.doc.ext}, U ID: ${this.currentUser?.email}, T ID: ${this.doc.teamId}, Error: ${JSON.stringify(message, null, 2)}
        `, Sentry.Severity.Fatal);

        Sentry.captureEvent({
            message: `[ebinder-client:Apryse-event] ${message}`,
            level: Sentry.Severity.Fatal,
            extra: {
                document: {
                    id: this.doc.id,
                    version: this.doc.version,
                    ext: this.doc.ext,
                    pageCount: this.doc.pageCount,
                    teamId: this.doc.teamId
                },
                user: this.currentUser
            }
        });
    }

    getDocRequestVersion(doc, explicitVersion): number | string {

        const docVersion = Object.prototype.hasOwnProperty.call(doc.id, 'version') ? doc.id.version : doc.version;
        if (doc.subType === 'shortcut' || docVersion === 0 || docVersion === '0') {
            return 0;
        }
        return explicitVersion || docVersion;
    }

    /**
     *
     * @param params
     * @param {Object} params.doc - A document
     * @param {number} [params.version]
     * @returns {{
    *              teamId: string,
    *              documentId: string,
    *              version: number,
    *              contentVersion: number|undefined
    *          }}
    * @private
    */
    private getNavParams({ doc, version }) {
        const requestVersion = this.getDocRequestVersion(doc, version);
        const navParams = {
            teamId: doc.teamId,
            documentId: doc.id.documentId || doc.id,
            version: requestVersion,
            contentVersion: version && version !== requestVersion ? version : null
        };
        return navParams;
    }

    saveForm(event: FormSaveEvent) {

        const {
            formFields,
            signaturesXfdfString,
            onSuccessfulSave,
            canFinalizeForm,
            numberOfSignaturesAddedToTheForm,
            hasToFinalizeForm
        } = event;

        if (numberOfSignaturesAddedToTheForm > 0 && this.jobTitleRequired && !this.currentUser.profile.jobTitle) {
            return this.modalHelper.open({
                animation: true,
                size: 'md',
                component: 'job-title-required-wrapper',
                resolve: {
                    onUpdateProfile: () => ({ onUpdateProfile }: JobTitleRequiredEvent): void => {
                        onUpdateProfile();
                        this.$state.go('app.user-profile');
                    }
                }
            });
        }

        this.checkRemoteUserMissingSignPin(numberOfSignaturesAddedToTheForm > 0)
            .then((missingPin) => {
                if (missingPin) {
                    return this.showMissingSignPinModal();
                }

                const jobTitle = this.jobTitleRequired && numberOfSignaturesAddedToTheForm > 0 ? this.currentUser.profile.jobTitle : '';

                const formSaveModal = this.Modals.show(NdvFormSaveComponent, {
                    animated: true,
                    class: 'modal-md',
                    initialState: {
                        jobTitle,
                        entity: this.doc,
                        canFinalizeForm,
                        mustFinalize: hasToFinalizeForm,
                        signatureCount: numberOfSignaturesAddedToTheForm
                    }
                });

                formSaveModal.content.onSubmit.subscribe(async (saveFormEvent: SaveFormModalEvent) => {
                    const {
                        shouldFinalize,
                        reason,
                        email,
                        password,
                        signingPasscode
                    } = saveFormEvent;

                    const updateFormParams = {
                        formFields,
                        signaturesXfdfString,
                        teamId: this.currentTeam.id,
                        documentId: this.doc.id as string,
                        shouldFinalize,
                        documentVersion: this.doc.version,
                        reason: reason || '',
                        email,
                        password,
                        signingPasscode
                    };

                    let preServiceCallMessage = 'Saving draft, this may take a minute.';
                    if (shouldFinalize) {
                        preServiceCallMessage = 'Finalizing document, this may take a minute.';
                    }
                    setTimeout(() => this.Notifications.info(preServiceCallMessage), 500);

                    await this.DocumentFormService.updateForm(updateFormParams)
                        .pipe(take(1))
                        .toPromise()
                        .then(() => {
                            onSuccessfulSave();

                            let successMessage = 'Document annotated and draft saved successfully.';
                            if (shouldFinalize) {
                                successMessage = 'Document annotated and finalized successfully.';

                                if (numberOfSignaturesAddedToTheForm > 0) {
                                    successMessage = 'Document signed, annotated and finalized successfully.';
                                }
                                else if (numberOfSignaturesAddedToTheForm === 0 && hasToFinalizeForm) {
                                    successMessage = 'Document finalized successfully.';
                                }
                            }

                            this.Notifications.success(successMessage);
                            saveFormEvent.onSuccess();

                            const navParams = this.getNavParams({ doc: this.doc, version: this.doc.version + 1 });
                            this.$state.go('app.team.document-show', navParams, {});
                            this.promptNextDocumentToSign(numberOfSignaturesAddedToTheForm > 0);
                        })
                        .catch((err) => {
                            saveFormEvent.onError();
                            if (err.error && err.error.message) {
                                this.Notifications.error(err.error.message);
                            }
                            else {
                                this.Notifications.unexpectedError();
                            }
                        });
                });
            });
    }

    clearAll(event: ClearAllEvent) {
        if (!this.isNewDocViewer) {
            return;
        }

        const modalRef = this.Modals.show(AnnotationsClearAllModalComponent, {
            initialState: {
                isForm: event.isForm
            }
        });

        modalRef.content.dismiss.subscribe(() => {
            modalRef.hide();
        });

        modalRef.content.clearAll.subscribe(() => {
            event.onClearAllConfirm();
            modalRef.hide();
        });
    }

    saveAnnotationChanges(event: AnnotationSaveEvent) {

        if (
            event.typesOfAnnotations.length === 0
            && Object.values(event.pageManipulationActions).every((value) => value === false)
        ) {
            return;
        }

        let annotations = [];

        if (this.isNewDocViewer) {
            // eslint-disable-next-line no-param-reassign
            annotations = event.typesOfAnnotations.map((annotationType) => ({ type: annotationType }));
        }

        this.checkRemoteUserMissingSignPin(event.numberOfSignaturesAdded > 0)
            .then((missingPin) => {
                if (missingPin) {
                    return this.showMissingSignPinModal();
                }

                const jobTitle = this.jobTitleRequired ? this.currentUser.profile.jobTitle : '';

                this.modalHelper.open({
                    animation: true,
                    size: 'md',
                    component: 'annotations-modal-wrapper',
                    resolve: {
                        isNewDocViewer: (): boolean => this.isNewDocViewer,
                        doc: (): Document => this.doc,
                        annotations: (): any[] => annotations,
                        jobTitle: (): string => jobTitle,
                        pages: () => event.pages,
                        pageManipulationActions: () => event.pageManipulationActions,
                        numberOfSignaturesAdded: (): number => event.numberOfSignaturesAdded,
                        onSubmit: () => ({ data, onSuccess, onError }: CreateAnnotationsEvent): void => {
                            const createAnnotationsParams = {
                                xfdfString: event.xfdfString,
                                teamId: this.currentTeam.id,
                                documentId: this.doc.id as string,
                                documentVersion: this.doc.version,
                                reason: data.reason,
                                signingReason: data.signingReason,
                                email: data.email,
                                password: data.password,
                                signingPasscode: data.signingPasscode,
                                pages: data.pages,
                                pageManipulationReason: data.pageManipulationReason
                            };

                            this.DocumentAnnotationsService.createAnnotations(createAnnotationsParams)
                                .pipe(take(1))
                                .subscribe(
                                    () => {
                                        onSuccess();
                                        event.onSuccessfulSave();

                                        const hasPageManipulationActions = Object.values(event.pageManipulationActions)
                                            .some((value) => value === true);

                                        const hasAnnotationActions = annotations.some((annotation) => annotation.type !== 'signature');
                                        const hasSignatureActions = annotations.some((annotation) => annotation.type === 'signature');

                                        const messages = {
                                            111: 'Document edited, annotated and signed successfully.',
                                            110: 'Document edited and annotated successfully.',
                                            101: 'Document edited and signed successfully.',
                                            '011': 'Document annotated and signed successfully.',
                                            '001': 'Document signed successfully.',
                                            '010': 'Document annotated successfully.',
                                            100: 'Document edited successfully.'
                                        };

                                        const key = `${+hasPageManipulationActions}${+hasAnnotationActions}${+hasSignatureActions}`;
                                        const notificationMessage = messages[key] || 'No actions performed';

                                        this.Notifications.success(notificationMessage);

                                        const navParams = this.getNavParams({ doc: this.doc, version: this.doc.version + 1 });
                                        this.$state.go('app.team.document-show', navParams, {});
                                        this.promptNextDocumentToSign(event.numberOfSignaturesAdded > 0);
                                    },
                                    (err) => {
                                        onError();
                                        if (err.error && err.error.message) {
                                            this.Notifications.error(err.error.message);
                                        }
                                        else {
                                            this.Notifications.unexpectedError();
                                        }
                                    }
                                );
                        }
                    }
                });
            });
    }


    $onDestroy(): void {
        if (this.editDetailsModal) {
            this.editDetailsModal.hide();
        }
        this.destroy$.next();
        this.destroy$.complete();
        this.formStatusResolved$.complete();
    }

    onQcReviewUpdate(): void {
        this.loadDocument();
    }

    setVersion(version): void {
        this.DocumentService.goToDocument({
            doc: this.doc,
            version

        });
    }

    private checkStatus(): void {
        this.timer = this.$timeout(() => {
            this.DocumentService.poll(this.stateParams.documentId, this.stateParams.version
                || this.currentDisplayVersion).toPromise().then((doc) => {

                this.doc.formStatus = doc.formStatus;
                this.doc.conversionStatus = doc.conversionStatus;
                this.doc.conversionStatusBooleans = doc.conversionStatusBooleans;

                if (this.isConverting(this.doc)) {
                    this.checkStatus();
                }
                else {
                    this.$state.reload();
                }
            });
        }, 5000);
    }

    acceptQcReview(event: QcAcceptEvent) {
        const params = {
            documentId: event.docId,
            documentVersion: event.docVersion,
            teamId: this.currentTeam.id
        };

        this.QCReviewService.acceptQcReview(params)
            .pipe(take(1))
            .subscribe(
                (qcReview) => {
                    const successMessage = `QC Review for version ${qcReview.documentVersion} successfully started`;
                    event.onSuccess();
                    this.Notifications.success(successMessage);
                },
                (err) => {
                    event.onError();
                    if (err.error && err.error.message) {
                        this.Notifications.error(err.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                },
                () => {
                    this.loadDocument();
                }
            );
    }

    approveQcReview($event) {
        const approveParams = {
            documentId: this.doc.id as string,
            documentVersion: this.doc.version,
            teamId: this.currentTeam.id,
            comment: $event.comment
        };

        this.QCReviewService.approveQcReview(approveParams)
            .pipe(take(1))
            .subscribe(
                (qcReview) => {
                    const successMessage = `QC Review for version ${qcReview.documentVersion} successfully approved`;
                    this.Notifications.success(successMessage);
                    $event.onSuccess();
                },
                (err) => {
                    if (err.error && err.error.message) {
                        this.Notifications.error(err.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                    $event.onError();
                },
                () => {
                    this.loadDocument();
                }
            );
    }

    strikeThroguhLogRows($event) {
        const params = {
            documentId: this.doc.id,
            teamId: this.currentTeam.id,
            logEntryIds: $event.logEntryIds,
            reason: $event.reason
        };

        this.LogEntries.strikeThroughLogEntries(params).subscribe(
            (result) => {
                let successes = 0;
                let failures = 0;
                result.filter((item) => {
                    if (item.statusCode === 200) {
                        successes += 1;
                        return true;
                    }

                    failures += 1;
                    return false;
                });

                if (successes) {
                    const rows = successes === 1 ? 'row' : 'rows';
                    this.Notifications.success(`${successes} Log ${rows} struckthrough successfully.`);
                }
                if (failures) {
                    const rows = failures === 1 ? 'row' : 'rows';
                    this.Notifications.error(`${failures} Log ${rows} not struckthrough.`);
                }
                $event.onSuccess();
                this.afterLogEntriesSigned(true);
            },
            (error) => {
                if (error.error && error.error.message) {
                    this.Notifications.error(error.error.message);
                }
                else {
                    this.Notifications.unexpectedError();
                }
                $event.onError();
            }
        );
    }

    rejectQcReview($event) {
        const rejectParams = {
            documentId: this.doc.id as string,
            documentVersion: this.doc.version,
            teamId: this.currentTeam.id,
            comment: $event.comment,
            reasons: $event.reasons
        };

        this.QCReviewService.rejectQcReview(rejectParams)
            .pipe(take(1))
            .subscribe(
                (qcReview) => {
                    const successMessage = `QC Review for version ${qcReview.documentVersion} successfully rejected`;
                    this.Notifications.success(successMessage);
                    $event.onSuccess();
                },
                (err) => {
                    if (err.error && err.error.message) {
                        this.Notifications.error(err.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                    $event.onError();
                },
                () => {
                    this.loadDocument();
                }
            );
    }

    private qcReviewDecline(params: QcReviewDeclineParam, event: QcDeclineEvent): Observable<QcReview | null> {
        return this.QCReviewService.declineQcReview(params).pipe(
            tap((qcReview) => {
                const successMessage = `QC Review for version ${qcReview.documentVersion} successfully declined`;
                this.Notifications.success(successMessage);
            },
            (error) => {
                event.onError();
                if (error.error && error.error.message) {
                    this.Notifications.error(error.error.message);
                }
                else {
                    this.Notifications.unexpectedError();
                }
                return of(null);
            },
            () => {
                this.loadDocument();
            })
        );
    }

    declineQcReview(event: QcDeclineEvent): void {
        const docVersion = this.doc.version;

        const modalRef = this.Modals.show(DocumentDeclineQcReviewComponent, {
            initialState: {}
        });

        const declineParams = {
            documentId: this.doc.id as string,
            documentVersion: docVersion,
            teamId: this.currentTeam.id
        };

        modalRef.content.close.subscribe(() => {
            event.onClose();
        });

        modalRef.content.decline.pipe(
            switchMap((resp) => this.qcReviewDecline({ ...declineParams, reason: resp.reason }, event))
        ).subscribe(() => {
            modalRef.hide();
        }, () => {
            modalRef.hide();
        });
    }

    private reloadDocument(): void {
        // no longer reload through ui router, just the data is reloaded
        this.loadDocument();
        this.loadSignatureQueue().subscribe();
        this.loadTaskQueue();
    }

    private loadDocument(): void {
        const { version } = this.stateParams;
        const contentVersion = this.stateParams.contentVersion || version;

        this.DocumentService
            .load(this.stateParams.documentId, version, contentVersion)
            .toPromise()
            .then((doc) => {
                this.doc = doc;
                this.checkPendingSignatures();
            });
    }

    private getDocumentId(): string {
        return typeof this.doc.id !== 'string' ? this.doc.id.documentId : this.doc.id;
    }

    private loadSignatureQueue(includeRequestId = true): Observable<ActionBarConfig> {
        this.loadingSignatureQueue = true;
        const documentId = this.getDocumentId();

        return this.ActionBar.getSignatureQueue({
            teamId: this.stateParams.teamId,
            documentId,
            documentType: this.doc.subType,
            ...includeRequestId && { signatureRequestId: this.stateParams.signatureRequestId }
        }).pipe(tap({
            next: (queue) => {
                this.signatureQueue = queue;
            },
            error: () => {
                this.signatureQueue = undefined;
            },
            complete: () => {
                this.loadingSignatureQueue = false;
            }
        }));
    }

    private loadTaskQueue(): void {
        this.loadingTaskQueue = true;
        const { taskId } = this.stateParams;
        const documentId = this.getDocumentId();
        this.ActionBar
            .getTaskQueue({
                teamId: this.stateParams.teamId,
                documentId,
                taskId
            })
            .subscribe((queue) => {
                this.taskQueue = queue;
                this.loadingTaskQueue = false;
            }, () => {
                this.taskQueue = undefined;
                this.loadingTaskQueue = false;
            });
    }

    private openDetailsIfMissing(): void {
        const details = this.doc
            && this.doc.permissions.updateLogDetails
            && this.doc.logDetails;

        if (this.logDetailsMissing(details) && this.doc.version === 1) {
            this.openEditDetails();
        }
    }

    private logDetailsMissing(details: LogDetail[]): boolean {
        const mutableDetails = details && details.length && details
            .filter(({ immutable }) => !immutable);

        return this.doc.isLatestVersion
            && mutableDetails && mutableDetails.length
            && mutableDetails.every(({ value }) => !value);
    }

    private getMonitorReviews(): void {
        if (!this.doc.permissions.documentViewMonitorReview) {
            this.monitorReviewsEnabled = false;
            return;
        }

        const documentId: string = this.doc.id as string; // Refactor this.doc.id typing when upgrading this component
        this.MonitorReviews.checkMonitorReviewEnabled(documentId).toPromise()
            .then((isEnabled) => {
                this.monitorReviewsEnabled = isEnabled;
                if (!isEnabled) {
                    return [];
                }
                return this.MonitorReviews.getReviewsForDoc(documentId).toPromise();
            })
            .then((reviews) => {
                this.monitorReviews = reviews;
                const { monitorReviewId } = this.stateParams;
                if (monitorReviewId) {
                    this.monitorReview = this.monitorReviews.find((review) => review.id === monitorReviewId);
                }
            })
            .catch(() => {
                this.monitorReviews = [];
            });
    }

    onMonitorReviewsUpdate($event): void {
        this.monitorReviews = _.cloneDeep($event.monitorReviews);
    }

    openAuditTrailModal(auditData): void {
        const {
            subject = AuditTrailSubject.DOCUMENT,
            ...auditItem
        } = auditData || this.doc;

        const params = {
            subject,
            teamId: auditItem.teamId,
            objectId: auditItem.id,
            limitToOverwritten: false,
            ...this.AuditTrail.auditPagination
        };

        this.AuditTrail.getAudits(params).toPromise().then((audits) => {
            this.Modals.show(AuditTrailModalComponent, {
                class: 'modal-lg',
                animated: true,
                initialState: {
                    data: audits,
                    item: auditItem,
                    subject: params.subject,
                    pagination: this.AuditTrail.auditPagination,
                    onPageChange: this.AuditTrail.getAudits.bind(this.AuditTrail)
                }
            });
        }).catch(this.ApiError.handleError);
    }

    openViewDocumentSharingHistoryModal() {

        const params: GetDocumentHistoriesPathParams = {
            document: {
                id: this.doc.id as string,
                version: this.doc.version
            },
            exchangeEventId: this.doc.exchangeEventId
        };

        this.Modals.show(DocumentHistoryModalComponent, {
            class: 'modal-lg',
            animated: true,
            initialState: {
                document: this.doc,
                getDocumentHistories: (paginationParams: GetDocumentHistoriesQueryParams) => this.DocumentHistory
                    .getDocumentHistories({ ...params, ...paginationParams })
                    .toPromise()
            }
        });
    }


    requestDownload(): void {

        const modalRef = this.Modals.show(DocumentDownloadComponent, {
            class: 'modal-md',
            animated: true,
            initialState: {
                options: {
                    teamId: this.doc.teamId,
                    binderId: this.doc.binderId,
                    version: this.doc.version,
                    contentVersion: this.currentDisplayVersion,
                    documents: [this.doc]
                },
                newDocViewerFeatureEnabled: this.isNewDocViewer,
                team: this.currentTeam
            }
        });

        modalRef.content.download.subscribe((params) => {
            this.Downloads
                .downloadFile(params)
                .toPromise()
                .then(() => {
                    const href = this.$state.href('app.team.downloads', { teamId: this.doc.teamId });
                    const message = `<p>Starting download now! We'll notify you when your download is ready.</p>
                        Go to <a class="page-action u-d-inline" href=${href}>MY DOWNLOADS</a> to view all downloads.`;
                    this.Notifications.info({ message, closeOnClick: false });
                })
                .catch((error) => {
                    const message = (error && error.error && error.error.message);
                    message ? this.Notifications.error(message) : this.Notifications.unexpectedError();
                });
        });
    }

    private checkPendingSignatures(): void {
        const currentTeam = this.CurrentSession.getCurrentTeam();
        const currentUser = this.CurrentSession.getCurrentUser();

        this.pendingSignatureRequest = this.doc.pendingSignatures.find((req) => req.userId === currentUser.id && !req.entryId);
        const teamHasPendingSignaturesRestriction = _.get(currentTeam, 'settings.signatures.pendingRestriction', false);

        this.requestIsPastDue = this.pendingSignatureRequest && this.pendingSignatureRequest.isDue;
        this.teamSignatureRestricted = !this.pendingSignatureRequest
            && teamHasPendingSignaturesRestriction
            && this.doc.pendingSignatures.length > 0;
    }

    saveDocument(data: { annotations?: { type: string }[]; formFields?: { type: string }[] } = {}): ng.IPromise<unknown> {
        const hasAnnotationSign = data.annotations
            && data.annotations.length
            && data.annotations.some(({ type }) => type === 'signature');
        const hasFormFieldSign = data.formFields
            && data.formFields.length
            && data.formFields.some(({ type }) => type === 'signature');

        return this.checkRemoteUserMissingSignPin(hasAnnotationSign || hasFormFieldSign)
            .then((missingPin) => {
                if (missingPin) {
                    return this.showMissingSignPinModal();
                }
                if (this.doc.formFields && this.doc.formFields.length > 0) {
                    return this.saveFormFields(data);
                }
                return this.saveAnnotations(data);
            });
    }

    private saveFormFields({ formFields = [] }) {
        if (formFields.some((formField) => formField.type === 'signature') && this.jobTitleRequired && !this.currentUser.profile.jobTitle) {
            return this.modalHelper.open({
                animation: true,
                size: 'md',
                component: 'job-title-required-wrapper',
                resolve: {
                    onUpdateProfile: () => ({ onUpdateProfile }: JobTitleRequiredEvent): void => {
                        onUpdateProfile();
                        this.$state.go('app.user-profile');
                    }
                }
            });
        }

        const jobTitle = formFields.some((formField) => formField.type === 'signature') && this.jobTitleRequired ? this.currentUser.profile.jobTitle : '';
        return this.$q(() => {
            this.modalHelper.open({
                animation: true,
                size: 'md',
                component: 'document-form-save',
                resolve: {
                    jobTitle: () => jobTitle,
                    entity: () => this.doc,
                    updatedFormFields: () => formFields,
                    originalFormFields: () => this.doc.formFields,
                    onSubmit: () => (onSubmitParams) => {
                        const {
                            email,
                            password,
                            shouldFinalize,
                            signingPasscode,
                            jobTitle
                        } = onSubmitParams;

                        if (!shouldFinalize) {
                            formFields.forEach((formField) => {
                                const originalField = this.doc.formFields
                                    .find((originalField) => originalField.id === formField.id);

                                const originalFieldHasCoordinates = originalField?.value?.coordinates;
                                if (originalFieldHasCoordinates && formField.value) {
                                    formField.value.coordinates = originalField.value.coordinates;
                                }
                                else {
                                    formField.value = this.DocumentAnnotationAdapter.adaptForDraftApi(formField.value);
                                }
                            });
                        }
                        const wasSigned = !!(password || signingPasscode);
                        const params = {
                            documentId: this.doc.id,
                            version: this.doc.version,
                            formFields,
                            email,
                            password,
                            shouldFinalize,
                            signingPasscode,
                            jobTitle
                        };
                        return this.DocumentService
                            .saveFormFields(params)
                            .toPromise()
                            .then(() => this.promptNextDocumentToSign(wasSigned));
                    }
                }
            });
        });
    }

    private saveAnnotations({ annotations = [] }): ng.IPromise<void> {
        return this.$q((resolve, reject) => {
            if (annotations.length === 0) {
                return reject();
            }

            if (annotations.some((annotation) => annotation.type === 'signature') && this.jobTitleRequired && !this.currentUser.profile.jobTitle) {
                return this.modalHelper.open({
                    animation: true,
                    size: 'md',
                    component: 'job-title-required-wrapper',
                    resolve: {
                        onUpdateProfile: () => ({ onUpdateProfile }: JobTitleRequiredEvent): void => {
                            onUpdateProfile();
                            this.$state.go('app.user-profile');
                        }
                    }
                });
            }

            const jobTitle = this.jobTitleRequired ? this.currentUser.profile.jobTitle : '';

            this.modalHelper.open({
                animation: true,
                size: 'md',
                component: 'annotations-modal-wrapper',
                resolve: {
                    doc: (): Document => this.doc,
                    annotations: (): Annotation[] => annotations,
                    jobTitle: (): string => jobTitle,
                    onSubmit: () => ({ data, onSuccess, onError }: CreateAnnotationsEvent): void => {
                        const wasSigned = !!(data.password || data.signingPasscode);
                        const params = {
                            documentId: this.doc.id,
                            version: this.doc.version,
                            annotations,
                            email: data.email,
                            reason: data.reason,
                            password: data.password,
                            signingPasscode: data.signingPasscode,
                            jobTitle: data.jobTitle
                        };
                        this.DocumentService
                            .saveAnnotations(params)
                            .toPromise()
                            .then((response) => resolve(response))
                            .then(() => {
                                onSuccess();
                                this.promptNextDocumentToSign(wasSigned);
                            })
                            .catch(({ error }): void => {
                                onError();

                                if (error.message === 'Signing PIN Expired.') {
                                    return this.Notifications.error('Your Signing PIN has expired. Please reset your Signing PIN and try again.');
                                }

                                this.Notifications.error(error.message);
                            });
                    }
                }
            });
        });
    }

    signDocument(decline: boolean): void {
        if (this.isSigning) {
            return;
        }
        this.isSigning = true;

        if (decline) {
            this.declineDocument();
        }
        else {
            this.successfulSign = true;
            this.handleSignatureEvent(() => {
                return this.openDocumentSignForm(this.doc, this.pendingSignatureRequest);
            }).then((closeReason: SignDocumentCloseReason) => {
                if (this.successfulSign) {
                    this.successfulSign = false;
                    this.promptNextDocumentToSign(closeReason === 'signed');
                }
                this.isSigning = false;
            });
        }
    }

    declineDocument(): void {
        this.handleSignatureEvent(() => {
            return this.openDocumentDeclineForm(this.doc, this.pendingSignatureRequest);
        });
    }

    signLogEntries(event: SignLogEntriesEvent): void {
        this.handleSignatureEvent(() => {
            // For a single log entry sign we still use the old document-sign component, that has the old log entry signing form
            if (event.length === 1) {
                return this.openDocumentSignForm(this.doc, null, event[0].logEntry, event[0].column);
            }
            return this.openLogEntriesSignForm(this.doc, event);
        }).then((closeReason: SignDocumentCloseReason | SignLogEntriesCloseReason) => {

            this.loadSignatureQueue(false).subscribe(() => {
                if (this.successfulSign) {
                    this.successfulSign = false;
                    this.promptNextDocumentToSign(closeReason === 'signed');
                }
            });
        });
    }

    declineLogEntry(event: DeclineLogEntryEvent): void {
        this.handleSignatureEvent(() => {
            return this.openLogEntryDeclineForm(this.doc, event);
        });
    }

    private openLogEntriesSignForm(doc: Document, signatures: SignLogEntriesEvent): ng.IPromise<SignLogEntriesCloseReason> {
        if (this.jobTitleRequired && !this.currentUser.profile.jobTitle) {
            return this.modalHelper.open({
                animation: true,
                size: 'md',
                component: 'job-title-required-wrapper',
                resolve: {
                    onUpdateProfile: () => ({ onUpdateProfile }: JobTitleRequiredEvent): void => {
                        onUpdateProfile();
                        this.$state.go('app.user-profile');
                    }
                }
            });
        }

        const jobTitle = this.jobTitleRequired ? this.currentUser.profile.jobTitle : '';
        return this.modalHelper.open<SignLogEntriesCloseReason>({
            animation: true,
            size: 'md',
            component: 'sign-log-entries-wrapper',
            resolve: {
                currentUser: (): User => this.currentUser,
                currentTeam: (): Team => this.currentTeam,
                doc: (): Document => doc,
                logEntrySignatures: (): LogEntrySignature[] => signatures,
                jobTitle: (): string => jobTitle,
                save: () => (event: SignLogEntriesSaveEvent): void => {
                    const { data, onSuccess, onError } = event;

                    this.saveLogEntrySignatures(data).subscribe(
                        (result) => {
                            if (result.successes) {
                                const rows = result.successes === 1 ? 'row' : 'rows';
                                this.Notifications.success(`${result.successes} Log ${rows} ${result.status} successfully.`);
                                this.successfulSign = true;
                            }
                            if (result.failures) {
                                const rows = result.failures === 1 ? 'row' : 'rows';
                                this.Notifications.error(`${result.failures} Log ${rows} not ${result.status}.`);
                            }
                            this.afterLogEntriesSigned(true);

                            if (result.successes) {
                                onSuccess();
                            }

                            if (!result.successes && result.failures) {
                                onError();
                            }
                        },
                        onError
                    );
                }
            }
        });
    }

    private openLogEntryDeclineForm(doc: Document, $event: DeclineLogEntryEvent): ng.IPromise<void> {
        const pendingSignature: SignatureRequest = $event.logEntry.columns.find((column) => {
            return column.name === $event.column;
        }).signatureRequest;

        return this.openDocumentDeclineForm(doc, pendingSignature, $event);
    }

    private handleSignatureEvent(cb: () => ng.IPromise<CloseReason>): ng.IPromise<CloseReason> {
        return this.checkRemoteUserMissingSignPin(true)
            .then((missingPasscode) => {
                this.isSigning = false;
                if (missingPasscode) {
                    this.showMissingSignPinModal();
                    return;
                }

                return cb();
            });
    }

    private checkRemoteUserMissingSignPin(hasSignature: boolean): ng.IPromise<boolean> {
        const requiresPin = this.currentUser.isRemote && hasSignature;
        if (!requiresPin) {
            return this.$q.resolve(false);
        }

        return this.$q((resolve) => this.Users.getCurrent()
            .subscribe((latestUser) => {
                this.currentUser = latestUser;
                this.CurrentSession.setCurrentUser(latestUser);
                resolve(!latestUser.hasSigningPasscode);
            }));
    }

    private showMissingSignPinModal(): void {
        this.modalHelper.open({
            animation: true,
            size: 'md',
            component: 'missing-signing-passcode-wrapper',
            resolve: {
                entity: () => this.doc,
                onSubmit: () => (event: MissingSigningPasscodeSubmitEvent): void => {
                    const { onSuccess, onError } = event;

                    this.SigningPasscode.resetSigningPasscode().subscribe(() => {
                        this.Notifications.success('Instructions for setting your Signing PIN has been sent to your email');
                        onSuccess();
                    }, onError);
                }
            }
        });
    }

    private afterLogEntriesSigned(reload = false): void {
        if (this.doc.logStatus === 'finalized') {
            this.setVersion(this.doc.version + 1);
        }
        else {
            this.DocumentService.goToDocument({
                doc: this.doc,
                reload
            });
        }
    }

    private saveLogEntrySignatures(signatures: BulkSignOrDeclineBody): Observable<SignLogEntriesResponse> {
        return this.LogEntries.signLogEntries(this.doc.id as string, signatures);
    }

    private saveDocumentSignature(signature: DocumentSignData | DocumentDeclineData): Observable<void | HttpErrorResponse> {
        const contentVersion = this.DocumentService.getCurrentDocDisplayVersion(this.doc);

        return this.DocumentService.signDocument(this.doc, signature, contentVersion)
            .pipe(take(1), tap(() => {
                this.reloadDocument();
            }));
    }

    private openDocumentSignForm(
        doc: Document,
        signatureRequest?: SignatureRequest,
        logEntry?: LogEntry,
        column?: string
    ): ng.IPromise<SignDocumentCloseReason> {
        if (this.jobTitleRequired && !this.currentUser.profile.jobTitle) {
            return this.modalHelper.open({
                animation: true,
                size: 'md',
                component: 'job-title-required-wrapper',
                resolve: {
                    onUpdateProfile: () => ({ onUpdateProfile }: JobTitleRequiredEvent): void => {
                        onUpdateProfile();
                        this.$state.go('app.user-profile');
                    }
                }
            });
        }

        const pendingSignature = signatureRequest && (signatureRequest.objectId === doc.id ? signatureRequest : null);
        const jobTitle = this.jobTitleRequired ? this.currentUser.profile.jobTitle : '';

        return this.modalHelper.open<SignDocumentCloseReason>({
            animation: true,
            size: 'md',
            component: 'sign-document-wrapper',
            resolve: {
                doc: (): Document => doc,
                currentUser: (): User => this.currentUser,
                currentTeam: (): Team => this.currentTeam,
                pendingSignature: (): SignatureRequest => pendingSignature,
                logEntry: (): LogEntry | undefined => logEntry,
                selectedColumnName: (): string | undefined => column,
                jobTitle: (): string => jobTitle,
                save: () => (event: SignDocumentSaveEvent): void => {
                    const { data, onSuccess, onError } = event;

                    let observable: Observable<void | HttpErrorResponse>;

                    if (data.logEntryId) {
                        observable = this.saveLogEntrySignatures(
                            this.convertSingleToBulkEntrySign(data)
                        ).pipe(
                            map((result) => {
                                if (result.successes) {
                                    const rows = result.successes === 1 ? 'row' : 'rows';
                                    this.Notifications.success(`${result.successes} Log ${rows} ${result.status} successfully.`);
                                    this.successfulSign = true;
                                }
                                if (result.failures) {
                                    const rows = result.failures === 1 ? 'row' : 'rows';
                                    this.Notifications.error(`${result.failures} Log ${rows} not ${result.status}.`);
                                }
                                this.afterLogEntriesSigned(true);
                            })
                        );
                    }
                    else {
                        observable = this.saveDocumentSignature(data);
                    }

                    observable.subscribe(onSuccess, onError);
                }
            }
        });
    }

    private openDocumentDeclineForm(
        doc: Document,
        pendingSignature: SignatureRequest,
        logEntryData?: DeclineLogEntryEvent
    ): ng.IPromise<void> {

        const declineSignatureModal = this.Modals.show(DeclineDocumentComponent, {
            class: 'modal-md',
            animated: true,
            initialState: {
                currentUser: this.currentUser,
                currentTeam: this.currentTeam,
                doc,
                logEntry: logEntryData && logEntryData.logEntry,
                columnName: logEntryData && logEntryData.column,
                pendingSignature
            }
        });

        return this.$q((resolve) => {
            const subscription = declineSignatureModal.content.onDecline.subscribe({
                next: (data: DocumentDeclineEventEmitter) => {
                    let operationPromise;

                    if (logEntryData) {
                        const params = this.convertSingleToBulkDeclineEntry(data.signature);
                        operationPromise = this.saveLogEntrySignatures(params).toPromise();
                    }
                    else {
                        operationPromise = this.saveDocumentSignature(data.signature).toPromise();
                    }

                    operationPromise.then((result) => {
                        data.onSuccess();

                        if (logEntryData) {
                            if (result.successes) {
                                const rows = result.successes === 1 ? 'row' : 'rows';
                                this.Notifications.success(`${result.successes} Log ${rows} ${result.status} successfully.`);
                            }
                            if (result.failures) {
                                const rows = result.failures === 1 ? 'row' : 'rows';
                                this.Notifications.error(`${result.failures} Log ${rows} not ${result.status}.`);
                            }
                            const reload = true;
                            this.afterLogEntriesSigned(reload);
                        }

                        resolve();
                    })
                        .catch(() => {
                            data.onError();
                        });
                },
                complete: () => {
                    subscription.unsubscribe();
                }
            });
        });
    }


    private convertSingleToBulkEntrySign(signature: DocumentSignData): BulkSignBody {
        return {
            signatures: [{
                logEntryId: signature.logEntryId,
                column: signature.column,
                reason: signature.reason,
                jobTitle: signature.jobTitle
            }],
            teamId: signature.teamId,
            status: signature.status,
            email: signature.email,
            password: signature.password,
            signingPasscode: signature.signingPasscode
        };
    }

    private convertSingleToBulkDeclineEntry(signature: DocumentDeclineData): BulkSignOrDeclineBody {
        return {
            signatures: [{
                logEntryId: signature.logEntryId,
                column: signature.column,
                reason: signature.reason
            }],
            teamId: signature.teamId,
            status: signature.status,
            email: signature.email,
            signatureComment: signature.signatureComment
        };
    }

    private promptNextDocumentToSign(wasSigned: boolean): void {
        if (!wasSigned) {
            return;
        }

        this.Modals.show(SignatureCompleteComponent, {
            class: 'modal-md',
            animated: true,
            initialState: {
                actionOptions: this.signatureQueue
            }
        });
    }

    private getStudies(): void {
        const { teamId } = this.doc;
        const documentId = this.getDocumentId();
        this.Studies.getDocumentStudyProfile(teamId, documentId).subscribe({
            next: (value) => {
                if (value && value.length === 1) {
                    this.hasUnviewableStudies = false;
                    this.viewableStudy.next(value[0]);
                }
                else {
                    this.hasUnviewableStudies = value && value.length > 1;
                    this.viewableStudy.next(undefined);
                }
            },
            error: (err) => {
                this.hasUnviewableStudies = err && err.status === 403;
                this.viewableStudy.next(undefined);
            }
        });
    }

    private isConverting(doc): boolean {

        return doc.subType !== 'placeholder'
            && (doc.conversionStatusBooleans.isConverting
                || doc.conversionStatusBooleans.isPending
                || doc.formStatus === 'checking-form');
    }

    onDocumentRename({ document }): void {
        this.doc = {
            ...this.doc,
            name: document.name,
            title: document.title,
            titleCanonical: document.titleCanonical,
            filename: document.filename,
            filenameCanonical: document.filenameCanonical
        };
        this.crumbs = this.getCrumbs();
    }

    openTask(taskToOpen: Task | undefined): void {

        this.$q.all({
            task: taskToOpen ? this.Teams.getTask(this.doc.teamId, taskToOpen.id).toPromise() : this.$q.resolve(null),
            users: this.Teams.getTaskUsers(this.doc.teamId, this.doc.id.toString(), this.doc.type).toPromise()
        }).then((data) => {
            const updateTaskModal = this.Modals.show(TaskFormComponent, {
                animated: true,
                class: 'modal-md',
                initialState: {
                    task: taskToOpen ? data.task : null,
                    doc: this.doc,
                    users: data.users,
                    isCreate: !taskToOpen
                }
            });
            updateTaskModal.content.onSave.subscribe((event: TaskSaveEvent) => {
                const { task } = event;
                if (taskToOpen) {
                    this.Teams.updateTask(this.doc.teamId.toString(), task)
                        .toPromise()
                        .then(() => {
                            event.onSuccess();
                            this.loadDocument();
                        })
                        .catch(() => {
                            event.onError();
                        });
                }
                else {
                    this.Teams.createTask(this.doc.teamId.toString(), task)
                        .toPromise()
                        .then(() => {
                            event.onSuccess();
                            this.loadDocument();
                        })
                        .catch(() => {
                            event.onError();
                        });
                }

            });
            updateTaskModal.content.onDestroy.subscribe((event: TaskDestroyEvent) => {
                const { task } = event;
                this.Teams.deleteTask(this.doc.teamId, task)
                    .toPromise()
                    .then(() => {
                        event.onSuccess();
                        this.loadDocument();
                    })
                    .catch(() => {
                        event.onError();
                    });
            });
        });
    }

    openEditDetails(): void {
        this.editDetailsModal = this.Modals.show(DocumentEditDetailsComponent, {
            initialState: {
                doc: this.doc
            }
        });
        this.editDetailsModal.content.save
            .pipe(take(1))
            .subscribe((editEvent: EditDetailsEvent): void => {
                this.saveLogDetails(editEvent);
            });
    }

    private saveLogDetails(saveData: EditDetailsEvent): void {
        const { data, onSuccess, onError } = saveData;
        const { logDetails, reason } = data;
        this.DocumentService.updateLogMetadata(this.doc.id as string, { logDetails, reason })
            .pipe(take(1))
            .subscribe((updatedDoc: Document) => {
                onSuccess();

                const documentId = updatedDoc.id as DocumentId;
                if (documentId.version > this.doc.versions.length) {
                    this.Notifications.success('Log version automatically updated');
                }

                if (this.logDetailsMissing(this.doc.logDetails)) {
                    this.doc.logDetails = updatedDoc.logDetails;
                }

                this.DocumentService.goToDocument({ doc: updatedDoc });

            }, onError);
    }

    openEditLogLegend(): void {
        this.openEditLogField('legend');
    }

    openEditLogInformation(): void {
        this.openEditLogField('information');
    }

    openEditDoaLogRolesResponsibilitiesModal(): void {
        this.StudyRolesService.setStudyRolesList$.pipe(take(1)).subscribe();

        const editRolesResponsibilitiesModalRef = this.Modals.show(DoaLogTemplateRolesResponsibilitiesEditComponent, {
            animated: true,
            class: 'modal-lg',
            initialState: {
                existingStudyResponsibilities: { ...this.doc.responsibilities },
                existingStudyRoles: [...this.doc.roles],
                studyRolesList$: this.StudyRolesService.studyRolesList$,
                studyRolesMap$: this.StudyRolesService.studyRolesMap$,
                isLoadingStudyRoles$: this.StudyRolesService.isLoadingStudyRoles$,
                isLogDocument: true
            }
        });

        editRolesResponsibilitiesModalRef.content.rolesResponsibilitiesEdited.pipe(
            switchMap(({ data, onSuccess, onError }) => (
                this.DocumentService.addDoaLogRolesResponsibilities(
                    this.doc.id as string,
                    {
                        responsibilities: data.studyResponsibilities.responsibilities,
                        roles: data.studyRoles.roles,
                        reason: data.studyRoles.reason
                    }
                ).pipe(
                    tap(() => {
                        this.setVersion(this.doc.version + 1);
                        onSuccess();
                    }),
                    catchError(() => {
                        onError();
                        return of(null);
                    })
                )
            )),
            take(1)
        ).subscribe();
    }

    private openEditLogField(field: 'legend' | 'information'): void {
        this.modalHelper.open({
            animation: true,
            component: 'log-edit-metadata-wrapper',
            size: 'md',
            resolve: {
                metadataType: (): string => field,
                document: (): Document => this.doc,
                save: () => ({ data, onSuccess, onError }: LogEditMetadataEvent): void => {
                    this.DocumentService.updateLogMetadata(this.doc.id as string, data)
                        .pipe(take(1)).subscribe(
                            (doc: Document) => {
                                onSuccess();
                                this.DocumentService.goToDocument({ doc });
                                const documentId = doc.id;
                                if (typeof documentId !== 'string' && documentId.version > this.doc.versions.length) {
                                    this.Notifications.success('Log version automatically updated');
                                }
                            },
                            onError
                        );
                }
            }
        });
    }

    replaceDocument(): void {

        const documentReplace = this.Modals.show(DocumentReplaceComponent, {
            initialState: {
                doc: this.doc
            }
        });

        documentReplace.content.onSubmit.subscribe((event: OnSubmitDocumentReplace) => {
            this.DocumentService.replace(
                event.doc, event.file, event.reason, event.clearExpiration, event.title, event.renameShortcuts
            )
                .toPromise()
                .then(() => {
                    event.onSuccess();
                })
                .catch(() => {
                    event.onError();
                });
        });
    }

    private get hasDocumentSharingPermission(): boolean {
        return !!this.currentTeam?.permissions?.documentSharingView;
    }

    private get hasTeamInboxPermission(): boolean {
        return this.currentTeam?.permissions?.viewTeamInbox
            || this.currentTeam?.permissions?.manageTeamInbox
            || this.currentTeam?.permissions?.performActionInTeamInbox;
    }

    private getCrumbs(): Crumb[] {
        const {
            teamId, binderId, binderName, folderId, path: docPath, name
        } = this.doc;
        const lineageCrumbs = [];
        const path = docPath as DocumentPathArray;
        if (path) {
            path.forEach((folder) => {
                lineageCrumbs.push({
                    name: folder.name,
                    stateName: 'app.team.folder',
                    stateParams: {
                        teamId,
                        binderId,
                        folderId: folder.id
                    },
                    icon: 'far fa-folder-open'
                });
            });
        }

        return [
            {
                name: 'My Downloads',
                icon: 'fa-download',
                stateName: 'app.team.downloads',
                stateParams: { teamId }
            },
            {
                name: 'Dashboard',
                icon: 'fas fa-tachometer-alt',
                stateName: 'app.team.dashboard-timelines',
                stateParams: {
                    teamId,
                    projectId: null
                }
            },
            {
                name: 'Reports',
                icon: 'far fa-newspaper',
                stateName: 'app.team.reports',
                stateParams: { teamId }
            },
            ...(this.hasDocumentSharingPermission ? [{
                name: 'Document Sharing',
                stateName: 'app.team.document-sharing',
                stateParams: {
                    teamId,
                    binderId
                },
                icon: 'fa fa-fw fa-users'
            }] : []
            ),
            ...(this.hasTeamInboxPermission ? [{
                name: 'Inbox',
                stateName: 'app.team.team-inbox',
                stateParams: {
                    teamId
                },
                icon: 'fa fa-fw fa-envelope'
            }] : []
            ),
            {
                name: 'Binders',
                stateName: 'app.team.binders',
                stateParams: { teamId },
                icon: 'fa-archive'
            },
            {
                name: 'Global View',
                stateName: 'app.team.global-view',
                stateParams: {
                    teamId,
                    objectId: folderId || binderId,
                    objectType: folderId ? 'folder' : 'binder',
                    objectName: folderId ? path[path.length - 1].name : binderName
                },
                icon: 'fa-globe'
            },
            {
                name: 'Announcements',
                stateName: 'app.team.announcements',
                stateParams: { teamId },
                icon: 'fa-bullhorn'
            },
            {
                name: binderName,
                stateName: 'app.team.folder',
                stateParams: {
                    teamId,
                    binderId
                },
                icon: 'fa-book u-font-style-italic'
            },
            ...lineageCrumbs,
            {
                name
            }
        ];
    }

    private constructDocumentFileUrl(): string {
        let documentFileUrl = `${this.AppConfig.config.ebindersApiUrl}/documents/${this.getDocumentId()}/versions/${this.doc.version}/file`;
        if (this.doc.isShortcut) {
            const contentVersion = this.doc.shortcutOf?.lockedToDocumentVersion
                || this.stateParams.contentVersion
                || this.doc.originalDocument?.id?.version;
            documentFileUrl = `${documentFileUrl}?contentVersion=${contentVersion}`;
        }
        return documentFileUrl;
    }
}

DocumentsShowController.$inject = [
    'AppConfigService',
    '$scope',
    'LogEntries',
    'MonitorReviews',
    'Studies',
    'Team',
    'Users',
    'SigningPasscode',
    '$state',
    '$timeout',
    '$q',
    'CurrentSession',
    'modalHelper',
    'ActionBar',
    'AuditTrail',
    'DocumentHistoryService',
    'DocumentAnnotationAdapter',
    'Downloads',
    'Notifications',
    'ApiError',
    'ModalsService',
    'QCReviewService',
    'DocumentAnnotationsService',
    'DocumentFormService',
    'SessionsService',
    '$window',
    'DocumentService',
    'StudyRolesService',
    'FeatureFlagService'
];

export default DocumentsShowController;
