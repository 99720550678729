import template from './signature.tool.html';
import controller from './signature.tool.controller';

const signatureToolComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        page: '<',
        font: '<?',
        fontSize: '<?', // FontSize in pixels
        lineHeight: '<?',
        color: '<?', // text color
        backgroundColor: '<?', // background color
        opacity: '<?', // the opacity of the background color,
        reason: '<?', // The reason for the signature
        defaultSigningReason: '<?'
    }
};

export default signatureToolComponent;
