import {
    Component,
    Input,
    OnInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';

import { Entity, DocumentSubTypes } from '@app/shared/models';
import { EntityTypeService } from '@app/widgets/entity/entity-type.service';
import { getIconClassFromFileExtension } from '@app/shared/documents/get-icon-class-from-file-extension.util';
import { ALLOWED_PREVIEW_FILE_TYPES } from '@florencehealthcare/florence-constants/lib/documents';

import { CurrentSessionService } from '@app/core/current-session.service';
import { placeholder } from '@app/shared/adapter/test-data.mock';
import styles from './entity-type-icon.component.scss';
import template from './entity-type-icon.component.html';

interface StackClasses {
    stackBaseClass: string;
    stackLayerClass: string;
    stackLayerColorClass: string;
}

@Component({
    selector: 'entity-type-icon',
    template,
    styles: [String(styles)]
})
export class EntityTypeIconComponent implements OnInit, OnChanges {
    @Input() item: Entity
    @Input() showLabelTooltip?= false;
    @Input() tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left' = 'top';
    @Input() conversionState?: {
        isFailed?: boolean;
    } = {};

    isStackedIcon: boolean;
    tooltipText: string;
    iconClass?: string;
    stackClasses?: StackClasses;

    newDocumentViewerEnabled: boolean;

    constructor(private EntityType: EntityTypeService, private CurrentSession: CurrentSessionService) { }

    ngOnInit(): void {
        this.newDocumentViewerEnabled = this.CurrentSession.getCurrentTeam().settings?.features?.newDocumentViewer;

        this.isStackedIcon = this._isStackedIcon();
        if (this.isStackedIcon) {
            this.stackClasses = this._getStackedIconClasses();
        }
        else {
            this.iconClass = this._getIconClass();
        }

        this.tooltipText = this._getTooltipText();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.item || changes.conversionState) {
            this.ngOnInit();
        }
    }

    private _isStackedIcon(): boolean {
        return (this.item.type === 'document' && this.item.subType === 'content') && !!((!this.newDocumentViewerEnabled && this.conversionState?.isFailed)
            || (this.newDocumentViewerEnabled && ALLOWED_PREVIEW_FILE_TYPES.indexOf(this.item.ext?.toLowerCase()) === -1));
    }

    private _getTooltipText(): string {
        let tooltipText = this.item['subType'] || this.item.type; // eslint-disable-line dot-notation
        if (this.item.type === 'document') {
            if (this.item.subType === 'content' && ((!this.newDocumentViewerEnabled && this.conversionState?.isFailed)
            || (this.newDocumentViewerEnabled && ALLOWED_PREVIEW_FILE_TYPES.indexOf(this.item.ext?.toLowerCase()) === -1))) {
                tooltipText = 'Document Preview Unavailable';
            }
            else if (this.item.subType === 'shortcut' && this.item.isBrokenShortcut) {
                tooltipText = 'Broken Shortcut';
            }
            else {
                tooltipText = this.EntityType.get(this.item);
            }
        }
        return tooltipText;
    }

    private _getStackedIconClasses(): StackClasses {
        return {
            stackBaseClass: 'far fa-file',
            stackLayerClass: 'fa-eye-slash',
            stackLayerColorClass: 'text-info'
        };
    }

    private _getIconClass(): string {
        switch (this.item.type) {
            case 'team':
                return 'fa-users';
            case 'binder':
                return 'fa-book u-font-style-italic';
            case 'folder':
                return 'far fa-folder-open';
            case 'document':
                if (this.item.subType === DocumentSubTypes.placeholder) {
                    return 'fas fa-bookmark';
                }
                if (this.item.subType === DocumentSubTypes.shortcut) {
                    return this.item.isBrokenShortcut ? 'fas fa-unlink' : 'fa-link';
                }
                if (this.item.subType === DocumentSubTypes.log) {
                    return 'fal fa-file-spreadsheet';
                }
                return this._getContentDocIconClass();
            case 'log-entry':
                return 'fal fa-file-spreadsheet';
            case 'log-template':
                return 'fa-clipboard-list';
            case 'study-templates':
                return 'fa-folder-tree';
            case 'project':
                return 'fa-briefcase';
            case 'timeline':
                return 'far fa-clock';
            case 'user':
                return 'fa-user';
            case 'role':
                return 'fa-user-md';
            case 'task':
                return 'fas fa-thumbtack';
            default:
                return '';
        }
    }

    private _getContentDocIconClass(): string {
        const fileNameOrExt = (this.item['filename'] || this.item['filenameCanonical'] || this.item['ext'] || this.item['extension'] || '').toLowerCase(); // eslint-disable-line dot-notation
        const iconClass = getIconClassFromFileExtension(fileNameOrExt);
        if (iconClass === 'far fa-file-pdf' && this.item.formStatus === 'form-in-progress') {
            return 'fa-file-pdf';
        }
        return iconClass;
    }
}
