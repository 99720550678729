import controller from './create-role.controller';
import template from './create-role.html';
import './create-role.scss';

const createRoleComponent: ng.IComponentOptions = {
    controller,
    controllerAs: 'vm',
    template,
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default createRoleComponent;
