import * as _ from 'lodash';
import { Transition } from '@uirouter/angularjs';

class RenameRoleController {
    constructor($scope, $transitions: Transition, REGEX, MESSAGES) {
        this._$scope = $scope;
        this.roleNamePattern = REGEX.names;
        this.nameMessage = MESSAGES.validNameMessage;

        this.isProcessing = false;
        this.showTooltip = {
            roleName: false
        };

        const deregister = $transitions.onExit({}, () => {
            this.cancel();
            deregister();
        });
    }

    $onInit(): void {
        this.role = _.cloneDeep(this.resolve.role);
        this.onRename = this.resolve.onRename;
        this.originalName = this.role.name;

        this._$scope.$on('modal.closing', this._onClosing.bind(this));
    }

    _onClosing(event, reason, closed) {
        if (!closed) {
            this.role.name = this.originalName;
        }
        const notAllowedToClose = reason && reason !== 'cancel' && !closed;

        if (this.isProcessing && notAllowedToClose) {
            event.preventDefault();
        }
    }

    cancel(): void {
        this.modalInstance.dismiss('cancel');
    }

    save() {
        this.isProcessing = true;

        this.onRename(this.role)
            .then(() => {
                this.modalInstance.close();
            })
            .finally(() => {
                this.isProcessing = false;
            });
    }
}

RenameRoleController.$inject = [
    '$scope',
    '$transitions',
    'REGEX',
    'MESSAGES'
];

export default RenameRoleController;
