import './checkbox.form.field.scss';
import template from './checkbox.form.field.html';
import controller from './checkbox.form.field.controller';

const checkboxFormFieldComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        doc: '<',
        data: '<',
        page: '<',
        isSelected: '<',
        isReadOnly: '<',
        onSelect: '&',
        onSelectNext: '&',
        onSelectPrevious: '&'
    }
};

export default checkboxFormFieldComponent;
