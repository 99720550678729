import * as angular from 'angular';
import textAnnotation from './text.annotation';
import highlight from './highlight';
import redaction from './redaction';
import annotation from './annotation';
import annotationBlock from './annotation.block';
import primitives from '../primitives';

const dependencies = [
    annotation.name,
    annotationBlock.name,
    highlight.name,
    redaction.name,
    textAnnotation.name,
    primitives.name
];
const annotations = angular.module('app.shared.documents.documentContentEditor.documentViewer.annotations', dependencies);

export default annotations;
