import { Transition } from '@uirouter/angularjs';

class LabelCreateController {
    constructor($transitions: Transition, REGEX) {
        this.isProcessing = false;
        this.label = {};
        this.maxLength = 250;
        this.namePattern = REGEX.labelNameValue;

        const deregister = $transitions.onExit({}, () => {
            this.cancel();
            deregister();
        });
    }

    $onInit(): void {
        this.onCreateLabel = this.resolve.onCreateLabel;
    }

    cancel(): void {
        this.modalInstance.dismiss('cancel');
    }

    validateValues() {
        if (!this.label.valuesText) {
            return;
        }

        this.label.values = this.label.valuesText.split('\n');
        // determine if a value is used more than once
        // or if a value contains special characters
        const reusedValues = [];
        const invalidValues = [];
        const tooLongValues = [];
        const valuesHash = {};
        this.label.values.forEach((v) => {
            valuesHash[v] = valuesHash[v] ? valuesHash[v] + 1 : 1;
        });
        Object.keys(valuesHash).forEach((v) => {
            if (valuesHash[v] > 1) {
                reusedValues.push(v);
            }
            if (!this.namePattern.test(v)) {
                invalidValues.push(v);
            }
            if (v.length >= this.maxLength) {
                tooLongValues.push(v);
            }
        });
        this.valuesError = '';
        if (reusedValues.length) {
            this.valuesError += `Values must be unique. These values appear more than once: ${reusedValues.join(',')}. `;
        }
        if (invalidValues.length) {
            this.valuesError += `Values cannot contain special characters > : ! # ^. These values are invalid: ${invalidValues.join(',')}.`;
        }
        if (tooLongValues.length) {
            this.valuesError += `Values cannot exceed ${this.maxLength} characters. There are ${tooLongValues.length} value(s) that are too long.`;
        }
    }

    onSubmit() {
        this.validateValues();
        if (this.valuesError) {
            return;
        }

        const {
            description,
            name,
            values
        } = this.label;

        const params = {
            description,
            name,
            values
        };

        this.isProcessing = true;
        this.onCreateLabel(params)
            .then(this.cancel.bind(this))
            .catch(() => {
                this.isProcessing = false;
            });
    }
}

LabelCreateController.$inject = ['$transitions', 'REGEX'];

export default LabelCreateController;
