import './document.toolbar.scss';
import template from './document.toolbar.html';
import controller from './document.toolbar.controller';

const documentToolbarComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        doc: '<',
        inFormMode: '<',
        hasSignatureRequest: '<',
        requestIsPastDue: '<',
        teamSignatureRestricted: '<',
        showClearAll: '<',
        currentTextAnnotationValue: '<',
        onSign: '&',
        onClearAll: '&',
        qcAcceptDocument: '&',
        qcDeclineDocument: '&',
        approveQcReview: '&',
        rejectQcReview: '&'
    }
};

export default documentToolbarComponent;
