import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { StructureTemplate } from '@app/components/study-startup/components/imported-structures-table/imported-structures-table.component.types';
import { StudyStartupService } from '@app/components/study-startup/study-startup.service';
import { AssignStructureTemplateParams, AssignStructureTemplateResponse } from '@app/components/study-startup/study-startup.service.types';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { FolderizeTreeItem } from '@app/shared/folders/folders.service.types';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { Binder, Folder } from '@app/shared/models';
import { StateOrName, StateService } from '@uirouter/core';
import { sortFolderStructureLexicographically } from '../sort/sort-folder-structure-lexicographically';
import template from './apply-reusable-structure-template.component.html';
import styles from './apply-reusable-structure-template.component.scss';
import { StructureTemplateAppliedDueDatesComponent } from '../structure-template-applied-due-dates/structure-template-applied-due-dates.component';
import { hasPlaceholderInStructure } from '../structure-template/structure-template-apply-due-dates.util';

@Component({
    template,
    styles: [String(styles)]
})
export class ApplyReusableStructureTemplateComponent {
    @Input() teamId: string;
    @Input() teamTimezone: string = null;
    @Input() binderId: string;
    @Input() folderId: string;
    @Input() templates: StructureTemplate[] = [];
    @Input() canManageDocumentDueAndExpirationDates: boolean;

    templateStructureIsLoading = false;
    selectedTemplate: StructureTemplate;
    folderizeTreeItems: FolderizeTreeItem[] = [];
    isProcessing = false;

    constructor(
        private modalRef: BsModalRef,
        private Modals: ModalsService,
        private Notifications: NotificationsService,
        private StudyStartup: StudyStartupService,
        private $state: StateService
    ) { }

    hideModal(): void {
        this.modalRef.hide();
    }

    onTemplateSelected(selectedTemplate: StructureTemplate): void {
        this.selectedTemplate = selectedTemplate;

        if (!selectedTemplate) {
            this.folderizeTreeItems = [];
            return;
        }

        this.templateStructureIsLoading = true;
        this.StudyStartup.getFolderStructureById(selectedTemplate.id, this.teamId)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    this.folderizeTreeItems = sortFolderStructureLexicographically(data.tree);
                    this.templateStructureIsLoading = false;
                },
                (error: HttpErrorResponse) => {
                    this.templateStructureIsLoading = false;
                    this.folderizeTreeItems = [];
                    const message = (error && error.error && error.error.message);
                    message ? this.Notifications.error(message) : this.Notifications.unexpectedError();
                }
            );
    }

    redirectToStructurePage(data: Binder | Folder): void {
        if (!data) {
            return;
        }
        const state: StateOrName = 'app.team.folder';
        const params: {[key: string]: string;} = { teamId: data.teamId };
        if (data.type === 'binder') {
            params.binderId = data.id;
        }
        else {
            params.binderId = data.binderId;
            params.folderId = data.id;
        }
        this.$state.go(state, params);
    }

    openConfirmationModal(assignStructureResponse: AssignStructureTemplateResponse): void {
        if (this.canManageDocumentDueAndExpirationDates && hasPlaceholderInStructure(assignStructureResponse.structure)) {
            this.Modals.show(StructureTemplateAppliedDueDatesComponent, {
                animated: true,
                initialState: {
                    data: assignStructureResponse
                }
            });
        }
        else {
            this.Notifications.success('Structure template successfully applied');
            this.redirectToStructurePage(assignStructureResponse.folder ?? assignStructureResponse.binder);
        }
        this.hideModal();
    }

    submit(): void {
        this.isProcessing = true;

        const params: AssignStructureTemplateParams = {
            addedLabels: [],
            binderId: this.binderId,
            folderId: this.folderId,
            isFlat: false
        };

        this.StudyStartup.assignStructure(this.teamId, this.selectedTemplate.id, params)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    this.openConfirmationModal(data);
                },
                (error: HttpErrorResponse) => {
                    const message = (error && error.error && error.error.message);
                    message ? this.Notifications.error(message) : this.Notifications.unexpectedError();
                    this.isProcessing = false;
                }
            );
    }
}
