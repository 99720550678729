import template from './redact.tool.html';
import controller from './redact.tool.controller';

const redactToolComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        page: '<'
    }
};

export default redactToolComponent;
