import { ROLE_TEMPLATES_AUDIT_ACTIONS } from '@florencehealthcare/florence-constants';
import { HttpErrorResponse } from '@angular/common/http';
import {
    Binder, BrowseNode, BrowseTree, Digest, Document, Folder, Role, User
} from '../models';
import { PermissionTree } from '../permissions/permissions.service.types';

export interface BrowseParams {
    objectId?: string;
    objectType?: 'folder' | 'binder';
    binderId?: string;
    lineage?: Array<string>;
    includeDocs?: boolean;
    includeDecorations?: boolean;
    tagIds?: string[];
    subTypes?: string[];
    includeArchived?: boolean;
}

export interface BrowseResponse {
    root: BrowseTree;
}

interface RoleAssignmentCreateParams {
    userId: string;
    roleId: string;
    start?: string;
    end?: string;
    isActive?: boolean;
}

interface RoleAssignmentUpdateParams {
    userId: string;
    roleId: string;
    start?: string;
    end?: string;
    isActive?: boolean;
}

interface RoleAssignmentDeleteParams {
    userId: string;
    roleId: string;
}

export interface UpdateRoleAssignmentMultipleParams {
    creates?: RoleAssignmentCreateParams[];
    updates?: RoleAssignmentUpdateParams[];
    deletes?: RoleAssignmentDeleteParams[];
}
export enum GlobalViewMonitorReviewFilter {
    openQuery = 'openQuery',
    approved = 'approved',
    reviewed = 'reviewed'
}
export type GlobalViewCountKey = 'openQuery' | 'approved' | 'reviewed' | 'almostDue' | 'almostExpired' | 'documents' | 'due' | 'expired' | 'pendingSignatures' | 'pendingSignaturesAlmostDue' | 'pendingSignaturesDue' | 'pendingTasks' | 'placeholders' | 'revisionsRequested';
export type GlobalViewTotalCounts = { [key in GlobalViewCountKey]: number }

export type GlobalViewTreeParamValue = string | boolean | GlobalViewCountKey | GlobalViewMonitorReviewFilter | null | undefined;

export type GlobalViewTreeParams = {
    objectId: string;
    objectType: string;
    includeDocs?: boolean;
    nameFilter?: string;
    statFilter?: GlobalViewCountKey;
    monitorReviewFilter?: GlobalViewMonitorReviewFilter;
    includeArchived?: boolean;
}
export interface GetObjectTreeResponse {
    root?: BrowseTree;
    totalCounts?: GlobalViewTotalCounts;
}

export interface FrequencySettings {
    days: number[];
    hour: number;
}

export interface UpsertDigestSubscriptionParams {
    teamId: string;
    digestToUpdate: Digest;
    frequency: string;
    frequencySettings: FrequencySettings;
    sendIfZero: boolean;
    teamMemberId: string;
}

export interface GetDigestsInput {
    teamId: string;
    teamMemberId?: string;
}

export interface AssignTagsResponse {
    result: {
      updatedCount: number;
    };
}

export interface RoleTemplatesAuditsFilter {
    studyNumbers: string[],
    practiceNames: string[],
    siteCredentialsNames: string[],
    rolesTemplateName: string,
    userEmails: string[],
    checkExistence: boolean
}

export interface CreateRolesTemplatesAuditParams {
    action: typeof ROLE_TEMPLATES_AUDIT_ACTIONS;
    requestedOn: Date;
    userId: string;
    filters: RoleTemplatesAuditsFilter
}

export interface GetRolesTemplatesAuditsResultsParams {
    action: typeof ROLE_TEMPLATES_AUDIT_ACTIONS;
    id: string;
    requestedOn: Date;
    userId: string;
    filters: RoleTemplatesAuditsFilter
}

export interface RoleTemplatesEntries {
    level: string;
    objects?: string[];
    privileges: string[]
}

export interface RoleTemplate {
    id: string;
    teamId: string;
    roleName: string;
    entries: RoleTemplatesEntries[];
}
export interface CreateRoleTemplateAuditResult {
    id: string;
    action: string;
    rolesTemplatesAuditId: string;
    roleName: string;
    missing: boolean;
    entries: RoleTemplatesEntries[];
    users: string[];
    userId: string;
    teamId: string;
    filters: RoleTemplatesAuditsFilter
}

export interface AssignUsersToRolesParams {
    action: string;
    roles: {
        roleName: string;
        userEmails: string[]
    }
}

interface UserAssignments {
    email: string;
    missing: boolean;
}

export interface AssignUsersToRolesResponse {
        roleName: string;
        usersAssignments: UserAssignments[]
}

export interface SubjectPermissionForObject {
    subject: string;
    tree: PermissionTree
}

export interface UpdatePermissionsParams {
    subject: Role;
    subjectId: string;
    subjectType: string;
    tree: PermissionTree
}

export interface TreeWithDocs {
    binders: Binder[];
    trees: BrowseNode[];
}

export interface CreateShortcutResult {
    originalDocument: Document;
    shortcut: Document;
    target: Binder | Folder;
    error?: HttpErrorResponse | Record<string, never>
}
export interface CreateDocumentShortcutReponse {
    errors: CreateShortcutResult[],
    results: CreateShortcutResult[]
}

export interface BulkUpdateTaskParams {
    teamId: string,
    remind: { taskId: string, userIds: string[] }[]
}

export interface BulkUpdateTaskResult {
    task: Task,
    identifiers: {
        taskId: string;
    }
}

export interface BulkUpdateTaskResponse {
    errors: {
        identifiers: {
            taskId: string;
        },
        error: HttpErrorResponse | Record<string, never>

    }[],
    results: BulkUpdateTaskResult[]
}

export interface ExecuteRemoveAllPermissionsParams {
    permissions: {
        finalState: { [key: string]: string }[],
        originalState: { [key: string]: string }[]
    }
    roles: Role[]
    subject: User
}

export interface LoadUsersParams {
    teamId: string;
    pageNum?: number;
    pageSize?: number;
    sortBy?: string;
    sortDir?: string;
    filter?: string;
}

export interface LoadUsersResponse {
    items: User[];
    recordCount: number;
}
