import template from './timestamp.html';
import controller from './timestamp.controller';

const timestampToolComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        page: '<',
        font: '<?',
        fontSize: '<?', // FontSize in pixels
        lineHeight: '<?',
        color: '<?', // text color
        backgroundColor: '<?', // background color
        opacity: '<?' // the opacity of the background color
    }
};

export default timestampToolComponent;
