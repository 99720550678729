import template from './tool.html';
import controller from './tool.controller';

const toolComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        mode: '<',
        page: '<',
        signatureData: '<',
        defaultSigningReason: '<?'
    }
};

export default toolComponent;
