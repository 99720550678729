import * as _ from 'lodash';

import Events from '../../../constants/document.content.events';

class PageRegionController {
    constructor($scope, DocumentViewerRotation, $timeout) {

        this._$scope = $scope;
        this._DocumentViewerRotation = DocumentViewerRotation;
        this._$timeout = $timeout;
        this.styles = {};
    }

    $onInit(): void {
        this.interactive = _.isUndefined(this.interactive) ? false : this.interactive;
        this.enforceBounds = _.isUndefined(this.enforceBounds) ? true : this.enforceBounds;

        this._$scope.$watchGroup([
            'vm.range',
            'vm.range.start.x',
            'vm.range.start.y',
            'vm.range.end.x',
            'vm.range.end.y',
            'vm.zIndex',
            'vm.enforceBounds'
        ], (newVal) => {
            if (newVal) {
                const left = Math.min(this.range.start.x, this.range.end.x);
                const right = Math.max(this.range.start.x, this.range.end.x);
                const top = Math.min(this.range.start.y, this.range.end.y);
                const bottom = Math.max(this.range.start.y, this.range.end.y);

                this.styles.left = `${left * 100}%`;
                this.styles.top = `${top * 100}%`;
                if (this.enforceBounds) {
                    this.styles.right = `${(1 - right) * 100}%`;
                    this.styles.bottom = `${(1 - bottom) * 100}%`;
                }
                else {
                    this.styles.right = undefined;
                    this.styles.bottom = undefined;
                }
                this.styles.zIndex = this.zIndex;

                this._calculateRotation();
            }
        });

        this._$scope.$on(Events.PAGE_RESIZE, (event, { zoomFactor }) => {
            this._$timeout(() => {
                // update font size when resize is done, and page is re-rendered
                this._calculateRotation();
                this.styles.fontSize = `${14 * zoomFactor}px`;
            });
        });

        this._$scope.$on(Events.PAGE_ROTATE, this._calculateRotation.bind(this));
    }

    _calculateRotation() {
        const { transform, transformOrigin } = this._DocumentViewerRotation
            .calculateRegionRotation(this.page, this.styles.left, this.styles.top);

        this.styles.WebkitTransformOrigin = transformOrigin;
        this.styles.transformOrigin = transformOrigin;
        this.styles.WebkitTransform = transform;
        this.styles.transform = transform;
    }
}

PageRegionController.$inject = ['$scope', 'DocumentViewerRotation', '$timeout'];

export default PageRegionController;
