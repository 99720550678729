import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { StateService } from '@uirouter/core';
import { FlatTreeControl } from '@angular/cdk/tree';

import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { FoldersService } from '@app/shared/folders/folders.service';
import { AdapterService } from '@app/shared/adapter/adapter.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { DocumentService } from '@app/shared/documents/document.service';

import { DocumentId } from '@app/shared/models';
import {
    GlobalViewItemDecoration,
    GlobalViewItemDecorationProperty,
    globalViewItemIsBinder,
    globalViewItemIsDocument,
    globalViewItemIsFolder
} from './global-view-item.types';

import template from './global-view-item.component.html';
import styles from './global-view-item.component.scss';
import { BindersService } from '../../../../shared/binders/binders.service';
import { GlobalViewTreeFlatNode } from '../../containers/global-view/global-view.component.types';

@Component({
    selector: 'global-view-item',
    template,
    styles: [String(styles)]
})
export class GlobalViewItemComponent implements OnInit, OnChanges {
    @Input() item: GlobalViewTreeFlatNode;
    @Input() teamId: string;
    @Input() isRoot: boolean;
    @Input() teamTimezone: string;
    @Output() expandTree = new EventEmitter<void>();
    @Output() collapseTree = new EventEmitter<void>();
    @Output() expandNode = new EventEmitter<void>();
    @Output() collapseNode = new EventEmitter<void>();

    @Input() treeControl: FlatTreeControl<GlobalViewTreeFlatNode>;
    readonly loadedTooltipPopupDelay = 250;
    itemHref: string;
    loading: { [key in GlobalViewItemDecorationProperty]: boolean } = {
        pendingSignatures: false,
        pendingTasks: false,
        timelines: false,
        directLabels: false
    };

    constructor(
        private $state: StateService,
        private Binders: BindersService,
        private Folders: FoldersService,
        private Adapter: AdapterService,
        private Notifications: NotificationsService,
        private Documents: DocumentService
    ) { }

    get itemIsAlmostDueOrExpired(): boolean {
        if (globalViewItemIsDocument(this.item)) {
            return this.item.isAlmostDue || this.item.isAlmostExpired;
        }
        return false;
    }

    get itemIsDueOrExpired(): boolean {
        if (globalViewItemIsDocument(this.item)) {
            return this.item.isDue || this.item.isExpired;
        }
        return false;
    }

    get iconIsSuccess(): boolean {
        let hasPendingTasksOrSigRequests = false;
        if (globalViewItemIsDocument(this.item)) {
            hasPendingTasksOrSigRequests = this.item.hasPendingTasks || this.item.hasPendingSignatures;
        }

        const isAnEmptyFolder = globalViewItemIsFolder(this.item) && !this.item.items?.length;

        return !this.itemIsDueOrExpired
            && !this.itemIsAlmostDueOrExpired
            && !hasPendingTasksOrSigRequests
            && !isAnEmptyFolder
            && !this.item.expanded;
    }

    ngOnInit(): void {
        if (this.isRoot) {
            this.expandNode.emit();
        }

        this.itemHref = this.generateHref();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.item.state = {
            conversion: this.Adapter.getConversionState(this.item),
            due: this.Adapter.getDueState(this.item),
            expiration: this.Adapter.getExpirationState(this.item),
            signatures: this.Adapter.getSignatures(this.item)
        };

        if (changes.item) {
            this.itemHref = this.generateHref();
        }
    }

    private generateHref(): string {
        if (globalViewItemIsBinder(this.item)) {
            return this.$state.href('app.team.folder', {
                teamId: this.teamId,
                binderId: this.getItemId()
            });
        }
        if (globalViewItemIsFolder(this.item)) {
            return this.$state.href('app.team.folder', {
                teamId: this.teamId,
                binderId: this.item.binderId,
                folderId: this.getItemId()
            });
        }
        if (globalViewItemIsDocument(this.item)) {
            let contentVersion: number;
            if (this.item.subType === 'shortcut') {
                const originalDocumentId = this.item.originalDocument?.id as DocumentId;
                contentVersion = this.item.shortcutOf?.lockedToDocumentVersion || originalDocumentId?.version;
            }
            return this.$state.href('app.team.document-show', {
                teamId: this.teamId,
                documentId: this.getItemId(),
                version: this.item.version,
                ...contentVersion && { contentVersion }
            });
        }
    }

    private getItemId(): string {
        return this.item.id || this.item._id;
    }

    toggleExpand(): void {
        if (!this.isRoot) {
            this.item.expanded ? this.collapseNode.emit() : this.expandNode.emit();
        }
    }

    private loadItemDecoration(
        decorationPropertyName: GlobalViewItemDecorationProperty,
        getterFunction: () => Observable<GlobalViewItemDecoration>
    ): void {

        if (this.loading[decorationPropertyName]) {
            return;
        }
        const alreadyLoadedData = this.item[decorationPropertyName] && this.item[decorationPropertyName].length;
        if (alreadyLoadedData) {
            return;
        }
        this.loading[decorationPropertyName] = true;
        getterFunction()
            .subscribe(
                (array) => {
                    this.item[decorationPropertyName] = array;
                    this.loading[decorationPropertyName] = false;
                },
                ({ error }) => {
                    const message = error.message || 'There was an unexpected error.  Please contact your administrator.';
                    this.Notifications.error(message);
                }
            );
    }

    loadTimelines(): void {
        this.loadItemDecoration('timelines', () => this.Documents.getTimelines(this.getItemId()));
    }

    loadTasks(): void {
        if (globalViewItemIsFolder(this.item) || globalViewItemIsBinder(this.item)) {
            return;
        }
        this.loadItemDecoration('pendingTasks', () => this.Documents.getTasks(this.getItemId()));
    }

    loadSignatureRequests(): void {
        if (globalViewItemIsFolder(this.item) || globalViewItemIsBinder(this.item)) {
            return;
        }
        this.loadItemDecoration('pendingSignatures', () => this.Documents.getSignatureRequests(this.getItemId()));
    }

    loadItemLabels(): void {
        if (globalViewItemIsBinder(this.item)) {
            this.loadItemDecoration('directLabels', () => this.Binders.getDirectLabels(this.teamId, this.getItemId()));
        }
        else if (globalViewItemIsFolder(this.item)) {
            const { binderId } = this.item;
            this.loadItemDecoration('directLabels', () => this.Folders.getDirectLabels({
                teamId: this.teamId, binderId, folderId: this.getItemId()
            }));
        }
    }

    sortMonitorReviewData(item): void {
        if (!item.monitorReviewData || item.monitorReviewData._isSorted) {
            return;
        }
        item.monitorReviewData.approvals = _.sortBy(item.monitorReviewData.approvals, ['paywallName', 'studyLabelValueName', 'documentVersion']);
        item.monitorReviewData.openQueries = _.sortBy(item.monitorReviewData.openQueries, ['paywallName', 'studyLabelValueName']);
        item.monitorReviewData._isSorted = true;
    }
}
