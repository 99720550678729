import './document.content.editor.scss';
import template from './document.content.editor.html';
import controller from './document.content.editor.controller';

const documentEditComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        doc: '<',
        currentDisplayVersion: '<',
        hasSignatureRequest: '<',
        signatureRequestType: '<',
        requestIsPastDue: '<',
        teamSignatureRestricted: '<',
        monitorReviewsEnabled: '<',
        monitorReviews: '<',
        currentTeam: '<',
        currentUser: '<',
        appConfig: '<',
        documentUrl: '<',
        token: '<',
        fhcDocViewerAnnotations: '<',
        jobTitleRequired: '<',
        replaceDocument: '&',
        reloadDocument: '&',
        openTask: '&',
        onMonitorReviewsUpdate: '&',
        onRequestDownload: '&',
        onSign: '&',
        onSetVersion: '&',
        onSave: '&',
        onQcAcceptDocument: '&',
        onQcDeclineDocument: '&',
        onApproveQcReview: '&',
        onRejectQcReview: '&',
        onSaveAnnotationChanges: '&',
        onClearAll: '&',
        onFormSave: '&'
    },
    transclude: true
};

export default documentEditComponent;
