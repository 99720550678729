import './document-page-load-error.scss';
import template from './document-page-load-error.html';
import controller from './document-page-load-error.controller';

export default {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};
