import * as angular from 'angular';
import signatureToolComponent from './signature.tool.component';

const dependencies = [

];

const signatureTool = angular.module('app.shared.documents.documentContentEditor.documentViewer.submodules.tools.signatureTool', dependencies)
    .component('signatureTool', signatureToolComponent);

export default signatureTool;
