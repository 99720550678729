import * as _ from 'lodash';
import uuid from 'uuid';
import {
    Annotation,
    AnnotationStyles,
    Page
} from '@app/shared/models';
import Actions from '../../../constants/document.content.actions';
import Events from '../../../constants/document.content.events';

const FocusState = {
    NOT_FOCUSED: 0,
    CONTENT_FOCUSED: 1
};

// Save some regexes for manipulating newlines in a cross-platform way
// const windowsNewlineSeriesRegex = /((\r\n)+)/g;
const windowsNewlineSet = /(\r\n){3}/g;
// const newlineRegex = /\n/g;
const trailingWhitespace = /\s+$/;
// const lastWindowNewlineRegex = /((\r\n)+)(\r\n)/g;

class TextAnnotationController {
    data: Annotation;
    interactive: boolean;
    enableMove: boolean;
    page: Page;
    isSelected: boolean;
    isContentEditable: boolean;
    isTouchDevice: boolean;
    enforceBounds: boolean;
    onSelect: ({ $event: object }) => void;;
    onSelectNext: ({ $event: object }) => void;;
    onSelectPrevious: ({ $event: object }) => void;;
    id: string;
    fontSize: number;
    style: AnnotationStyles;
    focusState;
    properties;
    _shouldHandleWindowsNewlineFormat: boolean;
    _hasRecalculatedSize: boolean;
    constructor(
        $scope, $window, AnnotationPropagation,
        $timeout, DocumentAnnotation
    ) {
        this._$scope = $scope;
        this._$window = $window;
        this._AnnotationPropagation = AnnotationPropagation;
        this._$timeout = $timeout;
        this._DocumentAnnotation = DocumentAnnotation;

        this.FocusState = FocusState;
        this.id = uuid.v4();
        this._shouldHandleWindowsNewlineFormat = false;
        this._hasRecalculatedSize = false;
    }

    $onInit(): void {
        this._addListeners();
        this._setupWatchers();

        this.isContentEditable = _.isUndefined(this.isContentEditable) ? true : this.isContentEditable;
        this.enableMove = _.isUndefined(this.enableMove) ? true : this.enableMove;
        this._setFocusState(FocusState.NOT_FOCUSED);
        this.isTouchDevice = this._isTouchDevice();

        this._setProperties();
        this._deriveProperties({ textValueChanged: true });
        this.focus();
    }

    $onDestroy(): void {
        this._cleanupListeners();
    }

    $onChanges(changes = {}): void {
        if (!changes.isSelected) {
            return;
        }

        if (changes.isSelected.currentValue) {
            this.focus({ moveCursorToEnd: true });
        }
        else if (!changes.isSelected.isFirstChange() && !changes.isSelected.currentValue) {
            this.onSelectedFalse();
        }
    }

    _setupWatchers() {
        this._$scope.$watchCollection('vm.properties', (newValue, oldValue) => {
            this._deriveProperties({ textValueChanged: newValue.textValue !== oldValue.textValue });
        });
    }

    _setProperties(): void {
        this.properties = this._DocumentAnnotation.getAnnotationPropertiesForPage({ page: this.page, annotation: this.data });
    }

    onOutsideMouseup(): void {
        this._$timeout(() => {
            const textBoxEl = this._getTextBoxElement();
            const rawTextValue = this._getRawTextContent(textBoxEl);
            const textValue = this._cleanTextContent(rawTextValue);

            this._setTextContent(textValue, textBoxEl);
            const properties = { textValue };
            if (!this.enforceBounds && textBoxEl) {
                this._hasRecalculatedSize = true;
                // Remove minWidth to get true sizing
                this.style.minWidth = null;
                textBoxEl.style.minWidth = '0';
                _.assign(properties, this._getRelativeTextboxSize(textBoxEl));
            }
            this._$scope.$emit(Actions.ANNOTATION_UPDATE_PROPERTIES, { annotation: this.data, page: this.page, properties });
        });
    }

    _deriveProperties({ textValueChanged }): void {
        this.fontSize = this.data.fontSize;
        const displayedFontSize = this._getDisplayedFontSize();
        this.style = {
            fontFamily: `${this.properties.font}, sans-serif`,
            fontSize: `${displayedFontSize}px`,
            minHeight: `${displayedFontSize * 1.5}px`,
            lineHeight: this.properties.lineHeight,
            backgroundColor: 'transparent',
            color: this.properties.color,
            // Signature annotations (or all) MUST have padding bottom set to at least 1 line height (em)
            // (to compensate possible text wrapping)
            padding: '.2em 1.25em 1.25em .2em'
        };

        if (this.properties.textValue.length === 0 && !this.enforceBounds) {
            // Set min width on empty text annotations
            this.style.minWidth = '50px';
        }

        this._$timeout(() => {
            const textValue = textValueChanged ? this.properties.textValue : this._getRawTextContent();
            this._setTextContent(textValue);
        });
    }

    _getTextBoxElement(): HTMLDivElement {
        return this._$window.document.getElementById(`text-box-${this.properties.id}-${this.page.pageIndex}`);
    }

    _addListeners(): void {
        this._$scope.$on(Events.PAGE_RESIZE, this._onPageResize.bind(this));

        const listenerCleanup = [];
        const params = { page: this.page, annotation: this.data };
        listenerCleanup.push(
            this._DocumentAnnotation.onAnnotationModified(params, this._setProperties.bind(this))
        );

        this._cleanupListeners = (): void => {
            listenerCleanup.forEach((cleanup) => {
                cleanup();
            });
        };
    }

    _onPageResize(): void {
        this._$timeout(() => {
            // update font size when resize is done, and page is re-rendered
            const displayedFontSize = this._getDisplayedFontSize();
            this.style.fontSize = `${displayedFontSize}px`;
        });
    }

    _getDisplayedFontSize(): number {
        const zoomFactor = 100 / (this.data.page.height / this.data.page.element.clientHeight);
        return (this.data.fontSize / 100) * zoomFactor;
    }

    onClick(): void {
        if (this._isTouchDevice() || !this.enableMove) {
            this.focus();
        }
    }

    onDoubleClick(): void {
        if (!this._isTouchDevice()) {
            this.focus();
        }
    }

    onKeyDown($event): void {
        if ($event.key !== 'Tab') {
            return;
        }
        $event.preventDefault();

        $event.shiftKey ? this.onSelectPrevious() : this.onSelectNext();
    }

    focus({ moveCursorToEnd }: { moveCursorToEnd?: boolean } = {}): void {
        if (!this.isSelected) {
            return;
        }
        if (this.isContentEditable) {
            this._setFocusState(FocusState.CONTENT_FOCUSED);
        }
        this._$timeout(() => {
            const textBoxEl = this._getTextBoxElement();
            if (!textBoxEl) {
                return;
            }
            textBoxEl.focus();
            if (this.isEditable() && moveCursorToEnd) {
                this._$window.document.execCommand('selectAll', false, null);
                this._$window.document.getSelection().collapseToEnd();
            }
        });
    }

    isEditable(): boolean {
        return this.isContentEditable && (this._isTouchDevice() || this.focusState === this.FocusState.CONTENT_FOCUSED);
    }

    handleTextChange() {
        if (this.isContentEditable) {
            const textBoxEl = this._getTextBoxElement();
            const currentTextValue = this._getRawTextContent(textBoxEl);

            const textAnnotationValue = {
                textValue: currentTextValue
            };
            this._$scope.$emit(Actions.TEXT_ANNOTATION_UPDATE_VALUE, { textAnnotationValue });
        }
    }

    _setFocusState(state): void {
        this.focusState = state;
    }

    _isContentFocused(): boolean {
        return this.focusState === FocusState.CONTENT_FOCUSED;
    }

    onSelectedFalse(): void {
        const textBoxEl = this._getTextBoxElement();
        const rawTextValue = this._getRawTextContent(textBoxEl);
        const textValue = this._cleanTextContent(rawTextValue);
        const textValueIsEmpty = textValue.trim().length === 0;
        // Text value has changed if the cleaned value differs from the original or stored value
        // Also, assume text has changed if the text box is currently focused, just to be safe
        const textValueHasChanged = textValue !== this.properties.textValue
            || rawTextValue !== textValue
            || this._isContentFocused();
        const needToInitializeTextboxSize = !this._hasRecalculatedSize && !this.enforceBounds;
        if (textValueIsEmpty) {
            // Delete empty textboxes
            this._$scope.$emit(Actions.ANNOTATION_DELETE, { annotation: this.data, page: this.page });
        }
        else if (textValueHasChanged || needToInitializeTextboxSize) {
            // Text has been updated or size needs to be calculated
            // Tool will initialize text values with no width, so make sure to update in this case
            this._setTextContent(textValue, textBoxEl);
            const properties = {
                textValue
            };
            if (!this.enforceBounds && textBoxEl) {
                this._hasRecalculatedSize = true;
                // Remove minWidth to get true sizing
                this.style.minWidth = null;
                textBoxEl.style.minWidth = '0';
                _.assign(properties, this._getRelativeTextboxSize(textBoxEl));
            }
            this._$scope.$emit(Actions.ANNOTATION_UPDATE_PROPERTIES, { annotation: this.data, page: this.page, properties });
        }
        this._setFocusState(FocusState.NOT_FOCUSED);
    }

    _getPropagationScale() {
        // Handle scaling in case this is a propagated annotation for a page of a different size
        return this._AnnotationPropagation.getPropagationScale(this.data.page, this.page);
    }

    _getRelativeTextboxSize(textBoxEl: HTMLDivElement): { w: number; h: number } {
        const textBox = textBoxEl || this._getTextBoxElement();
        const { clientHeight: textBoxHeight, clientWidth: textBoxWidth } = textBox;
        const { clientHeight: pageHeight, clientWidth: pageWidth } = this.page.element;

        return {
            w: textBoxWidth / pageWidth,
            h: textBoxHeight / pageHeight
        };
    }

    _setTextContent(content: string, textBoxEl: HTMLDivElement | undefined): void {
        if (this._shouldHandleWindowsNewlineFormat) {
            // @todo - figure out how to handle windows newlines
            // For now, just don't udpate text on windows
            // The effect of skipping this is that extra trailing whitespace may be left
            // in the textbox and therefore the bounding box will be larger than it has to be
            return;

            // Expand each newline into 3 newlines
            // content = content.replace(newlineRegex, '\r\n\r\n\r\n');
            // Remove the last newline
            // content = content.replace(lastWindowNewlineRegex, '$1');
        }
        textBoxEl = textBoxEl || this._getTextBoxElement();
        if (!textBoxEl) {
            return;
        }

        if (this.data.type === 'signature') {
            textBoxEl.innerHTML = this._signatureTemplate(content);
            return;
        }
        textBoxEl.innerText = content;
    }

    _getRawTextContent(textBoxEl: HTMLDivElement): string {
        textBoxEl = textBoxEl || this._getTextBoxElement();
        if (!textBoxEl) {
            return '';
        }

        return textBoxEl.innerText;
    }

    _cleanTextContent(content): string {
        // Handle weird windows newline format
        content = this._standardizeWindowsNewlineCharacters(content);
        // Remove trailing whitespace
        content = content.replace(trailingWhitespace, '');
        return content;
    }

    _standardizeWindowsNewlineCharacters(str: string): string {
        const originalLength = str.length;
        // Make newline format consistent across operating systems
        // Windows will handle n carriages returns with n * 3 - 1 \r\n char sequences
        // Add one additional \r\n so we can then compress 3 returns into 1

        // This seemed to be adding a bunch of extra new lines and caused EBS-3176
        // Removing it sees text and signatures working well in IE 11, Edge, and chrome
        // str = str.replace(windowsNewlineSeriesRegex, '$1\r\n');
        str = str.replace(windowsNewlineSet, '\n');
        if (originalLength > str.length) {
            // We're on a windows machine
            this._shouldHandleWindowsNewlineFormat = true;
        }
        return str;
    }

    _signatureTemplate(content): string {
        // Signature is inserted into `white-space: pre` tag, avoid having extra spaces
        return '<div class="u-d-flex u-align-items-flex-start ng-class="{text-annotation__hidden: content != null}">'
            + '<span class="text-annotation__signature-icon"></span>'
            + `<span>${content.trim()}</span>`
            + '</div>';
    }

    // TODO: Move method that duplicates document.viewer.message.controller to a util class
    _isTouchDevice(): boolean {
        const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

        if (('ontouchstart' in this._$window) || this._$window.DocumentTouch) {
            return true;
        }

        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return this._$window.matchMedia(query).matches;
    }
}

TextAnnotationController.$inject = [
    '$scope', '$window', 'AnnotationPropagation',
    '$timeout', 'DocumentAnnotation'
];

export default TextAnnotationController;
