import * as angular from 'angular';

const AutoHeightDirective = function AutoHeightDirective($window) {
    return {
        restrict: 'A',
        require: '^mdVirtualRepeatContainer',
        link: function autoHeightLink(scope, element, attrs, mdVirtualRepeatContainer): void {
            const getHeight = () => {
                const height = $window.innerHeight - (element[0].getBoundingClientRect().top + $window.pageYOffset);
                angular.element(element).css('height', `${height}px`);
                return height;
            };

            const onResize = () => {
                mdVirtualRepeatContainer.setSize_(getHeight());
            };
            onResize();

            const _window = angular.element($window);
            _window.on('resize', onResize);
            scope.$on('$destroy', () => {
                _window.off('resize', onResize);
            });
        }
    };
};

AutoHeightDirective.$inject = ['$window'];

export default AutoHeightDirective;
