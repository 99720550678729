import './add-users.scss';
import template from './add-users.html';
import controller from './add-users.controller';

const addUsersComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        modalInstance: '<'
    }
};

export default addUsersComponent;
