import template from './duplicate-timeline-modal.html';
import controller from './duplicate-timeline-modal.controller';

const duplicateTimelineModalComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        modalInstance: '<',
        resolve: '<'
    }
};

export default duplicateTimelineModalComponent;
