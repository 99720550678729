import * as _ from 'lodash';
import { Transition } from '@uirouter/angularjs';

class DeleteRoleController {
    constructor($scope, $transitions: Transition) {
        this._$scope = $scope;

        this.confirmName = '';
        this.isProcessing = false;
        this.reason = '';

        const deregister = $transitions.onExit({}, () => {
            this.cancel();
            deregister();
        });
    }

    $onInit(): void {
        this.role = this.resolve.role;
        this.onDestroy = this.resolve.onDestroy;
        this.namePattern = `^${_.escapeRegExp(this.role.name)}$`;

        this._$scope.$on('modal.closing', this._preventClosingIfProcessing.bind(this));
    }

    _preventClosingIfProcessing(event, reason, closed) {
        const notAllowedToClose = reason && reason !== 'cancel' && !closed;

        if (this.isProcessing && notAllowedToClose) {
            event.preventDefault();
        }
    }

    cancel(): void {
        this.modalInstance.dismiss('cancel');
    }

    destroy() {
        this.isProcessing = true;

        this.onDestroy(this.role, this.reason)
            .then(this.modalInstance.close)
            .finally(() => {
                this.isProcessing = false;
            });
    }
}

DeleteRoleController.$inject = [
    '$scope',
    '$transitions'
];

export default DeleteRoleController;
