import * as angular from 'angular';
import documentPageActions from './document-page-actions';
import documentToolbar from './document.toolbar';
import documentViewer from './document.viewer';
import documentViewerMessage from './document.viewer.message';
import docServices from './services';
import documentEditComponent from './document.content.editor.component';

const dependencies = [
    documentPageActions.name,
    documentToolbar.name,
    documentViewer.name,
    documentViewerMessage.name,
    docServices.name
];

const documentEdit = angular.module('app.shared.documents.documentContentEditor', dependencies)
    .component('documentContentEditor', documentEditComponent);

export default documentEdit;
