import template from './manage-team-profile.html';
import controller from './manage-team-profile.controller';
import './manage-team-profile.scss';

const manageTeamProfileComponent: ng.IComponentOptions = {
    controller,
    controllerAs: 'vm',
    template,
    bindings: {
        team: '<',
        onUpdate: '&'
    }
};

export default manageTeamProfileComponent;
