import './document.tools.dropdown.scss';
import template from './document.tools.dropdown.html';
import controller from './document.tools.dropdown.controller';

const documentToolsDropdownComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        doc: '<',
        selectedTool: '<',
        onSelectTool: '&'
    }
};

export default documentToolsDropdownComponent;
