import './destroy-item.scss';
import template from './destroy-item.html';
import controller from './destroy-item.controller';

const destroyItemComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        resolve: '<',
        modalInstance: '<'
    }
};

export default destroyItemComponent;
