import template from './user-privacy-consent.html';
import controller from './user-privacy-consent.controller';

const userPrivacyConsentComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        privacyConsent: '<',
        onSave: '&'
    }
};

export default userPrivacyConsentComponent;
