import uuid from 'uuid';

import * as Moment from 'moment-timezone';
import AnnotationType from '../../../constants/annotation.type';
import Actions from '../../../constants/document.content.actions';
import Events from '../../../constants/document.content.events';
import ToolMode from '../../../constants/tool.mode';

class TimestampToolController {
    constructor($scope, DocumentViewerRotation, DocumentViewerToolsOptions) {
        this._$scope = $scope;
        this._DocumentViewerRotation = DocumentViewerRotation;
        this._DocumentViewerToolsOptions = DocumentViewerToolsOptions;
    }

    $onInit(): void {
        this.page.element.style.cursor = 'crosshair';
        this._$scope.$on(Events.PAGE_MOUSEDOWN, this._onPageMousedown.bind(this));
    }

    $onDestroy(): void {
        this.page.element.style.cursor = 'auto';
    }

    _onPageMousedown($event, { x, y, page }) {
        if (page.id !== this.page.id) {
            return;
        }

        const annotation = {
            ...this._DocumentViewerToolsOptions.getOptionValuesFor(ToolMode.TIMESTAMP),
            id: uuid.v4(),
            type: AnnotationType.timestamp,
            textValue: this._getTimestampValue(),
            x,
            y,
            w: 0,
            h: 0,
            rotation: -this._DocumentViewerRotation.getRotationAngle(),
            page: this.page
        };

        this._$scope.$emit(Actions.ANNOTATION_CREATE, { annotation });
    }

    _getTimestampValue() {

        const timezone = Moment().tz(Moment.tz.guess()).format('z');
        return `${Moment(new Date()).format('DD-MMM-YYYY @ hh:mm A')} ${timezone}`;
    }
}

TimestampToolController.$inject = [
    '$scope',
    'DocumentViewerRotation',
    'DocumentViewerToolsOptions'
];

export default TimestampToolController;
