import * as angular from 'angular';
import pageRegionComponent from './page.region.component';


const dependencies = [

];
const pageRegion = angular.module('app.shared.documents.documentContentEditor.documentViewer.submodules.primitives.pageRegion', dependencies)
    .component('pageRegion', pageRegionComponent);

export default pageRegion;
