import { Transition } from '@uirouter/angularjs';

class PageLoadErrorModalController {
    constructor($scope, $transitions: Transition) {
        const deregister = $transitions.onExit({}, () => {
            this.modalInstance.close('state change');
            deregister();
        });
    }

    cancel(): void {
        this.modalInstance.dismiss('cancel');
    }

    handleSubmit() {
        this.modalInstance.close();
    }
}

PageLoadErrorModalController.$inject = ['$scope', '$transitions'];

export default PageLoadErrorModalController;
