import * as angular from 'angular';
import colorOptionComponent from './color.option.component';

const dependencies = [];

const colorOption = angular
    .module('app.shared.documents.documentContentEditor.documentToolbar.colorOption', dependencies)
    .component('colorOption', colorOptionComponent);

export default colorOption;
