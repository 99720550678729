import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    GetAccessDatesReportParams,
    GetAccessDatesReportResponse,
    GetRolesForUserParams,
    GetUsersForRoleParams
} from './access-dates.service.types';
import { UserRole } from '../models';

@Injectable()
export class AccessDatesService {
    private readonly url = {
        report: (teamId: string): string => `/api/teams/${teamId}/user-roles/access-dates-report`,
        rolesForUser: ({ teamId, userId }: GetRolesForUserParams): string => `/api/teams/${teamId}/users/${userId}/user-roles`,
        usersForRole: ({ teamId, roleId }: GetUsersForRoleParams): string => `/api/teams/${teamId}/roles/${roleId}/user-roles`
    };

    readonly MAX_DATE = new Date('9999-09-09T00:00:00.000Z');

    constructor(private $http: HttpClient) { }

    getAccessDatesReport(getReportParams: GetAccessDatesReportParams): Observable<GetAccessDatesReportResponse> {
        const { teamId, ...paginationParams } = getReportParams;
        const params = paginationParams
            && Object.keys(paginationParams).reduce((httpParam, param) => {
                return paginationParams[param] ? httpParam.set(param, paginationParams[param]) : httpParam;
            }, new HttpParams());
        return this.$http.get<GetAccessDatesReportResponse>(this.url.report(teamId), { params });
    }

    getRolesForUser(params: GetRolesForUserParams): Observable<UserRole[]> {
        return this.$http.get<UserRole[]>(this.url.rolesForUser(params));
    }

    getUsersForRole(params: GetUsersForRoleParams): Observable<UserRole[]> {
        return this.$http.get<UserRole[]>(this.url.usersForRole(params));
    }
}
