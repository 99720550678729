import { Transition } from '@uirouter/angularjs';

import { ApiError } from '@app/shared/models';

class ProjectizerController {
    constructor($scope, $transitions: Transition, $q, Projects, CurrentSession, Notifications) {
        this._$scope = $scope;
        this._$q = $q;
        this.isProcessing = false;
        this._Projects = Projects;
        this._CurrentSession = CurrentSession;
        this._Notifications = Notifications;

        const deregister = $transitions.onExit({}, () => {
            this.cancel();
            deregister();
        });
    }

    $onInit(): void {
        this.onSubmit = this.resolve.onSubmit;
        this.currentTeam = this._CurrentSession.getCurrentTeam();
        this._$scope.$on('modal.closing', this._preventClosingIfProcessing.bind(this));
    }

    _preventClosingIfProcessing(event, reason, closed) {
        const notAllowedToClose = reason && reason !== 'cancel' && !closed;

        if ((this.isProcessingPreview || this.isProcessingSave) && notAllowedToClose) {
            event.preventDefault();
        }
    }

    cancel(): void {
        this.modalInstance.dismiss('cancel');
    }

    preview() {
        this.isProcessingPreview = true;

        this._Projects.previewProjectizer(this.currentTeam.id, this.template)
            .toPromise()
            .then(({ tree }) => {
                this.items = tree;
                this.isProcessingPreview = false;
            }, ({ error }: ApiError) => {
                this._Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                this.items = [];
                this.isProcessingPreview = false;
            });
    }

    save() {
        this.isProcessingSave = true;

        this._Projects.saveProjectizer(this.currentTeam.id, this.template)
            .toPromise()
            .then(() => this.onSubmit())
            .then((returnValue) => {
                this._Notifications.success('Project structure successfully imported.');
                this.modalInstance.close(returnValue);
            }, ({ error }: ApiError) => {
                this._Notifications.error(error.message || 'Server Error: Please contact your administrator.');
            })
            .catch(() => {
                this.isProcessing = false;
            });
    }

}

ProjectizerController.$inject = ['$scope', '$transitions', '$q', 'Projects', 'CurrentSession', 'Notifications'];

export default ProjectizerController;
