import controller from './downloads-index.controller';
import template from './downloads-index.html';

const downloadsIndexComponent: ng.IComponentOptions = {
    controller,
    controllerAs: 'vm',
    template,
    bindings: {}
};

export default downloadsIndexComponent;
