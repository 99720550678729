import template from './annotation.html';
import controller from './annotation.controller';

const annotationComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        // Used by text tool when text editing is in focus
        interactive: '<',
        data: '<',
        page: '<'
    }
};

export default annotationComponent;
