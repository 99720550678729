import ToolMode from '../constants/tool.mode';

class DocumentViewerMessageController {
    constructor($scope, $timeout, $window) {
        this._$scope = $scope;
        this._$timeout = $timeout;
        this._$window = $window;
        this.hide = false;
        this._hideTimeout = null;
        this.lastMessage = null;
    }

    $onInit(): void {
        this.isTouchDevice = this._isTouchDevice();
    }

    $onChanges(changes: ng.IOnChangesObject): void {
        if (!changes.selectedTool) {
            return;
        }
        this.message = this._getHelpText();

        if (this.message) {
            this.hide = false;
            this._queueHide();
            this.lastMessage = this.message;
        }
    }

    _queueHide() {
        if (this._hideTimeout) {
            this._$timeout.cancel(this._hideTimeout);
        }

        this._hideTimeout = this._$timeout(() => {
            this.hide = true;
        }, 3000);
    }

    _getHelpText() {
        const clickAction = this.isTouchDevice ? 'Tap' : 'Click';
        const dragAction = this.isTouchDevice ? 'Tap two opposite corners' : 'Click and drag';
        switch (this.selectedTool) {
            case ToolMode.ADD_TEXT:
                return `${clickAction} anywhere to add text.`;
            case ToolMode.TIMESTAMP:
                return `${clickAction} anywhere to add a timestamp.`;
            case ToolMode.HIGHLIGHT:
                return `${dragAction} to define a highlight.`;
            case ToolMode.REDACT:
                return `${dragAction} to define a redaction.`;
            case ToolMode.SIGNATURE:
                return `${clickAction} anywhere to add a signature.`;
            case ToolMode.NONE:
            default:
        }
    }

    _isTouchDevice() {
        const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

        if (('ontouchstart' in this._$window) || this._$window.DocumentTouch) {
            return true;
        }

        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return this._$window.matchMedia(query).matches;
    }
}

DocumentViewerMessageController.$inject = [
    '$scope',
    '$timeout',
    '$window'
];

export default DocumentViewerMessageController;
