import * as angular from 'angular';
import fontSizeOptionComponent from './font.size.option.component';


const dependencies = [

];
const fontSizeOption = angular.module('app.shared.documents.documentContentEditor.documentToolbar.fontSizeOption', dependencies)
    .component('fontSizeOption', fontSizeOptionComponent);

export default fontSizeOption;
