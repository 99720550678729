import * as _ from 'lodash';
import { Transition } from '@uirouter/angularjs';

import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { sortBrowseTreeLexicographically } from '@app/widgets/sort/sort-browse-tree-lexicographically.util';
import { VirtualTreeSelectionMode } from '@app/widgets/virtual-tree/virtual-tree.component.types';
import { RolesService } from '../../roles.service';

class RolePermissionsFormController {
    private _Roles: RolesService;
    readonly zendenskLink = 'https://florencehealthcare.zendesk.com/hc/en-us/articles/115007344308-User-Permissions-Overview';
    showZendeskTooltip = false;

    constructor($transitions: Transition, Team, Binders, Roles: RolesService, CurrentSession, ApiError) {
        this._Team = Team;
        this._Binders = Binders;
        this._Roles = Roles;
        this._CurrentSession = CurrentSession;
        this._ApiError = ApiError;

        this.selectionMode = VirtualTreeSelectionMode.MULTI_SELECT;
        this.progress = 1;
        this.isProcessing = false;
        this.isValid = false;
        this.loadingRoot = false;

        this.loadItem = this._loadItem.bind(this);
        this.isItemSelectable = this._isItemSelectable.bind(this);

        const deregister = $transitions.onExit({}, () => {
            this.cancel();
            deregister();
        });
    }

    $onInit(): void {
        this.mode = this.resolve.mode;
        this.permissionSetType = this.resolve.permissionSetType;
        this.roleId = this.resolve.roleId;
        this.selectedItems = this.resolve.selectedItems;
        this.onSave = this.resolve.onSave;
        this.permissionSet = this.resolve.permissionSet || {};

        this._currentTeam = this._CurrentSession.getCurrentTeam();
        this.progressSteps = this._getSteps();
        this._loadRoot();
        this._loadPermissions();
    }

    cancel(): void {
        this.modalInstance.dismiss('cancel');
    }

    setProgress(n): void {
        this.progress = Number(n);
    }

    isLastStep() {
        return this.progress === Math.max(...Object.keys(this.progressSteps));
    }

    _isItemSelectable(item) {
        return item.type === this.permissionSetType && !_.includes(this.unselectableItems, item.id);
    }

    isStepValid() {
        if (this.progressSteps[this.progress] === 'Location') {
            return !!this.selectedItems.length;
        }

        if (this.progressSteps[this.progress] === 'Permissions') {
            if (this.mode === 'create' && this.permissions && this.permissions.tree) {
                return this.isAnyPermissionChecked(this.permissions.tree.permissions);
            }
            return true;
        }
    }

    isAnyPermissionChecked(permissions) {
        return Object.values(permissions).some((permission) => permission.has || permission.hasCheckedChildren);
    }

    onItemToggled = ($event) => {
        this.selectedItems = $event.selectedItems;
        if (this.mode === 'create') {
            this._loadPermissions();
        }
    }

    save() {
        this.isProcessing = true;
        this.onSave(this.selectedItems, this.permissions)
            .then(() => {
                this.modalInstance.close();
            })
            .finally(() => {
                this.isProcessing = false;
            });
    }

    _getSteps() {
        const steps = {
            1: 'Location',
            2: 'Permissions'
        };
        if (this.permissionSetType === 'team') {
            steps['1'] = steps['2'];
            delete steps['2'];
        }
        return steps;
    }

    _loadRoot() {
        if (this.permissionSetType === 'team') {
            return;
        }

        this.loadingRoot = true;
        this._Binders
            .getBinders(this._currentTeam.id)
            .toPromise()
            .then((rootItems) => {
                this.rootItems = sortByLexicographically(rootItems, 'name');
                return this._loadPermissionsFor(rootItems.map((i) => i.id));
            })
            .then(({ items = [] } = {}) => {
                this._setUnselectableItems(items);
                this.loadingRoot = false;
            })
            .catch(this._ApiError.handleError);
    }

    _loadItem(params) {
        let loaded;
        return this._Team
            .browse(this._currentTeam.id, params)
            .toPromise()
            .then((resp) => {
                loaded = sortBrowseTreeLexicographically(resp, 'name');
                return loaded.items;
            })
            .then((items) => {
                return this._loadPermissionsFor(items.map((i) => i.id));
            })
            .then(({ items = [] } = {}) => {
                this._setUnselectableItems(items);
                return loaded;
            });
    }

    _loadPermissionsFor(objectIds) {
        return this._Roles
            .getRolePermissions(
                this._currentTeam.id,
                this.roleId,
                {
                    skipPagination: true,
                    objectIds
                }
            ).toPromise();
    }

    _loadPermissions() {
        if (this.permissionSetType !== 'team' && !this.selectedItems.length) {
            this.permissions = [];
            return;
        }

        this._Team
            .loadSubjectPermissionsForObject({
                teamId: this._currentTeam.id,
                subjectId: this.roleId,
                subjectType: 'role',
                objectId: this.selectedItems[0].id,
                objectType: this.permissionSetType
            })
            .toPromise()
            .then((permissions) => {
                this.permissions = permissions;
                this._Team.setHasCheckedOrInheritedChildren(this.permissions.tree);
            });
    }

    _setUnselectableItems(items) {
        this.unselectableItems = [
            ...this.unselectableItems || [],
            ...items.map((i) => i.objectId).filter((i) => i !== this.permissionSet.objectId)
        ];
    }
}

RolePermissionsFormController.$inject = [
    '$transitions',
    'Team',
    'Binders',
    'Roles',
    'CurrentSession',
    'ApiError'
];

export default RolePermissionsFormController;
