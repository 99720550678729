import template from './document-viewer-image.html';
import controller from './document-viewer-image.controller';

const documentViewerImage: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        page: '<',
        shouldDisplay: '<',
        onLoadImage: '&',
        onMousedownImage: '&',
        onMousemoveImage: '&',
        onMouseupImage: '&',
        onTouchendImage: '&',
        onTouchcancelImage: '&',
        onErrorImage: '&'
    }
};

export default documentViewerImage;
