import { UserPrivacyConsent } from '@app/shared/models';
import { AppConfigService } from '@app/shared/app-config/app-config.service';

class UserPrivacyConsentController {
    resetShowing = false;
    isProcessing = false;
    agreeAll = false;
    communicationsOpen = false;
    privacyConsent: Partial<UserPrivacyConsent>;
    privacyConsentClone: Partial<UserPrivacyConsent>;
    userPrivacyConsentForm: ng.IFormController;
    onSave: (param: { privacyConsent: Partial<UserPrivacyConsent> }) => Promise<void>;

    appVersion = '0.0.0';

    constructor(
        private AppConfig: AppConfigService
    ) {}

    $onInit(): void {
        this.appVersion = this.AppConfig.config.release;
        this.updateAgreeAll();
    }

    updateAgreeAll(): void {
        this.agreeAll = this.privacyConsent.customerTraining
            && this.privacyConsent.communications
            && this.privacyConsent.resources;
    }

    $onChanges(): void {
        this.privacyConsentClone = { ...this.privacyConsent };
    }

    getChanges(): Partial<UserPrivacyConsent> {
        const updatedConsent = {};
        Object.keys(this.privacyConsent).forEach((prop) => {
            if (this.privacyConsent[prop] !== this.privacyConsentClone[prop]) {
                updatedConsent[prop] = this.privacyConsent[prop];
            }
        });
        return updatedConsent;

    }

    toggleAgreeAll(): void {
        this.agreeAll = !this.agreeAll;
        this.privacyConsent.customerTraining = this.agreeAll;
        this.privacyConsent.communications = this.agreeAll;
        this.privacyConsent.resources = this.agreeAll;
        this.userPrivacyConsentForm.$setDirty();
    }

    togglePrivacyConsent(field: string): void {
        this.privacyConsent[field] = !this.privacyConsent[field];
        this.updateAgreeAll();
        this.userPrivacyConsentForm.$setDirty();
    }

    updateUserPrivacyConsent(): void {
        this.isProcessing = true;
        const privacyConsent = this.getChanges();
        this.onSave({ privacyConsent })
            .then(() => {
                this.isProcessing = false;
                this.userPrivacyConsentForm.$setPristine();
                this.privacyConsentClone = { ...this.privacyConsent };
            });
    }
}

UserPrivacyConsentController.$inject = ['AppConfigService'];

export default UserPrivacyConsentController;
