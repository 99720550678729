import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import { downgradeComponent } from '@angular/upgrade/static';

import permissionsRoute from './permissions.route';
import permissionInfoModal from './containers/permission-info-modal/permission-info-modal.component';
import { PermissionsEditComponent } from './containers/permissions-edit/permissions-edit.component';
import { PermissionReportComponent } from './containers/permission-report/permission-report.component';
import { PermissionReportTreeComponent } from './components/permission-report-tree/permission-report-tree.component';
import { DownloadPermissionReportComponent } from './components/download-permission-report/download-permission-report.component';
import { PermissionTreePermissionComponent } from './components/permission-tree-permission/permission-tree-permission.component';

const dependencies = [
    uiRouter
];

const permissions = angular
    .module('permissions', dependencies)
    .config(permissionsRoute)
    .directive('permissionReportTree', downgradeComponent({ component: PermissionReportTreeComponent }) as ng.IDirectiveFactory)
    .directive('permissionReport', downgradeComponent({ component: PermissionReportComponent }) as ng.IDirectiveFactory)
    .directive('downloadPermissionReport', downgradeComponent({ component: DownloadPermissionReportComponent }) as ng.IDirectiveFactory)
    .directive('permissionTreePermission', downgradeComponent({ component: PermissionTreePermissionComponent }) as ng.IDirectiveFactory)
    .component('permissionInfo', permissionInfoModal)
    .directive('permissionsEdit', downgradeComponent({ component: PermissionsEditComponent }) as ng.IDirectiveFactory);

export default permissions;
