import * as _ from 'lodash';
import { Transition } from '@uirouter/angularjs';

class DuplicateTimelineController {
    constructor($scope, $transitions: Transition) {
        $scope.$on('modal.closing', this._preventClosingIfProcessing.bind(this));

        const deregister = $transitions.onExit({}, () => {
            this.cancel();
            deregister();
        });
    }

    $onInit(): void {
        this.isProcessing = false;
        this.onDuplicate = this.resolve.onDuplicate;
        this.timeline = this.resolve.timeline;
        this.timeline.name += ' copy';
        this.allProjects = this.resolve.allProjects;
        this.selectedProject = _.find(this.allProjects, { id: this.timeline.projectId });
    }

    _preventClosingIfProcessing(event, reason, closed) {
        const notAllowedToClose = reason && reason !== 'cancel' && !closed;

        if (this.isProcessing && notAllowedToClose) {
            event.preventDefault();
        }
    }

    cancel(): void {
        this.modalInstance.dismiss('cancel');
    }

    save() {
        this.isProcessing = true;
        this.timeline.projectId = this.selectedProject.id;
        this.onDuplicate(this.timeline)
            .then(this.cancel.bind(this))
            .catch(() => {
                this.isProcessing = false;
            });
    }
}

DuplicateTimelineController.$inject = ['$scope', '$transitions'];

export default DuplicateTimelineController;
