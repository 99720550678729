import uuid from 'uuid';

import AnnotationType from '../../../constants/annotation.type';
import Actions from '../../../constants/document.content.actions';
import Events from '../../../constants/document.content.events';
import ToolMode from '../../../constants/tool.mode';

class TextToolController {
    constructor($scope, DocumentViewerRotation, DocumentViewerToolsOptions) {
        this._$scope = $scope;
        this._DocumentViewerRotation = DocumentViewerRotation;
        this._DocumentViewerToolsOptions = DocumentViewerToolsOptions;
    }

    $onInit(): void {
        this.page.element.style.cursor = 'text';
        this._$scope.$on(Events.PAGE_MOUSEDOWN, this._onPageMousedown.bind(this));
    }

    $onDestroy(): void {
        this.page.element.style.cursor = 'auto';
    }

    _onPageMousedown($event, { x, y, page }) {
        if (page.id !== this.page.id) {
            return;
        }

        const annotation = {
            ...this._DocumentViewerToolsOptions.getOptionValuesFor(ToolMode.ADD_TEXT),
            id: uuid.v4(),
            type: AnnotationType.add_text,
            x,
            y,
            w: 0,
            h: 0,
            rotation: -this._DocumentViewerRotation.getRotationAngle(),
            page: this.page
        };
        this._$scope.$emit(Actions.ANNOTATION_CREATE, { annotation });
    }
}

TextToolController.$inject = [
    '$scope',
    'DocumentViewerRotation',
    'DocumentViewerToolsOptions'
];

export default TextToolController;
