import * as angular from 'angular';
import redactToolComponent from './redact.tool.component';

const dependencies = [

];

const redactTool = angular.module('app.shared.documents.documentContentEditor.documentViewer.submodules.tools.redactTool', dependencies)
    .component('redactTool', redactToolComponent);

export default redactTool;
