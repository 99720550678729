import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { Transition } from '@uirouter/angularjs';
import { PermissionsEditComponent } from '../permissions-edit/permissions-edit.component';

class PermissionInfoModalController {

    _Modals: ModalsService;

    constructor(
        $transitions: Transition,
        $timeout,
        modalHelper,
        Permissions,
        Adapter,
        private Modals: ModalsService
    ) {
        this._$timeout = $timeout;
        this._modalHelper = modalHelper;
        this._Permissions = Permissions;
        this._Adapter = Adapter;

        const deregister = $transitions.onExit({}, () => {
            this.cancel();
            deregister();
        });
    }

    $onInit(): void {
        this.item = this.resolve.item;
        this.subject = this.resolve.subject;
        this.onlyDirect = this.resolve.onlyDirect || false;
        this.editable = this.resolve.editable || false;
        this.disableEdit = this.resolve.disableEdit || false;
        this.onUpdate = this.resolve.onUpdate;
        this.effectivePermissions = this.resolve.effectivePermissions;

        this._setEntityState();
    }

    sizeOf(obj) {
        return Object.keys(obj).length;
    }

    _setEntityState() {
        this.item.state = { conversion: this._Adapter.getConversionState(this.item) };

        if (!this.effectivePermissions.length) {
            return;
        }
        this.effectivePermissions.forEach((ep) => {

            if (ep.list.length) {
                ep.list.forEach((obj) => {

                    obj.objectEntity.state = { conversion: this._Adapter.getConversionState(obj.objectEntity) };
                });
            }
        });
    }

    cancel(): void {
        this.modalInstance.dismiss('cancel');
    }

    /**
     * This method is passed as an event handler to the permissionsEditDialog to be triggered after a save happens.
     * After saving, we want to refresh the view both here and for the permission report.
     */
    afterEdit() {
        this._Permissions.getEffectivePermissions({
            subjectId: this.subject.id,
            subjectType: this.subject.type,
            objectId: this.item.id,
            objectType: this.item.type,
            teamId: this.item.teamId
        }).subscribe((permissions) => {
            this.effectivePermissions = permissions;
        });

        this.onUpdate();
    }

    openPermissionsModal() {
        if (this.disableEdit) {
            return;
        }

        const modal = this.Modals.show(PermissionsEditComponent, {
            animated: true,
            class: 'modal-lg',
            initialState: {
                item: this.item,
                activeSubject: this.subject
            }
        });

        modal.content.postSaveAction.subscribe(() => {
            this.afterEdit();
        });
    }


    download(format) {

        const options = {
            format,
            teamId: this.item.teamId,
            subjectId: this.subject.id,
            subjectType: this.subject.type,

            objectId: this.item.id,
            objectType: this.item.type
        };
        this._Permissions.downloadPermissions(options);
    }

}

PermissionInfoModalController.$inject = [
    '$transitions',
    '$timeout',
    'modalHelper',
    'Permissions',
    'Adapter',
    'ModalsService'
];

export default PermissionInfoModalController;
