import * as _ from 'lodash';

import { ApiError } from '@app/shared/models';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { DestroyProjectOrTimelineComponent } from '../../compontents/destroy-project/destroy-project-or-timeline.component';
import { DestroyProjectOrTimelineType, ProjectOrTimelineDeleteEvent } from '../../compontents/destroy-project/destroy-project-or-timeline.component.types';
import { EditProjectModalComponent } from '../../compontents/edit-project-modal/edit-project-modal.component';
import { EditProjectEmitData, EditProjectModalModes } from '../../compontents/edit-project-modal/edit-project-modal.component.types';

class ProjectsPageController {
    constructor(
        $state,
        $filter,
        CurrentSession,
        Team,
        Projects,
        SORT,
        modalHelper,
        Notifications,
        private Modals: ModalsService
    ) {
        this._$state = $state;
        this._$filter = $filter;
        this._CurrentSession = CurrentSession;
        this._Teams = Team;
        this._Projects = Projects;
        this.SORT = SORT;
        this._modalHelper = modalHelper;
        this._Notifications = Notifications;

        this.projects = [];
        this.selectedProject;
        this.crumbs = [{ name: 'Manage Projects' }];
        this.loadingData = false;
    }

    $onInit(): void {
        this.currentTeam = this._CurrentSession.getCurrentTeam();
        this.teamId = this.currentTeam.id;
        if (!this._Teams.canViewProjectsAndTimelines()) {
            return this._$state.go('app.select-team');
        }
        this._loadProjects();
    }

    _loadProjects() {
        this.loadingData = true;
        return this._Projects.getAllProjects(this.teamId)
            .toPromise()
            .then((projects) => {
                this.projects = projects.map((project) => {
                    return { ...project, href: this.generateProjectHref(project) };
                });
                this.updateSort('name', false);
                this.loadingData = false;
            }, ({ error }: ApiError) => {
                this._Notifications.error(error.message || 'Server Error: Please contact your administrator.');
            });
    }

    select(selectedProject) {

        const currentSelectionState = selectedProject.selected;
        this.projects.forEach((project) => {
            project.selected = false;
        });
        selectedProject.selected = !currentSelectionState;

        if (!selectedProject.selected) {
            this.selectedProject = undefined;
            return;
        }

        this.selectedProject = selectedProject;
    }

    canCreate() {
        return this.currentTeam.permissions.createProjects;
    }

    create() {
        if (!this.canCreate()) {
            return;
        }

        const createProjectModal = this.Modals.show(EditProjectModalComponent, {
            animated: true,
            class: 'modal-md',
            initialState: {
                project: undefined,
                editMode: EditProjectModalModes.CREATE
            }
        });

        createProjectModal.content.onSave.subscribe((event: EditProjectEmitData) => {
            return this._Projects.createProject(this.teamId, event.project)
                .toPromise()
                .then(() => {
                    event.onSuccess();
                    this._Notifications.success(`Project '${event.project.name}' created!`);
                    this._$state.reload();
                }, ({ error }: ApiError) => {
                    event.onError();
                    this._Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                });
        });
    }

    canEdit() {
        return this.currentTeam.permissions.updateProjects && this.selectedProject;
    }

    edit() {
        if (!this.canEdit()) {
            return;
        }

        const editProjectModal = this.Modals.show(EditProjectModalComponent, {
            animated: true,
            class: 'modal-md',
            initialState: {
                project: this.selectedProject,
                editMode: EditProjectModalModes.UPDATE
            }
        });

        editProjectModal.content.onSave.subscribe((event: EditProjectEmitData) => {
            return this._Projects.editProject(this.teamId, event.project)
                .toPromise()
                .then(() => {
                    event.onSuccess();
                    this._Notifications.success(`Project '${event.project.name}' updated!`);
                    this._$state.reload();
                }, ({ error }: ApiError) => {
                    event.onError();
                    this._Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                });
        });
    }

    remove() {
        if (this.canRemove()) {

            const destroyProjectModal = this.Modals.show(DestroyProjectOrTimelineComponent, {
                animated: true,
                class: 'modal-md',
                initialState: {
                    name: this.selectedProject.name,
                    type: DestroyProjectOrTimelineType.PROJECT
                }
            });

            destroyProjectModal.content.onDestroy.subscribe((event: ProjectOrTimelineDeleteEvent) => {
                this._Projects.deleteProject(this.teamId, this.selectedProject)
                    .toPromise()
                    .then(() => {
                        event.onSuccess();
                        this._Notifications.success(`Project '${this.selectedProject.name}' deleted!`);
                        this.selectedProject = null;
                        this._$state.reload();
                    }, ({ error }: ApiError) => {
                        event.onError();
                        this._Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                    });
            });
        }
    }

    canRemove() {
        return this.currentTeam.permissions.deleteProjects && this.selectedProject;
    }

    duplicate() {
        if (!this.canDuplicate()) {
            return;
        }

        const duplicateProjectModal = this.Modals.show(EditProjectModalComponent, {
            animated: true,
            class: 'modal-md',
            initialState: {
                project: _.cloneDeep(this.selectedProject),
                editMode: EditProjectModalModes.DUPLICATE
            }
        });

        duplicateProjectModal.content.onSave.subscribe((event: EditProjectEmitData) => {
            return this._Projects.duplicateProject(this.teamId, event.project)
                .toPromise()
                .then(() => {
                    event.onSuccess();
                    this._Notifications.success(`Project '${event.project.name}' duplicated!`);
                    this._$state.reload();
                }, ({ error }: ApiError) => {
                    event.onError();
                    this._Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                });
        });
    }

    canDuplicate() {
        return this.currentTeam.permissions.duplicateProjects && this.selectedProject;
    }

    canProjectize() {
        return this.currentTeam.permissions.importProjects;
    }

    projectize() {
        if (!this.canProjectize()) {
            return;
        }

        this._modalHelper.open({
            animation: true,
            size: 'lg',
            component: 'projectizer',
            resolve: {
                onSubmit: () => this._loadProjects.bind(this)
            }
        });
    }

    private generateProjectHref(project): string {
        return this._$state.href('app.team.manage-project', { teamId: this.teamId, projectId: project.id });
    }

    updateSort(sortName) {
        this.SORT.set(sortName);
        this.projects = this._$filter('orderBy')(this.projects, sortName, this.SORT.isReversed, this.SORT.naturalSort);
    }

    canActOnSelection() {
        return this.canRemove()
            || this.canDuplicate()
            || this.canEdit();
    }

    canActOnCreate() {
        return this.canCreate()
            || this.canProjectize();
    }

    toggleActions($event, project) {
        $event.stopPropagation();
        if (this.selectedProject !== project) {
            this.select(project);
        }
    }

    updateFilter(text: string): void {
        this.filter = text;
    }
}

ProjectsPageController.$inject = [
    '$state',
    '$filter',
    'CurrentSession',
    'Team',
    'Projects',
    'SORT',
    'modalHelper',
    'Notifications',
    'ModalsService'
];

export default ProjectsPageController;
