import * as _ from 'lodash';
import Actions from '../../../constants/document.content.actions';

class AbstractFormFieldController {
    constructor($scope, DocumentFormField, DocumentViewerToolsOptions) {
        this._$scope = $scope;
        this._DocumentFormField = DocumentFormField;
        this._DocumentViewerToolsOptions = DocumentViewerToolsOptions;
        this._listenerCleanup = [];
    }

    $onDestroy(): void {

        _.each(this._listenerCleanup, (cleanup) => {

            cleanup();
        });
    }

    $onInit(): void {

        this._setFormFieldState();
        this.range = this._getRange();
        this._addListeners();
    }

    _setFormFieldState() {

        this.annotation = this._DocumentFormField.getAnnotationForFormField(this.data, this.page);
        this.empty = !this.annotation;
    }

    _addListeners() {

        const cleanupModifiedListener = this._DocumentFormField.onFieldModified(this.data, () => {

            this._setFormFieldState();
        });
        this._listenerCleanup.push(cleanupModifiedListener);

        this._$scope.$on(Actions.ANNOTATION_DELETE, ($event) => {
            this._DocumentFormField.clearFormField(this.data);
            $event.stopPropagation();
        });
    }

    createAnnotation() {
        throw Error.new('extend and implement');
    }

    _getRange() {

        const { coordinates } = this.data;
        return {
            start: {
                x: coordinates.x,
                y: coordinates.y
            },
            end: {
                x: coordinates.x + coordinates.w,
                y: coordinates.y + coordinates.h
            }
        };
    }
}

AbstractFormFieldController.$inject = [
    '$scope',
    'DocumentFormField',
    'DocumentViewerToolsOptions'
];

export default AbstractFormFieldController;
