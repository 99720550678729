import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import uiRouter from '@uirouter/angularjs';
import * as angular from 'angular';
import { DocumentLogEntriesService } from '@app/shared/documents-log-entries/document-log-entries.service';
import { QCReviewService } from '@app/components/documents/qc-review.service';
import { DocumentAnnotationsService } from '@app/components/documents/document-annotation.service';
import { DocumentFormService } from '@app/components/documents/document-form.service';
import { DocumentService } from '@app/shared/documents/document.service';
import { DocViewerComponent } from '@florencehealthcare/doc-viewer';
import { ImportViaEmailComponent } from '../../widgets/import-via-email/import-via-email.component';
import { DocumentActionsComponent } from './components/document-actions/document-actions.component';
import annotationsModalWrapper from './components/annotations-modal/annotations-modal.wrapper';
import { AnnotationsModalComponent } from './components/annotations-modal/annotations-modal.component';
import { DocumentDownloadComponent } from './components/document-download/document-download.component';
import { DocumentEditDetailsComponent } from './components/document-edit-details/document-edit-details.component';
import { DocumentHistoryModalComponent } from './components/document-history-modal/document-history-modal.component';
import { DocumentExportModalComponent } from './components/document-export/document-export.component';
import documentFormSaveComponent from './components/document-form-save/document-form-save.component';
import { DocumentFormComponent } from './components/document-form/document-form.component';
import documentPageLoadError from './components/document-page-load-error/document-page-load-error.component';
import { DocumentPlaceholderDetailsComponent } from './components/document-placeholder-details/document-placeholder-details.component';
import { DocumentPlaceholderFillComponent } from './components/document-placeholder-fill/document-placeholder-fill.component';
import { DocumentReplaceComponent } from './components/document-replace/document-replace.component';
import { DocumentSendComponent } from './components/document-send/document-send.component';
import { DocumentSignatureListComponent } from './components/document-signature-list/document-signature-list.component';
import { DocumentSignaturesComponent } from './components/document-signatures/document-signatures.component';
import { DocumentTaskListComponent } from './components/document-task-list/document-task-list.component';
import { DocumentTimelineListComponent } from './components/document-timeline-list/document-timeline-list.component';
import { DocumentVersionsComponent } from './components/document-versions/document-versions.component';
import { MonitorReviewActionsComponent } from './components/monitor-review-actions/monitor-review-actions.component';
import { MonitorReviewEditComponent } from './components/monitor-review-edit/monitor-review-edit.component';
import { MonitorReviewApproveComponent } from './components/monitor-review-approve/monitor-review-approve.component';
import { MonitorReviewEventsComponent } from './components/monitor-review-events/monitor-review-events.component';
import { MonitorReviewIntegrationFormComponent } from './components/monitor-review-integration-form/monitor-review-integration-form.component';
import { MonitorReviewListComponent } from './components/monitor-review-list/monitor-review-list.component';
import { MonitorReviewPotentialRecipientsComponent } from './components/monitor-review-potential-recipients/monitor-review-potential-recipients.component';
import { PinExpiryWarningComponent } from './components/pin-expiry-warning/pin-expiry-warning.component';
import { PreviousVersionWarningComponent } from './components/previous-version-warning/previous-version-warning.component';
import { SignDocumentComponent } from './components/sign-document/sign-document.component';
import { SignDocumentWrapperComponent } from './components/sign-document/sign-document.wrapper';
import { SignatureCompleteComponent } from './components/signature-complete/signature-complete.component';
import { TaskFormComponent } from './components/task-form/task-form.component';
import { DeclineDocumentComponent } from './containers/document-decline/document-decline.component';
import { DocumentLogContentComponent } from './containers/document-log-content/document-log-content.component';
import { DocumentLogEntriesComponent } from './containers/document-log-entries/document-log-entries.component';
import { SignLogEntriesComponent } from './components/sign-log-entries/sign-log-entries.component';
import { SignLogEntriesWrapperComponent } from './components/sign-log-entries/sign-log-entries.wrapper';
import { LogCreateComponent } from './components/log-create/log-create.component';
import { LogEditMetadataComponent } from './components/log-edit-metadata/log-edit-metadata.component';
import { LogEditMetadataWrapperComponent } from './components/log-edit-metadata/log-edit-metadata.wrapper';
import documentShowComponent from './containers/document-show/document-show.component';
import documentContentEditor from './document-content-editor';
import { DocumentMonitorReviewService } from './document-monitor-review.service';
import documentsRoute from './documents.route';
import { SignatureCommentsViewerComponent } from './components/signature-comments-viewer/signature-comments-viewer.component';
import MissingSigningPasscodeWrapperComponent from './components/missing-signing-passcode/missing-signing-passcode.wrapper';
import { MissingSigningPasscodeComponent } from './components/missing-signing-passcode/missing-signing-passcode.component';
import { ViewRelatedDocumentsDetailsComponent } from './components/view-related-documents-details/view-related-documents-details.component';
import { SignatureDisabledModalComponent } from './components/signature-disabled/signature-disabled.component';
import { DestroyDocumentComponent } from './components/destroy-document/destroy-document.component';
import { DocumentLockingModalComponent } from './components/document-locking-modal/document-locking-modal.component';
import { LogImportRowsComponent } from './components/log-bulk-import-rows/log-import-rows/log-import-rows.component';
import { DocumentLockedWatermarkComponent } from './components/document-locked-watermark/document-locked-watermark.component';
import { DocumentBulkSetDatesComponent } from './components/document-bulk-set-dates/document-bulk-set-dates.component';
import { LogToolbarComponent } from './components/log-toolbar/log-toolbar.component';
import { QcReviewGlobalStatusComponent } from '../qc-reviews/components/qc-review-global-status/qc-review-global-status.component';
import { ViewManageQcReviewComponent } from '../qc-reviews/components/view-manage-qc-review/view-manage-qc-review.component';
import { DocumentHandleQcReviewComponent } from '../qc-reviews/components/document-qc-review/document-handle-qc-review.component';
import { QcReviewDetailsComponent } from '../qc-reviews/components/qc-review-details/qc-review-details.component';
import { DocumentHistoryService } from '../../shared/document-history/document-history.service';
import { ShortcutDetailsComponent } from './components/shortcut-details/shortcut-details.component';
import { AssignCategoryComponent } from './components/assign-category/assign-category.component';
import { NdvContentEditor } from './components/ndv-content-editor/ndv.content.editor.component';
import { DocumentViewerUnsupportedComponent } from './document-content-editor/document.viewer.unsupported/document.viewer.unsupported.component';
import { NdvFormSaveComponent } from './components/ndv-form-save/ndv-form-save.component';
import { StudyRolesService } from '../../shared/study-roles/study-roles.service';
import { DoaLogTemplateRolesResponsibilitiesEditComponent } from '../log-templates/components/doa-log-template/doa-log-template-roles-responsibilities-edit/doa-log-template-roles-responsibilities-edit.component';
import { DocumentDetailsComponent } from './components/document-details/document-details.component';


const dependencies = [
    uiRouter,
    documentContentEditor.name
];

const documentsModule = angular
    .module('app.documents', dependencies)
    .config(documentsRoute)
    .factory('MonitorReviews', downgradeInjectable(DocumentMonitorReviewService))
    .factory('LogEntries', downgradeInjectable(DocumentLogEntriesService))
    .factory('QCReviewService', downgradeInjectable(QCReviewService))
    .factory('DocumentAnnotationsService', downgradeInjectable(DocumentAnnotationsService))
    .factory('DocumentFormService', downgradeInjectable(DocumentFormService))
    .factory('DocumentHistoryService', downgradeInjectable(DocumentHistoryService))
    .factory('DocumentService', downgradeInjectable(DocumentService))
    .factory('StudyRolesService', downgradeInjectable(StudyRolesService))
    .component('missingSigningPasscodeWrapper', MissingSigningPasscodeWrapperComponent)
    .directive('missingSigningPasscode', downgradeComponent({ component: MissingSigningPasscodeComponent }) as ng.IDirectiveFactory)
    .component('annotationsModalWrapper', annotationsModalWrapper)
    .directive('annotationsModal', downgradeComponent({ component: AnnotationsModalComponent }) as ng.IDirectiveFactory)
    .directive('documentForm', downgradeComponent({ component: DocumentFormComponent }) as ng.IDirectiveFactory)
    .directive('documentHistoryModal', downgradeComponent({ component: DocumentHistoryModalComponent }) as ng.IDirectiveFactory)
    .directive('ndvFormSave', downgradeComponent({ component: NdvFormSaveComponent }) as ng.IDirectiveFactory)
    .component('documentFormSave', documentFormSaveComponent)
    .component('documentShow', documentShowComponent)
    .directive('documentDetails', downgradeComponent({ component: DocumentDetailsComponent }) as ng.IDirectiveFactory)
    .directive('declineDocument', downgradeComponent({ component: DeclineDocumentComponent }) as ng.IDirectiveFactory)
    .directive('documentPlaceholderDetails', downgradeComponent({ component: DocumentPlaceholderDetailsComponent }) as ng.IDirectiveFactory)
    .directive('documentViewerUnsupported', downgradeComponent({ component: DocumentViewerUnsupportedComponent }) as ng.IDirectiveFactory)
    .directive('documentDownload', downgradeComponent({ component: DocumentDownloadComponent }))
    .directive('documentPlaceholderFill', downgradeComponent({ component: DocumentPlaceholderFillComponent }) as ng.IDirectiveFactory)
    .directive('documentSignatureList', downgradeComponent({ component: DocumentSignatureListComponent }) as ng.IDirectiveFactory)
    .directive('documentReplace', downgradeComponent({ component: DocumentReplaceComponent }) as ng.IDirectiveFactory)
    .directive('doaLogTemplateRolesResponsibilitiesEdit', downgradeComponent({ component: DoaLogTemplateRolesResponsibilitiesEditComponent }) as ng.IDirectiveFactory)
    .directive('documentSignatures', downgradeComponent({ component: DocumentSignaturesComponent }) as ng.IDirectiveFactory)
    .directive('documentTaskList', downgradeComponent({ component: DocumentTaskListComponent }) as ng.IDirectiveFactory)
    .directive('documentTimelineList', downgradeComponent({ component: DocumentTimelineListComponent }) as ng.IDirectiveFactory)
    .directive('ndvContentEditor', downgradeComponent({ component: NdvContentEditor }) as ng.IDirectiveFactory)
    .component('documentPageLoadError', documentPageLoadError)
    .directive('taskForm', downgradeComponent({ component: TaskFormComponent }) as ng.IDirectiveFactory)
    .directive('monitorReviewActions', downgradeComponent({ component: MonitorReviewActionsComponent }) as ng.IDirectiveFactory)
    .directive('monitorReviewEdit', downgradeComponent({ component: MonitorReviewEditComponent }) as ng.IDirectiveFactory)
    .directive('monitorReviewApprove', downgradeComponent({ component: MonitorReviewApproveComponent }) as ng.IDirectiveFactory)
    .directive('monitorReviewEvents', downgradeComponent({ component: MonitorReviewEventsComponent }) as ng.IDirectiveFactory)
    .directive('monitorReviewIntegrationForm', downgradeComponent({ component: MonitorReviewIntegrationFormComponent }) as ng.IDirectiveFactory)
    .directive('monitorReviewList', downgradeComponent({ component: MonitorReviewListComponent }) as ng.IDirectiveFactory)
    .directive('monitorReviewPotentialRecipients', downgradeComponent({ component: MonitorReviewPotentialRecipientsComponent }) as ng.IDirectiveFactory)
    .directive('shortcutDetails', downgradeComponent({ component: ShortcutDetailsComponent }) as ng.IDirectiveFactory)
    .directive('signatureComplete', downgradeComponent({ component: SignatureCompleteComponent }) as ng.IDirectiveFactory)
    .component('signDocumentWrapper', SignDocumentWrapperComponent)
    .component('signLogEntriesWrapper', SignLogEntriesWrapperComponent)
    .component('logEditMetadataWrapper', LogEditMetadataWrapperComponent)
    .directive('importViaEmail', downgradeComponent({ component: ImportViaEmailComponent }) as ng.IDirectiveFactory)
    .directive('documentActions', downgradeComponent({ component: DocumentActionsComponent }) as ng.IDirectiveFactory)
    .directive('documentEditDetails', downgradeComponent({ component: DocumentEditDetailsComponent }) as ng.IDirectiveFactory)
    .directive('documentExportModal', downgradeComponent({ component: DocumentExportModalComponent }) as ng.IDirectiveFactory)
    .directive('documentVersions', downgradeComponent({ component: DocumentVersionsComponent }) as ng.IDirectiveFactory)
    .directive('documentLogContent', downgradeComponent({ component: DocumentLogContentComponent }) as ng.IDirectiveFactory)
    .directive('documentLogEntries', downgradeComponent({ component: DocumentLogEntriesComponent }) as ng.IDirectiveFactory)
    .directive('documentSend', downgradeComponent({ component: DocumentSendComponent }) as ng.IDirectiveFactory)
    .directive('previousVersionWarning', downgradeComponent({ component: PreviousVersionWarningComponent }) as ng.IDirectiveFactory)
    .directive('pinExpiryWarning', downgradeComponent({ component: PinExpiryWarningComponent }) as ng.IDirectiveFactory)
    .directive('signDocument', downgradeComponent({ component: SignDocumentComponent }) as ng.IDirectiveFactory)
    .directive('signLogEntries', downgradeComponent({ component: SignLogEntriesComponent }) as ng.IDirectiveFactory)
    .directive('signatureCommentsViewer', downgradeComponent({ component: SignatureCommentsViewerComponent }) as ng.IDirectiveFactory)
    .directive('logCreate', downgradeComponent({ component: LogCreateComponent }) as ng.IDirectiveFactory)
    .directive('logEditMetadata', downgradeComponent({ component: LogEditMetadataComponent }) as ng.IDirectiveFactory)
    .directive('viewRelatedDocumentsDetails', downgradeComponent({ component: ViewRelatedDocumentsDetailsComponent }) as ng.IDirectiveFactory)
    .directive('signatureDisabledModal', downgradeComponent({ component: SignatureDisabledModalComponent }) as ng.IDirectiveFactory)
    .directive('destroyDocument', downgradeComponent({ component: DestroyDocumentComponent }) as ng.IDirectiveFactory)
    .directive('documentLockingModal', downgradeComponent({ component: DocumentLockingModalComponent }) as ng.IDirectiveFactory)
    .directive('documentLockedWatermark', downgradeComponent({ component: DocumentLockedWatermarkComponent }) as ng.IDirectiveFactory)
    .directive('documentBulkSetDates', downgradeComponent({ component: DocumentBulkSetDatesComponent }) as ng.IDirectiveFactory)
    .directive('logImportRows', downgradeComponent({ component: LogImportRowsComponent }) as ng.IDirectiveFactory)
    .directive('logToolbar', downgradeComponent({ component: LogToolbarComponent }) as ng.IDirectiveFactory)
    .directive('qcReviewGlobalStatus', downgradeComponent({ component: QcReviewGlobalStatusComponent }) as ng.IDirectiveFactory)
    .directive('qcReviewViewManage', downgradeComponent({ component: ViewManageQcReviewComponent }) as ng.IDirectiveFactory)
    .directive('documentHandleQcReview', downgradeComponent({ component: DocumentHandleQcReviewComponent }) as ng.IDirectiveFactory)
    .directive('qcReviewDetails', downgradeComponent({ component: QcReviewDetailsComponent }) as ng.IDirectiveFactory)
    .directive('fhcDocViewer', downgradeComponent({ component: DocViewerComponent }) as ng.IDirectiveFactory)
    .directive('assignCategory', downgradeComponent({ component: AssignCategoryComponent }) as ng.IDirectiveFactory);

export default documentsModule;
