import * as _ from 'lodash';
import Actions from '../../../constants/document.content.actions';
import AbstractFormFieldController from '../abstract.form.field/abstract.form.field.controller';
import AnnotationType from '../../../constants/annotation.type';
import ToolMode from '../../../constants/tool.mode';

class TextboxFormFieldController extends AbstractFormFieldController {
    $onChanges(changes: ng.IOnChangesObject): void {
        if (changes.isSelected && changes.isSelected.currentValue) {
            this._createAnnotation();
        }
    }

    canEdit() {
        return _.get(this.doc, 'permissions.addTextToDocument', false);
    }

    onClick() {
        if (!this.canEdit()) {
            return;
        }

        this.onSelect({
            $event: {
                annotation: this.annotation,
                page: this.page,
                formField: this.data
            }
        });
    }

    isInteractive() {
        return this.canEdit() && !this.isReadOnly;
    }

    _createAnnotation() {
        if (this.isReadOnly || this.annotation) {
            return;
        }

        this.annotation = {
            id: this.data.id,
            textValue: '',
            ...this._DocumentViewerToolsOptions.getOptionValuesFor(ToolMode.ADD_TEXT, this.data),
            ...this.data.coordinates,
            resizable: false,
            type: AnnotationType.add_text,
            page: this.page
        };

        this.empty = false;

        if (this.annotation.fontSize === this._DocumentViewerToolsOptions._initialFontSizeOption.value.fontSize) {
            this.annotation.fontSize = this._DocumentFormField.calculateFontSizeForField(this.page, this.data);
            this._DocumentViewerToolsOptions.setOptionsFor(
                ToolMode.ADD_TEXT,
                this.data,
                { fontSize: this.annotation.fontSize }
            );
        }

        this._$scope.$emit(Actions.ANNOTATION_CREATE, {
            annotation: this.annotation,
            formField: this.data
        });

    }
}

TextboxFormFieldController.$inject = [...AbstractFormFieldController.$inject];

export default TextboxFormFieldController;
