import template from './role-permissions.html';
import controller from './role-permissions.controller';

const rolePermissionsComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        $transition$: '<'
    }
};

export default rolePermissionsComponent;
