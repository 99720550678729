import './timeline-form.scss';
import template from './timeline-form.html';
import controller from './timeline-form.controller';

const timelineFormComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        /**
         * @type {{isLoading: boolean, expandToDepth: int, ...}[]} A list of all binders.
         * Field 'isLoading' on each binder determines whether to display a loading state.
         * Field expandToDepth sets depth property on binderTree.
         */
        bindersList: '<',
        // A function that accepts a binderId and returns a promise with the newly loaded binder
        loadBinder: '<',
        /**
         * @type {Object} timeline
         * @type {String} timeline.name
         * @type {String} timeline.projectedStart An ISO date string
         * @type {String} timeline.projectedEnd An ISO date string
         * @type {Boolean} timeline.isComplete
         * @type {{objectId:String, objectType: string, completionDate: String, document:{}}[]} timeline.items
         */
        timeline: '<',
        selectedItems: '<',
        onNameChange: '&',
        onIsCompleteChange: '&',
        onSelectedItemsChange: '&',
        /**
         * @type {Object} fieldErrors An object mapping field name to an error string
         */
        fieldErrors: '<',
        /**
         * @type {String[]} globalErrors A list of global errors
         */
        globalErrors: '<'
    }
};

export default timelineFormComponent;
