const ToolMode = {
    NONE: 'NONE',
    HIGHLIGHT: 'HIGHLIGHT',
    REDACT: 'REDACT',
    ADD_TEXT: 'ADD_TEXT',
    TIMESTAMP: 'TIMESTAMP',
    SIGNATURE: 'SIGNATURE'
};

export default ToolMode;
