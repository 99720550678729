import * as _ from 'lodash';
import uuid from 'uuid';
import {
    Annotation,
    Document,
    Page
} from '@app/shared/models';

/**
 * This service manages document annotations for the document viewer
 */
const DocumentAnnotationServiceFactory = ($rootScope) => {
    const service = {};
    const internals = {};

    /**
     * Set up annotations for the given document
     * @param {object} doc
     */
    service.initialize = (doc: Document): void => {
        internals.currentDoc = doc;
        internals.annotations = doc.annotations || [];
        internals.$scope = $rootScope.$new();
    };
    /**
     * Cleanup when the service is no longer needed
     */
    service.destroy = (): void => {
        service.getAnnotations().length = 0;
        internals.$scope.$destroy();
    };
    /**
     * Resets all changes to annotations.
     */
    service.reset = (): void => {
        service.getAnnotations().length = 0;
    };

    // Private methods
    internals.getDocKey = (id: string, version: string | number): string => `${id}-${version}`;

    internals.deleteAnnotation = (annotation: Annotation): void => {
        _.pull(service.getAnnotations(), annotation);
    };

    internals.setAnnotationIsPropagated = (annotation: Annotation, doPropagate: boolean): void => {

        if (!doPropagate) {
            // We are undoing propagation, make sure any changed propagated properties
            // are moved back to the main list of annotation properties
            const { page } = annotation;
            const properties = service.getAnnotationPropertiesForPage({ annotation, page });
            _.assign(annotation, properties);
        }
        // Setup a data structure for tracking modifications to propagated instances
        annotation.propagatedInstances = {
            deletedByPageIndex: {},
            modifiedByPageIndex: {}
        };
        annotation.isPropagated = doPropagate;
        internals.emitAnnotationModifiedEvent({ annotation, page: annotation.page, properties: { isPropagated: doPropagate } });
    };

    internals.copyAnnotation = (annotation: Annotation, ...modifiedFields): Partial<Annotation> => {
        const copy = _.pick(annotation, ['id', 'type', 'x', 'y', 'w', 'h', 'textValue', 'font', 'fontSize', 'lineHeight', 'backgroundColor', 'opacity', 'color', 'isPropagated', 'reason']);
        _.assign(copy, ...modifiedFields);
        return copy;
    };

    internals.getAnnotationId = ({ annotation, page }): string => `${annotation.id}-${page.pageIndex}`;

    internals.getAnnotationModifiedEventString = ({ annotation, page }): string => {

        const annotationId = internals.getAnnotationId({ annotation, page });
        return `annotation-modified-${annotationId}`;
    };

    /**
     *
     * @param data
     * @param data.annotation
     * @param data.page
     * @param data.properties
     */
    internals.emitAnnotationModifiedEvent = (data: {
        annotation: Annotation; page: Page; properties: Partial<Annotation>;
    }): void => {

        const eventString = internals.getAnnotationModifiedEventString(data);
        internals.$scope.$emit(eventString, data);
    };

    /**
     * Adds a listener for annotation modified events
     * @param annotation The annotation to be watched
     * @param page The page to be watched
     * @param {function} listener - A callback that will fire when the target annotation is updated
     * @returns {function} Returns a method to deregister and cleanup listener.  Listener will be called with an $event
     * param and a data param that contains keys for the annotation, page, and properties updated.
     */
    service.onAnnotationModified = ({ annotation, page }, listener) => {

        const eventString = internals.getAnnotationModifiedEventString({ annotation, page });
        return internals.$scope.$on(eventString, listener);
    };

    /**
     * Returns the list of annotations for the current document
     * @returns {object[]}
     */
    service.getAnnotations = (): Annotation[] => internals.annotations;

    /**
     * Propagated annotations have a complex structure.
     * Helper for pulling out annotation properties for a particular page.
     * @param {object} annotation The annotation object
     * @param {object} page The current page object
     * @returns {*}
     */
    service.getAnnotationPropertiesForPage = (
        { annotation, page }: { annotation: Annotation; page: Page }
    ): Annotation | number => {

        return (annotation.isPropagated && annotation.propagatedInstances.modifiedByPageIndex[page.pageIndex])
            || annotation;
    };

    /**
     * Creates the new annotation and tracks it
     * @param {object} annotation
     */
    service.createAnnotation = (annotation: Annotation): void => {

        if (!annotation.id) {
            annotation.id = uuid.v4();
        }
        annotation.resizable = true;
        service.getAnnotations().push(annotation);
    };

    /**
     * Deletes the given annotation on the given page
     * @param {object} annotation
     * @param {object} page
     */
    service.deleteAnnotation = (annotation: Annotation, page: Page): void => {
        if (annotation.isPropagated) {
            // Modify the instance data
            annotation.propagatedInstances.deletedByPageIndex[page.pageIndex] = true;
            delete annotation.propagatedInstances.modifiedByPageIndex[page.pageIndex];
            if (_.size(annotation.propagatedInstances.deletedByPageIndex) === internals.currentDoc.pages.length) {
                // All instance have been deleted, delete the whole annotation
                internals.deleteAnnotation(annotation);
            }
        }
        else {
            internals.deleteAnnotation(annotation);
        }
    };

    /**
     * Propagate the annotation across all of the pages
     * @param {object} annotation
     */
    service.propagateAnnotation = (annotation: Annotation): void => {
        internals.setAnnotationIsPropagated(annotation, true);
    };

    /**
     * Cancel annotation propagation
     * @param {object} annotation
     */
    service.undoPropagateAnnotation = (annotation: Annotation): void => {
        if (annotation.propagatedInstances.deletedByPageIndex[annotation.page.pageIndex] === true) {
            // Propagation has been cancelled and the original annotation was deleted, so delete annotation altogether
            internals.deleteAnnotation(annotation);
        }
        else {
            internals.setAnnotationIsPropagated(annotation, false);
        }
    };

    /**
     * Update an annotation
     * @param {object} annotation
     * @param {object} page
     * @param {object} properties The properties to be modified
     */
    service.updateAnnotationProperties = (annotation: Annotation, page: Page, properties) => {

        // Derive any additional updates we may need based on what properties are changing
        const currentProps = service.getAnnotationPropertiesForPage({ annotation, page });
        if (properties && properties.fontSize && (!properties.w || !properties.h) && annotation.resizable) {
            // If fontsize has changed, w and h must change
            const percentChange = properties.fontSize / currentProps.fontSize;
            properties.w = currentProps.w * percentChange;
            properties.h = currentProps.h * percentChange;
        }

        // Update annotation
        if (annotation.isPropagated) {
            // Save modified version of the annotation
            const combinedProperties = _.assign(
                {},
                annotation.propagatedInstances.modifiedByPageIndex[page.pageIndex],
                properties
            );
            const copy = internals.copyAnnotation(annotation, combinedProperties, { page }, { isPropagated: false });
            annotation.propagatedInstances.modifiedByPageIndex[page.pageIndex] = copy;
        }
        else {
            _.assign(annotation, properties);
        }
        internals.emitAnnotationModifiedEvent({ annotation, page, properties });
    };

    return service;
};
DocumentAnnotationServiceFactory.$inject = ['$rootScope'];

export default DocumentAnnotationServiceFactory;
