import * as angular from 'angular';
import documentToolbarComponent from './document.toolbar.component';
import documentToolsDropdown from './document.tools.dropdown';
import documentToolbarButton from './document.toolbar.button';
import fontSizeOption from './font.size.option';
import colorOption from './color.option';
import annotationDropdownOption from './annotation.dropdown.option';
import prefillTextPicker from './prefill.text.picker';

const dependencies = [
    annotationDropdownOption.name,
    documentToolbarButton.name,
    documentToolsDropdown.name,
    fontSizeOption.name,
    colorOption.name,
    prefillTextPicker.name
];
const documentToolbar = angular.module('app.shared.documents.documentContentEditor.documentToolbar', dependencies)
    .component('documentToolbar', documentToolbarComponent);

export default documentToolbar;
