import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { getIconClassFromFileExtension } from '@app/shared/documents/get-icon-class-from-file-extension.util';
import { ImportStructureFileInfo } from '@app/components/study-startup/components/import-structure/import-structure.component';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { DocumentLogEntriesService } from '@app/shared/documents-log-entries/document-log-entries.service';
import { take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { GetLogImportRowsPreviewResponse } from '@app/shared/documents-log-entries/document-log-entries.service.types';
import { Column, LogEntryTypes } from '@app/shared/models';
import template from './log-import-rows.component.html';
import styles from './log-import-rows.component.scss';

@Component({
    selector: 'log-import-rows',
    template,
    styles: [String(styles)]
})

export class LogImportRowsComponent {
    @Input() teamId: string;
    @Input() documentId: string;

    fileInfo: ImportStructureFileInfo = null;
    isTutorialVisible = false;
    isProcessing = false;
    isImporting = false;
    data: GetLogImportRowsPreviewResponse = null;
    errorsCount = 0;
    @Output() downloadExcelFile = new EventEmitter<void>();
    @Output() rowsImported = new EventEmitter<void>();


    constructor(
        private modalRef: BsModalRef,
        private Notifications: NotificationsService,
        private LogEntries: DocumentLogEntriesService
    ) { }

    hideModal(): void {
        this.modalRef.hide();
    }

    onFilesAdded($event: FileList): void {
        const file = $event.item(0);

        if (!file) {
            this.clearFile();
            return;
        }

        const iconClass = getIconClassFromFileExtension(file.name);
        let error = null;
        if (iconClass !== 'far fa-file-excel') {
            error = 'File type is not supported';

            this.fileInfo = {
                iconClass,
                fileName: file.name,
                error
            };
        }
        else {
            this.isImporting = true;
            this.LogEntries.getLogImportRowsPreview(this.teamId, this.documentId, file).pipe(take(1)).subscribe(
                (data) => {
                    if (data.isEmpty) {
                        error = 'File is incomplete';
                        this.clearFile();
                    }
                    else {
                        this.data = data;
                        this.errorsCount = this.getErrorsCountFromPreview();
                    }
                    this.isImporting = false;
                    this.fileInfo = {
                        iconClass,
                        fileName: file.name,
                        error
                    };
                },
                (err: HttpErrorResponse) => {
                    this.clearFile();
                    const message = (err && err.error && err.error.message);
                    message ? this.Notifications.error(message) : this.Notifications.unexpectedError();
                    this.isImporting = false;
                    this.fileInfo = {
                        iconClass,
                        fileName: file.name,
                        error
                    };
                }
            );
        }
    }

    clearFile(): void {
        this.data = null;
        this.fileInfo = null;
        this.errorsCount = 0;
    }

    downloadExcelFileStructure() {
        this.downloadExcelFile.emit();
    }

    private getFormatedLogEntries(): Column[][] {
        if (!this.data) {
            return [];
        }

        const logEntries: Column[][] = [];

        this.data.rows.forEach((row) => {
            const logEntry: Column[] = [];

            row.forEach((cell, i) => {
                const column = this.data.columns[i];

                if (column.type !== LogEntryTypes.signature) {
                    logEntry.push({
                        name: column.name,
                        type: column.type,
                        value: cell.value
                    });
                }
            });

            if (logEntry.length) {
                logEntries.push(logEntry);
            }
        });

        return logEntries;
    }

    importRows(): void {
        const logEntries = this.getFormatedLogEntries();
        this.isProcessing = true;

        this.LogEntries.createLogEntries({
            teamId: this.teamId,
            documentId: this.documentId,
            logEntries
        }).subscribe(
            (data) => {
                this.Notifications.success(`${data.length} log row(s) successfully uploaded`);
                this.hideModal();
                this.rowsImported.emit();
            },
            (err: HttpErrorResponse) => {
                const message = (err && err.error && err.error.message);
                message ? this.Notifications.error(message) : this.Notifications.unexpectedError();
                this.isProcessing = false;
            }
        );
    }

    private getErrorsCountFromPreview(): number {
        if (!this.data || !this.data.errExists) {
            return 0;
        }

        const columnsErrorsCount = this.data.columns.reduce((acc, column) => {
            return column.error ? acc + 1 : acc;
        }, 0);

        const rowsErrorsCount = this.data.rows.reduce((rowAcc, row) => {
            const rowErrorsCount = row.reduce((cellAcc, cell) => {
                return cell.error ? cellAcc + 1 : cellAcc;
            }, 0);

            return rowAcc + rowErrorsCount;
        }, 0);

        return columnsErrorsCount + rowsErrorsCount;
    }
}
