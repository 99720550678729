import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { StateService } from '@uirouter/angular';
import { SelectionModel } from '@angular/cdk/collections';

import { ModalHelperService } from '@app/shared/modal-helper/modal-helper.service';
import { BindersService } from '@app/shared/binders/binders.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import {
    Binder, Crumb, Folder, Team, User, UserWithRoles, Role, BrowseNode, TeamPermissions
} from '@app/shared/models';
import { PermissionsService } from '@app/shared/permissions/permissions.service';
import ApiErrorFactory from '@app/shared/api-error/api-error.service';
import { sortBrowseTreeLexicographically } from '@app/widgets/sort/sort-browse-tree-lexicographically.util';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';

import { DownloadPermissionsParams } from '@app/shared/permissions/permissions.service.types';
import { TeamService } from '@app/shared/teams/team.service';
import template from './permission-report.component.html';
import styles from './permission-report.component.scss';
import { DownloadPermissionReportComponent } from '../../components/download-permission-report/download-permission-report.component';

@Component({
    selector: 'permission-report',
    template,
    styles: [String(styles)]
})
export class PermissionReportComponent implements OnInit {
    private readonly loadingTreeMessageDelay = 3000;

    public subject: UserWithRoles | Role;
    public currentTeam: Team;
    public currentUser: User;
    public crumbs: Crumb[];

    public binders: Binder[] = [];
    public visibleBinders: Binder[];
    public teamHasDirectPermissions: boolean;
    public binderSelection = new SelectionModel<Binder>(true);
    public tree: Binder[] = []

    public readonly contentFilters = {
        ALL: { id: 'ALL', name: 'ALL Items' },
        PERM: { id: 'PERM', name: 'ONLY Items with Permissions' },
        DIRECTPERM: { id: 'DIRECTPERM', name: 'ONLY Items with DIRECT Permissions' },
        NOPERM: { id: 'NOPERM', name: 'ONLY Restricted Items' }
    };

    public contentFilterSelection = new SelectionModel<{ id: string; name: string }>(false, [this.contentFilters.ALL]);
    public nameFilter: string;
    public loadingTree: boolean;
    private loadingBinder: boolean;

    constructor(
        private $state: StateService,
        private modalHelper: ModalHelperService,
        private Team: TeamService,
        private Binders: BindersService,
        private CurrentSession: CurrentSessionService,
        private Notifications: NotificationsService,
        private Permissions: PermissionsService,
        private ApiError: ApiErrorFactory,
        private Modals: ModalsService
    ) { }

    ngOnInit(): void {
        const { subjectId, subjectType } = this.$state.params;
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.currentUser = this.CurrentSession.getCurrentUser();

        if (!this.currentTeam || !this._canManagePermissionReport(this.currentTeam.permissions)) {
            this.$state.go('app.select-team');
        }

        if (subjectId) {
            this.Team
                .getPRSubject(this.currentTeam.id, subjectId, subjectType)
                .subscribe((subject) => {
                    this.subject = subject;
                    this.crumbs = this.getCrumbs();
                    this.refreshBinders();
                });

            this.Binders
                .getBinders(this.currentTeam.id)
                .toPromise()
                .then((binders) => {
                    this.binders = sortByLexicographically(binders, 'name');
                    this.binderSelection.select(...this.binders);
                })
                .catch(this.ApiError.handleError);
        }
        else {
            this.crumbs = this.getCrumbs();
        }
    }

    private _canManagePermissionReport(perm: TeamPermissions): boolean {
        return perm.manageTeam || perm.viewTeamUsersRolesPermissions;
    }

    openPermissionInfoModal(item): void {
        this.modalHelper.open({
            component: 'permission-info',
            animation: true,
            size: 'lg',
            resolve: {
                effectivePermissions: () => {
                    return this.Permissions.getEffectivePermissions({
                        subjectId: this.subject.id,
                        subjectType: this.subject.type,
                        objectId: item.id,
                        objectType: item.type,
                        teamId: this.currentTeam.id
                    }).toPromise();
                },
                item: (): Team | Binder | Folder | Document => item,
                subject: (): UserWithRoles | Role => this.subject,
                onlyDirect: (): boolean => false,
                editable: (): boolean => true,
                disableEdit: (): boolean => !this.currentTeam.permissions.manageTeamRolesAndPermissions,
                onUpdate: (): void => this.refreshBinders.bind(this)
            }
        });
    }

    findRolesAndUsers = (filter): ng.IPromise<(User | Role)[]> => {
        return this.Team.searchRolesAndUsers(filter).toPromise();
    }

    toggleBinder(binder: Binder): void {
        this.loadingTree = true;
        this.binderSelection.toggle(binder);
        this.tree = this.getBindersForTree(this.visibleBinders, this.binderSelection.selected);
        setTimeout(() => {
            this.loadingTree = false;
        });
    }

    toggleAllBinders(): void {
        const { selected } = this.binderSelection;

        if (selected.length === this.binders.length) {
            this.binderSelection.deselect(...this.binders);
        }
        else {
            this.binderSelection.select(...this.binders);
        }

        this.tree = this.getBindersForTree(this.visibleBinders, this.binderSelection.selected);
    }


    loadBinder = (binderId: string, binderName: string): ng.IPromise<void | BrowseNode[]> => {
        setTimeout(() => {
            this.loadingBinder && this.Notifications.info('Fetching a lot of documents, it might take us a few more seconds...');
        }, this.loadingTreeMessageDelay);

        this.loadingBinder = true;
        return this.Team.getPrBinderArray(this.currentTeam.id, binderId, true, {
            permFilter: this.contentFilterSelection.selected[0].id,
            subjectId: this.subject.id,
            subjectType: this.subject.type
        }).toPromise().then((data) => {
            return sortBrowseTreeLexicographically(data, 'name').items;
        }).then((data) => {
            this.loadingBinder = false;
            return data;
        })
            .catch((error) => {
                this.loadingBinder = false;
                if (error.error.statusCode === 413) {
                    const message = `${binderName} has over 45,000 documents and is too big to be shown.<br><br>Please try selecting a different Binder or Folder.`;
                    this.Notifications.error(message);
                }
            });
    }

    refreshBinders(): void {
        this.loadingTree = true;
        this.Permissions.getTeamBindersVisibility(this.currentTeam.id, {
            subjectId: this.contentFilterSelection.isSelected(this.contentFilters.ALL)
                ? this.currentUser.id : this.subject.id,
            subjectType: this.contentFilterSelection.isSelected(this.contentFilters.ALL)
                ? this.currentUser.type : this.subject.type,
            directMode: this.contentFilterSelection.isSelected(this.contentFilters.DIRECTPERM),
            restrictedMode: this.contentFilterSelection.isSelected(this.contentFilters.NOPERM)
        }).subscribe(({ visibleBinders, teamHasDirectPermissions }) => {
            this.visibleBinders = Object.values(visibleBinders);
            this.teamHasDirectPermissions = teamHasDirectPermissions;
            this.tree = this.getBindersForTree(this.visibleBinders, this.binderSelection.selected);
            this.loadingTree = false;
        });
    }

    selectContentFilter(filter: { id: string; name: string }): void {
        this.setFilter('');
        this.contentFilterSelection.select(filter);
        this.refreshBinders();
    }

    setFilter(filter: string): void {
        this.nameFilter = filter;
    }

    selectSubject = (subject: User | Role): void => {
        this.$state.go('app.team.permission-report', {
            teamId: this.currentTeam.id,
            subjectId: subject.id,
            subjectType: subject.type,
            filter: this.contentFilterSelection.selected[0].id
        });
    }

    openDownloadModal(format: string): void {
        if (this.contentFilterSelection.isSelected(this.contentFilters.DIRECTPERM)) {
            this.Modals.show(DownloadPermissionReportComponent, {
                initialState: {
                    binders: this.binders,
                    subject: this.subject,
                    format,
                    filterName: this.contentFilterSelection.selected[0].id
                }
            }).content.downloadClicked.subscribe(
                (params: DownloadPermissionsParams) => this.Permissions.downloadPermissions(params)
            );
        }
    }

    private getCrumbs(): Crumb[] {
        const permissionOverviewCrumb = {
            name: 'Permission Overview'
        };
        if (this.subject && this.subject.name) {
            permissionOverviewCrumb.name += ` for ${this.subject.type === 'user' ? 'User' : 'Role'}: ${this.subject.name}`;
        }
        return [
            {
                name: `Manage Team Members: ${this.currentTeam.name}`,
                stateName: 'app.team.manage-team-members',
                stateParams: {
                    teamId: this.currentTeam.id
                }
            },
            permissionOverviewCrumb
        ];
    }

    private getBindersForTree(selectedBinders: Binder[], visibleBinders: Binder[]): Binder[] {
        const treeBinders = _.intersectionBy(selectedBinders, visibleBinders, 'id');
        return sortByLexicographically(treeBinders, 'name');
    }
}
