import controller from './duplicate-role.controller';
import template from './duplicate-role.html';
import './duplicate-role.scss';

const duplicateRoleComponent: ng.IComponentOptions = {
    controller,
    controllerAs: 'vm',
    template,
    bindings: {
        modalInstance: '<',
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
};

export default duplicateRoleComponent;
