import template from './signature.form.field.html';
import controller from './signature.form.field.controller';

const signatureFormFieldComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        doc: '<',
        requestIsPastDue: '<',
        teamSignatureRestricted: '<',
        data: '<',
        signatureFieldPlaceholder: '<',
        page: '<',
        isSelected: '<',
        isReadOnly: '<',
        onSelect: '&',
        onSelectNext: '&',
        onSelectPrevious: '&'
    }
};

export default signatureFormFieldComponent;
