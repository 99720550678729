'use strict';

exports.logTemplateTypes = {
    ELOG: 'elog',
    DOA: 'doa'
};

exports.doaColumnTypes = {
    TEAM_MEMBER: 'team-member',
    ROLE: 'role',
    RESPONSIBILITIES: 'responsibilities',
    START_DATE: 'start-date',
    END_DATE: 'end-date',
    STAFF_SIGNATURE: 'staff-signature',
    PI_SIGNATURE: 'pi-signature'
};
