import './document-page-actions.scss';
import template from './document-page-actions.html';
import controller from './document-page-actions.controller';

const documentPageActionsComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        currentPage: '<?',
        totalPages: '<?',
        zoomFactor: '<?',
        onNavigate: '&',
        onRotate: '&',
        onZoom: '&'
    }
};

export default documentPageActionsComponent;
