import * as _ from 'lodash';

class DocumentViewerRotationService {
    constructor() {
        this.resetState();
    }

    resetState() {
        this.viewportWidth = 0;
        this.zoomFactor = 1;
        this.rotationAngle = 0;
    }

    setViewportWidth(viewportWidth) {
        this.viewportWidth = viewportWidth;
    }

    setZoomFactor(zoomFactor) {
        this.zoomFactor = zoomFactor;
    }

    getZoomFactor() {
        return this.zoomFactor;
    }

    setRotationAngle(rotationAngle) {
        this.rotationAngle = rotationAngle;
    }

    getRotationAngle() {
        return this.rotationAngle;
    }

    calculatePageDimensions(page, previousPage) {
        let translateY = 0;
        const { width, height } = this._verticalPageDimensions(page);

        if (previousPage && (this.rotationAngle === 90 || this.rotationAngle === 270)) {
            const { width: previousPageWidth, height: previousPageHeight } = this._verticalPageDimensions(previousPage);
            translateY = previousPageWidth - previousPageHeight + (previousPage.translateY || 0);
        }
        page.translateY = translateY;

        return {
            transform: `translateY(${translateY}px)`,
            width: `${width}px`,
            height: `${height}px`
        };
    }

    calculatePageRotation(page) {
        const { width, height } = this._verticalPageDimensions(page);
        let translateX = 0;
        let translateY = 0;

        switch (this.rotationAngle) {
            case 90:
                translateY = -height;
                break;
            case 180:
                translateX = -width;
                translateY = -height;
                break;
            case 270:
                translateX = -width;
                break;
            default:
                break;
        }

        return {
            width: `${width}px`,
            height: `${height}px`,
            transformOrigin: '0 0',
            transform: `rotate(${this.rotationAngle}deg) translate(${translateX}px, ${translateY}px)`
        };
    }

    calculateRegionRotation(page, left, top) {
        const { transform } = this.calculatePageRotation(page);
        const { width, height } = this._verticalPageDimensions(page);
        const leftElement = (width * Number(left.replace('%', ''))) / 100;
        const topElement = (height * Number(top.replace('%', ''))) / 100;

        return {
            transformOrigin: `${-leftElement}px ${-topElement}px`,
            transform
        };
    }

    rotateCoordinates({
        width,
        height,
        x,
        y
    }) {
        let verticalHeight = 0;
        let verticalWidth = 0;
        let offsetX = 0;
        let offsetY = 0;
        switch (this.rotationAngle) {
            case 90:
                [verticalHeight, verticalWidth] = [width, height];
                [offsetX, offsetY] = [y, width - x];
                break;
            case 180:
                [verticalHeight, verticalWidth] = [height, width];
                [offsetX, offsetY] = [width - x, height - y];
                break;
            case 270:
                [verticalHeight, verticalWidth] = [width, height];
                [offsetX, offsetY] = [height - y, x];
                break;
            default:
                [verticalHeight, verticalWidth] = [height, width];
                [offsetX, offsetY] = [x, y];
                break;
        }

        return {
            x: _.clamp(offsetX / verticalWidth, 0, 1),
            y: _.clamp(offsetY / verticalHeight, 0, 1)
        };
    }

    _verticalPageDimensions(page) {
        const width = this.viewportWidth * this.zoomFactor;
        const height = page.height / (page.width / width);

        return { width, height };
    }

    calculateFitToWidthZoomFactor(page) {
        let factor;
        const { height, width } = this._verticalPageDimensions(page);
        switch (this.rotationAngle) {
            case 90:
            case 270:
                factor = this.viewportWidth / height;
                break;
            default:
                factor = this.viewportWidth / width;
                break;
        }
        return this.zoomFactor * factor;
    }

    calculateScrollPaneStyle(pages, documentPageActionsExpanded) {
        let contentHeight = 0;
        let contentWidth = 0;

        pages.forEach((page) => {

            const { height, width } = this._verticalPageDimensions(page);
            switch (this.rotationAngle) {
                case 90:
                case 270:
                    contentHeight += width;
                    contentWidth = contentWidth > height ? contentWidth : height;
                    break;
                default:
                    contentHeight += height;
                    contentWidth = contentWidth > width ? contentWidth : width;
                    break;
            }
            contentHeight += 10; // page top margin
        });

        contentHeight += 10; // viewport bottom margin
        contentHeight += 60; // document page actions height
        contentWidth += 20; // page right margin

        return {
            height: `${contentHeight}px`,
            width: `${contentWidth}px`,
            // add padding-top to make space for document-page-actions
            'padding-top': documentPageActionsExpanded ? '60px' : '0px',
            overflow: 'hidden'
        };
    }
}

DocumentViewerRotationService.$inject = [];

export default DocumentViewerRotationService;
