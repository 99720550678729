import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

class FormFieldController {
    constructor(DocumentFormField, CurrentSession, DocumentViewerStore) {
        this._DocumentFormField = DocumentFormField;
        this._CurrentSession = CurrentSession;
        this._DocumentViewerStore = DocumentViewerStore;

        this.destroy$ = new Subject();
    }

    $onInit(): void {
        const currentTeam = this._CurrentSession.getCurrentTeam();
        const { formFieldPlaceholders } = currentTeam.settings;
        const arePlaceholdersDisabled = formFieldPlaceholders.disablePlaceholders || this.doc.isLocked;

        this.textFieldPlaceholder = arePlaceholdersDisabled ? '' : formFieldPlaceholders.textFieldPlaceholder;
        this.signatureFieldPlaceholder = arePlaceholdersDisabled ? '' : formFieldPlaceholders.signatureFieldPlaceholder;

        this._DocumentViewerStore.selectedObject$
            .pipe(takeUntil(this.destroy$))
            .subscribe((obj) => {
                this.isSelected = obj && obj.formField && obj.formField.id === this.data.id;
            });
    }

    $onDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onSelect($event) {
        this._DocumentViewerStore.setSelectedObject({
            annotation: $event.annotation,
            page: $event.page,
            formField: this.data
        });
    }
}

FormFieldController.$inject = [
    'DocumentFormField',
    'CurrentSession',
    'DocumentViewerStore'
];

export default FormFieldController;
