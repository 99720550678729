const AnnotationType = {
    add_text: 'add_text', // eslint-disable-line @typescript-eslint/naming-convention
    redaction: 'redaction',
    highlight: 'highlight',
    stamp: 'stamp',
    signature: 'signature',
    timestamp: 'timestamp'
};

export default AnnotationType;
