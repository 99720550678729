import {
    ChangeDetectionStrategy, Component, OnDestroy, OnInit
} from '@angular/core';
import { StateService } from '@uirouter/core';
import {
    catchError,
    filter, map, take, takeUntil, tap
} from 'rxjs/operators';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { AuditTrailModalComponent } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component';
import template from './doa-log-template-view.component.html';
import styles from './doa-log-template-view.component.scss';
import { CurrentSessionService } from '../../../../core/current-session.service';
import {
    AuditTrail, AuditTrailSubject, Crumb, CursorPaginationResponse, LogTemplate, Team
} from '../../../../shared/models';
import { FeatureFlagService } from '../../../../core/feature-flag.service';
import { FEATURE_FLAGS } from '../../../../core/constants/feature-flags';
import { ROUTES } from '../../../../core/constants';
import { LogTemplatesService } from '../../../../shared/log-templates/log-templates.service';
import { ModalsService } from '../../../../shared/modal-helper/modals.service';
import { LogTemplateInactiveWarningComponent } from '../../components/log-template-inactive-warning/log-template-inactive-warning.component';
import { AuditTrailService } from '../../../../shared/audit-trail/audit-trail.service';
import { AuditTrailTypes } from '../../../audit-trail/components/audit-trail-modal/audit-trail-modal.component.types';
import ApiErrorFactory from '../../../../shared/api-error/api-error.service';

@Component({
    selector: 'doa-log-template-view',
    template,
    styles: [String(styles)],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoaLogTemplateViewComponent implements OnInit, OnDestroy {
    clampLength = 50;
    private readonly destroy$ = new Subject<void>();

    constructor(
        private currentSessionService: CurrentSessionService,
        private $state: StateService,
        private featureFlagService: FeatureFlagService,
        private logTemplatesService: LogTemplatesService,
        private modalsService: ModalsService,
        private auditTrailService: AuditTrailService,
        private apiErrorFactory: ApiErrorFactory
    ) { }

    private doaLogTemplate = new BehaviorSubject<LogTemplate | null>(null);
    doaLogTemplate$ = this.doaLogTemplate.asObservable();

    private isLoading = new BehaviorSubject<boolean>(true);
    isLoading$ = this.isLoading.asObservable();

    canEditForm$ = this.doaLogTemplate$.pipe(
        map((doaLogTemplate) => {
            const { editLogTemplates, createLogTemplates } = this.currentTeam.permissions;

            const hasPermission = editLogTemplates || createLogTemplates;
            const { isLatestVersion } = doaLogTemplate;
            const doesNotHaveReferenceId = !doaLogTemplate?.referenceId;

            return hasPermission && isLatestVersion && doesNotHaveReferenceId;
        })
    );

    currentTeam: Team;
    crumbs: Crumb[] = [];

    ngOnInit(): void {
        const { logTemplateId, version } = this.$state.params;
        this.currentTeam = this.currentSessionService.getCurrentTeam();
        if (!this.currentTeam.settings.features.logTemplates || !this.currentTeam.permissions.viewLogTemplates) {
            this.$state.go(ROUTES.selectTeam);
        }
        this.redirectIfFeatureFlagDisabled();

        if (version) {
            this.getLogTemplateVersion(
                this.currentTeam.id,
                logTemplateId,
                version
            );
        }
        else {
            this.getLogTemplate(this.currentTeam.id, logTemplateId);
        }
    }

    openAuditTrail(): void {
        const doaLogTemplate = this.doaLogTemplate.getValue();

        const auditTrailParams = {
            permissions: this.currentTeam.permissions,
            name: doaLogTemplate.name,
            id: doaLogTemplate.id.templateId,
            teamId: this.currentTeam.id,
            type: AuditTrailTypes.logTemplate
        };

        this.auditTrailService.getAudits({
            subject: AuditTrailSubject.LOG_TEMPLATE,
            teamId: this.currentTeam.id,
            objectId: auditTrailParams.id,
            overwrittenObjectId: null,
            limitToOverwritten: false,
            ...this.auditTrailService.auditPagination
        }).pipe(
            tap((audits: CursorPaginationResponse<AuditTrail>) => {
                this.openAuditTrailsModal(audits, auditTrailParams);
            }),
            catchError((error) => {
                this.apiErrorFactory.handleError(error);
                return of(null);
            })
        ).subscribe();
    }

    private getCrumbs(logTemplate?: Partial<LogTemplate>): Crumb[] {
        const crumbs: Crumb[] = [
            {
                name: 'Log Templates',
                stateName: ROUTES.logTemplatesSelector
            },
            {
                name: 'DOA Log Templates',
                stateName: ROUTES.doaLogs
            },
            {
                name: logTemplate.name
            }
        ];

        return crumbs;
    }

    private getLogTemplate(teamId: string, logTemplateId: string): void {
        this.logTemplatesService.getLogTemplate(teamId, logTemplateId)
            .pipe(
                tap((logTemplate) => {
                    this.onLogTemplateLoaded(logTemplate);
                })
            )
            .subscribe();
    }

    private getLogTemplateVersion(teamId: string, logTemplateId: string, version?: number): void {
        this.logTemplatesService.getLogTemplateVersion(teamId, logTemplateId, version)
            .pipe(
                tap((logTemplate) => {
                    this.onLogTemplateLoaded(logTemplate);
                })
            )
            .subscribe();
    }


    private onLogTemplateLoaded(logTemplate: LogTemplate): void {
        this.redirectIfLogTemplateIsInactive(logTemplate);

        this.crumbs = this.getCrumbs(logTemplate);

        this.doaLogTemplate.next(logTemplate);
        this.isLoading.next(false);
    }

    handleSetVersion(version: number): void {
        this.$state.go(ROUTES.doaLogTemplateViewVersion, {
            logTemplateId: this.doaLogTemplate.getValue().id.templateId,
            version
        });
    }

    private redirectIfLogTemplateIsInactive(logTemplate: LogTemplate): void {
        if (!logTemplate || logTemplate.isActive) {
            return;
        }

        const modalRef = this.modalsService.show(LogTemplateInactiveWarningComponent, {
            animated: true,
            initialState: {}
        });

        modalRef.content.dismiss
            .pipe(take(1))
            .subscribe(() => {
                modalRef.hide();
            });

        this.$state.go(ROUTES.logTemplatesSelector);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private redirectIfFeatureFlagDisabled(): void {
        this.featureFlagService.getFlag(FEATURE_FLAGS.DOA_LOG_TEMPLATES, false)
            .pipe(
                filter((isFlagEnabled) => isFlagEnabled !== undefined),
                tap((isFlagEnabled) => {
                    if (isFlagEnabled === false) {
                        this.$state.go(ROUTES.elogs, { teamId: this.currentTeam.id });
                    }
                }),
                takeUntil(this.destroy$)
            ).subscribe();
    }

    private openAuditTrailsModal(audits, auditTrailParams): void {
        this.modalsService.show(AuditTrailModalComponent, {
            animated: true,
            class: 'modal-lg',
            initialState: {
                data: audits,
                item: auditTrailParams,
                subject: AuditTrailSubject.LOG_TEMPLATE,
                pagination: this.auditTrailService.auditPagination,
                onPageChange: this.auditTrailService.getAudits.bind(this.auditTrailService)
            }
        });
    }
}
