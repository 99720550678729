import * as angular from 'angular';
import documentViewerMessageComponent from './document.viewer.message.component';


const dependencies = [

];
const documentViewerMessage = angular.module('app.documentViewerMessage', dependencies)
    .component('documentViewerMessage', documentViewerMessageComponent);

export default documentViewerMessage;
