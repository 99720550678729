import * as angular from 'angular';

import { downgradeInjectable } from '@angular/upgrade/static';
import AnnotationPropagationService from './annotation-propagation.service';
import { DefaultSigningReasonService } from './default-signing-reason.service';
import DocumentAnnotationAdapterService from './document-annotation-adapter.service';
import DocumentAnnotationService from './document-annotation.service';
import DocumentFormFieldService from './document-form-field.service';
import DocumentPageDisplayService from './document-page-display.service';
import DocumentSignatureFormatService from './document-signature-format.service';
import DocumentViewerPageEventService from './document-viewer-page-event.service';
import DocumentViewerRotationService from './document-viewer-rotation.service';
import DocumentViewerStoreService from './document-viewer-store.service';
import DocumentViewerToolsOptionsService from './document-viewer-tools-options.service';

const dependencies = [];

export default angular.module('app.shared.documents.documentContentEditor.services', dependencies)
    .factory('AnnotationPropagation', AnnotationPropagationService)
    .factory('DefaultSigningReason', downgradeInjectable(DefaultSigningReasonService))
    .factory('DocumentAnnotationAdapter', DocumentAnnotationAdapterService)
    .factory('DocumentAnnotation', DocumentAnnotationService)
    .factory('DocumentFormField', DocumentFormFieldService)
    .factory('DocumentPageDisplay', DocumentPageDisplayService)
    .factory('DocumentSignatureFormat', DocumentSignatureFormatService)
    .service('DocumentViewerPageEvent', DocumentViewerPageEventService)
    .service('DocumentViewerRotation', DocumentViewerRotationService)
    .service('DocumentViewerStore', DocumentViewerStoreService)
    .service('DocumentViewerToolsOptions', DocumentViewerToolsOptionsService);
