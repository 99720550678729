class TimelineSelectionDropdownController {

    handleNavigate(option) {
        this.navigate({ option });
    }

}

TimelineSelectionDropdownController.$inject = [];

export default TimelineSelectionDropdownController;
