import template from './projects-page.html';
import controller from './projects-page.controller';

const projectsPageComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {}
};

export default projectsPageComponent;
