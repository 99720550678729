import {
    Component, OnInit, Output, EventEmitter, Input
} from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
    LogTemplate,
    LogTemplateDetailType,
    Team,
    Document
} from '@app/shared/models';
import { REGEX, MESSAGES } from '@app/core/constants';
import { LogTemplatesService } from '@app/shared/log-templates/log-templates.service';
import { LogColumnSelection, LogDetailSelection } from '@app/shared/documents/documents.service.types';
import { FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';
import { notBlank } from '@app/core/form-validators';

import template from './log-create.component.html';
import { LogCreateEvent, Pagination } from './log-create.component.types';
import styles from './log-create.component.scss';
import { GetLogTemplatesParams, TemplateSortableProperties } from '../../../../shared/log-templates/log-templates.service.types';
import { LogTemplateType } from '../../../log-templates/components/log-template-type-selector/log-template-type-selector.component.types';

@Component({
    selector: 'log-create',
    styles: [String(styles)],
    template
})
export class LogCreateComponent implements OnInit {
    logTemplateTypeEnum = LogTemplateType
    @Input() templateType: LogTemplateType = LogTemplateType.ELOG
    @Input() team: Team;
    @Input() placeholderToFill: Document;

    @Output() save = new EventEmitter<LogCreateEvent>();
    @Output() dismiss = new EventEmitter<void>();

    instructionText = MESSAGES.validNameMessage;
    placeholderHasShortcuts = false;
    placeholderHasTasks = false;
    multiChoiceDetails = [];
    multiChoiceColumns = [];
    form: FormGroup;
    isProcessing = false;
    templates = [];
    loadingTemplates = false;
    hasNext = false;
    currentFilter = '';
    ITEMS_PER_PAGE = 10;
    pagination: Pagination = {
        pageNum: 1,
        pageSize: this.ITEMS_PER_PAGE
    };

    constructor(
        private fb: FormBuilder,
        private LogTemplates: LogTemplatesService
    ) { }

    ngOnInit(): void {
        this.hasShortcutsAndTasks();
        this.initForm();
        this.loadTemplates('');
    }

    hasShortcutsAndTasks() {
        if (this.placeholderToFill
            && this.placeholderToFill.shortcuts
            && this.placeholderToFill.shortcuts.length !== 0) {
            this.placeholderHasShortcuts = true;
        }
        if (this.placeholderToFill
            && this.placeholderToFill.tasks
            && this.placeholderToFill.tasks.length !== 0) {
            this.placeholderHasTasks = true;
        }
    }

    saveForm(): void {
        if (this.isProcessing) {
            return;
        }

        this.isProcessing = true;

        this.save.emit({
            data: {
                name: this.form.controls.name.value,
                templateId: this.form.controls.template.value.id.templateId,
                detailSelections: this.getSelectedDetails(),
                columnSelections: this.getSelectedColumns()
            },
            onSuccess: () => this.dismissModal(),
            onError: () => {
                this.isProcessing = false;
            }
        });
    }

    dismissModal(): void {
        this.dismiss.emit();
    }

    onTemplateSelected(event: FilteredSelectEvent<LogTemplate>): void {
        const [logTemplate] = event.added;
        this.form.controls.template.setValue(logTemplate);
        this.form.controls.template.markAsDirty();
        this.initMultiChoiceOptions(logTemplate);
    }

    onSelectionCleared(): void {
        this.multiChoiceDetails = [];
        this.multiChoiceColumns = [];
        this.form.setControl('details', this.fb.group({}));
        this.form.setControl('columns', this.fb.group({}));
        this.form.controls.template.setValue(null);
    }

    onScrollEnd(): void {
        this.loadTemplates(this.currentFilter, {
            pageNum: this.pagination.pageNum + 1,
            pageSize: this.ITEMS_PER_PAGE
        });
    }

    canViewLogTemplates(): boolean {
        return this.team.permissions.viewLogTemplates;
    }

    getLogTemplatePlaceholder(): string {
        return this.canViewLogTemplates() ? '' : 'You must have permission to view Log Templates to perform this action';
    }

    private initForm(): void {
        this.form = this.fb.group({
            name: [this.placeholderToFill ? this.placeholderToFill.filename : '', [
                Validators.required,
                Validators.pattern(REGEX.names),
                notBlank
            ]],
            template: [null, [Validators.required]],
            details: this.fb.group({}),
            columns: this.fb.group({})
        });
    }

    private initMultiChoiceOptions(logTemplate: LogTemplate): void {
        this.initDetailOptions(logTemplate);
        this.initColumnHeaderOptions(logTemplate);
    }

    private initDetailOptions(logTemplate: LogTemplate): void {
        this.multiChoiceDetails = logTemplate.details.filter(({ type }) => type === LogTemplateDetailType.SELECT);

        const detailsControl = this.multiChoiceDetails.reduce((acc, { name }) => {
            acc[name] = this.fb.control('', Validators.required);
            return acc;
        }, {});

        this.form.setControl('details', this.fb.group(detailsControl));
    }

    private initColumnHeaderOptions(logTemplate: LogTemplate): void {
        this.multiChoiceColumns = logTemplate.columns.filter(({ headerOptions }) => headerOptions && headerOptions.length);

        const columnsControl = this.multiChoiceColumns.reduce((acc, { name }) => {
            acc[name] = this.fb.control('', Validators.required);
            return acc;
        }, {});

        this.form.setControl('columns', this.fb.group(columnsControl));
    }

    private getSelectedDetails(): LogDetailSelection[] {
        return Object.entries((this.form.controls.details as FormGroup).controls)
            .map(([name, formGroup]) => ({
                name,
                selectedOption: formGroup.value
            }));
    }

    private getSelectedColumns(): LogColumnSelection[] {
        return Object.entries((this.form.controls.columns as FormGroup).controls)
            .map(([name, formGroup]) => ({
                name,
                selectedHeader: formGroup.value
            }));
    }

    loadTemplates(filter: string, pagination: Pagination = this.pagination): void {
        this.loadingTemplates = true;

        const getLogTemplatesParams: GetLogTemplatesParams = {
            filter: {
                isActive: true,
                type: this.templateType,
                name: filter
            },
            sortBy: TemplateSortableProperties.NAME_CANONICAL,
            pageNum: pagination.pageNum,
            pageSize: pagination.pageSize
        };

        this.LogTemplates.getLogTemplates(this.team.id, getLogTemplatesParams)
            .pipe(
                tap((data) => {
                    this.loadingTemplates = false;
                    this.templates = this.currentFilter === filter ? this.templates.concat(data.items) : data.items;
                    this.currentFilter = filter;
                    this.hasNext = data.totalCount > this.templates.length;
                    this.setPagination(getLogTemplatesParams.pageNum, data.totalCount);
                }),
                take(1)
            )
            .subscribe();
    }

    onFilterChanged(filter: string): void {
        this.setPagination(1);
        this.loadTemplates(filter, this.pagination);
    }

    private setPagination(pageNum = 1, totalCount?: number): void {
        this.pagination.pageNum = pageNum;
        if (totalCount) {
            this.pagination.totalCount = totalCount;
        }
    }
}
