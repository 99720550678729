import * as _ from 'lodash';

// Color options generated by crossing:
// 11 of hues [0, 23, 42, 60, 80, 145, 190, 210, 250, 280, 300]
// 5 values for every hue [.8, .65, .5, .35, .25]
const colors = [
    '#ff9999', '#ffc099', '#ffe099', '#ffff99', '#ddff99', '#99ffc4', '#99eeff', '#99ccff', '#aa99ff', '#dd99ff', '#ff99ff',
    '#ff4d4d', '#ff914d', '#ffc94d', '#ffff4d', '#c4ff4d', '#4dff97', '#4de1ff', '#4da6ff', '#6a4dff', '#c44dff', '#ff4dff',
    '#ff0000', '#ff6200', '#ffb300', '#ffff00', '#aaff00', '#00ff6a', '#00d5ff', '#0080ff', '#2b00ff', '#aa00ff', '#ff00ff',
    '#b30000', '#b34400', '#b37d00', '#b3b300', '#77b300', '#00b34a', '#0095b3', '#0059b3', '#1e00b3', '#7700b3', '#b300b3',
    '#800000', '#803100', '#805900', '#808000', '#558000', '#008035', '#006a80', '#004080', '#150080', '#550080', '#800080'
];
const neutrals = ['#000000', '#ffffff'];

class ColorOptionController {
    $onInit(): void {
        this.colors = colors;
        this.neutrals = neutrals;
        this.rowItems = 0;
        if (this.options.includeNeutrals) {
            this.rowItems += 2;
        }
        if (this.options.includeTransparent) {
            this.rowItems += 1;
        }
        if (this.options.includeColors) {
            this.rowItems = 11; // Number of color hues
        }
    }

    isTransparent() {
        return this._shouldHandleOpacity() && this.value[this.options.opacityKey] === 0;
    }

    onSelectColor(color) {
        const value = {
            [this.options.colorKey]: color
        };
        if (this._shouldHandleOpacity()) {
            value[this.options.opacityKey] = this.options.defaultOpacity;
        }

        this._handleChange(value);
    }

    onSelectTransparent() {
        const value = {
            [this.options.opacityKey]: 0
        };
        this._handleChange(value);
    }

    _handleChange(value) {
        _.assign(this.value, value);
        this.onChange({ value });
    }


    _shouldHandleOpacity() {
        return !_.isUndefined(this.options.opacityKey);
    }
}

ColorOptionController.$inject = [];

export default ColorOptionController;
