import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

import ToolMode from '../constants/tool.mode';
import FieldType from '../constants/form.field.type';

const annotationType = {
    highlight: 'highlight',
    text: 'text',
    timestamp: 'timestamp',
    signature: 'signature',
    redact: 'redact',
    checkboxField: 'checkboxField',
    textField: 'textField',
    signatureField: 'signatureField'
};

class DocumentViewerToolsOptionsService {
    constructor() {
        const initialState = {
            [annotationType.highlight]: [
                this._initialHighlightColorOption
            ],
            [annotationType.text]: [
                this._initialPrefillOption,
                this._initialBackgroundColorOption,
                this._initialTextColorOption,
                this._initialFontSizeOption
            ],
            [annotationType.timestamp]: [
                this._initialBackgroundColorOption,
                this._initialTextColorOption,
                this._initialFontSizeOption
            ],
            [annotationType.signature]: [
                this._initialBackgroundColorOption,
                this._initialTextColorOption,
                this._initialFontSizeOption
            ],
            [annotationType.redact]: [
                this._initialRedactionOption
            ],
            [annotationType.checkboxField]: [
                this._initialBackgroundColorOption,
                this._initialTextColorOption,
                this._initialFontSizeOption
            ],
            [annotationType.textField]: [
                this._initialBackgroundColorOption,
                this._initialTextColorOption,
                this._initialFontSizeOption
            ],
            [annotationType.signatureField]: [
                this._initialBackgroundColorOption,
                this._initialTextColorOption,
                this._initialFontSizeOption
            ]
        };
        this._options = new BehaviorSubject(initialState);
        this.options$ = this._options.asObservable();
    }

    getOptions() {
        return _.cloneDeep(this._options.getValue());
    }

    setOptions(nextState) {
        this._options.next(nextState);
    }

    getOptionsFor(tool, formField) {
        const optionName = this._resolveOptionName(tool, formField);
        return this.getOptions()[optionName];
    }

    getOptionValuesFor(tool, formField) {
        return this.getOptionsFor(tool, formField).reduce((obj, item) => ({
            ...obj,
            ...item.value
        }), {});
    }

    setOptionsFor(tool, formField, values) {
        const optionName = this._resolveOptionName(tool, formField);
        const state = this.getOptions();

        Object.keys(values).forEach((key) => {
            state[optionName].forEach((toolOption) => {
                if (toolOption.value[key] !== undefined) {
                    toolOption.value[key] = values[key];
                }
            });
        });

        this.setOptions(state);
    }

    _resolveOptionName(tool, formField) {
        if (formField) {
            switch (formField.type) {
                case FieldType.TEXTBOX:
                    return annotationType.textField;
                case FieldType.CHECKBOX:
                case FieldType.RADIO:
                    return annotationType.checkboxField;
                case FieldType.SIGNATURE:
                    return annotationType.signatureField;
                default:
                    return '';
            }
        }
        switch (tool) {
            case ToolMode.ADD_TEXT:
                return annotationType.text;
            case ToolMode.TIMESTAMP:
                return annotationType.timestamp;
            case ToolMode.HIGHLIGHT:
                return annotationType.highlight;
            case ToolMode.REDACT:
                return annotationType.redact;
            case ToolMode.SIGNATURE:
                return annotationType.signature;
            case ToolMode.NONE:
            default:
                return '';
        }
    }

    get _initialPrefillOption() {
        return {
            type: 'prefill',
            enabled: true,
            value: {
                textValue: ''
            }
        };
    }

    get _initialBackgroundColorOption() {
        return {
            type: 'color',
            value: {
                backgroundColor: '#ffffff',
                opacity: 0
            },
            options: {
                colorKey: 'backgroundColor',
                opacityKey: 'opacity',
                defaultOpacity: 0.7,
                label: 'Background Color',
                includeTransparent: true,
                includeColors: true,
                includeNeutrals: true
            }
        };
    }

    get _initialTextColorOption() {
        return {
            type: 'color',
            value: {
                color: '#000000'
            },
            options: {
                colorKey: 'color',
                label: 'Text Color',
                includeColors: true,
                includeNeutrals: true,
                text: 'Aa'
            }
        };
    }

    get _initialFontSizeOption() {
        return {
            type: 'fontSize',
            value: {
                fontSize: 36,
                font: 'Arial',
                lineHeight: 1.1
            }
        };
    }

    get _initialHighlightColorOption() {
        return {
            type: 'color',
            value: {
                backgroundColor: '#ffff00',
                opacity: 0.3
            },
            options: {
                colorKey: 'backgroundColor',
                label: 'Highlight Color',
                includeColors: true,
                includeNeutrals: false
            }
        };
    }

    get _initialRedactionOption() {
        return {
            type: 'redaction',
            value: {
                backgroundColor: '#000000',
                opacity: 1
            }
        };
    }
}

DocumentViewerToolsOptionsService.$inject = [];

export default DocumentViewerToolsOptionsService;
