import * as angular from 'angular';
import toolComponent from './tool.component';

const dependencies = [

];

const tool = angular.module('app.shared.documents.documentContentEditor.documentViewer.submodules.tools.tool', dependencies)
    .component('tool', toolComponent);

export default tool;
