export const FEATURE_FLAGS = {
    EBINDERS_DOCUMENTS_GENERAL_REPORT: 'eBinders-Documents-General-Report',
    EBINDERS_DOCUMENTS_OVERVIEW_CUSTOMER_MESSAGE: 'eBinders-Documents-Overview-Report-Customer-Message',
    EBINDERS_SIP_REPORT: 'eBinders-SIP-report',
    EBINDERS_QC_REPORT: 'eBinders-QC-report',
    EBINDERS_APPROVAL_REPORT: 'eBinders-approval-report',
    EBINDER_REPORT_FOLDER_SEARCH: 'eBinders-report-folder-search',
    EBINDERS_REPORT_TAGS_LABELS_SEARCH: 'eBinders-Reports-Tags-And-Labels-Search',
    UPID_RELATED_TO_MONITOR_GROUP: 'eBinder-UPID-related-to-Monitor-Group',
    STUDY_MANAGER_ENABLED: 'eBinders-study-manager',
    DOA_LOG_TEMPLATES: 'eBinders-doa-log-templates',
    NEW_ACCESS_DATES_REPORT: 'eBinders-Access-Dates-Report-Improvements',
    LOG_TEMPLATE_ENHANCEMENTS: 'ebinders-log-template-enhancements',
    ALL_PLACEHOLDERS_REPORT: 'eBinders-All-Placeholders-Report-Improvements',
    NDV_PAGE_MANIPULATION: 'ndv-page-edit-permission',
    LTA_RETENTION_PERIOD: 'eBinders-LTA-retention-period',
    STUDY_PROFILE_SIDEMENU_ENTRY: 'study-profile-sidemenu-entry',
    EBINDERS_STUDY_SITE_TEAM: 'eBinders-study-site-team'
} as const;
