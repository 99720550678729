import * as angular from 'angular';
import textboxFormFieldComponent from './textbox.form.field.component';

const dependencies = [];

const textboxFormField = angular
    .module('app.textboxFormField', dependencies)
    .component('textboxFormField', textboxFormFieldComponent);

export default textboxFormField;
