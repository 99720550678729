import { Component } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

@Component({ template: '' })
export abstract class AbstractWizardStep {
    constructor(
        protected controlContainer: ControlContainer
    ) {}

    abstract readonly stepFriendlyName: string;
    abstract readonly stepFormControlName: string;

    private isStepValid = new BehaviorSubject<boolean>(false);
    isStepValid$ = this.isStepValid.asObservable();

    private isLoading = new BehaviorSubject<boolean>(false);
    isLoading$ = this.isLoading.asObservable();

    get parentForm() {
        return <FormGroup> this.controlContainer.control;
    }

    get stepFormGroup(): FormGroup {
        return <FormGroup> this.parentForm.get(this.stepFormControlName);
    }

    syncStepValidityWithFormGroupStatusChanges$(): Observable<void> {
        return this.stepFormGroup.statusChanges.pipe(
            distinctUntilChanged(),
            tap((status) => {
                if (status === 'VALID') {
                    this.setIsStepValid(true);
                }
                if (status === 'INVALID') {
                    this.setIsStepValid(false);
                }
            })
        );
    }

    private setIsStepValid(value: boolean): void {
        this.isStepValid.next(value);
    }

    setIsLoading(isLoading: boolean): void {
        this.isLoading.next(isLoading);
    }
}
