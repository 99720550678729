import * as _ from 'lodash';
import { Transition } from '@uirouter/angularjs';
import {
    Crumb,
    Team,
    Timeline,
    Project,
    ApiError
} from '@app/shared/models';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { TimelineWithHref } from './projects-detail-page.types';
import { DestroyProjectOrTimelineComponent } from '../../compontents/destroy-project/destroy-project-or-timeline.component';
import { DestroyProjectOrTimelineType, ProjectOrTimelineDeleteEvent } from '../../compontents/destroy-project/destroy-project-or-timeline.component.types';

class ProjectsDetailPageController {
    // bindings
    public $transition$: Transition;
    crumbs: Crumb[] = [];
    project: Project;
    timelines: TimelineWithHref[];
    selectedTimeline: TimelineWithHref;
    currentTeam: Team;
    teamId: string;
    filter: string;
    loadingData = false;

    constructor(
        $state,
        $filter,
        Team,
        Projects,
        SORT,
        modalHelper,
        CurrentSession,
        Notifications,
        $q,
        private Modals: ModalsService
    ) {
        this._$state = $state;
        this._$filter = $filter;
        this._Projects = Projects;
        this.SORT = SORT;
        this._Teams = Team;
        this._modalHelper = modalHelper;
        this._CurrentSession = CurrentSession;
        this._Notifications = Notifications;
        this._$q = $q;
    }

    $onInit(): void {
        const stateParams = this.$transition$.params();
        this.currentTeam = this._CurrentSession.getCurrentTeam();
        this.teamId = this.currentTeam.id;
        if (!this.canView()) {
            return this._$state.go('app.select-team');
        }

        this.loadingData = true;
        this._$q.all({
            project: this._Projects.getProject(this.teamId, stateParams.projectId).toPromise(),
            timelines: this._Projects.getProjectTimelines(this.teamId, stateParams.projectId).toPromise()
        }).then((data) => {
            this.project = data.project;
            this.timelines = data.timelines.map((timeline: Timeline) => {
                return { ...timeline, href: this.getTimelineHref(timeline) };
            });

            this.crumbs = this._getCrumbs();
            this.updateSort('name', false);
            this.loadingData = false;
        }, ({ error }: ApiError) => {
            this._Notifications.error(error.message || 'Server Error: Please contact your administrator.');
        });
    }

    select(timeline): void {
        if (timeline.selected) {
            timeline.selected = false;
            this.selectedTimeline = undefined;
        }
        else {
            this.timelines.forEach((t) => {
                t.selected = false;
            });
            timeline.selected = true;
            this.selectedTimeline = timeline;
        }
    }

    canCreate(): boolean {
        return this.currentTeam.permissions.createTimeline;
    }

    create(): void {
        if (!this.canCreate()) {
            return;
        }

        this._modalHelper.open({
            component: 'create-timeline-modal',
            animation: true,
            size: 'md',
            resolve: {
                project: () => this.project,
                onCreate: () => (createdTimeline) => this._Projects
                    .createTimeline(this.teamId, createdTimeline)
                    .toPromise()
                    .then((timeline) => {
                        this._Notifications.success(`Timeline "${createdTimeline.name}" created!`);
                        this.navigateToTimeline(timeline);
                    })
                    .catch(({ error }: ApiError) => {
                        this._Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                    })
            }
        });
    }


    edit(): void {
        if (this.canEdit()) {
            this.navigateToTimeline(this.selectedTimeline);
        }
    }

    canEdit(): boolean {
        return this.selectedTimeline && this.currentTeam.permissions.updateTimeline;
    }

    navigateToTimeline(timeline): void {
        this._$state.go('app.team.timeline-update', {
            teamId: timeline.teamId,
            projectId: timeline.projectId,
            timelineId: timeline.id
        });
    }

    canView(): boolean {
        return this._Teams.canViewProjectsAndTimelines();
    }

    remove(): void {
        if (this.canRemove()) {

            const destroyTimelineModal = this.Modals.show(DestroyProjectOrTimelineComponent, {
                animated: true,
                class: 'modal-md',
                initialState: {
                    name: this.selectedTimeline.name,
                    type: DestroyProjectOrTimelineType.TIMELINE
                }
            });

            destroyTimelineModal.content.onDestroy.subscribe((event: ProjectOrTimelineDeleteEvent) => {
                this._Projects.deleteTimeline(this.teamId, this.selectedTimeline)
                    .toPromise()
                    .then(() => {
                        event.onSuccess();
                        this._Notifications.success(`Timeline "${this.selectedTimeline.name}" Deleted!`);
                        this.timelines = _.pull(this.timelines, this.selectedTimeline);
                        this.selectedTimeline = undefined;
                    }, ({ error }: ApiError) => {
                        event.onError();
                        this._Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                    });
            });
        }
    }

    canRemove(): boolean {
        return this.selectedTimeline && this.currentTeam.permissions.deleteTimelines;
    }

    duplicate(): void {
        if (!this.canDuplicate()) {
            return;
        }

        this._modalHelper.open({
            component: 'duplicate-timeline-modal',
            animation: true,
            size: 'md',
            resolve: {
                timeline: () => _.cloneDeep(this.selectedTimeline),
                allProjects: () => this._Projects.getAllProjects(this.teamId).toPromise()
                    .then(
                        (projects) => projects,
                        ({ error }: ApiError) => {
                            this._Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                        }
                    ),
                onDuplicate: () => (duplicatedTimeline) => this._Projects
                    .duplicateTimeline(this.teamId, duplicatedTimeline)
                    .toPromise()
                    .then((timeline) => {
                        const msg = 'Successfully created new timeline <%= timeline.name %>.<br>'
                            + '<strong><a href="<%= urlRoute %>">GO TO TIMELINE</a></strong>';
                        const msgTemplate = _.template(msg);
                        const urlRoute = this._$state.href('app.team.manage-project-timeline', {
                            teamId: timeline.teamId,
                            projectId: timeline.projectId,
                            timelineId: timeline.id
                        });
                        const params = {
                            timeline,
                            urlRoute
                        };

                        this._Notifications.success(msgTemplate(params));
                        this._$state.reload();
                    }, ({ error }: ApiError) => {
                        this._Notifications.error(error.message || 'Server Error: Please contact your administrator.');
                    })
            }
        });
    }

    canDuplicate(): boolean {
        return this.selectedTimeline && this.currentTeam.permissions.duplicateTimelines;
    }


    _getCrumbs(): Crumb[] {
        return [
            {
                name: 'Manage Projects',
                stateName: 'app.team.manage-projects',
                stateParams: { teamId: this.teamId }
            },
            {
                name: this.project.name
            }
        ];
    }

    updateSort(sortName, isReversed): void {
        this.SORT.set(sortName, isReversed);
        this.timelines = this._$filter('orderBy')(this.timelines, sortName, this.SORT.isReversed, this.SORT.naturalSort);
    }

    canActOnSelection(): boolean {
        return this.selectedTimeline
            && (this.canRemove()
                || this.canDuplicate()
                || this.canEdit()
                || this.canView());
    }

    toggleActions($event, timeline): void {
        $event.stopPropagation();
        if (this.selectedTimeline !== timeline) {
            this.select(timeline);
        }
    }

    updateFilter(text: string): void {
        this.filter = text;
    }

    private getTimelineHref(timeline: Timeline): string {
        if (!timeline || !this.canView()) {
            return;
        }

        return this._$state.href('app.team.manage-project-timeline', {
            teamId: this.teamId,
            projectId: timeline.projectId,
            timelineId: timeline.id
        });
    }

    goToTimelineDetails(timeline: Timeline): string {
        if (!timeline || !this.canView()) {
            return;
        }

        this._$state.go('app.team.manage-project-timeline', {
            teamId: this.teamId,
            projectId: timeline.projectId,
            timelineId: timeline.id
        });
    }
}

ProjectsDetailPageController.$inject = [
    '$state',
    '$filter',
    'Team',
    'Projects',
    'SORT',
    'modalHelper',
    'CurrentSession',
    'Notifications',
    '$q',
    'ModalsService'
];

export default ProjectsDetailPageController;
