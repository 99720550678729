import './document-form-save.scss';
import template from './document-form-save.html';
import controller from './document-form-save.controller';

export default {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        modalInstance: '<',
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
};
