import './reports-table.scss';
import template from './reports-table.html';
import controller from './reports-table.controller';

const reportsTableComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        // report is an object with properties: { items, clxn, recordCount }
        report: '<',
        // reportName is a string such as 'Placeholders Due'
        reportName: '<',
        reportParams: '<',
        dateFilterConfig: '<',
        // onTableUpdate is a callback function to handle page or sort updates
        onTableUpdate: '&',
        onAdditionalFiltersUpdate: '&',
        onStartDateUpdate: '&',
        onEndDateUpdate: '&',
        selectedObjects: '<',
        selectedTags: '<',
        selectedLabels: '<',
        selectedSipStatuses: '<',
        selectedQcStatuses: '<',
        selectedStudy: '<',
        selectedMonitorStatuses: '<',
        allTags: '<',
        onTagsSelectionChange: '&',
        allLabels: '<',
        onLabelsSelectionChange: '&',
        allMonitorReviewStatuses: '<',
        onMonitorStatusesSelectionChange: '&',
        allSipStatuses: '<',
        allQcStatuses: '<',
        onSipStatusesSelectionChange: '&',
        onQcStatusesSelectionChange: '&',
        allStudies: '<',
        onStudySelectionChange: '&',
        minWidth: '<'
    }
};

export default reportsTableComponent;
