import * as _ from 'lodash';

class DocumentToolbarButtonController {
    $onInit(): void {
        this.isSelected = _.isUndefined(this.isSelected) ? false : this.isSelected;
    }
}

DocumentToolbarButtonController.$inject = [];

export default DocumentToolbarButtonController;
