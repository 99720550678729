import { string0To255 } from 'aws-sdk/clients/customerprofiles';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DownloadCheckerService } from './download-checker.service';
import { BinderDownloadOptions } from '../../components/binders/containers/binder-download/binder-download.component.types';
import { DocumentDownloadOptions, optionsAreDocumentDownloadOptions } from '../../components/documents/components/document-download/document-download.component.types';
import { FolderDownloadOptions, optionsAreFolderDownloadOptions } from '../../components/folders/components/folder-download/folder-download.component.types';
import { Document, Download, Folder } from '../models';
import {
    DownloadAccessDatesReportParams,
    DownloadAuditTrailParams,
    DownloadLogImportedRowsTemplateParams,
    DownloadReportParams,
    RequestDownloadResponse
} from './downloads.service.types';
import { getFilenameFromHeader } from './get-filename-form-header.util';

@Injectable()
export class DownloadsService {
    readonly url = {
        base: (teamId: string): string => `/api/teams/${teamId}/downloads`,
        get: (teamId: string, downloadId: string): string => `/api/teams/${teamId}/downloads/${downloadId}`,
        download: (teamId: string, downloadId: string): string => `/api/teams/${teamId}/downloads/${downloadId}/download`,
        file: {
            binder: (teamId: string, binderId: string): string => `/api/teams/${teamId}/binders/${binderId}/download`,
            folders: (teamId: string, binderId: string): string => `/api/teams/${teamId}/binders/${binderId}/folders/download`,
            folder: (teamId: string, binderId: string, folderId: string0To255): string => `/api/teams/${teamId}/binders/${binderId}/folders/${folderId}/download`,
            documents: (): string => '/api/documents/download',
            document: (documentId: string): string => `/api/documents/${documentId}/download`
        },
        auditTrail: (teamId: string, objectId: string): string => `/api/teams/${teamId}/auditTrail/${objectId}/download`,
        reports: (teamId: string, reportId: string): string => `/api/teams/${teamId}/reports/${reportId}/download`,
        studyTemplates: (id: string, teamId: string): string => `/api/teams/${teamId}/study-template/${id}/download`,
        accessDatesReport: (teamId: string): string => `/api/teams/${teamId}/user-roles/access-dates-report/download`,
        logImportedRowsTemplate: (teamId: string, logId: string) => `/api/teams/${teamId}/documents/logs/${logId}/log-imported-rows-template`
    };

    constructor(
        private $http: HttpClient,
        @Inject('Window') private $window: Window,
        private DownloadChecker: DownloadCheckerService
    ) { }

    getDownloads(teamId: string): Observable<Download[]> {
        return this.$http.get<{ downloads: Download[] }>(`${this.url.base(teamId)}?limit=100`)
            .pipe(map((data) => data.downloads));
    }

    destroy(teamId: string, downloads: Download[]): Observable<Download[]> {
        const downloadIds = downloads.map((item) => item.id);

        this.DownloadChecker.stopPolling();
        const baseUrl = this.url.base(teamId);
        const httpParams = new HttpParams().set('downloadIds', downloadIds.join(','));

        return this.$http.delete(baseUrl, { params: httpParams })
            .pipe(map((response: Download[]) => {
                this.DownloadChecker.downloadRemoved(response);
                this.DownloadChecker.startPolling();

                return response;
            }));
    }

    downloadItem(teamId: string, download: Download): void {
        this.$http.get<Blob>(this.url.download(teamId, download.id), {
            observe: 'response',
            responseType: 'blob' as 'json'
        })
            .subscribe(
                (response) => {
                    const blob = new Blob([response.body], {
                        type: 'application/octet-stream'
                    });

                    const fileName = getFilenameFromHeader(response.headers.get('content-disposition'));
                    const blobUrl = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.download = fileName;
                    link.click();

                    URL.revokeObjectURL(link.href);
                    this.DownloadChecker.itemDownloaded(download);
                }
            );
    }

    downloadFile(
        options: BinderDownloadOptions | FolderDownloadOptions | DocumentDownloadOptions
    ): Observable<RequestDownloadResponse> {

        const {
            teamId,
            binderId,
            includeReduction = false,
            includeSignature = false,
            includeAllVersionsOfDocuments = false
        } = options;

        let folders: Folder[] = [];
        if (optionsAreFolderDownloadOptions(options)) {

            folders = options.folders;
        }

        let documents: Document[] = [];
        let version: number;
        let contentVersion: number;
        let logDownloadFileType: 'pdf' | 'csv' = 'pdf';
        if (optionsAreDocumentDownloadOptions(options)) {
            documents = options.documents;
            version = options.version;
            contentVersion = options.contentVersion;
            logDownloadFileType = options.logDownloadFileType;
        }

        let baseUrl = '';
        let httpParams = new HttpParams()
            .set('includeSignature', includeSignature.toString())
            .set('includeReduction', includeReduction.toString())
            .set('includeAllVersionsOfDocuments', includeAllVersionsOfDocuments.toString());

        if (folders.length) {
            const folderIds = folders.map((folder) => folder.id);

            if (folderIds.length === 1) {
                baseUrl = this.url.file.folder(teamId, binderId, folderIds[0]);
            }
            else {
                baseUrl = this.url.file.folders(teamId, binderId);
                httpParams = folderIds.reduce((httpParam, param) => {
                    return param ? httpParam.append('folderIds', param) : httpParam;
                }, httpParams);
            }
        }
        else if (documents.length) {
            const documentIds = documents.map((doc) => doc.id as string);
            const isSingleDoc = documentIds.length === 1;
            const isSingleLog = isSingleDoc && documents[0].subType === 'log';
            const shouldPutContentVersion = version === 0 && Number.isInteger(contentVersion);


            httpParams = httpParams.set('teamId', teamId);
            httpParams = httpParams.set('binderId', binderId);

            if (isSingleDoc) {
                baseUrl = this.url.file.document(documentIds[0]);

                httpParams = version !== undefined ? httpParams.set('version', version.toString()) : httpParams;

                if (isSingleLog) {

                    httpParams = httpParams.set('logDownloadFileType', logDownloadFileType);
                }
                if (shouldPutContentVersion) {
                    httpParams = httpParams.set('contentVersion', contentVersion.toString());
                }
            }
            else {
                baseUrl = this.url.file.documents();
                documentIds.forEach((id) => {
                    httpParams = httpParams.append('documentIds', id);
                });
            }
        }
        else {
            baseUrl = this.url.file.binder(teamId, binderId);
        }


        return this.$http.post<RequestDownloadResponse>(baseUrl, null, {
            params: httpParams
        }).pipe(map((response: Download) => {
            this.DownloadChecker.downloadRequested(response);
            return response;
        }));
    }

    downloadAuditTrail(params: DownloadAuditTrailParams | any): Observable<Download> {
        const downloadUrl = this.url.auditTrail(
            params.teamId,
            params.objectId
        );

        return this.$http.post(downloadUrl, {
            subject: params.subject,
            format: params.format,
            name: params.name,
            overwrittenObjectId: params.overwrittenObjectId || ''
        }).pipe(map((response: Download) => {
            this.DownloadChecker.downloadRequested(response);
            return response;
        }));
    }

    downloadReport(params: DownloadReportParams): Observable<Download> {
        const downloadUrl = this.url.reports(
            params.teamId,
            params.reportId
        );

        let httpParams = new HttpParams()
            .set('format', params.format);
        if (params.sortBy) {
            httpParams = httpParams.append('sortBy', params.sortBy);
        }
        if (params.sortDir) {
            httpParams = httpParams.set('sortDir', params.sortDir);
        }
        if (params.filterBy) {
            httpParams = httpParams.set('filterBy', params.filterBy);
        }
        if (params.filterField) {
            httpParams = httpParams.set('filterField', params.filterField);
        }
        if (params.dateFilter) {
            httpParams = httpParams.set('dateFilter', JSON.stringify(params.dateFilter));
        }

        const payload = {
            labelIds: params.labelIds,
            tagIds: params.tagIds,
            sipStatuses: params.sipStatuses,
            qcStatuses: params.qcStatuses,
            monitorStatuses: params.monitorStatuses,
            ...params.studyIds && { studyIds: params.studyIds },
            ...params.binderIds && { binderIds: params.binderIds },
            ...params.folderIds && { folderIds: params.folderIds },
            ...params.additionalFilters && { additionalFilters: params.additionalFilters }
        };

        return this.$http.post(downloadUrl, payload, { params: httpParams })
            .pipe(map((response: Download) => {
                this.DownloadChecker.downloadRequested(response);
                return response;
            }));
    }

    downloadStudyStructureTemplate(params: { id: string; teamId: string }): Observable<Download> {
        const url = this.url.studyTemplates(params.id, params.teamId);

        return this.$http.post(url, {}).pipe(tap((response: Download) => {
            this.DownloadChecker.downloadRequested(response);
        }));
    }

    downloadAccessDatesReport(params: DownloadAccessDatesReportParams): Observable<Download> {
        const { teamId, format, name } = params;
        const downloadPayload = {
            teamId,
            format,
            name
        };

        return this.$http.post(this.url.accessDatesReport(teamId), downloadPayload)
            .pipe(map((response: Download) => {

                this.DownloadChecker.downloadRequested(response);
                return response;
            }));
    }

    downloadLogImportedRowsTemplate(params: DownloadLogImportedRowsTemplateParams): Observable<Download> {
        const { teamId, logId } = params;

        return this.$http.post(this.url.logImportedRowsTemplate(teamId, logId), {})
            .pipe(tap((response: Download) => {
                this.DownloadChecker.downloadRequested(response);
            }));
    }
}
