import './document.viewer.message.scss';
import template from './document.viewer.message.html';
import controller from './document.viewer.message.controller';

const documentViewerMessageComponent: ng.IComponentOptions = {
    template,
    controller,
    controllerAs: 'vm',
    bindings: {
        selectedTool: '<'
    }
};

export default documentViewerMessageComponent;
