import * as _ from 'lodash';
import Actions from '../../../constants/document.content.actions';
import Events from '../../../constants/document.content.events';

class AnnotationBlockController {
    constructor($scope, $window, $timeout, AnnotationPropagation, DocumentViewerPageEvent, DocumentAnnotation) {
        this._$scope = $scope;
        this._$window = $window;
        this._$timeout = $timeout;
        this._AnnotationPropagation = AnnotationPropagation;
        this._DocumentViewerPageEvent = DocumentViewerPageEvent;
        this._DocumentAnnotation = DocumentAnnotation;

        this.styles = {};
        this._globalMouseupListener = this.onMouseUp.bind(this);
    }

    $onInit(): void {
        this.enableMove = _.isUndefined(this.enableMove) ? true : this.enableMove;
        this.id = _.uniqueId();
        this._setupWatchers();
        this._addListeners();
        this._setProperties();
        this._derivePropertiesFromData();
        this.dragStart = null;
        this.lastDrag = null;
    }

    $onDestroy(): void {
        this._removeGlobalMouseupListener();
        this._cleanupListeners();
    }

    getRegionContentStyle() {
        const dynamicStyles = {};

        if (this.isSelected) {
            dynamicStyles.boxShadow = '0 0 0 2px #00ffff';
        }
        if (this.movable) {
            dynamicStyles.cursor = 'move';
        }

        return {
            ...dynamicStyles,
            ...this.styles
        };
    }

    _addGlobalMouseupListener() {
        this._$window.addEventListener('mouseup', this._globalMouseupListener);
        // When dragging off into content around the docviewer, if content gets selected
        // we need to listen for dragend because mouseup will not fire
        this._$window.addEventListener('dragend', this._globalMouseupListener);
    }

    _removeGlobalMouseupListener() {
        this._$window.removeEventListener('mouseup', this._globalMouseupListener);
        this._$window.removeEventListener('dragend', this._globalMouseupListener);
    }

    _addListeners() {
        this._$scope.$on(Events.PAGE_MOUSEMOVE, ($event, { x, y, page }) => {
            if (page.pageIndex !== this.page.pageIndex) {
                return;
            }
            this._handleMouseMove(x, y);
        });
        this._$scope.$on(Events.PAGE_MOUSEUP, this.onMouseUp.bind(this));

        const listenerCleanup = [];
        const annotation = { page: this.page, annotation: this.data };
        listenerCleanup.push(
            this._DocumentAnnotation.onAnnotationModified(annotation, this._setProperties.bind(this))
        );

        this._cleanupListeners = () => {
            listenerCleanup.forEach((cleanup) => {
                cleanup();
            });
        };
    }

    _derivePropertiesFromData() {
        this._setRange();
        this._setStyles();
    }

    _setupWatchers() {
        this._$scope.$watchCollection('vm.properties', () => this._derivePropertiesFromData());
    }

    _setProperties() {
        this.properties = this._DocumentAnnotation.getAnnotationPropertiesForPage({ page: this.page, annotation: this.data });
    }

    _setStyles() {
        const annotationProperties = this.properties;
        const [r, g, b] = this._hexToRgb(annotationProperties.backgroundColor);
        this.styles.backgroundColor = `rgba(${r},${g},${b},${annotationProperties.opacity})`;

        if (this.data.rotation) {
            this.styles.transformOrigin = 'top left';
            this.styles.transform = `rotate(${this.data.rotation}deg)`;
        }
    }

    _setRange() {
        const { x: xOffset, y: yOffset } = this._getDragOffset();
        const bounds = this._getPropagatedBounds();

        if (xOffset || yOffset) {
            bounds.x += xOffset;
            bounds.y += yOffset;

            // Make sure dragging does not cause bounds do not extend beyond the page
            const right = bounds.x + bounds.w;
            if (right > 1) {
                bounds.x -= (right - 1);
            }
            const bottom = bounds.y + bounds.h;
            if (bottom > 1) {
                bounds.y -= (bottom - 1);
            }
            bounds.x = Math.max(0, bounds.x);
            bounds.y = Math.max(0, bounds.y);
        }

        this.range = {
            start: {
                x: bounds.x,
                y: bounds.y
            },
            end: {
                x: bounds.x + bounds.w,
                y: bounds.y + bounds.h
            }
        };
    }

    _getDragOffset() {
        if (!this.enableMove || !this.dragStart || !this.lastDrag) {
            return {
                x: 0,
                y: 0
            };
        }
        return {
            x: this.lastDrag.x - this.dragStart.x,
            y: this.lastDrag.y - this.dragStart.y
        };
    }

    _getPropagatedBounds() {
        // Handle scaling in case this is a propagated annotation for a page of a different size
        const annotationProperties = this._DocumentAnnotation
            .getAnnotationPropertiesForPage({ page: this.page, annotation: this.data });
        const {
            page,
            x,
            y,
            w,
            h
        } = annotationProperties;
        return this._AnnotationPropagation.getPropagatedBounds({
            x,
            y,
            w,
            h
        }, page, this.page);
    }

    onMouseDown($event) {
        if ($event.button === 2 || $event.ctrlKey === true) {
            // ignore right click
            return;
        }
        this._addGlobalMouseupListener();
        // TODO rastasheep
        // this._$scope.$emit(Events.SELECT, { annotation: this.data, page: this.page });

        const { x, y } = this._DocumentViewerPageEvent.getPageDataFromEvent($event);
        this.dragStart = { x, y };
        this.lastDrag = null;

        this.onSelect({
            $event: {
                annotation: this.data,
                page: this.page
            }
        });
    }

    onMouseMove($event) {
        if (!this.dragStart) {
            return;
        }
        const { x, y } = this._DocumentViewerPageEvent.getPageDataFromEvent($event);
        this._handleMouseMove(x, y);
    }

    _handleMouseMove(x, y) {
        if (!this.dragStart) {
            return;
        }
        this.lastDrag = { x, y };
        this._setRange();
    }

    onMouseUp() {
        if (this.dragStart && this.lastDrag) {
            // Calculate new bounds from range
            this._setRange();
            const { x, y } = this.range.start;
            const properties = {
                x,
                y,
                w: this.range.end.x - this.range.start.x,
                h: this.range.end.y - this.range.start.y
            };
            this._$scope.$emit(Actions.ANNOTATION_UPDATE_PROPERTIES, { annotation: this.data, page: this.page, properties });
        }

        this._removeGlobalMouseupListener();
        this.dragStart = null;
        this.lastDrag = null;
    }

    _hexToRgb(hex: string): number[] {
        return hex
            .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
            .substring(1).match(/.{2}/g)
            .map((x) => parseInt(x, 16));
    }
}

AnnotationBlockController.$inject = ['$scope', '$window', '$timeout', 'AnnotationPropagation', 'DocumentViewerPageEvent', 'DocumentAnnotation'];

export default AnnotationBlockController;
