import { downgradeComponent } from '@angular/upgrade/static';
import uiRouter from '@uirouter/angularjs';
import * as angular from 'angular';
import { ChangePasswordComponent } from './components/change-password/change-password.component';

import { UserProfileFormComponent } from './components/user-profile-form/user-profile-form.component';
import userPrivacyConsentComponent from './components/user-privacy-consent/user-privacy-consent.component';
import userSecuritySettingsComponent from './components/user-security-settings/user-security-settings.component';
import userProfileComponent from './containers/user-profile/user-profile.component';
import userConstants from './users.constants';
import userProfileRoute from './users.route';
import { ResetPasswordInfoComponent } from './components/reset-password-info/reset-password-info.component';

const dependencies = [
    uiRouter
];

const userProfile = angular
    .module('app.users', dependencies)
    .component('userProfile', userProfileComponent)
    .directive('changePassword', downgradeComponent({ component: ChangePasswordComponent }) as ng.IDirectiveFactory)
    .component('userSecuritySettings', userSecuritySettingsComponent)
    .component('userPrivacyConsent', userPrivacyConsentComponent)
    .directive('userProfileForm', downgradeComponent({ component: UserProfileFormComponent }) as ng.IDirectiveFactory)
    .directive('resetPasswordInfoComponent', downgradeComponent({ component: ResetPasswordInfoComponent }) as ng.IDirectiveFactory)
    .constant('UserConstants', userConstants)
    .config(userProfileRoute);

export default userProfile;
