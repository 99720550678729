import * as angular from 'angular';
import checkboxFormField from './checkbox.form.field';
import signatureFormField from './signature.form.field';
import textboxFormField from './textbox.form.field';
import formField from './form.field';

const dependencies = [
    checkboxFormField.name,
    signatureFormField.name,
    textboxFormField.name,
    formField.name
];
const annotations = angular.module('app.shared.documents.documentContentEditor.documentViewer.formFields', dependencies);

export default annotations;
