import * as _ from 'lodash';

const ZOOM_FACTORS = [0.25, 1 / 3, 0.5, 2 / 3, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4, 5];
const ROTATION_ANGLES = [0, 90, 180, 270];

class DocumentPageActionsController {
    $onInit(): void {
        this.currentPage = this.currentPage || 1;
        this.rotationAngle = 0;
        this.zoomFactor = this.zoomFactor || 1;
    }

    goToPage(page) {
        this.currentPage = _.clamp(page, 1, this.totalPages);
        this.onNavigate({ $event: this.currentPage });
    }

    rotate(clockwise) {
        let marker = ROTATION_ANGLES.indexOf(this.rotationAngle) || 0;
        marker = (marker + ROTATION_ANGLES.length + (clockwise ? 1 : -1)) % ROTATION_ANGLES.length;
        this.rotationAngle = ROTATION_ANGLES[marker];
        this.onRotate({ $event: this.rotationAngle });
    }

    zoomIn() {
        let newZoomFactor = ZOOM_FACTORS[ZOOM_FACTORS.length - 1];
        for (let i = ZOOM_FACTORS.length - 1; i >= 0; i -= 1) {
            if (ZOOM_FACTORS[i] > this.zoomFactor) {
                newZoomFactor = ZOOM_FACTORS[i];
            }
        }
        this.zoom(newZoomFactor);
    }

    zoomOut() {
        let newZoomFactor = ZOOM_FACTORS[0];
        for (let i = 0; i < ZOOM_FACTORS.length; i += 1) {
            if (ZOOM_FACTORS[i] < this.zoomFactor) {
                newZoomFactor = ZOOM_FACTORS[i];
            }
        }
        this.zoom(newZoomFactor);
    }

    zoom(factor) {
        if (factor) {
            this.zoomFactor = factor;
        }
        this.onZoom({ $event: factor });
    }
}

DocumentPageActionsController.$inject = [];

export default DocumentPageActionsController;
