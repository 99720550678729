import * as _ from 'lodash';
import {
    Team,
    TeamSignatureOptions,
    TeamFormFieldPlaceholders,
    TeamSigningPINPolicy,
    TeamMonitorReviewStatusOptions
} from '@app/shared/models';
import { ModalHelperService } from '@app/shared/modal-helper/modal-helper.service';
import messages from '@app/core/constants/messages';
import regex from '@app/core/constants/regex';
import {
    FormSignatureOptions,
    TeamProfileFormFields,
    SigningPINPolicyFormFields,
    TeamProfileFormErrors,
    UpdatedTeamSettings,
    FormFieldPlaceholders,
    FormFieldErrors,
    MonitorReviewStatusOptions
} from './manage-team-profile.types';

class ManageTeamProfileController {
    canEditProfile: boolean;
    canEditName: boolean;
    canEditPasswordPolicy: boolean;
    canEditSigningPINPolicy: boolean;
    canEditSignatureOptions: boolean;
    canEditMonitorReviewStatus: boolean;
    canEditFormFieldPlaceholders: boolean;
    isProcessing = false;

    fields: TeamProfileFormFields;
    fieldErrors: FormFieldErrors;
    team: Team;
    teamProfileForm: ng.IFormController;
    onUpdate: (params: { updatedFields: UpdatedTeamSettings }) => ng.IPromise<Team>;

    constructor(
        private readonly ModalHelper: ModalHelperService
    ) { }

    $onInit(): void {
        this.setFormFromTeam();
        this._checkAvailablePermissions();
    }

    $onChanges(changes: ng.IOnChangesObject): void {
        if (changes.team) {
            this.setFormFromTeam();
        }
    }

    _checkAvailablePermissions(): void {
        const { permissions } = this.team;
        this.canEditProfile = permissions.updateTeamProfile;
        this.canEditName = permissions.renameTeam;
        this.canEditPasswordPolicy = permissions.managePasswordPolicy && !!this.team.settings.passwordPolicy;
        this.canEditSigningPINPolicy = permissions.manageSigningPINPolicy && !!this.team.settings.signingPINPolicy;
        this.canEditSignatureOptions = !!permissions.manageSignatureOptions;
        this.canEditMonitorReviewStatus = !!permissions.manageMonitorReviewStatus;
        this.canEditFormFieldPlaceholders = !!permissions.manageFormFieldPlaceholders;
    }

    setFormFromTeam(): void {
        this.fields = {
            name: _.get(this.team, 'name', ''),
            timezone: _.get(this.team, 'settings.timezone', null),
            automaticBinderOwnerPermissions: _.get(this.team, 'settings.automaticBinderOwnerPermissions'),
            automaticDocumentOwnerPermissions: _.get(this.team, 'settings.automaticDocumentOwnerPermissions'),
            enablePasswordPolicy: _.get(this.team, 'settings.features.passwordPolicy', false),
            passwordPolicy: _.cloneDeep(_.get(this.team, 'settings.passwordPolicy')),
            signingPINPolicy: _.cloneDeep(_.get(this.team, 'settings.signingPINPolicy')),
            teamSignatureOptions: this.mapSignatureOptionsToForm(),
            monitorReviewStatusOptions: this.mapMonitorReviewOptionsToForm(),
            supportEmail: _.get(this.team, 'settings.support.email', ''),
            supportPhone: _.get(this.team, 'settings.support.phone', ''),
            ...this.mapFormFieldPlaceholdersOptionsToForm()
        };
        this.fieldErrors = {};
    }

    mapMonitorReviewOptionsToForm(): MonitorReviewStatusOptions {
        const monitorReviewConfig = _.get(this, 'team.settings.monitorReviewStatus', {});

        if (!monitorReviewConfig.disableMonitorReviewedStatus && monitorReviewConfig.disableMonitorApprovedStatus) {
            return MonitorReviewStatusOptions.REVIEW;
        }
        if (monitorReviewConfig.disableMonitorReviewedStatus && !monitorReviewConfig.disableMonitorApprovedStatus) {
            return MonitorReviewStatusOptions.APPROVE;
        }

        return MonitorReviewStatusOptions.BOTH;
    }

    mapFormToMonitorReviewOptions(): Partial<TeamMonitorReviewStatusOptions> {

        switch (this.fields.monitorReviewStatusOptions) {
            case 'review':
                return { disableMonitorApprovedStatus: true, disableMonitorReviewedStatus: false };
            case 'approve':
                return { disableMonitorApprovedStatus: false, disableMonitorReviewedStatus: true };
            case 'both':
            default:
                return { disableMonitorApprovedStatus: false, disableMonitorReviewedStatus: false };
        }
    }

    mapSignatureOptionsToForm(): FormSignatureOptions {
        const signatureConfig = _.get(this, 'team.settings.signatures', {});

        if (!signatureConfig.disableAddendum && signatureConfig.disableAnnotation) {
            return FormSignatureOptions.ADDENDUM;
        }
        if (signatureConfig.disableAddendum && !signatureConfig.disableAnnotation) {
            return FormSignatureOptions.ANNOTATION;
        }

        return FormSignatureOptions.BOTH;
    }

    mapFormToSignatureOptions(): Partial<TeamSignatureOptions> {

        switch (this.fields.teamSignatureOptions) {
            case 'addendum':
                return { disableAddendum: false, disableAnnotation: true };
            case 'annotation':
                return { disableAddendum: true, disableAnnotation: false };
            case 'both':
            default:
                return { disableAddendum: false, disableAnnotation: false };
        }
    }

    mapFormFieldPlaceholdersOptionsToForm(): FormFieldPlaceholders {
        const placeholdersConfig = _.get(this, 'team.settings.formFieldPlaceholders', {});

        return {
            enablePlaceholders: !placeholdersConfig.disablePlaceholders,
            signatureFieldPlaceholder: placeholdersConfig.signatureFieldPlaceholder,
            textFieldPlaceholder: placeholdersConfig.textFieldPlaceholder
        };
    }

    mapFormToFormFieldPlaceholdersOptions(): Partial<TeamFormFieldPlaceholders> {
        const oldOptions = _.get(this, 'team.settings.formFieldPlaceholders');
        const newOptions: Partial<TeamFormFieldPlaceholders> = {
            disablePlaceholders: !this.fields.enablePlaceholders,
            textFieldPlaceholder: this.fields.textFieldPlaceholder,
            signatureFieldPlaceholder: this.fields.signatureFieldPlaceholder
        };

        let changes: Partial<TeamFormFieldPlaceholders>;
        const placeholdersNotToggled = oldOptions.disablePlaceholders !== newOptions.disablePlaceholders;
        const placeholdersChanged = oldOptions.signatureFieldPlaceholder !== newOptions.signatureFieldPlaceholder
            || oldOptions.textFieldPlaceholder !== newOptions.textFieldPlaceholder;
        const enabledAndChanged = oldOptions.disablePlaceholders === false
            && newOptions.disablePlaceholders === false && placeholdersChanged;
        if (placeholdersNotToggled || enabledAndChanged) {
            changes = newOptions.disablePlaceholders ? { disablePlaceholders: true } : newOptions;
        }
        return changes;
    }

    onSelectTimezone(value: string): void {
        this.fields.timezone = value;
        if (value) {
            this.teamProfileForm.$setDirty();
        }
    }

    _validateForm(): TeamProfileFormErrors {
        const {
            name,
            enablePlaceholders,
            enablePasswordPolicy,
            passwordPolicy,
            signatureFieldPlaceholder,
            textFieldPlaceholder,
            signingPINPolicy,
            supportEmail,
            supportPhone,
            timezone
        } = this.fields;
        const errors: TeamProfileFormErrors = {
            hasErrors: false,
            field: {}
        };

        if (!timezone) {
            errors.hasErrors = true;
            errors.field.timezone = 'Please choose a timezone.';
        }

        if (!name) {
            errors.hasErrors = true;
            errors.field.name = 'Please choose a name.';
        }
        else if (!regex.names.test(name)) {
            errors.hasErrors = true;
            errors.field.name = `Please choose a valid name.  ${messages.validNameMessage}`;
        }

        if (enablePasswordPolicy) {
            const fields = ['keepLast', 'expiryDays', 'loginAlertsDays', 'emailRemindersDays'];
            fields.forEach((field) => {

                if (parseInt(passwordPolicy[field], 10) > 0) {
                    return;
                }

                errors.hasErrors = true;
                if (!errors.field.passwordPolicy) {
                    errors.field.passwordPolicy = {};
                }
                errors.field.passwordPolicy[field] = 'Please enter a positive number.';
            });
        }

        if (signingPINPolicy.isEnabled) {
            const expiryDays = parseInt(signingPINPolicy.expiryDays, 10);
            const loginAlertsDays = parseInt(signingPINPolicy.loginAlertsDays, 10);
            let signingPINPolicyErrors = {};

            if (expiryDays <= 0) {
                errors.hasErrors = true;
                signingPINPolicyErrors = { ...signingPINPolicyErrors, expiryDays: 'Please enter a positive number.' };
            }
            if (loginAlertsDays <= 0) {
                errors.hasErrors = true;
                signingPINPolicyErrors = { ...signingPINPolicyErrors, loginAlertsDays: 'Please enter a positive number.' };
            }
            errors.field.signingPINPolicy = signingPINPolicyErrors;
        }

        if (enablePlaceholders) {
            if (!signatureFieldPlaceholder) {
                errors.hasErrors = true;
                errors.field.signatureFieldPlaceholder = 'Please enter signature placeholder text.';
            }

            if (!textFieldPlaceholder) {
                errors.hasErrors = true;
                errors.field.textFieldPlaceholder = 'Please enter text field placeholder text.';
            }
        }

        if (supportEmail && !regex.email.test(supportEmail)) {
            errors.hasErrors = true;
            errors.field.supportEmail = 'Please enter a valid email address.';
        }

        if (supportPhone && !regex.phone.test(supportPhone)) {
            errors.hasErrors = true;
            errors.field.supportPhone = 'Please enter a valid phone number.';
        }

        return errors;
    }

    onSubmit(): void {
        const validation = this._validateForm();
        if (validation.hasErrors) {
            this.fieldErrors = validation.field;
            return;
        }
        this.fieldErrors = {};
        this.isProcessing = true;
        const updatedFields = this._getUpdatedFields();

        this.onUpdate({ updatedFields })
            .then(() => {
                this.isProcessing = false;
                this.teamProfileForm.$setPristine();
            });
    }

    _getUpdatedFields(): UpdatedTeamSettings {
        const {
            name,
            enablePasswordPolicy,
            passwordPolicy,
            timezone,
            signingPINPolicy,
            automaticBinderOwnerPermissions,
            automaticDocumentOwnerPermissions,
            supportEmail,
            supportPhone
        } = this.fields;
        const updatedFields: UpdatedTeamSettings = {};

        if (this.team.name !== name) {
            updatedFields.name = name;
        }
        if (this.team.settings.automaticDocumentOwnerPermissions !== automaticDocumentOwnerPermissions) {
            updatedFields.automaticDocumentOwnerPermissions = automaticDocumentOwnerPermissions;
        }
        if (this.team.settings.automaticBinderOwnerPermissions !== automaticBinderOwnerPermissions) {
            updatedFields.automaticBinderOwnerPermissions = automaticBinderOwnerPermissions;
        }
        if (this.team.settings.timezone !== timezone) {
            updatedFields.timezone = timezone;
        }

        if (this.team.settings.support.email !== supportEmail || this.team.settings.support.phone !== supportPhone) {
            _.set(updatedFields, 'support.email', supportEmail);
            _.set(updatedFields, 'support.phone', supportPhone);
        }

        if (_.get(this, 'team.settings.features.passwordPolicy') !== enablePasswordPolicy) {
            updatedFields.enablePasswordPolicy = enablePasswordPolicy;
        }

        if (enablePasswordPolicy) {
            // check for password policy changes only if feature is enabled
            if (!_.isEqual(this.team.settings.passwordPolicy, passwordPolicy)) {
                updatedFields.passwordPolicy = passwordPolicy;
            }
        }

        const updatedSigningPINPolicy = this.getSigningPINPolicyUpdatedFields(signingPINPolicy);
        if (updatedSigningPINPolicy !== null) {
            updatedFields.signingPINPolicy = updatedSigningPINPolicy;
        }

        const newSignatureOptions: Partial<TeamSignatureOptions> = this.mapFormToSignatureOptions();
        const oldSignatureOptions: Partial<TeamSignatureOptions> = _.get(this, 'team.settings.signatures', {});
        if (
            newSignatureOptions.disableAddendum !== oldSignatureOptions.disableAddendum
            || newSignatureOptions.disableAnnotation !== oldSignatureOptions.disableAnnotation
        ) {
            updatedFields.disableAddendum = newSignatureOptions.disableAddendum;
            updatedFields.disableAnnotation = newSignatureOptions.disableAnnotation;
        }

        const newMonitorReviewStatusOptions: Partial<TeamMonitorReviewStatusOptions> = this.mapFormToMonitorReviewOptions();
        const oldMonitorReviewStatusOptions: Partial<TeamMonitorReviewStatusOptions> = _.get(this, 'team.settings.monitorReviewStatus', {});
        if (
            newMonitorReviewStatusOptions.disableMonitorApprovedStatus
            !== oldMonitorReviewStatusOptions.disableMonitorApprovedStatus
            || newMonitorReviewStatusOptions.disableMonitorReviewedStatus
            !== oldMonitorReviewStatusOptions.disableMonitorReviewedStatus
        ) {
            updatedFields.disableMonitorApprovedStatus = newMonitorReviewStatusOptions.disableMonitorApprovedStatus;
            updatedFields.disableMonitorReviewedStatus = newMonitorReviewStatusOptions.disableMonitorReviewedStatus;
        }

        updatedFields.formFieldPlaceholders = this.mapFormToFormFieldPlaceholdersOptions();

        return updatedFields;
    }

    private getSigningPINPolicyUpdatedFields(formFields: SigningPINPolicyFormFields): Partial<TeamSigningPINPolicy> | null {
        const updatedSettings: Partial<TeamSigningPINPolicy> = {};
        const { signingPINPolicy: lastSaved } = this.team.settings;

        if (formFields.isEnabled !== lastSaved.isEnabled) {
            updatedSettings.isEnabled = formFields.isEnabled;
        }
        if (+formFields.expiryDays !== lastSaved.expiryDays) {
            updatedSettings.expiryDays = +formFields.expiryDays;
        }
        if (+formFields.loginAlertsDays !== lastSaved.loginAlertsDays) {
            updatedSettings.loginAlertsDays = +formFields.loginAlertsDays;
        }

        return Object.keys(updatedSettings).length > 0 ? updatedSettings : null;
    }

}

ManageTeamProfileController.$inject = [
    'modalHelper'
];

export default ManageTeamProfileController;
