import * as angular from 'angular';
import component from './selection.tool.component';

const dependencies = [

];

const createFromSelectionTool = angular.module('app.shared.documents.documentContentEditor.documentViewer.submodules.tools.selectionTool', dependencies)
    .component('selectionTool', component);

export default createFromSelectionTool;
