import * as _ from 'lodash';

/**
 * Handles tracking document pages for display.  Keeps track of the original size of a page, and its display
 * size on the screen.  Tracks display state for each page as well (whether it should be loaded, is already loaded, etc).
 * @returns {{}}
 * @constructor
 */
const DocumentPageDisplayServiceFactory = () => {
    const service = {};
    const internals = {};

    service.initialize = (doc) => {

        internals.pages = doc.pages.map((page, index) => ({
            id: `${doc.id}-page-${index}`,
            loaded: false,
            shouldDisplay: false,
            pageIndex: index,
            pageNumber: page.number,
            url: page.url,
            element: null
        }));
    };

    /**
     * Returns page at the given index.  Index is equal to (pageNumber - 1).
     * @param {number} pageIndex
     * @returns {object}
     */
    service.getPage = (pageIndex) => internals.pages[pageIndex];

    service.getPages = () => internals.pages;

    service.getPageId = (pageIndex) => internals.pages[pageIndex].id;

    /**
     * Return the number of pages associated with the document this service was initialized with
     * @returns {Number}
     */
    service.getPageCount = () => internals.pages.length;

    service.getLastPageIndex = () => service.getPageCount() - 1;

    service.setPageShouldLoad = (pageIndex) => {
        const page = internals.pages[pageIndex];
        if (page.loaded) {
            // Already loaded
            return;
        }
        page.isLoading = true;
        page.shouldDisplay = true;
    };

    /**
     *
     * @param {number} pageIndex
     * @param {HTMLImageElement} imageElement - The element representing the page, used to get client dimensions
     */
    service.setPageHasLoaded = (pageIndex, imageElement) => {
        const page = internals.pages[pageIndex];
        page.loaded = true;
        page.isLoading = false;
        // Width and height represent the full resolution of the page - its original dimensions
        page.width = imageElement.naturalWidth;
        page.height = imageElement.naturalHeight;
        page.element = imageElement;

        internals.pages.forEach((p) => {

            if (!p.loaded && !p.isLoading) {
                // Set width & height for pages which are not loaded, so they can occupy sufficient space
                // This is a hack to fix the problems with page pagination,
                // as we cant predict page width/height without loading the image,
                // so we assume that all pages have same width/height (ratio).
                // Proper way would be to store width & height for every image, so we can have its dimensions in advance
                p.width = imageElement.naturalWidth;
                p.height = imageElement.naturalHeight;
            }
        });
    };

    /**
     *
     * @param {Number} pageIndex
     * @param {HTMLImageElement} imageElement
     */
    service.setPageLoadError = (pageIndex, imageElement) => {
        imageElement.src = '';

        const page = service.getPage(pageIndex);
        page.shouldDisplay = false;
        page.showError = true;
        page.loaded = true;
        page.isLoading = false;
    };

    service.isAnyPageLoading = () => _.some(internals.pages, 'isLoading');

    service.hasPageLoaded = (pageIndex) => internals.pages[pageIndex].loaded;

    return service;
};

DocumentPageDisplayServiceFactory.$inject = [];

export default DocumentPageDisplayServiceFactory;
