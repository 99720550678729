import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import layoutRoute from './layout.route';
import navbar from './components/navbar/navbar.component';
import appLayoutComponent from './containers/app-layout/app-layout.component';

const dependencies = [
    uiRouter
];

const layoutModule = angular
    .module('app.layout', dependencies)
    .component('appLayout', appLayoutComponent)
    .component('navbar', navbar)
    .config(layoutRoute);

export default layoutModule;
