import * as _ from 'lodash';

class AnnotationDropdownOptionController {
    $onInit(): void {
        this._setDisplayValue();
    }

    _setDisplayValue() {
        this.displayValue = _.get(this._getSelectedOption(), 'display', `Choose ${this.label}`);
    }

    _getOptionByValue(value) {
        return _.find(this.options, { value });
    }

    _getSelectedOption() {
        return this._getOptionByValue(this._getDropdownValue());
    }

    _getDropdownValue() {
        return this.value[this.valueKey];
    }

    _setDropdownValue(value) {
        this.value[this.valueKey] = value;
        this._setDisplayValue();
    }

    onSelect(selected) {

        // Update our value
        this._setDropdownValue(selected);
        // Broadcast change
        this.onChange({ value: this.value });
    }

}

AnnotationDropdownOptionController.$inject = [];

export default AnnotationDropdownOptionController;
